import { validateCNPJ, validateCPF } from 'validations-br';
import { formatDocument } from '../../../utils/formatDocument';

export function formatType(type) {
  switch (type) {
    case 2:
      return {
        text: 'Sucesso',
        color: 'var(--green-status)',
        id: 2,
      };
    case 3:
      return {
        text: 'Erro',
        color: 'var(--red-status)',
        id: 3,
      };

    case 4:
      return {
        text: 'Pronto',
        color: 'var(--blue-status)',
        id: 4,
      };

    case 6:
      return {
        text: 'Corrigir',
        color: 'var(--orange-status)',
        id: 6,
      };
    default:
      return {
        text: 'Indefinido',
        color: 'var(--red-status)',
        id: 98,
      };
  }
}
export function validateName(value) {
  let text = value.toString().toLowerCase();
  if (text?.length < 8) {
    return false;
  }
  text = text.trim();

  const words = text.split(' ');

  if (words?.length >= 2) {
    return true;
  }

  return false;
}
function stringToNumber(value) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const formattedValue = value.replace(/\./g, '').replace(',', '.');

    const numericValue = parseFloat(formattedValue);

    if (!isNaN(numericValue)) {
      return numericValue;
    }
  }

  throw new Error(
    'O valor passado não é um tipo numérico válido ou uma string válida para conversão.',
  );
}

const checkDocumentInList = (user, list) => {
  return list.some((item) => {
    if (user?.cnpj || user?.cnpj === ' ') {
      return item.cpf === user.cpf && item.cnpj === user.cnpj;
    } else {
      return item.cpf === user.cpf;
    }
  });
};

export function formatUserTypes(user, listActive = []) {
  let alert = [];
  let erros = [];
  if (user?.cnpj) {
    if (checkDocumentInList(user, listActive)) {
      alert.push('dup-active-cnpj');
    }
  } else {
    if (checkDocumentInList(user, listActive)) {
      alert.push('dup-active-cpf');
    }
  }

  if (user?.response?.id) {
    erros.push('res');
  }
  if (user?.cnpj) {
    if (validateCNPJ(user?.cnpj) === false) {
      erros.push('cnpj');
    }
  }
  if (validateCPF(user?.cpf) === false) {
    erros.push('cpf');
  }
  if (validateName(user?.name) === false) {
    erros.push('name');
  }
  if (user?.NOME_DO_AGENCIADOR) {
    if (validateName(user?.NOME_DO_AGENCIADOR) === false) {
      erros.push('name-ag');
    }
  }

  if (user?.DOCUMENTO_DO_AGENCIADOR) {
    if (
      user?.DOCUMENTO_DO_AGENCIADOR.toString().replace(/\D/g, '')?.length <= 11
    ) {
      if (validateCPF(user?.DOCUMENTO_DO_AGENCIADOR) === false) {
        erros.push('AGENCIADOR-PF');
      }
    } else {
      if (validateCNPJ(user?.DOCUMENTO_DO_AGENCIADOR) === false) {
        erros.push('AGENCIADOR-PJ');
      }
    }
  }
  let type = erros?.length === 0 || !erros ? formatType(4) : formatType(6);

  if (user?.DOCUMENTO_DO_AGENCIADOR) {
    return {
      ...user,
      DOCUMENTO_DO_AGENCIADOR: user?.DOCUMENTO_DO_AGENCIADOR
        ? formatDocument(
            user?.DOCUMENTO_DO_AGENCIADOR.toString().replace(/\D/g, ''),
          )
        : false,
      cpf: user?.cpf
        ? formatDocument(user?.cpf.toString().replace(/\D/g, ''))
        : false,
      phone_number: user?.phone_number
        ? user?.phone_number.toString()
        : user?.phone_number,
      cnpj: user?.cnpj
        ? formatDocument(user?.cnpj.toString().replace(/\D/g, ''))
        : false,
      type,
      erros,
      alert,
    };
  }
  if (user?.COMODATO) {
    return {
      ...user,
      COMODATO: user?.COMODATO,
      cpf: user?.cpf
        ? formatDocument(user?.cpf.toString().replace(/\D/g, ''))
        : false,
      phone_number: user?.phone_number
        ? user?.phone_number.toString()
        : user?.phone_number,
      cnpj: user?.cnpj
        ? formatDocument(user?.cnpj.toString().replace(/\D/g, ''))
        : false,
      type,
      erros,
      alert,
    };
  }
  if (user?.VALOR_DO_SERVICO) {
    return {
      ...user,
      VALOR_DO_SERVICO: user?.VALOR_DO_SERVICO
        ? stringToNumber(user?.VALOR_DO_SERVICO)
        : stringToNumber(0.0),
      cpf: user?.cpf
        ? formatDocument(user?.cpf.toString().replace(/\D/g, ''))
        : false,
      phone_number: user?.phone_number
        ? user?.phone_number.toString()
        : user?.phone_number,
      cnpj: user?.cnpj
        ? formatDocument(user?.cnpj.toString().replace(/\D/g, ''))
        : false,
      type,
      erros,
      alert,
    };
  }
  if (user?.VALOR_DA_DIARIA) {
    return {
      ...user,
      VALOR_DA_DIARIA: user?.VALOR_DA_DIARIA
        ? stringToNumber(user?.VALOR_DA_DIARIA)
        : stringToNumber(0.0),
      VALOR_DA_DIARIA_AJUDANTE: user?.VALOR_DA_DIARIA_AJUDANTE
        ? stringToNumber(user?.VALOR_DA_DIARIA_AJUDANTE)
        : stringToNumber(0.0),
      cpf: user?.cpf
        ? formatDocument(user?.cpf.toString().replace(/\D/g, ''))
        : false,
      phone_number: user?.phone_number
        ? user?.phone_number.toString()
        : user?.phone_number,
      cnpj: user?.cnpj
        ? formatDocument(user?.cnpj.toString().replace(/\D/g, ''))
        : false,
      type,
      erros,
      alert,
    };
  } else {
    // eslint-disable-next-line
    return {
      ...user,
      cpf: user?.cpf
        ? formatDocument(user?.cpf.toString().replace(/\D/g, ''))
        : false,
      phone_number: user?.phone_number
        ? user?.phone_number.toString()
        : user?.phone_number,
      cnpj: user?.cnpj
        ? formatDocument(user?.cnpj.toString().replace(/\D/g, ''))
        : false,
      type,
      erros,
      alert,
    };
  }
}

export function formatErrorResponse(id) {
  const error = [
    {
      id: 16,
      message: 'Ja existe um usuário cadastrado',
    },
    {
      id: 25,
      message: 'Contrato não encontrado',
    },
    {
      id: 54,
      message: 'CPF não está associado ao CNPJ referenciado',
    },
    {
      id: 55,
      message: 'CNPJ irregular com a Receita Federal',
    },
  ];

  return error.find((item) => item.id === id);
}

export function findDuplicates(list) {
  const duplicateInfo = [];

  list.forEach((object, index) => {
    list.slice(index + 1).forEach((otherObject) => {
      if (object.cpf === otherObject.cpf) {
        const duplicateFields = [];

        if (object.cpf === otherObject.cpf) {
          duplicateFields.push('dup-cpf');
        }
        duplicateInfo.push({
          ids: [object.id, otherObject.id],
          duplicateFields,
        });
      }
    });
  });

  return list.map((item) => {
    const duplicateEntry = duplicateInfo.find((info) =>
      info.ids.includes(item.id),
    );

    const erros = duplicateEntry
      ? [...item?.erros, ...duplicateEntry.duplicateFields]
      : item?.erros;

    return {
      ...item,
      erros,
      type: item?.response?.id
        ? item?.type
        : erros?.length === 0
        ? formatType(4)
        : formatType(6),
    };
  });
}
