import React, { useState } from 'react';

import { MdDelete } from 'react-icons/md';
import { formatMoney } from '../../../../../../utils/formatMoney';
import styles from './styles.module.scss';
import FormGroup from './FormGroup';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { Loader } from 'rsuite';
import { useUser } from '../../../../../../hooks/useUser';
import { formatDate } from '../../../../../../utils/formatDate';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useOrder } from '../../../../../../hooks/useOrder';

import { useHistory } from 'react-router-dom';
import { usePayment } from '../../../../../../hooks/usePayment';
import { useNotification } from '../../../../../../hooks/useNotifications';
import { formatFileSize } from '../../../../../GroupOnboarding/ExcelReader/utils';
import DropZone from 'react-drop-zone/dist/DropZone';
import {
  IconAlert,
  IconDate,
  IconLike,
  IconMoney,
  IconPerson,
  IconProof,
} from '../icons';
import { IconBook, IconBookLine } from './icons';

const Validate = yup.object().shape({
  description: yup
    .string()
    .typeError('O campo deve receber uma descrição.')
    .required('Descrição é obrigatória'),
  is_salary: yup.string().notRequired(),
});

const PaymentFile = () => {
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,

    file,
    setFile,
    errosFile,
    setErrosFile,
  } = usePayment();

  const { addNotification } = useNotification();

  const [clear] = useState(false);

  const { user } = useUser();
  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );

  const {
    fetchTransferConsignment,
    fetchOrderKeyValidation,
    fetchOrderKey,
    fetchDataOrderKey,
    fetchOrderKeyCancel,
    fetchOrderPreview,
  } = useOrder();
  const history = useHistory();
  const handleChangeFile = (file) => {
    if (
      [
        'application/vnd.ms-excel.sheet.macroenabled.12',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.ms-exce',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
      ].includes(file.type)
    ) {
      setFile(file);
      setErrosFile(false);
    } else {
      addNotification(
        <>Aquivo de formato invalido.</>,
        2,
        'Upload de Arquivo',
        true,
      );
      setFile(false);
    }
  };
  const handleCancelOrder = async () => {
    try {
      setLoading({ paymentOrderCancel: true });
      if (payment) {
        await fetchOrderKeyCancel(payment.key, 3);
      }
      clearStatePayment();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderKey = () => {
    const orderKey = payment?.key ? payment.key : finishPayment.key;

    history.push({
      pathname: `/menu/order/${orderKey}`,
      state: payment?.duplicate ? { duplicate: 99 } : {},
    });

    clearStatePayment();
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(Validate),
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
    },
  });

  const onSubmit = async (values) => {
    const { description } = values;
    try {
      setLoading({ paymentOrder: true });
      if (!file) {
        setPayment({
          is_salary: !values.is_salary,
          description: values.description,
          date: values.date,
        });
        setErrosFile('Arquivo é obrigatório');
        setLoading(false);
        return;
      }

      const formData = new FormData();

      formData.append('file', file);
      formData.append('Description', !!description ? description : 'Pagamento');

      const order = await fetchTransferConsignment({
        body: formData,
        isManual: false,
        isSalary: true,
      });

      const { movements, duplicate } = await fetchOrderKey(order.key);

      const fee = await movements.reduce((acc, item) => {
        return item?.bank_code === 'OX'
          ? acc + user.company.internal_transfer_fee
          : item?.bank_code === false
          ? acc + user.company.transfer_fee
          : acc + user.company.transfer_fee;
      }, 0);

      if (!!order) {
        setPayment({ ...order, fee, duplicate });
        setStepPayment(2);
        setErrosSteps({
          status: 'process',
        });
      } else {
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Documento não encontrado',
        });

        addNotification(
          <>
            Não foi encontrado dados do documento informado! <br />
            Por favor, verifique o CPF/CNPJ e tente novamente.
          </>,
          2,
          'Conta não encontrada',
          true,
        );
      }
    } catch (error) {
      if (description) {
        setPayment(values);
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    setLoading({ paymentOrder: true });

    try {
      if (payment.key) {
        await fetchOrderPreview(payment?.key);
        await fetchOrderKeyValidation(payment.key, 3);
        const { order } = await fetchDataOrderKey(payment.key);
        if (order) {
          setFinishPayment(order);
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <div className={styles.wrapper_drop}>
              <DropZone
                register={register}
                name="file"
                onDrop={(files) => handleChangeFile(files)}
              >
                {({ over }) => (
                  <div className={styles.drop_zone}>
                    {over ? (
                      'Clique aqui ou arraste o arraste o arquivo'
                    ) : file ? (
                      <div className={styles.box_file}>
                        <div>
                          <h3> {file.name} </h3>
                          <p> {formatFileSize(file.size)}</p>
                        </div>
                        <button onClick={() => setFile(false)}>
                          <MdDelete size={'1.5rem'} color={'var(--gray-300)'} />
                        </button>
                      </div>
                    ) : (
                      <div className={styles.drop}>
                        <IconBookLine /> Arraste o arquivo aqui
                      </div>
                    )}
                  </div>
                )}
              </DropZone>
            </div>
            {errosFile && <span className={styles.error}>{errosFile}</span>}
            <div className={styles.wrapper_form_button}>
              <div className={styles.wrapper_radio_}>
                <FormGroup
                  name="description"
                  label={
                    <>
                      <strong>Descrição</strong>
                    </>
                  }
                  type="text"
                  placeholder={'Antecipação'}
                  disabled={!loading?.paymentOrder ? false : true}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  clean={clear}
                  data={{ value: payment?.description }}
                  Icon={IconBook}
                />
              </div>
              <div className={styles.buttons_}>
                <button
                  type="button"
                  disabled={loading.paymentOrder}
                  className={styles.button_cancel}
                  onClick={clearStatePayment}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={styles.button}
                  disabled={loading.paymentOrder}
                >
                  Confirmar
                  {loading.paymentOrder && <Loader size="xs " />}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>Foi criado um lote de antecipação:</h1>

          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description}>
                  {payment?.duplicate?.res && (
                    <>
                      <div className={styles.alert}>
                        <IconAlert />

                        <span>
                          <strong> Atenção:</strong> Dados em duplicidade
                        </span>
                        <span>
                          <strong> Quantidade:</strong>
                          <p>{payment?.duplicate.data.length}</p>
                        </span>

                        <button onClick={handleOrderKey}>Verificar</button>
                      </div>
                    </>
                  )}
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>
                        Total de Colaboradores:
                      </div>
                      {payment.total_moviments === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.total_moviments}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(payment.total_amount)}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Data:</div>

                        <div className={styles.title_description}>
                          {formatDate(payment.created_at, 'dd/MM/yyyy')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Arquivo: <p> {payment.file_name} </p>
                    </span>

                    <span>
                      Descrição: <p> {payment.description} </p>
                    </span>
                  </div>
                </div>

                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(Number(payment.total_amount))}
                    </span>
                    <div className={styles.fee}></div>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={handleCancelOrder}
                    >
                      {loading.paymentOrderCancel && <Loader size="xs " />}
                      Cancelar
                    </button>
                    {payment.status.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Antecipação finalizada!'
                    : 'Antecipação Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar sua
                      antecipação.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa antecipação.
                    </>
                  )}
                </p>
                <div className={styles.buttons_finish}>
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentFile };
