class FormatNameString {
  constructor() {
    this.now = new Date();
  }

  pad(num) {
    return String(num).padStart(2, '0');
  }

  formatNameDateTime() {
    const day = this.pad(this.now.getDate());
    const month = this.pad(this.now.getMonth() + 1); // getMonth() is zero-based
    const year = this.now.getFullYear();
    const hours = this.pad(this.now.getHours());
    const minutes = this.pad(this.now.getMinutes());
    const seconds = this.pad(this.now.getSeconds());

    return `Relatório_extrato_ofx_${day}_${month}_${year}__${hours}_${minutes}_${seconds}.ofx`;
  }
}

export { FormatNameString };
