import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

export const CardResumeSelect = ({ resume }) => {
  return (
    <div className={styles.card}>
      <div className={styles.total}>
        <span className={styles.amount}>
          {formatMoney(resume?.total_amount)}
        </span>
        <p className={styles.label}>valor total</p>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.quantity}>
        <span className={styles.qty}>
          {resume?.total_order > 10
            ? resume?.total_order
            : '0' + resume?.total_order}
        </span>
        <p className={styles.label}>quantidade</p>
      </div>
    </div>
  );
};
