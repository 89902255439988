function Icon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M6 20c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 014 18v-3h2v3h12v-3h2v3c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0118 20H6zm6-4l-5-5 1.4-1.45 2.6 2.6V4h2v8.15l2.6-2.6L17 11l-5 5z"
      ></path>
    </svg>
  );
}

export { Icon };
