import { addDays } from 'date-fns';

export function handleDataRanger(dateInitial, dateFinally) {
  let startDateString = addDays(dateInitial, 1).toISOString().substring(0, 10);

  let endDateString = addDays(dateFinally, 1).toISOString().substring(0, 10);

  let startDate = new Date(startDateString);

  startDate.setHours(-3, 0, 0);
  startDate = startDate.toISOString();

  let endDate = new Date(endDateString);

  endDate.setHours(20, 59, 59);
  endDate = endDate.toISOString();

  return {
    dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
    dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
  };
}
