import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormGroup from '../../components/Forms/FormGroup';
import { Loading } from '../../components/UI/Loading';
import { useCustomers } from '../../hooks/useCustomers';
import styles from './styles.module.scss';

import { ValidateFormCustomer } from '../../Helpers/validatePasswordReset';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '../../hooks/useUser';
import { Link } from 'react-router-dom';

const PasswordReset = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidateFormCustomer),
  });
  const { user, setDropMenu, dropMenu } = useUser();
  const { loading, fetchPasswordReset } = useCustomers();

  async function handlerFetch(data) {
    await fetchPasswordReset({
      current_password: data.password_old,
      new_password: data.password,
      customer_id: user.customer_id,
      first_name: user.first_name,
    });
  }

  useEffect(() => {
    !dropMenu && setDropMenu(true);
  });

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1>Alterar Senha</h1>
        <form onSubmit={handleSubmit(handlerFetch)} className={styles.form}>
          <FormGroup
            name="password"
            label="Nova Senha"
            type="password"
            data={{ password: null }}
            disabled={false}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="password_confirm"
            label="Confirmar Senha"
            type="password"
            data={{ password_confirm: null }}
            disabled={false}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="password_old"
            label="Senha Atual"
            type="password"
            data={{ password_old: null }}
            disabled={false}
            eyes={true}
            register={register}
            errors={errors}
          />
          {!loading?.add ? (
            <div className={styles.wrapper_button}>
              <button
                style={
                  errors === false ? { display: 'none' } : { display: 'inline' }
                }
                className={styles.button}
                type="submit"
              >
                Confirmar
              </button>
              <Link className={styles.button} to="/menu/profile">
                Cancelar
              </Link>
            </div>
          ) : (
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loading />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
