import { useEffect, useRef, useState } from 'react';

import styles from './styles/styles.module.scss';
import FormCard from './components/FormCard';

import {
  ConfirmPurchase,
  PaymentInfo,
  Document,
  Preview,
} from './components/Forms';
import FormCompleted from './components/FormCompleted';
import FormProvider from '../../data/formContext';
import ButtonGoBack from '../../components/ButtonGoBack';

const Payment = () => {
  const [formStep, setFormStep] = useState(0);
  const clearFormSteps = () => {
    setFormStep(0);
  };
  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);

  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={scrollRef} className={styles.container}>
      <div className={styles.back}>
        <ButtonGoBack />
      </div>
      <div className={styles.box}>
        <div className={styles.header}>
          <h2>Adicionar colaborador</h2>
          <p>
            Adicione um colaborador para fazer parte do seu banco de dados e ter
            acesso a uma conta digital exclusiva para receber pagamentos,
            realizar transferências e solicitar antecipações.
          </p>
        </div>
        <div className={styles.list}>
          <div className={styles.list_ranger}>
            <div className={styles.ball_}>
              <div> </div>
            </div>
            <div className={1 > formStep ? styles.ball : styles.ball_}>
              <p></p>
              <div> </div>
            </div>
            <div className={2 > formStep ? styles.ball : styles.ball_}>
              <p></p>
              <div> </div>
            </div>
            <div className={3 > formStep ? styles.ball : styles.ball_}>
              <p></p>
              <div> </div>
            </div>
            <div className={4 > formStep ? styles.ball : styles.ball_}>
              <p></p>
              <div> </div>
            </div>
          </div>
          <div className={styles.list_ranger_text}>
            <div className={styles.text}>
              <h3>Início </h3>
              <p>Procure a conta para criação de vinculo.</p>
            </div>
            <div className={styles.text}>
              <h3>Informações do Colaborador </h3>
              <p>Cadastro </p>
            </div>
            <div className={styles.text}>
              <h3>Tipo de Contrato </h3>
              <p>Selecione um contrato.</p>
            </div>
            <div className={styles.text}>
              <h3>Confirmação </h3>
              <p>Revise as informações para concluir.</p>
            </div>
            <div className={styles.text}>
              <h3>Concluído </h3>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <FormProvider>
            <FormCard currentStep={formStep} prevFormStep={prevFormStep}>
              {formStep === 0 && (
                <ConfirmPurchase
                  formStep={formStep}
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                />
              )}

              {formStep === 1 && (
                <PaymentInfo
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  currentStep={formStep}
                />
              )}
              {formStep === 2 && (
                <Document
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                />
              )}

              {formStep === 3 && (
                <Preview
                  currentStep={formStep}
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                />
              )}
              {formStep > 3 && (
                <FormCompleted clearFormSteps={clearFormSteps} />
              )}
            </FormCard>
          </FormProvider>
        </div>
      </div>
    </section>
  );
};

export { Payment };
