import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-svg-trans-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-svg-trans-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 250, 235);
    }
  }

  .svg-elem-1 {
    animation:
      animate-svg-trans-stroke-1 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
        both,
      animate-svg-trans-fill-1 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-svg-trans-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-svg-trans-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4167_116');
    }
  }

  .svg-elem-2 {
    animation:
      animate-svg-trans-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s
        both,
      animate-svg-trans-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-svg-3-stroke-1 {
    0% {
      stroke-dashoffset: 128.2389373779297px;
      stroke-dasharray: 128.2389373779297px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.2389373779297px;
    }
  }

  .svg-elem-3 {
    animation:
      animate-svg-3-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-3-fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-svg-trans-stroke-4 {
    0% {
      stroke-dashoffset: 158.84959411621094px;
      stroke-dasharray: 158.84959411621094px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 158.84959411621094px;
    }
  }

  @keyframes animate-svg-trans-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-elem-4 {
    animation:
      animate-svg-trans-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s
        both,
      animate-svg-trans-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }
`;

const IconAwaitPayment = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="6.125rem"
      height="3.125rem"
      fill="none"
      viewBox="0 0 98 50"
    >
      <path
        fill="#FFFAEB"
        d="M97 1C81.403 2.3 80.437 33.416 65 37c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V1z"
        className="svg-elem-1"
      ></path>
      <path
        fill="url(#paint0_linear_4167_116)"
        d="M97 1C81.403 2.3 80.437 33.416 65 37c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V1z"
        className="svg-elem-2"
      ></path>
      <path
        stroke="#B54708"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 49c13.64-4.546 18.09-22.026 32-24 12.824-1.82 19.27 14.955 32 12C80.437 33.416 81.403 2.3 97 1"
        className="svg-elem-3"
      ></path>
      <path
        fill="#B54708"
        d="M68.5 2.5h-9a2.25 2.25 0 00-2.25 2.25v16.5a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25V4.75A2.25 2.25 0 0068.5 2.5zM58.75 7h10.5v12h-10.5V7zm.75-3h9a.75.75 0 01.75.75v.75h-10.5v-.75A.75.75 0 0159.5 4zm9 18h-9a.75.75 0 01-.75-.75v-.75h10.5v.75a.75.75 0 01-.75.75z"
        className="svg-elem-4"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4167_116"
          x1="49"
          x2="49"
          y1="1"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.641" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconAwaitPayment };
