import React, { useEffect, useState } from 'react';
import { IoReloadOutline } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { useQuery, useQueryClient } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import ButtonGoBack from '../../components/ButtonGoBack';
import CardMovements from './Components/CardMovements';

import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { ButtonFilterSelect } from '../../components/UI/ButtonFilterSelect';
import { InputSearch } from '../../components/UI/InputSearch';
import { LoadingBall } from '../../components/UI/LoadingBall';

import { usePagination } from '../../hooks/usePagination';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import CardResume from './Components/CardResume';
import styles from './styles.module.scss';
import { useMovement } from '../../hooks/useMovement';

const AnalyzeMovement = () => {
  const [filter, setFilter] = useState(0);
  const { loading } = useUser();
  const [search, setSearch] = useState(false);

  const { fetchAnalyzeMovement } = useMovement();

  const { id } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState(history.location.state?.status ?? 6);

  const queryClient = useQueryClient();
  const [time, setTime] = useState(status === 4 ? 1000 * 10 : false);

  if (!id) {
    <Redirect to="/transfer" />;
  }
  const { data, isFetching } = useQuery(
    ['AnalyzeMovement', time],
    () => fetchAnalyzeMovement(id),
    {
      staleTime: 1000 * 60,
      refetchInterval: time,
    },
  );

  const list = data
    ? data?.movements
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;

          return lower(`${item['name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search) return item;

          return (
            item['document'].includes(search.replace(/\D/g, '')) ||
            [item['id']].includes(search)
          );
        })
        .filter((item) => {
          if (parseInt(filter) > 0) {
            return item.status.id === parseInt(filter);
          }
          return item;
        })
    : [];
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    50,
    '/menu/analyze/'
  );

  useEffect(() => {
    if (status === 4) {
      setStatus(data?.order?.status.id);

      setTime(1000 * 30);
    }
    if (status !== 4) {
      setStatus(data?.order?.status.id);
      setTime(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [search]);

  async function handleClick() {
    await queryClient.invalidateQueries(['AnalyzeMovement']);
  }
  return (
    <section
      className={
        // isFetching
        // ? `${styles.loading} ${styles.sectionContainer}`
        // :
        styles.sectionContainer
      }
    >
      <main>
        <div className={styles.upload}>
          <div className={styles.button}>
            <span className={styles.back}>
              <ButtonGoBack backRoute={'/menu/transfer'} />
            </span>
          </div>
          <div className={styles.button_}>
            <h2 className={styles.header}>Análise de Pagamentos</h2>
            <InputSearch
              label="Pesquisar por nome ou documento"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
              width="60%"
              filter={setFilter}
            />
          </div>
          <div className={styles.buttonContainer}>
            {/* {data?.order?.status && (
              <div className={styles.status}>
                <Status status={data.order.status} />
              </div>
            )} */}
            <div className={styles.wrapper_resume}>
              {data && <CardResume resume={data} />}
            </div>
          </div>
        </div>
      </main>
      {data && (
        <div className={styles.arowPages}>
          <div className={styles.wrapper__buttons}>
            <ButtonFilterSelect
              setCurrentPage={setCurrentPage}
              title="Todos"
              value={0}
              setIsActive={setFilter}
              account={data?.movements?.length ?? '--'}
              isActive={filter}
            />
            {data?.approved > 0 && (
              <ButtonFilterSelect
                setCurrentPage={setCurrentPage}
                title="Aprovado"
                value={6}
                setIsActive={setFilter}
                account={data?.approved ?? '--'}
                isActive={filter}
              />
            )}
            {data?.erros > 0 && (
              <ButtonFilterSelect
                setCurrentPage={setCurrentPage}
                title="Erros"
                value={[7, 8]}
                setIsActive={setFilter}
                account={data?.erros ?? '--'}
                isActive={filter}
              />
            )}
          </div>
          <div className={styles.wrapper__selection}>
            <ButtonMenuDownload list={data.movements} error={data.erros}>
              Exportar
            </ButtonMenuDownload>

            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
            <button
              disabled={!loading ? false : true}
              className={styles.reload}
              onClick={handleClick}
            >
              <IoReloadOutline size="1.125rem" />
            </button>
          </div>
        </div>
      )}
      <div>
        <div className={styles.cardHeader}>
          <div>
            <span className={styles.heading__cards}>Data</span>
            <span className={styles.heading__cards}>Favorecido</span>
            <span className={styles.heading__cards}>Documento</span>
            <span className={styles.heading__cards}>Valor</span>
            <span className={styles.heading__cards}>Método</span>
            <span className={styles.heading__status}>Status</span>
          </div>
        </div>

        {currentItens.length >= 1 ? (
          currentItens.map((movement) => (
            <CardMovements
              key={movement.id}
              back={id}
              {...movement}
              isFetching={isFetching}
            />
          ))
        ) : (
          <div className={styles.listLoader}>
            {isFetching ? (
              <LoadingBall />
            ) : (
              <h5>
                <RiSearch2Line size={12} color="#222" /> Nenhum resultado
                encontrado!
              </h5>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default AnalyzeMovement;
