import { Steps } from 'rsuite';
import styles from './styles.module.scss';
import { PaymentTransfer } from './Components/PaymentTransfer';
import { usePayment } from '../../../../hooks/usePayment';
import { memo } from 'react';

function StepsPayment() {
  const { stepPayment, typePayment, errosSteps } = usePayment();

  const typePaymentMethod = [
    {
      id: 1,
      description: 'transfer-bank-slip',
      title: 'Pagamento de Boletos',
      Component: () => <PaymentTransfer />,
    },
  ];

  const CurrentComponent = typePaymentMethod[0]?.Component;

  const title = typePaymentMethod.find(
    (item) => item.id === typePayment,
  )?.title;

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <Steps current={stepPayment} currentStatus={errosSteps.status}>
        <Steps.Item
          status={errosSteps.step === 0 ?? errosSteps.status}
          description={errosSteps.step === 0 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 1 ?? errosSteps.status}
          description={errosSteps.step === 1 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 2 ?? errosSteps.status}
          description={errosSteps.step === 2 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 3 ?? errosSteps.status}
          description={errosSteps.step === 3 ? errosSteps.mensage : false}
        />
      </Steps>

      {CurrentComponent && <CurrentComponent />}
    </section>
  );
}

export default memo(StepsPayment);
