import { validateName } from '../../utils/format';
import * as yup from 'yup';
import { validateCPF, validateCNPJ } from 'validations-br';

export const ValidateAddCollaboratorAgent = yup.object().shape({
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => {
      if (!value || value === ' ' || value.length === 0) {
        return true;
      } else {
        return validateCNPJ(value);
      }
    })
    .notRequired(),
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => {
      return validateCPF(value);
    })
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .notRequired(),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório')
    .test('is-name', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    }),
  email: yup.string().typeError('O campo deve receber um email.').notRequired(),
  DOCUMENTO_DO_AGENCIADOR: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test(
      'is-cpf-cnpj',
      'Documento não é valido',
      (value) => validateCPF(value) || validateCNPJ(value),
    )
    .required('Documento do Agenciador é obrigatório'),
  NOME_DO_AGENCIADOR: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .test('is-name-ag', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    })
    .required('Nome do Agenciador é obrigatório'),
});
export const ValidateAddCollaboratorDaily = yup.object().shape({
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => {
      if (!value || value === ' ' || value.length === 0) {
        return true;
      } else {
        return validateCNPJ(value);
      }
    })
    .notRequired(),
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => {
      return validateCPF(value);
    })
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .notRequired(),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório')
    .test('is-name', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    }),
  email: yup.string().typeError('O campo deve receber um email.').notRequired(),
  VALOR_DA_DIARIA: yup
    .string()
    .typeError('O campo deve receber um Valor em real Valido.')
    .test(
      'is-name-ag',
      'O campo deve receber um Valor em real Valido.',
      (value) => {
        const regex = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;

        return regex.test(value);
      },
    )
    .required('valor da diária é obrigatório'),
  VALOR_DA_DIARIA_AJUDANTE: yup
    .string()
    .typeError('O campo deve receber um Valor em real Valido.')
    .test(
      'is-name-ag',
      'O campo deve receber um Valor em real Valido.',
      (value) => {
        const regex = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;

        return regex.test(value);
      },
    )
    .required('valor da diária é obrigatório'),
});
export const ValidateAddCollaborator = yup.object().shape({
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => {
      if (!value || value === ' ' || value.length === 0) {
        return true;
      } else {
        return validateCNPJ(value);
      }
    })
    .notRequired(),
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => {
      return validateCPF(value);
    })
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .notRequired(),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório')
    .test('is-name', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    }),
  email: yup.string().typeError('O campo deve receber um email.').notRequired(),
});

export const ValidateAddCollaboratorServices = yup.object().shape({
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => {
      if (!value || value === ' ' || value.length === 0) {
        return true;
      } else {
        return validateCNPJ(value);
      }
    })
    .notRequired(),
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => {
      return validateCPF(value);
    })
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .notRequired(),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório')
    .test('is-name', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    }),
  email: yup.string().typeError('O campo deve receber um email.').notRequired(),
  VALOR_DO_SERVICO: yup
    .string()
    .typeError('O campo deve receber um Valor em real Valido.')
    .test(
      'is-name-ag',
      'O campo deve receber um Valor em real Valido.',
      (value) => {
        const regex = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;

        return regex.test(value);
      },
    )
    .required('valor da diária é obrigatório'),
});

export const ValidateAddCollaboratorComodato = yup.object().shape({
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => {
      if (!value || value === ' ' || value.length === 0) {
        return true;
      } else {
        return validateCNPJ(value);
      }
    })
    .notRequired(),
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => {
      return validateCPF(value);
    })
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .notRequired(),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório')
    .test('is-name', 'Deve ter no mínimo sobrenome  ', (value) => {
      return validateName(value);
    }),
  email: yup.string().typeError('O campo deve receber um email.').notRequired(),
  COMODATO: yup
    .string()
    .max(56, 'Certificado indefinido')
    .typeError('O campo deve receber um Valor em real Valido.')
    .required('Certificado de propriedade é obrigatório'),
});
