import { BlobProvider } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../../../services/api';
import Contract from '../Contract';

export default function WebContract() {
  const [loading, setLoading] = useState(true);
  const [errorContract, setErrorContract] = useState(false);
  const [contract, setContract] = useState('');
  const { id, number } = useParams();

  const contractNumber = number || '1';

  async function fetchGetOnboardingContract(document) {
    try {
      const { data } = await apiGet(`/contract/${document}`);

      setContract(data?.value);
    } catch (error) {
      setErrorContract(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchGetOnboardingContract(id);
  }, [id, number]);

  if (loading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
          backgroundColor: '#fff',
        }}
      >
        <img src="/search-file.gif" alt="Search File" width={120} />
        <p style={{ marginTop: 20 }}>
          Processando as informações, por favor aguarde...
        </p>
      </div>
    );
  }

  if (errorContract) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
        }}
      >
        Desculpe, algo deu errado. Tente novamente, mais tarde.
        {errorContract}
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
      }}
    >
      {contract && (
        <BlobProvider
          document={
            <Contract data={contract} id={id} contractNumber={contractNumber} />
          }
        >
          {({ blob, url, loading, error }) => {
            if (loading) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#777',
                    backgroundColor: '#fff',
                  }}
                >
                  <img src="/search-file.gif" alt="Search File" width={120} />
                  Processando as informações, por favor aguarde.
                </div>
              );
            }

            if (error) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#777',
                  }}
                >
                  Desculpe, algo deu errado. Tente novamente, mais tarde.
                  {error}
                </div>
              );
            }

            return (
              <div
                style={{
                  width: '100%',
                  maxHeight: '100dvh',
                  overflow: 'hidden',
                }}
              >
                <embed
                  src={url}
                  style={{
                    height: '100vh',
                    width: '100%',
                  }}
                />
              </div>
            );
          }}
        </BlobProvider>
      )}
    </div>
  );
}
