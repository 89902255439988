export function formatMethodPayment(type) {
  switch (type) {
    case '01':
      return 'Ted';
    case '02':
      return 'Pix';
    case '03':
      return 'Pix';
    case '04':
      return 'Pix';
    case '05':
      return 'Ted';
    case '06':
      return 'OX';
    case '07':
      return 'OX';
    case '08':
      return 'Boleto';
    case '09':
      return 'Antecipação';
    case '10':
      return 'Aporte de antecipação';
    case '11':
      return 'Estorno';
    case '12':
      return 'Antecipação';
    case '13':
      return 'Cartão';
    default:
      return '---';
  }
}

export const paymentMethods = {
  '01': 'TED OUT',
  '02': 'PIX IN',
  '03': 'PIX OUT',
  '04': 'PIX KEY',
  '05': 'TED IN',
  '06': 'INTERNAL OUT',
  '07': 'INTERNAL IN',
  '08': 'BANKSLIP',
  '09': 'CONSIGNATION',
  10: 'CONSIGNABLE BALANCE',
  11: 'PIX REFUND',
  12: 'CONSIGNATION IN',
};
