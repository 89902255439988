import React from 'react';
import { useState } from 'react';
import { IconDownload } from '../../../../components/Details/Icons';
import { ExportExcelExtract } from '../ExportExcelExtract';
import styles from './styles.module.scss';
import { ExportXMLExtract } from '../ExportXMLExtract';
import { ExportOFXExtract } from '../ExportOFXExtract';

export function ButtonMenuDownload({ list, model = [1, 2, 3] }) {
  const [active, setActive] = useState(false);

  const templates = [
    {
      id: 1,
      title: 'Extrato de pagamento (Excel)',
      Component: ({ title }) => (
        <ExportExcelExtract list={list} title={title} />
      ),
    },
    {
      id: 2,
      title: 'Extrato de pagamento (XML)',
      Component: ({ title }) => <ExportXMLExtract list={list} title={title} />,
    },
    {
      id: 3,
      title: 'Extrato de pagamento (OFX)',
      Component: ({ title }) => <ExportOFXExtract list={list} title={title} />,
    },
  ];
  const filter = templates?.filter((item) => model.includes(item.id));

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      <IconDownload color="var(--primary)" size={'2rem'} />
      {active && (
        <ul className={styles.menu}>
          {filter &&
            filter.map(({ id, title, Component }) => (
              <Component key={id} title={title} />
            ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
