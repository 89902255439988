import { useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import styles from './styles.module.scss';
import { mask as masker, unMask } from 'remask';
import { validateCNPJ, validateCPF } from 'validations-br';
export const InputDebounce = ({ label, type, setValue, value, Icon }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const { isDebounce } = useDebounce(setValue, 1500);
  const [error, setError] = useState(false);
  function removeMask(inputValue) {
    return inputValue.replace(/\D/g, '');
  }

  function handleChange({ target }) {
    const inputValue = target.value;
    const unmaskedValue = removeMask(inputValue);
    const originalValue = unMask(unmaskedValue);
    const maskedValue = masker(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);

    setDisplayValue(maskedValue);
    if (unmaskedValue.length >= 11) {
      const errorDocument =
        validateCPF(unmaskedValue) || validateCNPJ(unmaskedValue);

      if (!errorDocument) {
        setError('Documento Inválido');
      } else {
        setError(false);
        isDebounce(unmaskedValue);
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper__input}>
        <input type={type} onChange={handleChange} value={displayValue} />
        <div className={!!value ? `${styles.isValue}` : `${styles.label}`}>
          {label}
        </div>
        {Icon && <Icon className={styles.icon} size="1.125rem" />}
      </div>
      {error && <div className={styles.error__input}>{error}</div>}
    </div>
  );
};
