import differenceInDays from 'date-fns/differenceInDays';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../../utils/formatMoney';
import { IconPeoplePayment } from '../animation/IconPeoplePayment';

import styles from './styles.module.scss';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export function CardAmountPeople({
  data,
  loading,
  status,
  title,
  filter_expirate_date,
  handleFilterExpireDate,
}) {
  const [currentValue, setCurrentValue] = useState(0);
  const duration = 600; // Duração da animação em milissegundos

  const total_amount_account_ox = data.reduce((acc, item) => {
    if (
      [1, 3].includes(item?.status?.id) &&
      item?.amount &&
      differenceInDays(new Date(item?.expiration_date), new Date()) <= 2
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

  useEffect(() => {
    const total_amount_account = data.reduce((acc, item) => {
      if (
        [1, 3].includes(item?.status?.id) &&
        item?.amount &&
        differenceInDays(new Date(item?.expiration_date), new Date()) <= 2
      ) {
        return acc + item.amount;
      }
      return acc;
    }, 0);

    const decimalNumber =
      total_amount_account - Math.floor(total_amount_account);
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * total_amount_account);

      setCurrentValue(nextValue + decimalNumber);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div
      className={styles.container}
      onClick={handleFilterExpireDate}
      style={
        filter_expirate_date ? { border: '1px solid var(--gray-100)' } : {}
      }
    >
      <div className={styles.card}>
        <ul>
          <li className={styles.clients}>{title} </li>
        </ul>
        <div className={styles.wrapper_balance}>
          {data?.length >= 1 ? (
            <div className={styles.balance}>
              {loading ? (
                <LoadingBall />
              ) : currentValue >= 0 && typeof totalAmount !== 'boolean' ? (
                formatMoney(currentValue)
              ) : (
                typeof totalAmount === 'boolean' && <span> - - - </span>
              )}
            </div>
          ) : (
            <div className={styles.balance_}>0</div>
          )}
        </div>
        <div className={styles.name}>
          <div className={styles.wrapper_footer}>
            {!loading && <strong>{total_amount_account_ox}</strong>}
            <span>Total</span>
          </div>
        </div>
      </div>
      <div className={styles.icon}>
        <IconPeoplePayment />
      </div>
    </div>
  );
}
