import React from 'react';
import styled from 'styled-components';

const TooltipCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 0;
  position: relative;
  margin: 0 auto;
  padding: 0;
  box-sizing: none;
  pointer-events: all;

  &:hover {
    ::after {
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '${(props) => props.text}';
      position: absolute;
      top: -3.5rem;
      right: 0;

      white-space: nowrap;
      padding: 0 2rem 0 2rem;
      height: 2rem;
      color: var(--gray-400);
      background: var(--gray-10);
      border: 1px solid var(--gray-50);
      border-radius: 0.5rem;
      margin: 0 auto;
    }
  }
`;
export default function ToolTip({ children, text }) {
  return <TooltipCard text={text}>{children}</TooltipCard>;
}
