import { IconAlert, IconCheck, IconInfo, IconError } from './icon';
import { useNotification } from '../../hooks/useNotifications';
import styles from './styles.module.scss';
import { IoMdClose } from 'react-icons/io';
/**
 * O componente `NotificationSystem` exibe notificações para os usuários.
 *
 * Ele é responsável por renderizar notificações com base nos dados armazenados no contexto `useNotification`.
 *
 * @component
 * @example
 * // Exemplo de uso:
 * <NotificationSystem />
 */
export function NotificationSystem() {
  /**
   * Utiliza o contexto `useNotification` para acessar as notificações e a função `removeNotification`.
   * @type {object}
   */
  const { notifications, removeNotification } = useNotification();
  /**
   * Obtém a classe de estilo CSS com base no tipo de notificação.
   *
   * @param {number} type - O tipo de notificação.
   * @returns {string} - A classe de estilo CSS correspondente ao tipo de notificação.
   */
  function getNotificationStyle(type) {
    switch (type) {
      case 0:
        return styles.notificationType0; // Classe CSS para tipo informação
      case 1:
        return styles.notificationType1; // Classe CSS para tipo atenção
      case 2:
        return styles.notificationType2; // Classe CSS para tipo error
      case 3:
        return styles.notificationType3; // Classe CSS para tipo concluído
      default:
        return ''; // Classe CSS padrão
    }
  }
  /**
   * Obtém o ícone correspondente com base no tipo de notificação.
   *
   * @param {number} type - O tipo de notificação.
   * @returns {React.Element} - O ícone correspondente ao tipo de notificação.
   */
  function getIconStyle(type) {
    switch (type) {
      case 0:
        return () => <IconInfo />; // Classe CSS para tipo informação
      case 1:
        return () => <IconAlert />; // Classe CSS para tipo atenção
      case 2:
        return () => <IconError />; // Classe CSS para tipo error
      case 3:
        return () => <IconCheck />; // Classe CSS para tipo concluído
      default:
        return ''; // Classe CSS padrão
    }
  }

  return (
    <div className={styles.notification_list}>
      {notifications?.length > 0 &&
        notifications.map((notification) => (
          <div
            className={`${styles.notification} ${
              styles.animeLeft
            } ${getNotificationStyle(notification.type)}`}
            key={notification.id}
          >
            {notification?.timer && <div className={styles.progress}> </div>}

            <div className={styles.container_progress}>
              <div className={styles.container}>
                <div className={styles.icon}>
                  {getIconStyle(notification.type)()}
                </div>
                <div className={styles.content}>
                  {notification?.title && <h3>{notification.title}</h3>}
                  <p>{notification.message}</p>
                </div>
              </div>
              <div className={styles.icon_close}>
                <IoMdClose
                  onClick={() => removeNotification(notification.id)}
                  size={'1.5rem'}
                  color={'var(--gray-400)'}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
