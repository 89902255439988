import React, { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import { useBalance } from '../../hooks/useBalance';
import { useUser } from '../../hooks/useUser';
import { formatMoney } from '../../utils/formatMoney';
import styles from './styles.module.scss';

import { FormTransferBalance } from './Components/FormTransferBalance';
import { FormAddedBalance } from './Components/FormAddedBalance';
import { Details } from '../../components/Details';

export default function AddBalance() {
  const { user, setDropMenu, dropMenu } = useUser();
  const {
    loading,
    balance,
    balanceConsignable,
    fetchGetBalance,
    fetchGetBalanceConsignable,
    fetchGetLimits,
    limits,
  } = useBalance();

  const [view, setView] = React.useState({
    info: true,
    balance: false,
    form: false,
    added: false,
  });

  useEffect(() => {
    !dropMenu && setDropMenu(true);
    if (!balance) {
      fetchGetBalance();
    }
    if (!limits) {
      fetchGetLimits();
    }
    if (!balanceConsignable) {
      fetchGetBalanceConsignable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleCloseFormsAdded() {
    if (view.form) {
      setView({ ...view, added: !view.added, form: false });
    } else {
      setView({ ...view, added: !view.added });
    }
  }
  function handleCloseForms() {
    if (view.added) {
      setView({ ...view, added: false, form: !view.form });
    } else {
      setView({ ...view, form: !view.form });
    }
  }

  return (
    <ul className={styles.container}>
      <div className={styles.header_style}>
        <h1 className={styles.title_style}>Informações Bancárias</h1>
      </div>

      <main className={styles.main}>
        <article
          className={view.info ? styles.balance_fat_ : styles.balance_fat}
        >
          <div className={styles.box}>
            <div className={styles.content__balance_fat}>
              <h4>Saldo Conta</h4>
              <strong>
                {loading ? 'Carregando ...' : formatMoney(balance)}
              </strong>
            </div>
          </div>
          {view.info && (
            <>
              <div className={styles.info_account}>
                <h3 className={styles.title}>Dados da conta </h3>
                {user?.company?.default_pix_key ? (
                  <>
                    {user?.company?.social_reason && (
                      <Details
                        title={'Nome:'}
                        value={user?.company?.social_reason}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                    )}
                    {user?.company?.social_reason && (
                      <Details
                        title={'CNPJ:'}
                        value={user?.company?.cnpj}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                    )}
                    {user?.company?.social_reason && (
                      <Details
                        title={'Instituição:'}
                        value={'ISPB: 31680151 - Swap Ip S.A.'}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                    )}
                    {user?.company?.swap_account?.branch && (
                      <Details
                        title={'Agência:'}
                        value={user?.company?.swap_account?.branch}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                        copy={true}
                      />
                    )}
                    {user?.company?.swap_account?.account && (
                      <Details
                        title={'Número da Conta:'}
                        value={user?.company?.swap_account?.account}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                        copy={true}
                      />
                    )}
                    {user?.company?.default_pix_key && (
                      <Details
                        title={'Chave Pix: (Aleatória)'}
                        value={user?.company?.default_pix_key}
                        border={true}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                        copy={true}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Details
                      title={'Nome:'}
                      value={'OX SERVICOS DE TECNOLOGIA LTDA'}
                      border={false}
                      maxWidth="60rem"
                      padding="0.39rem 0.89rem"
                    />
                    <Details
                      title={'Chave Pix (Depósito):'}
                      value={'38.927.293/0001-20'}
                      border={false}
                      maxWidth="60rem"
                      padding="0.39rem 0.89rem"
                      copy={true}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </article>
        <article className={view.form ? styles.balance__ : styles.balance}>
          <h4>Saldo de Antecipação</h4>
          <strong>
            {loading ? 'Carregando ...' : formatMoney(balanceConsignable)}
          </strong>
          {[10, 12, 61].every((item) => user?.permission.includes(item)) && (
            <>
              {view.form ? (
                <div className={styles.wrapper_buttons}>
                  <button
                    className={styles.button_close}
                    onClick={handleCloseForms}
                  >
                    Fechar
                    <IoMdClose size={'1.2rem'} color={'var(--primary-700)'} />
                  </button>
                </div>
              ) : (
                <button
                  className={styles.button_open}
                  onClick={handleCloseForms}
                >
                  <FaPlus size={'1rem'} color={'var(--white)'} />
                  Adicionar
                </button>
              )}
            </>
          )}
          {view.form && <FormTransferBalance />}
        </article>
        {[33, 34].every((item) => user?.permission.includes(item)) && (
          <article className={view.added ? styles.balance__ : styles.balance}>
            <h4>Saldo de Antecipação </h4>
            <strong>
              {loading ? 'Carregando ...' : formatMoney(balanceConsignable)}
            </strong>
            {[33, 34].every((item) => user?.permission.includes(item)) && (
              <>
                {view.added ? (
                  <div className={styles.wrapper_buttons}>
                    <button
                      className={styles.button_}
                      onClick={handleCloseFormsAdded}
                    >
                      Fechar
                      <IoMdClose size={'1.2rem'} color={'var(--primary-700)'} />
                    </button>
                  </div>
                ) : (
                  <button
                    className={styles.button}
                    onClick={handleCloseFormsAdded}
                  >
                    <FaPlus size={'1rem'} color={'var(--white)'} />
                    Adicionar Gestão
                  </button>
                )}
              </>
            )}
            {view.added && <FormAddedBalance />}
          </article>
        )}
      </main>
    </ul>
  );
}
