import React from 'react';

import { IconReason } from '../../../pages/Collaborators/Components/Icon';
import { Span } from './styles';

const Status = ({ status, reproval_reasons }) => {
  return (
    <Span color={status?.color} colorText={status?.colorText}>
      {status?.text}
      {reproval_reasons && <IconReason size="1rem" />}
    </Span>
  );
};
export default Status;
