export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');

  let countryCodeLength = 0;

  if (phoneNumber.startsWith('55')) {
    countryCodeLength = 2;
  } else if (phoneNumber.startsWith('1')) {
    countryCodeLength = 1;
  }

  phoneNumber = phoneNumber.slice(countryCodeLength);

  phoneNumber = phoneNumber.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');

  return phoneNumber;
}
