const { addHours, format, parseISO, isDate } = require('date-fns');

export function addHoursAndFormat(dateString, hoursToAdd) {
  let date;

  if (typeof dateString === 'string') {
    date = parseISO(dateString);
  } else if (isDate(dateString)) {
    date = dateString;
  } else {
    throw new Error('Input deve ser uma string ISO 8601 ou um objeto Date.');
  }

  const newDate = addHours(date, hoursToAdd);
  return format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}
