import { db } from '../services/firebase';

import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  doc,
} from 'firebase/firestore';
const dbStorage = process.env.REACT_APP_MODAL;

export async function fireStoreAdd(data, user) {
  const action = process.env.REACT_APP_FIRE;
  const fireStore = collection(db, action);

  await addDoc(fireStore, {
    ...data,
    userId: user.customer_id,
    company: user.company_id,
    companyName: user.fantasy_name,
    userName: `${user.first_name}${user.last_name}`,
  });
}

export async function fireStoreSetModalFeedback(user, values) {
  const action = dbStorage;
  const fireStore = collection(db, action);
  await addDoc(fireStore, {
    companyId: user.company_id,
    userId: user.customer_id,
    value: [{ date: new Date(), user: user.customer_id, value: values }],
  });
}
export async function fireStoreUpdateModalFeedback(user, date, value) {
  let feedbacksArray = user.value;
  feedbacksArray.push({ date: new Date(), text: value });
  const action = dbStorage;
  const fireStore = doc(db, action, user.id);
  const newFields = { date, value: feedbacksArray };
  await updateDoc(fireStore, newFields);
}
export async function fireStoreGetModalFeedback(id) {
  const action = dbStorage;
  const fireStoreRef = collection(db, action);

  const data = await getDocs(fireStoreRef);
  const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  const findUser = docs.find((user) => {
    return user.companyId === id;
  });
  const findUserValue = findUser !== undefined;

  return !findUserValue;
}
