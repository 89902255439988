export function formatAccount(type) {
  switch (type) {
    case 'CC':
      return 'Conta Corrente';
    case 'CD':
      return 'Conta de Depósito';
    case 'CG':
      return 'Conta Garantida';
    case 'PG':
      return 'Conta de Pagamento';
    case 'PP':
      return 'Conta Poupança';
    default:
      return '';
  }
}
export function formatAccountType(type) {
  switch (type) {
    case 1:
      return 'Conta Corrente';
    case 2:
      return 'Conta de Depósito';
    case 3:
      return 'Conta Garantida';
    case 4:
      return 'Conta de Pagamento';
    case 5:
      return 'Conta Poupança';
    default:
      return '';
  }
}
