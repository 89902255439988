class OFXGenerator {
  constructor(user) {
    this.company = user?.company;
    this.formattedDates = [];
  }
  generateOFX(list) {
    /* prettier-ignore-start */
    const ofxHeader = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:USASCII
CHARSET:1252
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE

`;
    const ofxSignOn = `<SIGNONMSGSRSV1>
<SONRS>
<STATUS>
<CODE>0
<SEVERITY>INFO
</STATUS>
<DTSERVER>${this._formatDateTime(
      list[list.length - 1]?.OFX?.DTEND || new Date(),
    )}[-03:EST]
<LANGUAGE>POR
</SONRS>
</SIGNONMSGSRSV1>`;
    const ofxBody = `<OFX>
${ofxSignOn}
<BANKMSGSRSV1>
<STMTTRNRS>
<TRNUID>1001
<STATUS>
<CODE>0
<SEVERITY>INFO
</STATUS>
<STMTRS>
<CURDEF>BRL
<BANKACCTFROM>
<BANKID>31680151
<ACCTID>${this?.company?.company_id}
<ACCTTYPE>CHECKING
</BANKACCTFROM>
<BANKTRANLIST>
<DTSTART>${this._formatDateTime(list[0]?.OFX?.DTEND || new Date())}[-03:EST]
<DTEND>${this._formatDateTime(
      list[list.length - 1]?.OFX?.DTEND || new Date(),
    )}[-03:EST]
${list.reverse().map(this._mapItemToOFX).join('')}</BANKTRANLIST>
<LEDGERBAL>
<BALAMT>${this._formatMoney(list[list.length - 1]?.after || 0)}
<DTASOF>${this._formatDateTime(
      list[list.length - 1]?.OFX?.DTEND || new Date(),
    )}[-03:EST]
</LEDGERBAL>
</STMTRS>
</STMTTRNRS>
</BANKMSGSRSV1>
</OFX>`;
    /* prettier-ignore-end */
    const text_ofx = ofxHeader + ofxBody;
    return text_ofx;
  }

  /* prettier-ignore-start */
  _mapItemToOFX = (item) => {
    return `<STMTTRN>
<TRNTYPE>${item.OFX.TRNTYPE}
<DTPOSTED>${this._formatDateTime(item.OFX.DTPOSTED)}[-03:EST]
<TRNAMT>${this._formatMoney(item.OFX.TRNAMT, item.OFX.TRNTYPE)}
<FITID>${item.OFX.FITID}
<CHECKNUM>${item.OFX.FITID}
<MEMO>${item.OFX.MEMO ? item.OFX.MEMO : item.OFX.TRNTYPE}
</STMTTRN>
`;
  };
  /* prettier-ignore-end */

  _formatDateTime = (date) => {
    if (!date) return '';
    const dt = new Date(date);
    dt.setUTCHours(dt.getUTCHours() - 6);
    return `${dt.getUTCFullYear()}${String(dt.getUTCMonth() + 1).padStart(
      2,
      '0',
    )}${String(dt.getUTCDate()).padStart(2, '0')}${String(
      dt.getUTCHours(),
    ).padStart(2, '0')}${String(dt.getUTCMinutes()).padStart(2, '0')}${String(
      dt.getUTCSeconds(),
    ).padStart(2, '0')}`;
  };

  _formatMoney = (amount, type) => {
    if (isNaN(amount) || amount == null) return '0.00';
    // Formatar o valor
    let formattedAmount = parseFloat(amount).toFixed(2);
    // Se for 'DEBIT', adicionar sinal negativo
    if (type === 'DEBIT') {
      formattedAmount = `-${formattedAmount}`;
    }
    return formattedAmount;
  };
}

export { OFXGenerator };
