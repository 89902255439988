import React from 'react';
import { useState } from 'react';

import { FaDownload } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import templateOnboarding from '../../../Assets/template/Onboarding/template_colaborador.xlsx';
import templateOnboardingAgent from '../../../Assets/template/Onboarding/template_agenciador.xlsx';
import templateOnboardingDaily from '../../../Assets/template/Onboarding/template_diarista.xlsx';
import templateOnboardingNotContract from '../../../Assets/template/Onboarding/template_sem_contrato.xlsx';
import templateOnboardingPrestacaoServicos from '../../../Assets/template/Onboarding/template_prestacao_servicos.xlsx';
import templateOnboardingNumberComodato from '../../../Assets/template/Onboarding/template_numero_certificado.xlsx';

import styles from './styles.module.scss';

/**
 * Componente de botão de menu suspenso para download de modelos de arquivo.
 *
 * Este componente renderiza um botão que, quando clicado, exibe um menu suspenso com links para download de modelos de arquivo. É projetado para fornecer acesso conveniente a diferentes modelos de arquivo, como modelos de transferência bancária, Pix, transferência interna e antecipação.
 *
 * @component
 * @param {React.ReactNode} children - Os elementos filhos a serem renderizados no botão. Pode ser usado para personalizar o conteúdo do botão.
 * @param {number[]} model - Um array de números que representa os modelos de arquivo disponíveis para download. Cada número corresponde a um modelo específico.
 * @returns {JSX.Element} Um botão de menu suspenso para download de modelos de arquivo.
 *
 * @example
 * // Exemplo de uso:
 * import { ButtonMenuDownload } from './ButtonMenuDownload';
 *
 * function MeuComponente() {
 *   return (
 *     <ButtonMenuDownload model={[1, 2, 3]}>
 *       <span>Modelos de Arquivo</span>
 *     </ButtonMenuDownload>
 *   );
 * }
 */

export function ButtonMenuDownload({ children, model = [1, 2, 3, 4, 5] }) {
  const [active, setActive] = useState(false);

  const templates = [
    {
      id: 1,
      title: 'Colaborador',
      href: templateOnboarding,
      name: 'template_colaborador',
    },
    {
      id: 2,
      title: 'Agenciador',
      href: templateOnboardingAgent,
      name: 'template_agenciador',
    },
    {
      id: 3,
      title: 'Diária',
      href: templateOnboardingDaily,
      name: 'template_diaria',
    },
    {
      id: 4,
      title: 'Sem Contrato',
      href: templateOnboardingNotContract,
      name: 'template_sem_contrato',
    },
    {
      id: 4,
      title: 'Prestação de Serviços ',
      href: templateOnboardingPrestacaoServicos,
      name: 'template_prestacao_servicos',
    },
    {
      id: 5,
      title: 'Certificado de propriedade Nº ',
      href: templateOnboardingNumberComodato,
      name: 'template_certificado_comodato',
    },
  ];

  const filter = templates?.filter((item) => model.includes(item.id));
  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      {children}
      <IoIosArrowDown color="var(--gray-400)" size="1.125rem" />
      {active && (
        <ul className={styles.menu}>
          {filter &&
            filter.map(({ title, id, href, name }) => (
              <a
                key={id}
                className={styles.button__menu}
                href={href}
                download={name}
              >
                <FaDownload color="var(--gray-400)" size="0.9rem" />
                <span> {title}</span>
              </a>
            ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
