import React from 'react';
import { useState } from 'react';
import { IconClose } from '../../pages/Collaborators/Components/TagInput/icon';
import styles from './styles.module.scss';

export default function FlagStatus() {
  const _env = process.env.REACT_APP_ENV === 'dev' ? true : false;
  const [active, setActive] = useState(_env);

  if (active)
    return (
      <div className={`${styles.error} ${styles.animeLeft}`}>
        <button
          className={styles.button}
          onClick={() => {
            setActive(false);
          }}
        >
          <IconClose />
        </button>
        Sand box
      </div>
    );

  return <div style={{ display: 'none' }} />;
}
