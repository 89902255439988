import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { apiGet, apiPost, apiPut } from '../../services/api';
import { useUser } from '../../hooks/useUser';
import FormGroup from '../../components/Forms/FormGroup';
import { useBalance } from '../../hooks/useBalance';

import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
export function CreateAccount() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [matchAccountable, setMatchAccountable] = useState(false);

  const [loading, setLoading] = useState(false);
  const { user, setMessage, setError } = useUser();
  const { fetchGetAllBalances } = useBalance();

  const [clear, setClear] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setValue('city', ' a');
    setValue('first_name', 'a ');
    setValue('last_name', ' a');
    setValue('neighborhood', 'a ');
    setValue('street', ' a');
    setValue('Zipcode', ' a');
    setValue('street_number', ' a');
    //eslint-disable-next-line
  }, []);

  const onSubmit = async (body) => {
    try {
      setLoading(true);

      var { status: isUserStatus, data: dataUserExist } = await apiGet(
        `/Customer/document/${body.cpf.replace(/[^\d]/g, '')}`,
      );

      if (isUserStatus === 200) {
        const user = {
          first_name: dataUserExist.first_name,
          last_name: dataUserExist.last_name,
          cpf: dataUserExist.cpf,
          email: dataUserExist.email,
          telephone: dataUserExist.telephone,
          date_added: dataUserExist.date_added,
          zipcode: dataUserExist.zipcode,
          street: dataUserExist.street,
          street_number: dataUserExist.street_number,
          street_complement: dataUserExist.street_complement,
          neighborhood: dataUserExist.neighborhood,
          city: dataUserExist.city,
        };

        await apiPost('/Account/Personal', user);
      }
      if (isUserStatus === 200) {
        reset();
        setValue('phone', '');
        setValue('zipcode', '');
        setClear(true);
        setMessage({ login: 'Empresa adicionada com sucesso' });

        const profile = parseInt(process.env.REACT_APP_PROFILE);

        if (user.profile !== profile) {
          user.additional_company_ids = [...user.additional_company_ids];
        }
        if (user.profile !== profile) {
          await apiPut(`/customer/AdditionalCompanies`, {
            customer_id: user.customer_id,
            company_ids: [...user.additional_company_ids],
          });
        }

        const { status: isUserStatus, data: dataUserExist } = await apiGet(
          `/Customer/document/${body.cpf.replace(/[^\d]/g, '')}`,
        );

        if (dataUserExist) {
          const dataAdditionalCompanies = {
            customer_id: dataUserExist.customer_id,
            company_ids: [...dataUserExist.additional_company_ids],
          };

          if (isUserStatus === 200 && dataUserExist.profile !== profile) {
            const { status: res } = await apiPut(
              `/customer/AdditionalCompanies`,
              dataAdditionalCompanies,
            );
            res === 200
              ? setMessage({ login: 'Usuário Adicionado' })
              : setError({ login: 'Usuário Adicionado' });
          }
        } else {
        }
      }
    } catch (error) {
      setValue('closing_day', 1);
      setValue('month_installment_limit', 1);
      setValue('transfer_fee', 0);
      setValue('webhook_url', null);
    } finally {
      setLoading(false);
      fetchGetAllBalances();
      setClear(false);

      //setError({login: 'não foi possivel adicionar Empressa'})
    }
  };
  const handleChange = () => {
    setMatchAccountable(!matchAccountable);
  };

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title_}>
          <h1>Dados do Responsável</h1>
        </div>

        <div className={styles.box_1}>
          <FormGroup
            name="cpf"
            label="CPF:"
            type="text"
            data={{ ...register('cpf') }}
            disabled={loading}
            register={register}
            errors={errors}
            mask={'999.999.999-99'}
            setValue={setValue}
            autofocus={true}
            clean={clear}
          />
          {matchAccountable && (
            <FormGroup
              name="accountable"
              label="Nome do Responsável:"
              type="text"
              data={{ ...register('accountable') }}
              disabled={loading}
              register={register}
              errors={errors}
            />
          )}
        </div>
        {matchAccountable && (
          <>
            <div className={styles.box_2}>
              <FormGroup
                name="phone"
                label="Telefone:"
                type="tel"
                data={{ ...register('phone') }}
                disabled={loading}
                register={register}
                errors={errors}
                setValue={setValue}
                mask={'(99) 99999-9999'}
                clean={clear}
              />
              <FormGroup
                name="email"
                label="E-mail:"
                type="email"
                data={{ ...register('contact') }}
                disabled={loading}
                register={register}
                errors={errors}
              />
            </div>
            <div className={styles.title_}>
              <h1>Endereço</h1>
            </div>

            <div className={styles.box_3}>
              <FormGroup
                name="zipcode"
                label="CEP:"
                type="text"
                data={{ ...register('zipcode') }}
                disabled={loading}
                register={register}
                errors={errors}
                setValue={setValue}
                mask={'99999-999'}
                clean={clear}
              />
              <FormGroup
                name="street"
                label="Rua:"
                type="text"
                data={{ ...register('street') }}
                disabled={loading}
                register={register}
                errors={errors}
              />

              <FormGroup
                name="neighborhood"
                label="Bairro:"
                type="text"
                data={{ ...register('neighborhood') }}
                disabled={loading}
                register={register}
                errors={errors}
              />

              <FormGroup
                name="street_number"
                label="Número:"
                type="text"
                data={{ ...register('street_number') }}
                disabled={loading}
                register={register}
                errors={errors}
              />
            </div>
          </>
        )}

        <div className={styles.box_4}>
          {matchAccountable && (
            <>
              <FormGroup
                name="city"
                label="Cidade:"
                type="text"
                data={{ ...register('city') }}
                disabled={loading}
                register={register}
                errors={errors}
              />
              <FormGroup
                name="street_complement"
                label="Complemento:"
                type="text"
                data={{ ...register('street_complement') }}
                disabled={loading}
                register={register}
                errors={errors}
              />
            </>
          )}
          <div className={styles.box_5}>
            <div></div>
            <div>
              <input
                onChange={handleChange}
                checked={matchAccountable}
                type="checkbox"
                name="check"
                text="Preencher com dados dos responsáveis!"
                id=""
              />
              <label htmlFor="check">Usuário não existe</label>
            </div>
          </div>
        </div>

        <div className={styles.wrapper_button}>
          <button className={styles.button} type="submit" disabled={loading}>
            <AiOutlineCheckCircle color="var(--white)" size={24} />
            <span>Salvar</span>
          </button>

          <button
            className={styles.button}
            type="button"
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              history.goBack();
            }}
          >
            <BiArrowBack color="var(--white)" size={24} />
            <span>Voltar</span>
          </button>
        </div>
      </form>
    </section>
  );
}
