import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';

export function useCollaborator() {
  const {
    selectCheckedTags,
    setSelectCheckedTags,
    statusCollaboratorSelect,
    setStatusCollaboratorSelect,
  } = useContext(StoreContext);

  return {
    selectCheckedTags,
    setSelectCheckedTags,
    statusCollaboratorSelect,
    setStatusCollaboratorSelect,
  };
}
