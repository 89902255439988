import {
  validateCPF,
  validateCNPJ,
  validateEmail,
  validatePhone,
} from 'validations-br';
function validateRandom(key) {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  return regex.test(key);
}

export const assignMaskAndValidate = (value, check) => {
  const regex = /^[^\d]{3}/;

  if (check === 1) {
    return {
      id: 1,
      mask: '999.999.999-99',
      type: 'CPF',
    };
  }

  if (check === 4) {
    return {
      id: 4,
      mask: '(99) 99999-9999',
      type: 'Telefone',
    };
  }
  if (validateEmail(value)) {
    return {
      id: 3,
      mask: false,
      type: 'E-mail',
    };
  }

  if (validateRandom(value)) {
    return {
      id: 5,
      mask: ['99999999-9999-9999-9999-999999999999'],
      type: 'Chave Aleatória',
    };
  }
  if (!value || regex.test(value)) {
    return {
      id: 0,
      mask: false,
      type: 'Chave Pix',
    };
  }

  const maskMap = [
    { id: 1, mask: ['999.999.999-99'], validator: validateCPF, type: 'CPF' },
    {
      id: 2,
      mask: ['99.999.999/9999-99'],
      validator: validateCNPJ,
      type: 'CNPJ',
    },
    {
      id: 3,
      mask: false,
      validator: validateEmail,
      type: 'E-mail',
    },
    {
      id: 4,
      mask: ['(99)99999-9999'],
      validator: validatePhone,
      type: 'Telefone',
    },
    {
      id: 5,
      mask: ['99999999-9999-9999-9999-999999999999'],
      validator: validateRandom,
      type: 'Chave Aleatória',
    },
  ];

  const validItem = maskMap.filter((item) => item.validator(value));

  if (validItem.length === 0) {
    return {
      id: 5,
      mask: ['99999999-9999-9999-9999-999999999999'],
      type: 'Chave Aleatória',
    };
  }
  if (validItem[0]?.id === 1 && validItem[1]?.id === 4) {
    return {
      id: 6,
      mask: ['(99)99999-9999', '999.999.999-99'],
      type: 'CPF ou Telefone',
    };
  }

  if (!validItem) {
    return {
      id: 5,
      mask: ['99999999-9999-9999-9999-999999999999'],
      type: 'Chave Aleatória',
    };
  }

  return validItem[0];
};
