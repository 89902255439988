import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';
import { AiOutlineArrowRight } from 'react-icons/ai';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiChevronDown } from 'react-icons/bi';

const ValidateSelect = yup.object().shape({
  type: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .test('is-type', 'Selecione um tipo de Pagamento', (value) => {
      return value !== '0';
    })
    .required('Tipo de pagamento é obrigatório'),
});
export default function ConfirmPurchase({
  formStep,
  nextFormStep,
  prevFormStep,
}) {
  const { setTypePayment } = useFormData();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ resolver: yupResolver(ValidateSelect) });

  const onSubmit = (values) => {
    setTypePayment(Number(values.type));
    nextFormStep();
  };
  const types = [
    {
      type: 1,
      name: 'Pagamento com Arquivo',
    },
    {
      type: 2,
      name: 'Pagamentos',
    },
  ];

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow}>
          <div className={styles.select_type}>
            <div className={styles.select}>
              <select
                name="Tipo de pagamento?"
                {...register('type', { required: true })}
              >
                <option value="0"> Selecione o tipo de Pagamento:</option>
                {types.map((type) => (
                  <option key={type.type} value={type.type}>
                    {type.name}
                  </option>
                ))}
              </select>
              <BiChevronDown color="var(--primary-700)" size={'2rem'} />
            </div>

            {errors.type && (
              <p className={styles.errorText}>{errors.type.message}</p>
            )}
          </div>
        </div>

        <div className={styles.next_button}>
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
