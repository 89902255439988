import React, { useMemo } from 'react';
import subDays from 'date-fns/subDays';
import { DateRangePicker } from 'rsuite';
import {
  predefinedRangesAnticipation,
  predefinedRangesPayment,
  predefinedRangesExtract,
} from './data';
import { IconCalendarRanger } from '../Details/Icons';
/**
 * Componente que exibe um seletor de faixa de datas personalizado com predefinições de períodos.
 *
 * O componente `DatePicker` permite ao usuário selecionar uma faixa de datas com base em predefinições específicas, como "extrato", "pagamento" ou "antecipação". Ele fornece uma interface de seleção de datas amigável ao usuário e suporta diferentes idiomas por meio do pacote `rsuite`.
 *
 * @component
 * @param {function} setDate - Uma função para atualizar o estado da data selecionada.
 * @param {object} style - Um objeto de estilo opcional para personalizar a aparência do componente.
 * @param {number} type - Um número que representa o tipo de predefinição de faixa de datas (1 para extrato, 2 para pagamento, 3 para antecipação).
 * @returns {JSX.Element} Um seletor de faixa de datas personalizado.
 *
 * @example
 * // Exemplo de uso:
 * import { DatePicker } from './DatePicker';
 *
 * function MeuComponente() {
 *   const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
 *
 *   return (
 *     <DatePicker
 *       setDate={setSelectedDate}
 *       style={{ width: 300 }}
 *       type={1}
 *     />
 *   );
 * }
 */
export const DatePicker = ({
  setDate,
  style = { width: 400 },
  type = 1,
  disabled = false,
  value = [subDays(new Date(), 6), new Date()],
}) => {
  /**
   * Calcula e retorna um conjunto de faixas de data predefinidas com base no tipo.
   *
   * @param {number} type - O tipo de faixas de data predefinidas a serem retornadas (1, 2 ou 3).
   * @returns {array} Um conjunto de faixas de data predefinidas com base no tipo especificado.
   */
  const predefinedRanges = useMemo(() => {
    const rangesByType = {
      1: predefinedRangesExtract,
      2: predefinedRangesPayment,
      3: predefinedRangesAnticipation,
    };

    return rangesByType[type] || predefinedRangesExtract; // Use predefinedRangesExtract as default
  }, [type]);

  return (
    <DateRangePicker
      ranges={predefinedRanges}
      onClean={() => setDate([subDays(new Date(), 6), new Date()])}
      placeholder="Período"
      style={style}
      format="dd/MM/yyyy"
      defaultValue={[subDays(new Date(), 6), new Date()]}
      value={value}
      onChange={setDate}
      disabled={disabled}
      caretAs={IconCalendarRanger}
    />
  );
};
