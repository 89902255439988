import React from 'react';
import { useState } from 'react';
import styles from './styles.module.scss';
import { exportEcxel } from '../../utils/exportEcxel';
import { IconAction, IconCancel, IconCheck, IconExport } from './icons';

export function ButtonMenuActions({
  listSelect,
  orders,
  handleSendPayment,
  handleSendCancel,
}) {
  const [active, setActive] = useState(false);
  const count_listSelect = listSelect.length;
  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      Ações <IconAction size={'1.8rem'} />
      {active && (
        <div className={styles.menu}>
          {count_listSelect > 0 && (
            <>
              <button
                className={styles.button__plus}
                onClick={() => handleSendPayment()}
              >
                <IconCheck size={'1.5rem'} />
                Aprovar ({count_listSelect})
              </button>
              <button
                className={styles.button__plus}
                onClick={() => handleSendCancel()}
              >
                <IconCancel size={'1.5rem'} />
                Cancelar ({count_listSelect})
              </button>
            </>
          )}
          {orders && orders.length > 0 && (
            <button
              className={styles.button__plus}
              onClick={() => exportEcxel({ orders })}
            >
              <IconExport size={'1.5rem'} />
              Exportar relatório
            </button>
          )}
        </div>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
