import { status, type, typeDocument } from '../pages/Collaborators/data';
import { formatDate } from './formatDate';
import { formatPhoneNumber } from './formatPhoneNumber';

const translations = {
  liscensePlate: 'Placa',
  chassis: 'Chassi',
  renavam: 'Renavam',
  manufactureYear: 'Ano de Fabricação',
  modelYear: 'Ano do Modelo',
  brandModel: 'Marca/Modelo',
  color: 'Cor',
  vehicleType: 'Tipo de Veículo',
  licensePlateCity: 'Cidade da Placa',
  licensePlateState: 'Estado da Placa',
  proprietaryName: 'Nome do Proprietário',
  proprietaryDocumentNumber: 'Número do Documento do Proprietário',
  NOME_DO_AGENCIADOR: 'Nome do Agenciador',
  DOCUMENTO_DO_AGENCIADOR: 'Documento do Agenciador',
  VALOR_DA_DIARIA: 'Valor da Diária',
  VALOR_DA_DIARIA_AJUDANTE: 'Valor da Diária do Ajudante',
  VALOR_DO_SERVICO: 'Valor do Serviço',
  COMODATO: 'Certificado de propriedade Nº',
  ENDERECO: 'Endereço',
  NOME: 'Nome',
  DATA_ASSINATURA: 'Data de Assinatura',
  RG: 'Registro Geral (RG)',
  CNH: 'Carteira Nacional de Habilitação (CNH)',
  INSCRICAO_MUNICIPAL: 'Inscrição Municipal',
  INSS: 'Número de Identificação do INSS',
  RNTRC: 'Registro Nacional de Transportadores Rodoviários de Carga (RNTRC)',
  CHASSI: 'Chassi',
  EMISSOR_RG: 'Órgão Emissor do RG',
  EMISSOR_CNH: 'Órgão Emissor da CNH',
  CATEGORIA_CNH: 'Categoria da CNH',
  RAZAO_SOCIAL_TRANSPORTADORA: 'Razão Social da Transportadora',
  ENDERECO_TRANSPORTADORA: 'Endereço da Transportadora',
  CNPJ_TRANSPORTADORA: 'CNPJ da Transportadora',
  RAZAO_SOCIAL: 'Razão Social',
  ENDERECO_CNPJ: 'Endereço do CNPJ',
  NOME_PROPRIETARIO_CNPJ: 'Nome do Proprietário do CNPJ',
  CPF_PROPRIETARIO_CNPJ: 'CPF do Proprietário do CNPJ',
  NOME_PROPRIETARIO_VEICULO: 'Nome do Proprietário do Veículo',
  CPF_PROPRIETARIO_VEICULO: 'CPF do Proprietário do Veículo',
  PLACA: 'Placa do Veículo',
  COR: 'Cor do Veículo',
  RENAVAM: 'Número RENAVAM',
  MARCA: 'Marca do Veículo',
  MODELO: 'Modelo do Veículo',
  ANO: 'Ano do Veículo',
  LICENSE_PLATE_CITY: 'Cidade da Placa',
  VEHICLE_TYPE: 'Tipo de Veículo',
  COMBUSTIVEL: 'Tipo de Combustível',
  ANO_FABRICACAO: 'Ano de Fabricação',
  file: 'Arquivo',
};

export const formatCollaborator = (item) => {
  const documents = item?.documents?.map((document) => {
    return { ...document, type: formatDocumentType(document?.type) };
  });

  const vehicleData = item?.additional_data?.vehicle;

  const vehicle = vehicleData
    ? Object.entries(item?.additional_data.vehicle).map(
        ([title, value], index) => ({
          index: index,
          key: translations[title],
          value: value,
        }),
      )
    : false;

  delete item?.additional_data?.vehicle;

  const additional_data_json = item?.additional_data
    ? Object.entries(item?.additional_data).map(([title, value], index) => ({
        index: index,
        key: translations[title],
        value: value,
      }))
    : false;

  return {
    ...item,
    id: item?.key,
    onboarding_key: item?.key,
    phone_number: formatPhoneNumber(item?.phone_number ?? '---'),
    cpf: item?.cpf,
    cnpj: item?.cnpj,
    status: formatStatusOnboarding(item?.status),
    date_added: item?.created_at
      ? formatDate(item.created_at, 'dd/MM/yyyy - HH:mm:ss')
      : '---',
    id_customer: item?.id_customer,
    type: formatType(item?.type),
    email: item?.email,
    name: item?.name ?? '---',
    selfie_url: item?.selfie_url,
    is_contract_required: item?.contract_type ? true : false,
    contract_type: item?.contract_type,
    contract_name: item?.contract_name,
    updated_at: item?.updated_at,
    additional_data: item?.additional_data,
    addresses: item?.addresses ?? false,
    documents: documents ?? false,
    document: item?.cnpj ? item?.cnpj : item?.cpf,
    type_document: item?.cnpj ? 'PJ' : 'PF',
    additional_data_json: additional_data_json ?? null,
    vehicle: vehicle ?? null,
  };
};

function formatType(id) {
  const typeFormatted = type.find((item) => {
    return item.idOnboardingType === id;
  });

  return typeFormatted;
}
function formatDocumentType(id) {
  const documentTypeFormatted = typeDocument.find((item) => {
    return item.id === id;
  });

  return documentTypeFormatted;
}
export function formatStatusOnboarding(id) {
  const statusFormatted = status.find((item) => {
    return item.idOnboardingStatus === id;
  });

  return statusFormatted;
}
