import React, { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';
import { HiDotsVertical } from 'react-icons/hi';
import {
  AiOutlineDownCircle,
  AiOutlineLock,
  AiOutlineUnlock,
} from 'react-icons/ai';

import { InputSearch } from '../../components/UI/InputSearch';
import { apiGetBalance, apiMutationCompany } from '../../services/api';
import { formatMoney } from '../../utils/formatMoney';
import { lower } from '../../utils/lower';
import styles from './styles.module.scss';
import { useUser } from '../../hooks/useUser';
import { Link, useHistory } from 'react-router-dom';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { usePagination } from '../../hooks/usePagination';
import { BiAddToQueue } from 'react-icons/bi';
import { useCompany } from '../../hooks/useCompany';
import { useAllBalancesQuery } from '../../hooks/useQuery';
import { IconEyes } from '../../components/Details/Icons';
import { InputDebounce } from './components/InputDebounce';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default function Balances() {
  const [search, setSearch] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState('');

  const { setMessage, setError, user } = useUser();
  const { data, isFetching: loading } = useAllBalancesQuery(value);
  const { userSelectCompanyId } = useCompany();

  function Card({ company }) {
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(company.balance);
    const [visible, setVisible] = useState(true);
    const [openMenu, setOpenMenu] = useState(false);

    function handlerPushCompanyPage() {
      history.push({
        pathname: `/menu/company/${company.company_id}`,
        state: company,
      });
    }

    function handleCompanyIdSelect(company) {
      userSelectCompanyId(company);
    }

    async function handlerMutationStatus(isCompany) {
      try {
        setLoading(true);
        if (isCompany.financially_blocked) {
          const { status } = await apiMutationCompany(
            '/company/unblock',
            isCompany.company_id,
          );

          if (status === 200) {
            isCompany.financially_blocked = false;
            setMessage({ customers: 'Conta Desbloqueada' });
          }
        } else {
          const { status } = await apiMutationCompany(
            '/company/block',
            isCompany.company_id,
          );

          if (status === 200) {
            isCompany.financially_blocked = true;
            setMessage({ customers: 'Conta Bloqueada' });
          }
        }
      } catch (error) {
        setError({ customers: 'Não foi possível' });
      } finally {
        setOpenMenu(!openMenu);
        setLoading(false);
      }
    }

    async function fetchGetBalance() {
      try {
        setLoading(true);
        setBalance(false);
        const { data } = await apiGetBalance(
          '/company/balance',
          company.company_id,
        );

        const { after } = await data;
        setBalance(after);
      } catch (error) {
        setBalance(0.0);
      } finally {
        setVisible(!visible);
        setLoading(false);
      }
    }

    return (
      <div className={styles.card}>
        <ul>
          <button className={styles.buttonNav} onClick={handlerPushCompanyPage}>
            <IconEyes size="1.2rem" color="var(--gray-500)" />
          </button>
          {company.fantasy_name === company.social_name ? (
            <div className={styles.wrapper_name}>
              <li
                className={
                  company?.status_company === 4
                    ? styles.Alert
                    : company.financially_blocked === true
                    ? styles.Disabled
                    : styles.Active
                }
              >
                {company.social_name.toUpperCase()}
              </li>
            </div>
          ) : (
            <div className={styles.wrapper_name}>
              <li
                className={
                  company?.status_company === 4
                    ? styles.Alert
                    : company.financially_blocked === true
                    ? styles.Disabled
                    : styles.Active
                }
              >
                {company.social_name.toUpperCase()}
              </li>
              <li className={styles.fantasy_name}>
                {company.fantasy_name.toUpperCase()}
              </li>
            </div>
          )}
          <div className={styles.wrapper_button_nav}>
            <li className={styles.document}>
              <span>{company.document}</span>
            </li>
          </div>
          <li className={styles.date_added}>{company?.date_added}</li>
          {[5].every((item) => user?.permission.includes(item)) ? (
            <>
              <li
                className={
                  company?.status_company === 4
                    ? styles.Alert
                    : company.financially_blocked === true
                    ? styles.Disabled
                    : styles.Active
                }
                onClick={fetchGetBalance}
              >
                {loading ? (
                  <LoadingBall />
                ) : typeof balance !== 'boolean' ? (
                  formatMoney(balance)
                ) : (
                  typeof balance === 'boolean' && <span> - - - </span>
                )}
              </li>
            </>
          ) : (
            <div />
          )}

          <div className={styles.actions}>
            <HiDotsVertical
              color="#a1a0bd"
              className={styles.button_action}
              onClick={() => setOpenMenu(!openMenu)}
            />
            <div
              className={
                openMenu ? styles.menu_actions : styles.menu_actions_desative
              }
            >
              {[4].every((item) => user?.permission.includes(item)) &&
                company?.financially_blocked && (
                  <button
                    className={styles.button_actions}
                    onClick={() => handlerMutationStatus(company)}
                  >
                    {company?.financially_blocked ? 'Desbloquear' : 'Bloquear'}
                    {company?.financially_blocked ? (
                      <AiOutlineUnlock />
                    ) : (
                      <AiOutlineLock />
                    )}
                  </button>
                )}
              {[3].every((item) => user?.permission.includes(item)) &&
                !company?.financially_blocked && (
                  <button
                    className={styles.button_actions}
                    onClick={() => handlerMutationStatus(company)}
                  >
                    {company?.financially_blocked ? 'Desbloquear' : 'Bloquear'}
                    {company?.financially_blocked ? (
                      <AiOutlineUnlock />
                    ) : (
                      <AiOutlineLock />
                    )}
                  </button>
                )}
              <button
                className={styles.button_actions}
                onClick={() => handleCompanyIdSelect(company)}
              >
                Conectar
                <AiOutlineDownCircle />
              </button>
            </div>
            <div
              onClick={() => setOpenMenu(false)}
              className={openMenu ? styles.overlay : styles.overlay_disabled}
            ></div>
          </div>
        </ul>
      </div>
    );
  }

  const list = data?.allBalance
    ? data?.allBalance
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item['fantasy_name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item['social_name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search) return item;

          return item['document']
            ?.replace(/\D/g, '')
            .includes(search.replace(/\D/g, ''));
        })
    : [];

  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    50,
    '/menu/bank-slip/',
  );

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper_balances}>
        <div className={styles.header_style}>
          <h1 className={styles.title_style}>Minhas Contas</h1>
          {[2].every((item) => user?.permission.includes(item)) && (
            <Link to="/menu/account">
              <BiAddToQueue color="var(--white)" size="1.125rem" />
              <span>Criar Conta</span>
            </Link>
          )}
        </div>
        {[0].every((item) => user?.permission.includes(item)) ? (
          <div className={styles.search_container}>
            <InputDebounce
              label="Pesquisar por nome ou Documento"
              type="text"
              setValue={setValue}
              value={value}
              Icon={RiSearch2Line}
            />
          </div>
        ) : (
          <div className={styles.search_container}>
            <InputSearch
              label="Pesquisar por nome ou CNPJ"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
              width="100%"
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            borderRadius: '0.5rem',
            height: '4rem',
          }}
        >
          <div className={styles.wrapper__selection}>
            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
        <div className={styles.cards}>
          {currentItens.length >= 1 ? (
            currentItens.map((company) => (
              <Card key={company.company_id} company={company} />
            ))
          ) : (
            <div className={styles.listLoader}>
              {loading && (
                <div style={{ width: '12rem' }}>
                  <LoadingBall />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
