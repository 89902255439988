/**
 * O componente `IconAlert` renderiza um ícone de alerta.
 *
 * @component
 * @returns {React.Element} - O ícone de alerta.
 */
function IconAlert() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#FF8C00" rx="6"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M12 9v5"
      ></path>
      <circle cx="12" cy="17" r="1" fill="#fff"></circle>
    </svg>
  );
}

/**
 * O componente `IconCheck` renderiza um ícone de marca de verificação.
 *
 * @component
 * @returns {React.Element} - O ícone de marca de verificação.
 */
function IconCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#40B66C" rx="6"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.5 12.5l2 2 5-5"
      ></path>
    </svg>
  );
}

/**
 * O componente `IconInfo` renderiza um ícone de informação.
 *
 * @component
 * @returns {React.Element} - O ícone de informação.
 */
function IconInfo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#4496FC" rx="6"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M12 16v-5"
      ></path>
      <circle
        cx="1"
        cy="1"
        r="1"
        fill="#fff"
        transform="matrix(1 0 0 -1 11 9)"
      ></circle>
    </svg>
  );
}

/**
 * O componente `IconError` renderiza um ícone de erro.
 *
 * @component
 * @returns {React.Element} - O ícone de erro.
 */
function IconError() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#FF326A" rx="6"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M15 9l-6 6m0-6l6 6"
      ></path>
    </svg>
  );
}

export { IconAlert, IconCheck, IconInfo, IconError };
