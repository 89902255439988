import * as S from './styles';
const Dashboard = () => {
  const dashboard = process.env.REACT_APP_DASH;
  return (
    <>
      <S.Container
        src={dashboard}
        allowfullscreen
        allow="encrypted-media"
        frameborder="0"
      ></S.Container>
    </>
  );
};

export default Dashboard;
