function IconBusinessLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.25rem"
      height="6.25rem"
      className="illustration"
      viewBox="0 0 500 500"
    >
      <path
        fill="#380195"
        d="M107.36 150.8v98s4.5 10.14 14.64 12.39 217.36 9 217.36 9l-229.75 7.88v102.48s4.51 18 18 19.15 280.43 0 280.43 0l-6.75 12.39-309.7 1.12-5.63-18-2.25-118.27-9-20.28V145.17z"
        data-name="Layer 14"
      ></path>
      <g data-name="Layer 11">
        <path
          fill="none"
          d="M246.62 91.13a70.83 70.83 0 00-66.32 46.2h132.64a70.82 70.82 0 00-66.32-46.2z"
        ></path>
        <path
          fill="#051532"
          d="M419 400.53V280a24 24 0 0010.23-19.64V144.33a7 7 0 00-7-7H327.7a84.7 84.7 0 00-162.16 0H77.8a7 7 0 00-7 7v116.06A24 24 0 0081 280v120.53a22.36 22.36 0 0022.34 22.34h293.29A22.36 22.36 0 00419 400.53zm-3.77-249.2v109.06a10 10 0 01-10 10h-69.57v-26.11a7 7 0 00-14 0v26.11H175.17v-26.11a7 7 0 00-14 0v26.11H94.8a10 10 0 01-10-10V151.33zM95 400.53V284.39h66.16v9.2h-13.82a7 7 0 00-7 7v38.85a7 7 0 007 7h42.23a7 7 0 007-7v-38.85a7 7 0 00-7-7h-14.4v-9.2h146.49v9.2h-15.8a7 7 0 00-7 7v38.85a7 7 0 007 7h42.23a7 7 0 007-7v-38.85a7 7 0 00-7-7h-12.43v-9.2H405v116.14a8.35 8.35 0 01-8.34 8.34H103.35a8.35 8.35 0 01-8.35-8.34zm59.33-92.94h28.23v24.85h-28.22zm158.52 0h28.23v24.85h-28.22zM246.62 91.13a70.82 70.82 0 0166.32 46.2H180.3a70.83 70.83 0 0166.32-46.2z"
        ></path>
      </g>
    </svg>
  );
}

function IconUserLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.25rem"
      height="6.25rem"
      fill="none"
      viewBox="0 0 500 500"
    >
      <path
        fill="#380195"
        d="M295 112.65s14 67.35-38.35 83.45c-52.35 16.1-66.3-1.65-66.3-1.65s9 27.85 55.65 27.85 88.35-67.95 49-109.65zM249.3 282.8s60.5 3.3 79.35-54L291.8 225s16.4 30.8-42.5 57.8zM322.9 317.2S291.8 381 237 383.45c0 0 47.45.85 85.9-10V317.2zM330.3 307.35s30-31.05 29.45-58.9a82.542 82.542 0 0128.6 56.55l-58.05 2.35z"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M241.4 383.85A315.001 315.001 0 0193.15 347.3v-38.15a81.248 81.248 0 0111.85-42.5A82.444 82.444 0 01136.5 237a81.536 81.536 0 0137.7-9.35h133.85a77.94 77.94 0 0114.45 1.2 81.353 81.353 0 0155 37.65v.3a80.545 80.545 0 0111.7 42.05"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M293.8 227.5a52.649 52.649 0 01-89.879 37.229A52.649 52.649 0 01188.5 227.5h105.3zM241 221.75c38.439 0 69.6-31.161 69.6-69.6 0-38.439-31.161-69.6-69.6-69.6-38.439 0-69.6 31.161-69.6 69.6 0 38.439 31.161 69.6 69.6 69.6z"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M323 373.05a316.236 316.236 0 01-81.6 10.8A315.001 315.001 0 0193.15 347.3v-38.15a81.248 81.248 0 0111.85-42.5A82.444 82.444 0 01136.5 237a81.536 81.536 0 0137.7-9.35h133.85a77.94 77.94 0 0114.45 1.2 81.353 81.353 0 0155 37.65v.3a80.545 80.545 0 0111.7 42.05"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M293.8 227.5a52.649 52.649 0 01-89.879 37.229A52.649 52.649 0 01188.5 227.5h105.3z"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M298.75 190.95a69.552 69.552 0 01-124.239-59.076 69.552 69.552 0 0176.714-48.558A69.55 69.55 0 01310.6 152a69.4 69.4 0 01-11.85 38.95zM424.15 406.45l-36.2 26.8a12.398 12.398 0 01-14.35 0l-36.2-26.8a37.8 37.8 0 01-13.7-28.5v-71.5h114.15v71.5a37.798 37.798 0 01-13.7 28.5v0z"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M367.05 347.099v-6.75a12.2 12.2 0 0112.2-12.2h3.05a12.2 12.2 0 0112.2 12.2v10"
      ></path>
      <path
        stroke="#051532"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M409.65 350.2H351.9v37.85h57.75V350.2z"
      ></path>
    </svg>
  );
}

export { IconBusinessLine, IconUserLine };
