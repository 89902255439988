import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import logo from '../../Assets/logotextblack.svg';
import { getCookieNpsSystem, setCookieNpsSystem } from '../../Helpers/cookies';
import { useUser } from '../../hooks/useUser';
import { NpsSurveyAnimated } from './survey';

export function NpsModal(props = false) {
  const [seeModal, setSeeModal] = useState(false);
  const [nps, setNps] = useState();
  const { userLogout } = useUser();
  const info = [
    {
      message: `
        Você é importante para nós, por esse motivo criamos essa pesquisa.
        Participe e ajude-nos à melhorar cada dia mais.`,
    },
    {
      subtitle: 'Precisamos da sua ajuda!',
      message: `
        Sua opinião faz a diferença e nos ajuda a criar soluções que realmente fazem a diferença no seu dia a dia.
      `,
    },
    {
      subtitle: 'Queremos que sua experiência seja incrível!',
      message: `
      Participe da nossa pesquisa e nos diga como podemos tornar nossos serviços ainda melhores para você. 
      `,
    },
  ];

  useEffect(() => {
    getNps();

    if (props.open) handleSeeModal();
    // eslint-disable-next-line
  }, [props]);

  async function getNps() {
    const nps = await getCookieNpsSystem();

    setNps(nps);
  }

  async function handleAfterNps() {
    if (nps >= 200) return userLogout();

    if (nps >= 3 && nps <= 200) {
      setCookieNpsSystem(200);
      return userLogout();
    }

    setNps(Number(nps) + 1);
    setCookieNpsSystem(Number(nps) + 1 || 1);
    setSeeModal(false);

    userLogout();
  }

  async function handleSignInNps() {
    setCookieNpsSystem(200);

    window.open('https://bit.ly/3Ws83YI', '_blank');

    userLogout();
  }

  async function handleSeeModal() {
    await getNps();

    if (nps < 1 || !nps) setNps(1);

    if (Number(nps) >= 4) {
      setCookieNpsSystem(200);

      return userLogout();
    } else {
      setSeeModal(true);
    }
  }

  return (
    <div
      style={{ visibility: seeModal ? 'visible' : 'hidden' }}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <img src={logo} alt="Ox pay" width={150} />
        <h2>Pesquisa de satisfação</h2>
        <h4>{nps > 0 && info[Number(nps) - 1]?.subtitle}</h4>
        <p>{(nps && info[Number(nps) - 1 || 0]?.message) || info[0].message}</p>
        <div className={styles.canvas}>
          {seeModal && <NpsSurveyAnimated width="300" />}
        </div>
        <div className={styles.footer}>
          <button
            style={{
              display: nps === 3 ? '' : nps > 3 && nps <= 200 ? 'none' : '',
            }}
            className={styles.buttonLatter}
            onClick={handleAfterNps}
          >
            {Number(nps) === 3 ? 'Não ver isso novamente' : 'Talvez mais tarde'}
          </button>
          <button className={styles.button} onClick={handleSignInNps}>
            Participar
          </button>
        </div>
      </div>
    </div>
  );
}
