import React, { useState } from 'react';
import styles from './styles.module.scss';
import logo from '../../Assets/logotextblack.svg';

import { useHistory } from 'react-router-dom';

const RecoverGetToken = () => {
  const [token, setToken] = useState('');
  const history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();

    history.push(`/recover/${token}`);
  }
  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label htmlFor="token">Preencha com seu Token</label>
          <p>
            Copie o token que você recebeu no email de recuperação e insira
            abaixo para validação.
          </p>

          <input
            className={styles.input}
            id="token"
            onChange={(e) => setToken(e.target.value)}
            type="text"
            required
            placeholder="token"
          />

          <button type="submit">Confirmar</button>
        </form>
      </div>
    </section>
  );
};

export default RecoverGetToken;
