import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';

import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineMail,
} from 'react-icons/ai';
import { BiPhone } from 'react-icons/bi';
import { FaFileContract, FaHandsHelping, FaIdCard } from 'react-icons/fa';
import { useState } from 'react';
import { apiPost } from '../../../../services/api';
import { useUser } from '../../../../hooks/useUser.js';
import { LoadingBall } from '../../../../components/UI/LoadingBall';
export default function Preview({ currentStep, nextFormStep, prevFormStep }) {
  const { form } = useFormData();
  const { handleSubmit } = useForm();
  const { setError } = useUser();
  const [loading, setLoading] = useState(false);
  function capitalizeName(name) {
    const nameParts = name.toLowerCase().split(' ');

    const capitalizedParts = nameParts.map((part) => {
      if (part.length > 0) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      }
      return part;
    });

    return capitalizedParts.join(' ');
  }

  form.date_added = new Date().toISOString();

  async function fetchGetOnboardingCustomer(document) {
    setLoading(true);
    try {
      const onboardingStart = {
        name: capitalizeName(form.name),
        cpf: form.cpf,
        phone_number: form.phone_number,
        email: form.email,
        is_contract_required: JSON.parse(form.is_contract_required),
        type: Number(form.type),
      };

      if (form.cnpj) {
        onboardingStart.cnpj = form.cnpj;
      }
      const { error } = await apiPost(`/Onboarding`, {
        ...onboardingStart,
      });

      return error ? false : true;
    } catch (error) {
      setError('Erro ao cadastrar');
    } finally {
      setLoading(false);
    }
  }
  const onSubmit = async () => {
    const responseOnboarding = await fetchGetOnboardingCustomer();
    responseOnboarding && nextFormStep();
  };

  const types = [
    {
      idOnboardingType: '1',
      name: 'Cadastro de agregado (conta)',
    },
    {
      idOnboardingType: '2',
      name: 'Cadastro de empresa',
    },
    {
      idOnboardingType: '3',
      name: 'Cadastro de funcionário',
    },
  ];

  function getTypeNameById(id) {
    const foundType = types.find((type) => type.idOnboardingType === id);
    return foundType ? foundType.name : 'Tipo não encontrado';
  }
  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow_preview}>
          {loading ? (
            <LoadingBall />
          ) : (
            <div className={styles.box_preview}>
              {form?.name && (
                <p className={styles.items}>
                  <h2> {form?.name}</h2>
                </p>
              )}
              <div className={styles.box_flex}>
                <div className={styles.box_right}>
                  {form?.cnpj && (
                    <p className={styles.items}>
                      <strong>
                        <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                        CNPJ:
                      </strong>
                      <span> {form.cnpj}</span>
                    </p>
                  )}
                  <p className={styles.items}>
                    <strong>
                      <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                      CPF:
                    </strong>
                    <span> {form.cpf}</span>
                  </p>
                  <p className={styles.items}>
                    <strong>
                      <BiPhone size={'1.2rem'} color="var(--gray-400)" />
                      Celular:
                    </strong>
                    <span>{form.phone_number}</span>
                  </p>
                  <p className={styles.items}>
                    <strong>
                      <AiOutlineMail size={'1.2rem'} color="var(--gray-400)" />
                      Email:
                    </strong>
                    <span> {form?.email}</span>
                  </p>
                </div>

                <div className={styles.box_left}>
                  <p className={styles.items}>
                    <strong>
                      <FaFileContract size={'1.2rem'} color="var(--gray-400)" />
                      Contrato obrigatório:
                    </strong>
                    <span>{form?.is_contract_required ? 'SIM' : 'NÃO'}</span>
                  </p>
                  <p className={styles.items}>
                    <strong>
                      <FaHandsHelping size={'1.2rem'} color="var(--gray-400)" />
                      Contrato:
                    </strong>
                    <span> {getTypeNameById(form?.type)}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.next_button}>
          {currentStep > 0 && (
            <button onClick={prevFormStep} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Finalizar <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
