import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { PaymentContext } from '../data/paymentContext';
import { apiGet } from '../services/api';

export function usePayment() {
  const {
    stepPayment,
    setStepPayment,
    typePayment,
    setTypePayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    loading,
    setLoading,
    errosSteps,
    setErrosSteps,
    isSalary,
    setIsSalary,
    showModal,
    setShowModal,
    file,
    setFile,
    banks,
    setBanks,
    descriptionFile,
    errosFile,
    setErrosFile,
  } = useContext(PaymentContext);
  function clearStatePayment() {
    setStepPayment(0);
    setTypePayment(0);
    setPayment(false);
    setFinishPayment(false);
    setErrosSteps(false);
    setIsSalary(true);
    setShowModal(false);
    setFile(false);
    setErrosFile(false);
  }
  const queryClient = useQueryClient();

  useEffect(() => {
    if (stepPayment === 2) {
      handleReloadFetch('OrdersList');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepPayment]);

  async function fetchBanks() {
    setLoading({ paymentOrder: true });
    try {
      if (banks === false) {
        const { data } = await apiGet('/bank/bacen');
        const banksValues = data.map((item) => ({
          label: `${item.code} - ${item.name}`,
          value: item.code,
          ispb: item.ispb,
        }));
        setBanks(banksValues);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleCloseModal() {
    setPayment({ ...payment, is_salary: false });

    setShowModal(false);
  }

  function handleCloseIsModal() {
    setPayment({ ...payment, is_salary: true });

    setShowModal(false);
  }

  function handleSalary(data) {
    setPayment({ ...payment, ...data });
    if (data?.is_salary === true) {
      setShowModal(true);
    }
  }

  async function handleReloadFetch(type) {
    await queryClient.invalidateQueries([type]);
  }
  return {
    handleSalary,
    handleCloseIsModal,
    handleCloseModal,
    stepPayment,
    setStepPayment,
    typePayment,
    setTypePayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    errosSteps,
    setErrosSteps,
    isSalary,
    setIsSalary,
    showModal,
    setShowModal,
    file,
    setFile,
    banks,
    setBanks,
    fetchBanks,
    descriptionFile,
    errosFile,
    setErrosFile,
  };
}
