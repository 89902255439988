import React, { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';

import { InputSearch } from '../../components/UI/InputSearch';

import { lower } from '../../utils/lower';
import styles from './styles.module.scss';
import { useUser } from '../../hooks/useUser';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { usePagination } from '../../hooks/usePagination';
import { useCompany } from '../../hooks/useCompany';
import { useAllBalancesQuery } from '../../hooks/useQuery';
import { InputDebounce } from './components/InputDebounce';
import { Tooltip, Whisper } from 'rsuite';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default function AccountExchange({ setActive }) {
  const [search, setSearch] = useState(false);

  const [value, setValue] = useState('');

  const { user } = useUser();
  const { data, isFetching: loading } = useAllBalancesQuery(value);
  const { userSelectCompanyId } = useCompany();

  function Card({ company }) {
    function handleCompanyIdSelect(company) {
      setActive(false);
      userSelectCompanyId(company);
    }

    return (
      <Whisper
        trigger="hover"
        placement={'right'}
        controlId={`control-id-click`}
        speaker={<Tooltip>Conectar</Tooltip>}
      >
        <div
          className={styles.card}
          onClick={() => handleCompanyIdSelect(company)}
        >
          <ul>
            {company.fantasy_name === company.social_name ? (
              <div className={styles.wrapper_name}>
                <li
                  className={
                    company?.status_company === 4
                      ? styles.Alert
                      : company.financially_blocked === true
                      ? styles.Disabled
                      : styles.Active
                  }
                >
                  {company.social_name.toUpperCase()}
                </li>
              </div>
            ) : (
              <div className={styles.wrapper_name}>
                <li
                  className={
                    company?.status_company === 4
                      ? styles.Alert
                      : company.financially_blocked === true
                      ? styles.Disabled
                      : styles.Active
                  }
                >
                  {company.social_name.toUpperCase()}
                </li>
                <li className={styles.fantasy_name}>
                  {company.fantasy_name.toUpperCase()}
                </li>
              </div>
            )}
            <div className={styles.wrapper_button_nav}>
              <li className={styles.document}>
                <span>{company.document}</span>
              </li>
            </div>
          </ul>
        </div>
      </Whisper>
    );
  }

  const list = data?.allBalance
    ? data?.allBalance
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item['fantasy_name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item['social_name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search) return item;

          return item['document']
            ?.replace(/\D/g, '')
            .includes(search.replace(/\D/g, ''));
        })
    : [];

  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    5,
    '/menu/bank-search/',
  );

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper_balances}>
        <div className={styles.header}>
          <h1 className={styles.title}>Trocar de Conta</h1>
        </div>
        {[0].every((item) => user?.permission.includes(item)) ? (
          <div className={styles.search_container}>
            <InputDebounce
              label="Pesquisar por nome ou Documento"
              type="text"
              setValue={setValue}
              value={value}
              Icon={RiSearch2Line}
            />
          </div>
        ) : (
          <div className={styles.search_container}>
            <InputSearch
              label="Pesquisar por nome ou CNPJ"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
              width="100%"
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            borderRadius: '0.5rem',
            height: '4rem',
          }}
        >
          <div className={styles.wrapper__selection}>
            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
        <div className={styles.cards}>
          {currentItens.length >= 1 ? (
            currentItens.map((company) => (
              <Card key={company.company_id} company={company} />
            ))
          ) : (
            <div className={styles.listLoader}>
              {loading && (
                <div style={{ width: '12rem' }}>
                  <LoadingBall />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
