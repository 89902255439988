import { useState, createContext } from 'react';

export const FormContext = createContext();
const { Provider } = FormContext;

export default function FormProvider({ children }) {
  // form onboarding
  const [form, setForm] = useState(false);
  const [type, setType] = useState(false);
  const [isOnboarding, setOnboarding] = useState();
  //form payment
  const [formPayment, setFormPayment] = useState(false);
  const [typePayment, setTypePayment] = useState(false);
  const [payment, setPayment] = useState(false);

  return (
    <Provider
      value={{
        form,
        setForm,
        type,
        setType,
        isOnboarding,
        setOnboarding,
        typePayment,
        setTypePayment,
        formPayment,
        setFormPayment,
        payment,
        setPayment,
      }}
    >
      {children}
    </Provider>
  );
}
