import { formatCollaborator } from './formatCollaborator';
import { formatLinkedData } from './formatLinkedData';

export const formatDataOnboarding = (onboardingArray, linkedArray) => {
  const formattedOnboarding = onboardingArray.map(formatCollaborator);
  const formattedLinked = formatLinkedData(linkedArray);

  const mergedData = [...formattedOnboarding, ...formattedLinked].reduce(
    (acc, current) => {
      acc.set(current.id, current);
      return acc;
    },
    new Map(),
  );

  return Array.from(mergedData.values());
};
