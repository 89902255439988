import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip, Whisper } from 'rsuite';
import TooTipCopy from '../UI/ToolTipCopy';
import { IconCopy } from './Icons';
import styles from './styles.module.scss';
const WrapperToolTip = ({ children }) => {
  return (
    <Whisper
      trigger="hover"
      placement={'left'}
      controlId={`control-id-click`}
      speaker={<Tooltip>Copiar</Tooltip>}
    >
      {children}
    </Whisper>
  );
};
export function Details({
  Icon,
  title,
  value,
  copy = false,
  description = false,
  maxWidth = '100%',
  border = true,
  Context = false,
  padding = '0.87rem',
  left = false,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (text) => {
    setIsCopied(text);

    setInterval(() => {
      setIsCopied(false);
    }, 2000);
  };
  const CardCopy = ({ text, isCopied, handleCopyClick, id }) => {
    return (
      <div className={styles.card_box}>
        {isCopied && (
          <div className={styles['check-wrap-box']}>
            <div className={styles['check-wrap']}></div>
          </div>
        )}

        <div className={styles.key}>
          <TooTipCopy
            text={isCopied ? 'Copiado' : false}
            id={id}
            keyMovements={id}
          >
            <WrapperToolTip>
              <CopyToClipboard text={text}>
                <button onClick={() => handleCopyClick(text)}>
                  <IconCopy />
                </button>
              </CopyToClipboard>
            </WrapperToolTip>
          </TooTipCopy>
        </div>
      </div>
    );
  };
  const styles_ = {
    maxWidth,
    padding,
    ...(border ? {} : { border: 'none' }),
    ...(left ? { justifyContent: 'flex-start', gap: '0.5rem' } : {}),
  };
  if (description) {
    return (
      <div
        className={styles.box_}
        style={
          border
            ? { maxWidth: maxWidth, padding }
            : { maxWidth: maxWidth, border: 'none', padding }
        }
      >
        {title && (
          <div className={styles.header_}>
            {Icon && <Icon />}
            <p className={styles.title}>{title}</p>
          </div>
        )}
        <div className={styles.left_card_}>
          <p className={styles.value}>{Context ? <Context /> : value}</p>
          {copy && (
            <p>
              <CardCopy
                isCopied={isCopied}
                handleCopyClick={handleCopyClick}
                text={value}
                id={value}
              />
            </p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.box} style={styles_}>
      <div className={styles.header}>
        {Icon && <Icon />}
        <p className={styles.title}>{title}</p>
      </div>
      <div
        className={styles.left_card}
        style={left ? { justifyContent: 'space-between', width: '100%' } : {}}
      >
        <p className={styles.value}>{value}</p>

        {copy && (
          <p>
            <CardCopy
              isCopied={isCopied}
              handleCopyClick={handleCopyClick}
              text={value}
              id={value}
            />
          </p>
        )}
      </div>
    </div>
  );
}
