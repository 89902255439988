function IconFinally() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="123"
      fill="none"
      viewBox="0 0 124 123"
    >
      <rect
        width="122.838"
        height="122.838"
        x="0.582"
        fill="#EAFCF7"
        rx="61.419"
      ></rect>
      <path
        fill="#020246"
        fillRule="evenodd"
        d="M88.087 37.824L51.44 73.19 41.715 62.8c-1.791-1.69-4.606-1.792-6.653-.359-1.996 1.485-2.56 4.095-1.331 6.193l11.516 18.733c1.126 1.74 3.071 2.815 5.272 2.815 2.098 0 4.094-1.075 5.22-2.815 1.843-2.405 37.005-44.324 37.005-44.324 4.607-4.709-.972-8.854-4.657-5.272v.052z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export { IconFinally };
