import { formatDate } from './formatDate';
import { EnumerateCustomCapabilities } from './decode.js';
function capitalizeFirstLetter(sentence) {
  const words = sentence.split(' ');

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );

  const capitalizedSentence = capitalizedWords.join(' ');

  return capitalizedSentence;
}

export const formatCustomers = (item) => {
  const limit = item?.total_limit ? Number(item?.total_limit) : 0;
  const debit = item?.debit ? Number(item?.debit) : 0;

  return {
    debit: debit,
    customer_id: item?.customer_id,
    first_name: item?.first_name,
    last_name: item?.last_name,
    name: capitalizeFirstLetter(`${item?.first_name} ${item?.last_name}`),
    register: item?.register,
    document: item?.document ?? '',
    date_added: item.date_added
      ? formatDate(item.date_added, 'dd/MM/yyyy')
      : '---',
    is_active: item?.is_active,
    salary: item?.salary ?? 0,
    email: item?.email,
    telephone: item?.telephone,
    balance: limit - debit,
    limit: limit,
    company_id: item?.company_id,
    additional_company_ids: item?.additional_company_ids,
    profile: item?.profile,
    capabilities: item?.capabilities
      ? JSON.parse(EnumerateCustomCapabilities(item.capabilities))
      : null,
  };
};
