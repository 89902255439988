import { formatGateway } from '../../../../utils/formatGateway';

export const findValue = (obj, objectKey, fieldKey) => {
  if (objectKey) {
    if (obj[objectKey] && obj[objectKey].hasOwnProperty(fieldKey)) {
      return obj[objectKey][fieldKey];
    } else if (!obj[objectKey].hasOwnProperty(fieldKey)) {
      return fieldKey === 'bank_code' ? 'OX' : undefined;
    }
  } else {
    if (obj.hasOwnProperty(fieldKey)) return obj[fieldKey];
    for (let k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        const found = findValue(obj[k], null, fieldKey);
        if (found !== undefined) return found;
      }
    }
  }
  return undefined;
};

export async function processData(schema, data, token) {
  let result = {
    details: [],
    destiny: [],
    origin: [],
  };

  data.order.bank_code = {
    bank_code: formatGateway(data.movement.gateway),
    company_name:
      data.company?.social_reason ||
      data.company?.social_name ||
      data.company?.fantasy_name ||
      data.company?.accountable,
  };

  for (const [key, fields] of Object.entries(schema)) {
    for (const { object, field, translate, handle } of fields) {
      let value;
      if (Array.isArray(field)) {
        for (let f in field) {
          value = findValue(data, object, field[f]);
          if (!!value) break;
        }
      } else value = findValue(data, object, field);

      if (value !== undefined) {
        const processedValue = handle ? await handle(value) : value;
        result[key].push({
          field: translate,
          value: processedValue,
          order: fields.indexOf(field),
        });
      }
    }
  }

  for (const key of Object.keys(result)) {
    result[key].sort((a, b) => a.order - b.order);
  }

  return filterArrays(result);
}

function filterArrays(obj) {
  let resultado = {};

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      resultado[key] = obj[key];
    }
  }

  return resultado;
}
