import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { apiGet, apiPut } from '../../services/api';
import { useUser } from '../../hooks/useUser';
import FormGroup from '../../components/Forms/FormGroup';
import { useBalance } from '../../hooks/useBalance';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidateAddCompany } from '../../Helpers/validateAddCompanyPeople';
import { useCustomers } from '../../hooks/useCustomers';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
export function AddCompanyPeople() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(ValidateAddCompany) });
  const [matchAccountable, setMatchAccountable] = useState(false);

  const [loading, setLoading] = useState(false);
  const { user, setMessage, setError } = useUser();
  const { fetchGetAllBalances } = useBalance();
  const { fetchAddCustomers } = useCustomers();
  const [clear, setClear] = useState(false);
  const history = useHistory();
  const userIsCompany_default = [0].every((item) =>
    user?.permission.includes(item),
  );
  useEffect(() => {
    setValue('month_installment_limit', 1);
    setValue('closing_day', 1);
    setValue('transfer_fee', 0);
    setValue('webhook_url', null);
    setValue('social_reason', null);
    setValue('fantasy_name', null);

    //eslint-disable-next-line
  }, []);

  const onSubmit = async (body) => {
    try {
      setLoading(true);

      const { status, data } = await apiPut('/company', body);

      const customer = {
        is_active: true,
        first_name: body.accountable.split(' ')[0],
        last_name: body.accountable.split(' ')[1],
        document: body.cpf.replace(/[^\d]+/g, ''),
        email: body.email,
        telephone: body.phone,
      };
      const { company_id } = await data;

      if (status === 200) {
        reset();
        setValue('accountable_cpf', '');
        setValue('phone', '');
        setValue('zipcode', '');
        setValue('tel_contato', '');
        setClear(true);
        setMessage({ login: 'Empresa adicionada com sucesso' });

        if (status === 200 && !userIsCompany_default) {
          user.additional_company_ids = [
            ...user.additional_company_ids,
            company_id,
          ];
        }
        if (!userIsCompany_default) {
          await apiPut(`/customer/AdditionalCompanies`, {
            customer_id: user.customer_id,
            company_ids: [...user.additional_company_ids, company_id],
          });
        }

        const { status: isUserStatus, data: dataUserExist } = await apiGet(
          `/Customer/document/${body.cpf.replace(/[^\d]/g, '')}`,
        );

        if (dataUserExist) {
          const dataAdditionalCompanies = {
            customer_id: dataUserExist.customer_id,
            company_ids: [...dataUserExist.additional_company_ids, company_id],
          };

          if (isUserStatus === 200) {
            const { status: res } = await apiPut(
              `/customer/AdditionalCompanies`,
              dataAdditionalCompanies,
            );
            res === 200
              ? setMessage({ login: 'Usuário Adicionado' })
              : setError({ login: 'Usuário Adicionado' });
          }
        } else {
          await fetchAddCustomers({ ...customer, company: company_id });
        }
      }
    } catch (error) {
      setValue('closing_day', 1);
      setValue('month_installment_limit', 1);
      setValue('transfer_fee', 0);
      setValue('webhook_url', null);
    } finally {
      setLoading(false);
      fetchGetAllBalances();
      setClear(false);

      //setError({login: 'não foi possivel adicionar Empressa'})
    }
  };
  const handleChange = () => {
    const { accountable, phone } = getValues();

    if (!matchAccountable) {
      setValue('tel_contato', phone);
      setValue('contact', accountable);
    } else {
      setValue('tel_contato', '');
      setValue('contact', '');
    }
    setMatchAccountable(!matchAccountable);
  };

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title_}>
          <h1>Dados do Responsável</h1>
        </div>

        <div className={styles.box_1}>
          <FormGroup
            name="cpf"
            label="CPF:"
            type="text"
            data={{ ...register('cpf') }}
            disabled={loading}
            register={register}
            errors={errors}
            mask={'999.999.999-99'}
            setValue={setValue}
            autofocus={true}
            clean={clear}
          />
          <FormGroup
            name="accountable"
            label="Nome do Responsável:"
            type="text"
            data={{ ...register('accountable') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
        </div>

        <div className={styles.box_2}>
          <FormGroup
            name="phone"
            label="Telefone:"
            type="tel"
            data={{ ...register('phone') }}
            disabled={loading}
            register={register}
            errors={errors}
            setValue={setValue}
            mask={'(99) 99999-9999'}
            clean={clear}
          />
          <FormGroup
            name="email"
            label="E-mail:"
            type="email"
            data={{ ...register('contact') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
        </div>
        <div className={styles.title_}>
          <h1>Endereço</h1>
        </div>

        <div className={styles.box_3}>
          <FormGroup
            name="zipcode"
            label="CEP:"
            type="text"
            data={{ ...register('zipcode') }}
            disabled={loading}
            register={register}
            errors={errors}
            setValue={setValue}
            mask={'99999-999'}
            clean={clear}
          />
          <FormGroup
            name="street"
            label="Rua:"
            type="text"
            data={{ ...register('street') }}
            disabled={loading}
            register={register}
            errors={errors}
          />

          <FormGroup
            name="neighborhood"
            label="Bairro:"
            type="text"
            data={{ ...register('neighborhood') }}
            disabled={loading}
            register={register}
            errors={errors}
          />

          <FormGroup
            name="street_number"
            label="Número:"
            type="text"
            data={{ ...register('street_number') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
        </div>

        <div className={styles.box_4}>
          <FormGroup
            name="city"
            label="Cidade:"
            type="text"
            data={{ ...register('city') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="street_complement"
            label="Complemento:"
            type="text"
            data={{ ...register('street_complement') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="contact"
            label="Nome para Contato:"
            type="text"
            data={{ ...register('contact') }}
            disabled={loading}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="tel_contato"
            label="Telefone do Contato:"
            type="tel"
            data={{ ...register('tel_contato') }}
            disabled={loading}
            register={register}
            errors={errors}
            setValue={setValue}
            mask={matchAccountable ? false : '(99) 99999-9999'}
            clean={clear}
          />
          <div className={styles.box_5}>
            <div></div>
            <div>
              <input
                onChange={handleChange}
                checked={matchAccountable}
                type="checkbox"
                name="check"
                text="Preencher com dados dos responsáveis!"
                id=""
              />
              <label htmlFor="check">
                Preencher com dados dos responsáveis!
              </label>
            </div>
          </div>
        </div>

        <div className={styles.wrapper_button}>
          <button className={styles.button} type="submit" disabled={loading}>
            <AiOutlineCheckCircle color="var(--white)" size={24} />
            <span>Salvar</span>
          </button>

          <button
            className={styles.button}
            type="button"
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              history.goBack();
            }}
          >
            <BiArrowBack color="var(--white)" size={24} />
            <span>Voltar</span>
          </button>
        </div>
      </form>
    </section>
  );
}
