import React from 'react';
import { saveAs } from 'file-saver';
import { formatExcelData } from './formatExcelData';
import { ExcelGenerator } from './ExcelGenerator';
import { ExportButton } from './ExportButton';

export const ExportExcelExtract = ({ list, title }) => {
  const handleExportClick = () => {
    const formattedData = formatExcelData(list);
    const excelBuffer = ExcelGenerator.generateExcel(formattedData);

    const blobData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    saveAs(blobData, 'Relatório_extrato.xlsx');
  };

  return <ExportButton title={title} onClick={handleExportClick} />;
};
