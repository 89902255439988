import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';
import FormGroup from './FormGroup';
import { useState } from 'react';
import { validateCNPJ, validateCPF } from 'validations-br';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { LoadingBall } from '../../../../components/UI/LoadingBall';
import { apiGet } from '../../../../services/api';
const ValidateAddCompany = yup.object().shape({
  document: yup
    .string()
    .typeError('O campo deve receber um cpf.')
    .test(
      'is-cpf',
      'CPF ou CNPJ não é valido',
      (value) => validateCPF(value) || validateCNPJ(value),
    )
    .required('document é obrigatório'),
});

export default function Document({ nextFormStep }) {
  const { setFormValues, setType, setOnboarding } = useFormData();
  const [clear] = useState(false);
  const [loading, setLoading] = useState();

  async function fetchGetOnboardingCustomer(document) {
    setLoading(true);
    try {
      const { data } = await apiGet(`/Onboarding/${document}`);

      const user = await data;

      return user.length ? user[0] : false;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({ resolver: yupResolver(ValidateAddCompany) });

  const onSubmit = async (values) => {
    const isOnboardingExist = await fetchGetOnboardingCustomer(values.document);

    if (await isOnboardingExist) {
      setOnboarding(isOnboardingExist);
      nextFormStep();
    } else {
      const documentType = validateCPF(values.document)
        ? 'cpf'
        : validateCNPJ(values.document)
        ? 'cnpj'
        : null;
      setType(documentType);

      if (documentType === 'cnpj') {
        setType('cnpj');
        setFormValues({ cnpj: values.document });
      }

      if (documentType === 'cpf') {
        setType('cpf');
        setFormValues({ cpf: values.document });
      }
      nextFormStep();
    }
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {loading ? (
          <div className={styles.formRow_preview}>
            <LoadingBall />
          </div>
        ) : (
          <div className={styles.formRow}>
            <FormGroup
              name="document"
              label="Documento:"
              type="text"
              placeholder="999.999.999-99"
              data={{ ...register('document') }}
              disabled={false}
              register={register}
              errors={errors || { document: 'Documento inválido' }}
              mask={['999.999.999-99', '99.999.999/9999-99']}
              setValue={setValue}
              clean={clear}
            />
          </div>
        )}
        <div className={styles.next_button}>
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
