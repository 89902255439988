function IconBook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.1875rem"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#31519A"
        d="M11.25 17.437h-4.5c-4.072 0-5.813-1.74-5.813-5.813v-4.5c0-4.072 1.74-5.812 5.813-5.812h4.5c4.072 0 5.813 1.74 5.813 5.813v4.5c0 4.072-1.74 5.812-5.813 5.812zm-4.5-15c-3.458 0-4.688 1.23-4.688 4.688v4.5c0 3.457 1.23 4.687 4.688 4.687h4.5c3.457 0 4.688-1.23 4.688-4.688v-4.5c0-3.457-1.23-4.687-4.688-4.687h-4.5z"
      ></path>
      <path
        fill="#31519A"
        d="M11.813 7.687H6.186a.567.567 0 01-.562-.562c0-.308.255-.563.563-.563h5.625c.307 0 .562.255.562.563a.567.567 0 01-.563.562zM11.813 12.187H6.186a.567.567 0 01-.562-.563c0-.307.255-.562.563-.562h5.625c.307 0 .562.255.562.563a.567.567 0 01-.563.562z"
      ></path>
    </svg>
  );
}

function IconBookLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1.0625rem"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#686868"
        d="M10 15.541H6c-3.62 0-5.167-1.546-5.167-5.166v-4C.833 2.755 2.38 1.208 6 1.208h3.333c.273 0 .5.227.5.5s-.227.5-.5.5H6c-3.074 0-4.167 1.093-4.167 4.167v4c0 3.073 1.093 4.166 4.167 4.166h4c3.073 0 4.166-1.093 4.166-4.166V7.04c0-.273.227-.5.5-.5.274 0 .5.227.5.5v3.334c0 3.62-1.546 5.166-5.166 5.166z"
      ></path>
      <path
        fill="#686868"
        d="M14.666 7.541H12c-2.28 0-3.167-.886-3.167-3.166V1.708c0-.2.12-.387.307-.46.186-.08.4-.033.546.107l5.334 5.333a.502.502 0 01-.354.853zM9.833 2.915v1.46c0 1.72.447 2.166 2.167 2.166h1.46L9.833 2.915zM8.667 9.541h-4a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h4c.273 0 .5.227.5.5s-.227.5-.5.5zM7.334 12.208H4.667a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h2.667c.273 0 .5.227.5.5s-.227.5-.5.5z"
      ></path>
    </svg>
  );
}

export { IconBook, IconBookLine };
