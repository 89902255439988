import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { formatMoney } from '../../../../utils/formatMoney';

export function exportEcxel({ orders }) {
  const data = orders.map((items) => {
    return {
      Chave: items?.key,
      'Dados adicionais': items?.additional_data ?? '--',
      Valor: formatMoney(items?.amount),
      'Valor mínimo': formatMoney(items?.min_amount),
      'Valor máximo': formatMoney(items?.max_amount),
      Data: items?.date,
      'Data de expiração': items?.expiration_date ?? '--',
      'Método de pagamento': items?.method ?? '--',
      'ID do Pedido': items?.order_id,
      'Chave do Pedido': items?.order_key,
      Documento: items?.document ?? '--',
      'Nome do Beneficiário': items?.name ?? '--',
      'Código de autenticação do banco': items?.bank_autentication_code ?? '--',
      'Data de início': items?.start_date ?? '--',
      Salário: items?.is_salary ? 'Sim' : 'Não',
      'Está bloqueado?': items?.is_locked ? 'Sim' : 'Não',
      'Código do boleto bancário': items?.bank_slip ?? '--',
      'Código do Banco': items?.bank_code ?? '--',
      Agência: items?.branch ?? '--',
      'Dígito da Agência': items?.branch_digit ?? '--',
      Conta: items?.account ?? '--',
      'Dígito da Conta': items?.account_digit ?? '--',
      'Tipo de Conta': items?.account_type ?? '--',
      'E-mail': items?.email ?? '--',
      'Data de conclusão': items?.finish_date ?? '--',
      'Código de erro': items?.error_code ?? '--',
      'Descrição do erro': items?.error_description ?? '--',
      'Chave PIX': items?.pix_key ?? '--',
      'End-to-End ID do PIX': items?.pix_end_to_end_id ? 'Sim' : 'Não',
      'Movimentação Key': items?.movementKey ?? '--',
      'Taxa reembolsável': formatMoney(items?.refundable_fee_amount),
      'Montante consignado': formatMoney(items?.collected_consignation_amount),
    };
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportToCSV(data, 'relatorio_boletos');
}
