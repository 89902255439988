import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import FormGroup from './FormGroup';
import { formatUserTypes } from '../utils/format';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { apiGet } from '../../../services/api';
import { BiChevronDown } from 'react-icons/bi';
import { v4 as uid } from 'uuid';
import { containsWord } from '../utils/containWord';
import { useUser } from '../../../hooks/useUser';

import {
  ValidateAddCollaboratorAgent,
  ValidateAddCollaboratorDaily,
  ValidateAddCollaborator,
  ValidateAddCollaboratorServices,
  ValidateAddCollaboratorComodato,
} from './utils/editValidate';
import {
  key_contract_agent,
  key_contract_comodato,
  key_contract_daily,
  key_contract_service,
} from '../../../config';
import TagInput from './TagInput';
const contractValidate = [
  { id: 0, text: '', validate: ValidateAddCollaborator },
  { id: 1, text: key_contract_agent, validate: ValidateAddCollaboratorAgent },
  { id: 2, text: key_contract_daily, validate: ValidateAddCollaboratorDaily },
  {
    id: 3,
    text: key_contract_service,
    validate: ValidateAddCollaboratorServices,
  },
  {
    id: 4,
    text: key_contract_comodato,
    validate: ValidateAddCollaboratorComodato,
  },
];
export function filterContracts(str) {
  const filteredContracts = contractValidate.find((contract) =>
    containsWord(contract.text.toLowerCase(), str),
  );

  if (!filteredContracts) {
    return { id: 0, text: '', validate: ValidateAddCollaborator };
  }

  return filteredContracts;
}
const EditUserForm = (props) => {
  const [user, setUser] = useState(props.currentUser);
  const { user: userPermission } = useUser();
  const [contract, setContract] = useState();
  const [contractType, setContractType] = useState(contractValidate[0]);
  const [tags, setTags] = useState(props.currentUser.tags ?? []);
  const permissionTags = [40].every((item) =>
    userPermission?.permission?.includes(item),
  );

  const [isRequired, setIsRequired] = useState(
    props.currentUser?.contract_type ? true : false,
  );

  const [isContract, setIsContract] = useState(
    props.currentUser?.contract_type ? true : false,
  );

  const [clear] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(contractType?.validate),
  });
  const contractIsType = watch('contract_type');

  useEffect(() => {
    setContractType(filterContracts(contractIsType));
  }, [contractIsType]);

  function handlerIsContract(value) {
    setIsContract(value);
    setIsRequired(value);
  }
  async function fetchGetListContract() {
    setLoading(true);
    try {
      const { data } = await apiGet(`/Contract/template/list`);
      setContract(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (user.contract_type) {
      setValue('is_contract_required', true);

      setValue('contract_type', user.contract_type);
    }

    fetchGetListContract();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlerCancel = () => {
    props.setType(false);
    props.setEditing(false);
  };
  useEffect(() => {
    setUser(props.currentUser);
    // handleSubmit(onSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onSubmit = (values) => {
    if (contractType?.id !== 1) {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
    } else {
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
      delete values.COMODATO;
    }
    if (contractType?.id !== 2) {
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DO_SERVICO;
      delete values.COMODATO;
    }
    if (contractType?.id !== 3) {
      delete values.VALOR_DO_SERVICO;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.COMODATO;
    }
    if (contractType?.id !== 4) {
      delete values.COMODATO;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
    }
    if (values?.is_contract_required === 'false') {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
      delete values?.COMODATO;
      delete values?.is_contract_required;
      delete values?.contract_type;

      const updateUsers = {
        ...values,
        id: user.id,
        ...(tags.length > 0 && { tags }),
      };
      const formatType = formatUserTypes(updateUsers, props.listActive);
      props.updateUser(user.id, formatType);
    } else {
      delete props.currentUser?.contract_type;
      const updateUsers = {
        ...values,
        id: user.id,
        ...(tags.length > 0 && { tags }),
      };

      const formatType = formatUserTypes(updateUsers);
      props.updateUser(user.id, formatType);
    }

    props.setEditing(false);
  };
  return (
    <form
      className={styles['form-container']}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.wrapper_form}>
        <div className={styles.block_form}>
          <FormGroup
            name="name"
            label="Nome:"
            type="text"
            placeholder="Nome completo"
            data={{ value: props.currentUser?.name }}
            disabled={false}
            setValue={setValue}
            register={register}
            errors={errors}
            typeMask={0}
          />
          <FormGroup
            name="cpf"
            label="CPF:"
            type="text"
            placeholder="999.999.999-99"
            data={{ value: props.currentUser?.cpf }}
            disabled={false}
            register={register}
            errors={errors || { document: 'Documento inválido' }}
            mask={['999.999.999-99']}
            setValue={setValue}
            clean={clear}
            typeMask={1}
          />
          <FormGroup
            name="cnpj"
            label="CNPJ:"
            type="text"
            placeholder="99.999.999/9999-99"
            data={{
              value: !!props.currentUser?.cnpj ? props.currentUser?.cnpj : '',
            }}
            disabled={false}
            register={register}
            errors={errors || { document: 'Documento inválido' }}
            mask={['99.999.999/9999-99']}
            setValue={setValue}
            clean={clear}
            typeMask={1}
          />
          <FormGroup
            name="email"
            label="email:"
            type="email"
            placeholder="exemple@mail.com"
            data={{ value: props.currentUser?.email }}
            disabled={false}
            setValue={setValue}
            register={register}
            errors={errors}
            typeMask={0}
          />
        </div>
        <div className={styles.block_check}>
          {permissionTags && <TagInput tags={tags} setTags={setTags} />}
          <FormGroup
            name="phone_number"
            label="Celular:"
            type="tel"
            placeholder="(99) 99999-9999"
            data={{ value: props.currentUser?.phone_number }}
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            mask={'(99) 99999-9999'}
            clean={clear}
            typeMask={1}
          />
          <div className={styles.wrapper_form_}>
            <div className={styles.radio_type}>
              <label className={styles.label} for="is_contract_required">
                A assinatura do contrato é obrigatória?
              </label>
              <br />
              <div className={styles.wrapper_radio}>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-true"
                    name="is_contract_required"
                    value={'true'}
                    checked={isContract === true}
                    onClick={() => handlerIsContract(true)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />
                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-true"
                  >
                    sim
                  </label>
                </p>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-false"
                    name="is_contract_required"
                    value={'false'}
                    checked={isContract === false}
                    onClick={() => handlerIsContract(false)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />

                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-false"
                  >
                    não
                  </label>
                </p>
              </div>

              {errors.is_contract_required && (
                <p className={styles.errorText}>
                  {errors.is_contract_required.message}
                </p>
              )}
              {isRequired === true && (
                <div className={styles.select_type}>
                  <div className={styles.select}>
                    <select
                      name="contract_type"
                      {...register('contract_type')}
                      disabled={loading}
                      value={user?.contract_type ? user.contract_type : '0'}
                      onChange={(e) => {
                        setUser({ ...user, contract_type: e.target.value });
                      }}
                    >
                      <option value="0">
                        {loading ? 'Carregando ...' : ' Selecione o contrato:'}
                      </option>
                      {contract?.map((type) => (
                        <option key={uid()} value={type.contract_type}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    <BiChevronDown color="var(--primary-700)" size={'2rem'} />
                  </div>

                  {errors.type && (
                    <p className={styles.errorText}>{errors.type.message}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          {contractType?.id === 1 && (
            <>
              <FormGroup
                name="NOME_DO_AGENCIADOR"
                label="NOME DO AGENCIADOR:"
                type="text"
                placeholder="Nome completo"
                disabled={false}
                data={{ value: props.currentUser?.NOME_DO_AGENCIADOR }}
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <FormGroup
                name="DOCUMENTO_DO_AGENCIADOR"
                label="DOCUMENTO DO AGENCIADOR (CPF ou CNPJ):"
                type="text"
                placeholder="99.999.999/9999-99"
                disabled={false}
                data={{ value: props.currentUser?.DOCUMENTO_DO_AGENCIADOR }}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={['999.999.999-99', '99.999.999/9999-99']}
                setValue={setValue}
                clean={clear}
                typeMask={1}
              />
            </>
          )}
          {contractType?.id === 2 && (
            <>
              <FormGroup
                name="VALOR_DA_DIARIA"
                label="Valor da diária (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                register={register}
                data={{
                  value: props.currentUser?.VALOR_DA_DIARIA.toFixed(2)
                    .toString()
                    .replace('.', ','),
                }}
                errors={errors || { document: 'Documento inválido' }}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                setValue={setValue}
                clean={clear}
                typeMask={2}
              />
              <FormGroup
                name="VALOR_DA_DIARIA_AJUDANTE"
                label="Valor da diária ajudante (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                data={{
                  value: props.currentUser?.VALOR_DA_DIARIA_AJUDANTE.toFixed(2)
                    .toString()
                    .replace('.', ','),
                }}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                typeMask={2}
                setValue={setValue}
                clean={clear}
              />
            </>
          )}
          {contractType?.id === 3 && (
            <>
              <FormGroup
                name="VALOR_DO_SERVICO"
                label="Valor do Serviço (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                data={{
                  value: props.currentUser?.VALOR_DO_SERVICO.toFixed(2)
                    .toString()
                    .replace('.', ','),
                }}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                setValue={setValue}
                clean={clear}
                typeMask={2}
              />
            </>
          )}
          {contractType?.id === 4 && (
            <>
              <FormGroup
                name="COMODATO"
                label="Certificado de propriedade Nº"
                type="text"
                placeholder="9999 9999 9999 9999 9999 9999 9999"
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                data={{
                  value: props.currentUser?.COMODATO,
                }}
                setValue={setValue}
                clean={clear}
                typeMask={3}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.wrapper_button}>
        <button
          type="button"
          className={styles.button_canceled}
          onClick={handlerCancel}
        >
          <IoMdClose /> Cancelar
        </button>
        <button className={styles.button_save} type="submit">
          <FaRegCheckCircle /> Confirmar
        </button>
      </div>
    </form>
  );
};

export default EditUserForm;
