import React from 'react';
import { IconShare } from '../../../../components/Icons';
import styles from './styles.module.scss';

const ShareButton = ({ pdfUrl }) => {
  const handleMobileShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Comprovante',
          text: 'Aqui está o comprovante de transação.',
          files: [new File([pdfUrl], 'comprovante.png', { type: 'image/png' })],
        });
      } catch (err) {
        console.error('Erro ao compartilhar:', err);
      }
    } else {
      downloadFile();
    }
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'comprovante.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={handleMobileShare} className={styles.share} id="download">
      <IconShare />
    </button>
  );
};

export default ShareButton;
