import React from 'react';
import { saveAs } from 'file-saver';
import { ExportButton } from './ExportButton';
import { XMLGenerator } from './XMLGenerator';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatAccount } from '../../../../utils/formatAccount';

export const ExportXMLExtract = ({ list, title }) => {
  const xmlGenerator = new XMLGenerator(formatMoney, formatAccount);

  const handleExportClick = () => {
    const xmlData = xmlGenerator.generateXML(list);
    const blobData = new Blob([xmlData], { type: 'application/xml' });
    saveAs(blobData, 'Relatório_extrato.xml');
  };

  return <ExportButton title={title} onClick={handleExportClick} />;
};
