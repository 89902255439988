import React, { memo, useCallback, useEffect } from 'react';
import styles from './styles.module.scss';
import { mask as masker, unMask } from 'remask';
import { useState } from 'react';

function maskComodato(text) {
  text = text.toString().replace(/(\w{4})/g, '$1 ');
  text = text.trim();
  return text;
}

const FormGroup = ({
  name,
  label,
  type,
  disabled,
  register,
  errors,
  mask,
  onBlur,
  setValue,
  autofocus,
  clean,
  data,
  placeholder,
  typeMask = 0,
}) => {
  const operations = {
    0: (value) => value,
    1: (value) => masker(unMask(value ?? ''), mask),
    2: (value) => masker(unMask(value ?? ''), mask),
    3: (value) => maskComodato(value),
  };

  function processValue(value, type = 0) {
    if (type in operations) {
      return operations[type](value);
    } else {
      return value;
    }
  }

  const [formValue, setFormValue] = useState(
    mask ? processValue(data?.value, typeMask) : data?.value,
  );

  const handleChange = (ev) => {
    const originalValue = ev.target.value;
    const maskedValue = mask
      ? processValue(originalValue, typeMask)
      : ev.target.value.replace(/\s/g, '');
    setFormValue(maskedValue);
    setValue(name, maskedValue);
  };

  useEffect(() => {
    if (clean) {
      setFormValue(' ');
    }
  }, [clean]);

  useEffect(() => {
    if (formValue) {
      setValue(name, processValue(formValue, typeMask));
    }
    // eslint-disable-next-line
  }, [formValue]);
  const inputFocus = useCallback((inputElement) => {
    if (inputElement && autofocus) {
      inputElement.focus();
    }
    // eslint-disable-next-line
  }, []);

  if (mask || typeMask === 3) {
    return (
      <div className={styles.wrapper}>
        <label>{label}</label>
        <input
          type={type}
          disabled={disabled}
          value={formValue}
          {...register(name)}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
          ref={inputFocus}
          maxLength="200"
        />
        {errors[name] && (
          <span className={styles.error__input}>{errors[name].message}</span>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        <label className="font-weight-bold">{label}</label>
        <input
          type={type}
          disabled={disabled}
          {...register(name)}
          onBlur={onBlur}
          autofocus={autofocus}
          placeholder={placeholder}
          onChange={(e) => setFormValue(e.target.value)}
          value={formValue}
        />
        {errors[name] && (
          <span className={styles.error__input}>{errors[name].message}</span>
        )}
      </div>
    );
  }
};

export default memo(FormGroup);
