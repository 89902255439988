import { EnumerateCustomCapabilities } from './decode.js';

const decodeToken = (token) => {
  if (!token) {
    console.error('Token não fornecido.');
    return null;
  }

  const [headerBase64, payloadBase64, signature] = token.split('.');

  if (!headerBase64 || !payloadBase64 || !signature) {
    console.error('Token JWT inválido.');
    return null;
  }

  try {
    const decodedHeader = JSON.parse(atob(headerBase64));
    const decodedPayload = JSON.parse(atob(payloadBase64));

    return {
      header: decodedHeader,
      payload: decodedPayload,
      signature,
    };
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return null;
  }
};

function extractCapabilities(json) {
  const capabilities = {};

  // Iterate over the keys of the JSON
  for (const key in json) {
    // Check if the key starts with "Capabilities_"
    if (key.startsWith('Capabilities_')) {
      // Extract the CompanyId from the key
      const companyId = key.replace('Capabilities_', '');

      // Store the capability in the capabilities object
      capabilities[companyId] = json[key];
    } else if (key === 'Capabilities') {
      // Handle the case when the key is "Capabilities"
      capabilities['0'] = json[key] !== undefined ? json[key] : '';
    }
  }

  return capabilities;
}
export function decodeJwtToken(token, company) {
  try {
    const decodedToken = decodeToken(token);

    const user = decodedToken.payload;
    const Capabilities = extractCapabilities(user);

    const { CompanyId } = user;

    const company_id_ref = company ? company : CompanyId;
    const CapabilitiesArray = EnumerateCustomCapabilities(
      Capabilities[company_id_ref]
        ? Capabilities[company_id_ref]
        : Capabilities[0],
    );

    return JSON.parse(CapabilitiesArray) ?? [];
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return [];
  }
}
