export function EnumerateCustomCapabilities(base64) {
  try {
    if (!base64) {
      console.error('Base64 não fornecido.');
      return null;
    }

    const arrayBuffer = new Uint8Array(
      [...atob(base64)].map((c) => c.charCodeAt(0)),
    ).buffer;
    const capabilityBytes = new Uint8Array(arrayBuffer);
    const values = [];

    for (let index = 0; index < capabilityBytes.length; index++) {
      const entry = capabilityBytes[index];
      for (let bit = 0; bit < 8; bit++) {
        const mask = 1 << bit;
        const check = entry & mask;
        if (check === 0) {
          continue;
        }

        const capabilityId = index * 8 + bit;
        values.push(capabilityId);
      }
    }

    const text = JSON.stringify(values);

    return text;
  } catch (error) {
    console.error('Error decoding :', error);
  }
}
