import React, { memo, useEffect, useState } from 'react';
import { useBalance } from '../../hooks/useBalance';
import { useUser } from '../../hooks/useUser';
import styles from './styles.module.scss';

import { Link } from 'react-router-dom';
import logo from '../../Assets/lg.svg';
import { useAllBalancesQuery } from '../../hooks/useQuery';
import { formatMoney } from '../../utils/formatMoney';
import { ButtonMenuBalances } from '../ButtonMenuBalances';
import { IconPreference } from '../TabMenu/Icon';
import { ButtonMenu } from './ButtonMenu';
import useVerifyVersion from '../../hooks/useVerifyVersion';
import { ButtonMenuAccount } from './ButtonMenuAccount';

const Header = () => {
  const { login, userLogout, user, expend } = useUser();
  const { balance, fetchGetBalance } = useBalance();
  const [visible, setVisible] = useState(true);
  const { fetchGetBalanceConsignable } = useBalance();
  const { data } = useAllBalancesQuery();
  useVerifyVersion();
  const [menu, setMenu] = useState(false);
  function handleLogout(e) {
    userLogout();
  }
  useEffect(() => {
    if (visible) {
      fetchGetBalance();
      fetchGetBalanceConsignable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <header
      className={
        expend
          ? `${styles.headerContainer} ${styles.nav_bar}`
          : `${styles.headerContainer_} ${styles.nav_bar}`
      }
    >
      <nav className={styles.navbar}>
        <div className={styles.company}>
          <div className={styles.company_}>
            <h1>
              {user?.company?.social_name
                ? user?.company?.social_name
                : user?.company?.fantasy_name
                ? user?.company?.fantasy_name
                : user?.company?.accountable}
            </h1>
            -{' '}
            <div>
              {user?.company?.cnpj ? user?.company?.cnpj : user?.company?.cpf}
            </div>
          </div>

          <span className={styles.name_}>
            <p className={styles.welcome}>Bem vindo!</p>
            <p className={styles.name__social}>
              {user?.customer?.social_name
                ? `${user?.customer?.social_name}`
                : `${user?.customer?.first_name}  ${user?.customer?.last_name}`}
            </p>
          </span>
        </div>
        {data?.allBalance.length > 0 && <ButtonMenuAccount />}
        {login && (
          <>
            <div className={styles.logout}>
              <div className={styles.wrapper_button_menu_balances}>
                {[5].every((item) => user?.permission.includes(item)) && (
                  <ButtonMenuBalances
                    visible={visible}
                    setVisible={setVisible}
                  />
                )}
              </div>

              <Link to="/menu/profile">
                <IconPreference color={'var(--gray-300'} size="1.5rem" />
              </Link>

              <span>
                <ButtonMenu>
                  <div className={styles.profile}>
                    <div>
                      {user &&
                        `${user?.customer?.first_name?.trim()[0] ?? ''}${
                          user?.customer?.last_name?.trim()[0] ?? ''
                        }`}
                    </div>
                  </div>
                </ButtonMenu>
              </span>
            </div>
          </>
        )}
      </nav>
      {login && (
        <menu>
          <input
            className={styles.hamburger_check}
            type="checkbox"
            name="hamburger_check"
            id=""
            checked={menu}
            onChange={({ target }) => setMenu(!menu)}
          />
          <div className={styles.hamburger}>
            <div
              className={`${styles.hamburger_line} ${styles.hamburger_line_top}`}
            ></div>
            <div
              className={`${styles.hamburger_line} ${styles.hamburger_line_middle}`}
            ></div>
            <div
              className={`${styles.hamburger_line} ${styles.hamburger_line_bottom}`}
            ></div>
          </div>
          <img className={styles.logoMenu} src={logo} alt="logo" />

          <ul className={styles.list}>
            {login && (
              <>
                <div className={styles.name}>
                  <span>{user && user.first_name}</span>
                  <label>{user && user.fantasy_name}</label>
                </div>
                <span>
                  {balance && (
                    <span className={styles.balanceMobile}>
                      {formatMoney(balance)}
                    </span>
                  )}
                </span>
              </>
            )}

            <li onClick={() => setMenu(false)}>
              <Link to="/menu/transfer">Transferências</Link>
            </li>
            <li onClick={() => setMenu(false)}>
              <Link to="/menu/balances">Contas</Link>
            </li>

            <li onClick={handleLogout}>sair</li>
          </ul>
        </menu>
      )}
    </header>
  );
};

export default memo(Header);
