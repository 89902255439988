import { formatDate } from './formatDate';
import { formatDocument } from './formatDocument';
import { formatStatusAnticipation } from './formatStatus';

export function formatListAnticipation(data) {
  return data
    ? data.flatMap((list) =>
        list.movements.map((item) => ({
          ...list,
          ...item,
          file_name: list.file_name,
          id_consignable: item.consignable_limit?.id,
          id: item.key,
          order: list.key,
          amount: item.amount,
          description: list.description
            ? list.description
            : `Antecipação ${formatDate(item.start_date, 'dd,MM/yyyy')}`.slice(
                0,
                15,
              ),
          date: item.date
            ? formatDate(item.date, 'dd/MM/yyyy')
            : formatDate(item.start_date, 'dd/MM/yyyy'),
          isDate: item.finish_date
            ? formatDate(item.finish_date, 'dd/MM/yyyy')
            : formatDate(item.date, 'dd/MM/yyyy'),
          order_id: list.key,
          document: formatDocument(item.document),
          name: item.name ?? 'SEM NOME',
          statusRef: formatStatusAnticipation(
            item.consignable_limit?.used_amount > 0 ? '99' : item.status,
            item.error_description,
          ),
          useds: item.consignable_limit?.useds ?? false,
          billed_at: item.consignable_limit?.billed_at
            ? formatDate(item.consignable_limit.billed_at, 'dd/MM/yyyy')
            : false,
          is_consignable_limit: item.consignable_limit?.used_amount > 0,
          consignable_limit: item.consignable_limit ?? false,
          is_consignable: (item.consignable_limit?.useds ?? []).reduce(
            (acc, used) => acc + (used.amount ?? 0),
            0,
          ),
          is_consignable_fee: (item.consignable_limit?.useds ?? []).reduce(
            (acc, used) => acc + (used.fee_amount ?? 0),
            0,
          ),
          used_amount: item.consignable_limit?.used_amount ?? false,
          valid_since: item.consignable_limit?.valid_since
            ? formatDate(item.consignable_limit.valid_since, 'dd/MM/yyyy')
            : false,
          valid_until: item.consignable_limit?.valid_until
            ? formatDate(item.consignable_limit.valid_until, 'dd/MM/yyyy')
            : false,
        })),
      )
    : [];
}
