import { LoadingBall } from '../LoadingBall';
import logo from '../../../Assets/logotextblack.svg';
import styles from './styles.module.scss';
import { useCompany } from '../../../hooks/useCompany';
import { useUser } from '../../../hooks/useUser';

export function LoadingPageCompany({ view }) {
  const { loadingCompany } = useCompany();
  const { loading } = useUser();

  if (loadingCompany.company || view || loading.login)
    return (
      <div className={styles.container}>
        <LoadingBall />
        <img className={styles.logo} src={logo} alt="" />
      </div>
    );
  return <div />;
}
