export function reorganizeFieldsColum(jsonData) {
  const keyMapping = {
    A: 'name',
    B: 'phone_number',
    C: 'cpf',
    D: 'cnpj',
    E: 'email',
    F: 'contract_type',
    G: 'NOME_DO_AGENCIADOR',
    H: 'DOCUMENTO_DO_AGENCIADOR',
    I: 'VALOR_DA_DIARIA',
    J: 'VALOR_DA_DIARIA_AJUDANTE',
    K: 'VALOR_DO_SERVICO',
    L: 'COMODATO',
  };

  const newJSON = jsonData.map((item) => {
    const newItem = {};
    for (const originalKey in keyMapping) {
      if (item[originalKey] !== undefined) {
        newItem[keyMapping[originalKey]] = item[originalKey];
      }
    }
    return newItem;
  });

  return newJSON;
}
export function formatFileSize(bytes) {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
  }
}
