import React, { useState } from 'react';

import styles from './styles.module.scss';

import { useUser } from '../../hooks/useUser';
import { formatDocument } from '../../utils/formatDocument';
import { formatMoney } from '../../utils/formatMoney';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { apiPost, apiPutEditUser } from '../../services/api';
import { IconPen } from '../Limits/icons';
import { setCookieUser } from '../../Helpers/cookies';
import { Loader } from 'rsuite';

export const Profile = () => {
  const { user, setUser } = useUser();

  const [loading, setLoading] = useState(false);

  const [socialNameUser, setSocialNameUser] = useState(
    user?.customer?.social_name || '',
  );
  const [socialNameCompany, setSocialNameCompany] = useState(
    user?.company?.social_name || user?.company?.accountable,
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveUser = async () => {
    setLoading({ user: true });
    try {
      const updateUser = {
        ...user.customer,
        social_name: socialNameUser,
      };

      const { status } = await apiPutEditUser(
        `/customer`,
        updateUser,
        user.company_id,
      );

      if (status === 200) {
        user.customer.social_name = socialNameUser;
        setCookieUser(user);
        setUser(user);
        setIsEditing(false);
      } else {
        console.error('Failed to update social name');
      }
    } catch (error) {
      console.error('Error updating social name:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveCompany = async () => {
    setLoading({ company: true });
    try {
      const updateCompany = {
        social_name: socialNameCompany,
      };

      const { status } = await apiPost(`/Company/Update`, updateCompany);

      if (status === 200) {
        user.company.social_name = socialNameCompany;
        setCookieUser(user);
        setUser(user);

        setIsEditing(false);
      } else {
        console.error('Failed to update social name company');
      }
    } catch (error) {
      console.error('Error updating social name company:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.wrapper__container}>
      <div className={styles.container}>
        <div className={styles.info}>
          <h3>{`${user?.customer?.first_name} ${user?.customer?.last_name}`}</h3>
          {user?.customer?.social_name && (
            <li>
              <strong>Nome Social:</strong>
              {isEditing.user ? (
                <>
                  <input
                    className={styles.input}
                    type="text"
                    value={socialNameUser}
                    onChange={(e) => setSocialNameUser(e.target.value)}
                  />
                  <div className={styles.wrapper__button}>
                    {loading?.user ? (
                      <Loader />
                    ) : (
                      <>
                        <button
                          className={styles.button__plus}
                          onClick={handleSaveUser}
                        >
                          Confirmar
                        </button>
                        <button
                          className={styles.button__plus_}
                          onClick={() => setIsEditing(false)}
                        >
                          Cancelar
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p>{user.customer.social_name}</p>
                  <button
                    className={styles.button__edit_}
                    onClick={() => setIsEditing({ user: true })}
                  >
                    <IconPen color={'var(--gray-500)'} size={'1.2rem'} />
                  </button>
                </>
              )}
            </li>
          )}
          {user?.document && (
            <li>
              <strong>Documento:</strong>
              <p> {formatDocument(user?.document)}</p>
            </li>
          )}
          {user?.email && (
            <li>
              <strong>Email:</strong>
              <p>{user.email}</p>
            </li>
          )}
          {user?.telephone && (
            <li>
              <strong>Telefone:</strong>
              <p> {formatPhoneNumber(user.telephone)}</p>
            </li>
          )}
        </div>
        <div className={styles.info}>
          <h3>Dados da conta </h3>
          {socialNameCompany && (
            <li>
              <strong>Nome Social:</strong>
              {isEditing.company ? (
                <>
                  <input
                    type="text"
                    className={styles.input}
                    value={socialNameCompany}
                    onChange={(e) => setSocialNameCompany(e.target.value)}
                  />
                  <div className={styles.wrapper__button}>
                    {loading?.company ? (
                      <Loader />
                    ) : (
                      <>
                        <button
                          className={styles.button__plus}
                          onClick={handleSaveCompany}
                        >
                          Confirmar
                        </button>
                        <button
                          className={styles.button__plus_}
                          onClick={() => setIsEditing(false)}
                        >
                          Cancelar
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p>{socialNameCompany}</p>
                  <button
                    className={styles.button__edit_}
                    onClick={() => setIsEditing({ company: true })}
                  >
                    <IconPen color={'var(--gray-500)'} size={'1.2rem'} />
                  </button>
                </>
              )}
            </li>
          )}
          {user?.company?.fantasy_name && (
            <li>
              <strong>Nome Fantasia:</strong>
              <p>{user?.company?.fantasy_name}</p>
            </li>
          )}
          {user?.company?.social_reason && (
            <li>
              <strong>Nome:</strong>
              <p>{user?.company?.social_reason}</p>
            </li>
          )}
          {user?.company?.cnpj && (
            <li>
              <strong>CNPJ:</strong>
              <p> {user?.company?.cnpj}</p>
            </li>
          )}
          {user?.company?.account_cpf && (
            <li>
              <strong>CPF Responsável:</strong>
              <p> {user?.company?.account_cpf}</p>
            </li>
          )}
          {user?.company?.cpf && (
            <li>
              <strong>CNPJ:</strong>
              <p> {user?.company?.cpf}</p>
            </li>
          )}{' '}
          {user?.company?.default_pix_key && (
            <li>
              <strong>Chave PIX:</strong>
              <p> {user?.company?.default_pix_key}</p>
            </li>
          )}
          {user?.company?.swap_account?.branch && (
            <li>
              <strong>Agência:</strong>
              <p> {user?.company?.swap_account?.branch}</p>
            </li>
          )}
          {user?.company?.swap_account?.account && (
            <li>
              <strong>Número da Conta:</strong>
              <p> {user?.company?.swap_account?.account}</p>
            </li>
          )}
          {user?.company?.swap_account?.branch && (
            <li>
              <strong>Instituição:</strong>
              <p> {'ISPB: 31680151 - Swap Ip S.A.'}</p>
            </li>
          )}
        </div>
        <div className={styles.info}>
          <h3>Taxas da conta </h3>
          {user?.company?.transfer_fee >= 0 && (
            <li>
              <strong>Taxa de Pagamentos:</strong>
              <p>{formatMoney(user?.company?.transfer_fee)}</p>
            </li>
          )}
          {user?.company?.transfer_fee >= 0 && (
            <li>
              <strong>Boletos:</strong>
              <p>{formatMoney(user?.company?.transfer_fee)}</p>
            </li>
          )}

          {user?.company?.internal_transfer_fee >= 0 && (
            <li>
              <strong>Transferência Interna(Contas Digitais):</strong>
              <p>{formatMoney(user?.company?.internal_transfer_fee)}</p>
            </li>
          )}
        </div>
        {user.customer?.company_id === 4 && (
          <div className={styles.info}>
            <h3>Antecipação </h3>
            <li>
              <strong>Lançamento de Antecipação:</strong>
              <p>{user.company?.consignation_enabled ? 'Sim' : 'Não'}</p>
            </li>
          </div>
        )}
        <div className={styles.info}>
          <h3>Endereço </h3>
          {user?.company?.state && (
            <li>
              <strong>Estado:</strong>
              <p>{user?.company?.state}</p>
            </li>
          )}
          {user?.company?.city && (
            <li>
              <strong>Cidade:</strong>
              <p>{user?.company?.city}</p>
            </li>
          )}

          {user?.company?.neighborhood && (
            <li>
              <strong>Bairro:</strong>
              <p> {user?.company?.neighborhood}</p>
            </li>
          )}
          {user?.company?.street && (
            <li>
              <strong>Rua:</strong>
              <p> {user?.company?.street}</p>
            </li>
          )}
          {user?.company?.street_number && (
            <li>
              <strong>Numero:</strong>
              <p> {user?.company?.street_number}</p>
            </li>
          )}
          {user?.company?.street_complement && (
            <li>
              <strong>Complemento:</strong>
              <p> {user?.company?.street_complement}</p>
            </li>
          )}
        </div>
      </div>
    </div>
  );
};
