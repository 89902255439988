import React from 'react';

import * as FileSaver from 'file-saver';
import styles from './styles.module.scss';

import * as XLSX from 'xlsx';

import { Icon } from '../icon';
import { formatAccount } from '../../../../utils/formatAccount';

const ExportExcel = ({ title, movements }) => {
  const data = movements.map((items) => {
    //exportar arquivo XLSX nesse padrão.

    return {
      Nome: items.name,
      CPF: items.document,

      Codigo_do_banco: items.bank_code,
      Agencia: items.branch,
      'Digito da Agência': items.branch_digit,
      'Numero da Conta': items.account,
      'Digito da Conta': items.account_digit,
      'Tipo de Conta': formatAccount(items.account_type),

      Email: items.email,
      Descrição: items.description,
      Valor: items.amount,
      Status: items.status.text,

      Data: items.date,
      'Descricao de error': items.error_description,
      'Codigo de Autenticacao do Banco': items.bank_autentication_code,
      Metodo: items?.method,
      'Chave Pix': items?.pix_key,
      'Condigo de Transacao': items?.bank_autentication_code,
      'End to End': items?.pix_end_to_end_id,
    };
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className={styles.button}
      onClick={(e) => exportToCSV(data, 'relatorio-de-pagamento')}
      name="export"
    >
      <Icon color="var(--gray-400)" size={'1.2rem'} />
      {title}
    </button>
  );
};

export default ExportExcel;
