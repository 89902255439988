import React from 'react';
import { useState } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import AccountExchange from '../../../pages/AccountExchange';

import styles from './styles.module.scss';

/**
 * Componente de botão de menu suspenso para download de modelos de arquivo.
 *
 * Este componente renderiza um botão que, quando clicado, exibe um menu suspenso com links para download de modelos de arquivo. É projetado para fornecer acesso conveniente a diferentes modelos de arquivo, como modelos de transferência bancária, Pix, transferência interna e antecipação.
 *
 * @component
 * @param {React.ReactNode} children - Os elementos filhos a serem renderizados no botão. Pode ser usado para personalizar o conteúdo do botão.
 * @param {number[]} model - Um array de números que representa os modelos de arquivo disponíveis para download. Cada número corresponde a um modelo específico.
 * @returns {JSX.Element} Um botão de menu suspenso para download de modelos de arquivo.
 *
 * @example
 * // Exemplo de uso:
 * import { ButtonMenuDownload } from './ButtonMenuDownload';
 *
 * function MeuComponente() {
 *   return (
 *     <ButtonMenuDownload model={[1, 2, 3]}>
 *       <span>Modelos de Arquivo</span>
 *     </ButtonMenuDownload>
 *   );
 * }
 */

function Icon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M18.85 12.75h-2.7c-2.66 0-3.9-1.24-3.9-3.9v-3.7c0-2.66 1.24-3.9 3.9-3.9h2.7c2.66 0 3.9 1.24 3.9 3.9v3.7c0 2.66-1.24 3.9-3.9 3.9zm-2.7-10c-1.84 0-2.4.56-2.4 2.4v3.7c0 1.84.56 2.4 2.4 2.4h2.7c1.84 0 2.4-.56 2.4-2.4v-3.7c0-1.84-.56-2.4-2.4-2.4h-2.7zM7.85 22.75h-2.7c-2.66 0-3.9-1.24-3.9-3.9v-3.7c0-2.66 1.24-3.9 3.9-3.9h2.7c2.66 0 3.9 1.24 3.9 3.9v3.7c0 2.66-1.24 3.9-3.9 3.9zm-2.7-10c-1.84 0-2.4.56-2.4 2.4v3.7c0 1.84.56 2.4 2.4 2.4h2.7c1.84 0 2.4-.56 2.4-2.4v-3.7c0-1.84-.56-2.4-2.4-2.4h-2.7zM15 22.75a.745.745 0 01-.64-1.13l1.05-1.75c.21-.36.67-.47 1.03-.26.36.21.47.67.26 1.03l-.27.45c2.76-.65 4.83-3.13 4.83-6.09 0-.41.34-.75.75-.75s.75.34.75.75c-.01 4.27-3.49 7.75-7.76 7.75zM2 9.75c-.41 0-.75-.34-.75-.75 0-4.27 3.48-7.75 7.75-7.75a.745.745 0 01.64 1.13L8.59 4.14c-.21.35-.67.47-1.03.25a.746.746 0 01-.25-1.03l.27-.45A6.261 6.261 0 002.75 9c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}
export function ButtonMenuAccount({ children, isFetching }) {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.container}>
      <Whisper
        trigger="hover"
        placement={'right'}
        controlId={`control-id-click`}
        speaker={<Tooltip>Trocar de Conta</Tooltip>}
      >
        <button
          style={{ background: 'transparent' }}
          onClick={() => setActive(!active)}
        >
          <Icon color="var(--gray-400)" size="1.5rem" />
        </button>
      </Whisper>

      {children}

      {active && (
        <ul className={styles.menu} onClick={() => setActive(true)}>
          <AccountExchange setActive={setActive} />
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
