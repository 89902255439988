import React from 'react';
import styles from './styles.module.scss';
import { IconLogoOxPay, IconBox } from './icons/index';

const PaymentMenu = ({ setTypePayment, setStepPayment }) => {
  return (
    <div className={styles.container}>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(3);
          setStepPayment(1);
        }}
      >
        <IconBox />
        <p>Lote</p>
      </button>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(2);
          setStepPayment(1);
        }}
      >
        <IconLogoOxPay />
        <p>Individual</p>
      </button>
    </div>
  );
};

export { PaymentMenu };
