export function formatGateway(id) {
  switch (id) {
    case 1:
      return 'OX';
    case 2:
      return '16501555';
    case 3:
      return '13935893';
    case 4:
      return '13935893';
    case 5:
      return '13935893';
    case 6:
      return '16501555';
    case 7:
      return '16501555';
    case 8:
      return '31680151';
    case 9:
      return 'OX';
    default:
      return 'CONSIGN';
  }
}
