export const statusData = [
  {
    value: 1,
    movementStatusCode: '01',
    label: 'Criado',
    movementStatusDescription: null,
  },
  // {
  //   value: 2,
  //   movementStatusCode: '02',
  //   label: 'Aguardando Processam',
  //   movementStatusDescription: null,
  // },
  {
    value: 3,
    movementStatusCode: '03',
    label: 'Aguardando Aprovação',
    movementStatusDescription: null,
  },
  {
    value: 4,
    movementStatusCode: '04',
    label: 'Processando',
    movementStatusDescription: null,
  },
  {
    value: 5,
    movementStatusCode: '05',
    label: 'Cancelado',
    movementStatusDescription: null,
  },
  {
    value: 6,
    movementStatusCode: '06',
    label: 'Liberado',
    movementStatusDescription: null,
  },
  {
    value: 7,
    movementStatusCode: '07',
    label: 'Erro',
    movementStatusDescription: null,
  },
  {
    value: 99,
    movementStatusCode: '07',
    label: 'Antecipado',
    movementStatusDescription: null,
  },
  // {
  //   value: 8,
  //   movementStatusCode: '08',
  //   label: 'Aguardando Saldo',
  //   movementStatusDescription: null,
  // },
  // {
  //   value: 9,
  //   movementStatusCode: '09',
  //   label: 'Aguardando Fatura',
  //   movementStatusDescription: null,
  // },
  // {
  //   value: 10,
  //   movementStatusCode: '10',
  //   label: 'Aguardando Análise',
  //   movementStatusDescription: null,
  // },
  // {
  //   value: 11,
  //   movementStatusCode: '11',
  //   label: 'Aguardando Consignaç',
  //   movementStatusDescription: null,
  // },
];
