import { useState, createContext, useRef } from 'react';

export const PaymentContext = createContext();
const { Provider } = PaymentContext;

export default function PaymentProvider({ children }) {
  //payment
  const [stepPayment, setStepPayment] = useState(0);
  const [typePayment, setTypePayment] = useState(0);
  const [payment, setPayment] = useState(false);
  const [finishPayment, setFinishPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errosSteps, setErrosSteps] = useState(false);
  const [isSalary, setIsSalary] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(false);
  const [banks, setBanks] = useState(false);
  const descriptionFile = useRef();
  const [errosFile, setErrosFile] = useState(false);
  return (
    <Provider
      value={{
        typePayment,
        setTypePayment,
        stepPayment,
        setStepPayment,
        payment,
        setPayment,
        finishPayment,
        setFinishPayment,
        loading,
        setLoading,
        errosSteps,
        setErrosSteps,
        isSalary,
        setIsSalary,
        showModal,
        setShowModal,
        file,
        setFile,
        banks,
        setBanks,
        descriptionFile,
        errosFile,
        setErrosFile,
      }}
    >
      {children}
    </Provider>
  );
}
