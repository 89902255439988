import { memo } from 'react';
import { GrRevert } from 'react-icons/gr';
import { MdPayments } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useBalance } from '../../../../../hooks/useBalance';
import { useUser } from '../../../../../hooks/useUser';
import { formatDocument } from '../../../../../utils/formatDocument';
import { formatMoney } from '../../../../../utils/formatMoney';
import * as S from './styles';

function hasNoNumbers(str) {
  return !/\d/.test(str);
}

function TableItem({ item, currentPage, setModal }) {
  const history = useHistory();
  const { setPageExtractSelect } = useBalance();
  const { user } = useUser();

  function handlePushRouter() {
    if (item.type === 1) {
      history.push({
        pathname: `/menu/proof/${item.id}`,
        state: {
          ...item,
        },
      });
      setPageExtractSelect(currentPage);
    }
    if (item.orderKey && item.movementKey) {
      history.push({
        pathname: `/menu/proof/${item.movementKey}`,
        state: {
          ...item,
        },
      });
      setPageExtractSelect(currentPage);
    }

    if (item.orderKey && !item.movementKey) {
      history.push(`/menu/order/${item.orderKey}`);
      setPageExtractSelect(currentPage);
    }
  }

  const isDisabled = (item) => {
    if (item.type === 4 || item.type === 1) {
      return false;
    }
    if (
      item &&
      ((item.service && item.service.type === 10) ||
        item.movementStatus !== 'success' ||
        !item.movementKey)
    ) {
      return true;
    }
    return false;
  };

  return (
    <S.TableBodyLine key={item.movementKey}>
      <S.TableBodyItems>{item.date}</S.TableBodyItems>
      <S.TableBodyItems>{item.service.value}</S.TableBodyItems>
      <S.TableBodyItems color={item.title.color}>
        {item.title.description}
      </S.TableBodyItems>
      <S.TableBodyItems bold={400}>
        {item.name ?? item.additional_data}
      </S.TableBodyItems>
      <S.TableBodyItems>
        {hasNoNumbers(item.document) ? '---' : formatDocument(item.document)}
      </S.TableBodyItems>
      <S.TableBodyMoney
        types={item.type}
        value={{
          before: item?.before,
          after: item?.after,
          amount: item?.amount,
        }}
      >
        {formatMoney(item.amount)}
      </S.TableBodyMoney>
      <S.TableBodyItems>{formatMoney(item.after)}</S.TableBodyItems>
      <S.TableBodyItems>{formatMoney(item.fee_amount ?? 0)}</S.TableBodyItems>

      {item?.type === 7 &&
      [60].every((item) => user?.permission.includes(item)) ? (
        <>
          <S.TableBodyItemsButton
            cursor={'pointer'}
            opacity={'1'}
            onClick={() => setModal(item)}
          >
            {<GrRevert size="1rem" color={'var(--primary)'} />}
          </S.TableBodyItemsButton>
        </>
      ) : (
        <>
          <S.TableBodyItemsButton
            cursor={isDisabled(item) ? 'no-drop' : 'pointer'}
            opacity={isDisabled(item) ? '0.4' : undefined}
            onClick={!isDisabled(item) ? () => handlePushRouter() : undefined}
            disabled={isDisabled(item)}
          >
            {<MdPayments size="1rem" color={'var(--primary)'} />}
          </S.TableBodyItemsButton>
        </>
      )}
    </S.TableBodyLine>
  );
}

export default memo(TableItem);
