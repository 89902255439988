import React, { useState } from 'react';

import { formatMoney } from '../../../../../../utils/formatMoney';
import styles from './styles.module.scss';
import FormGroup from './FormGroup';
import { useForm } from 'react-hook-form';
import { IoIosArrowForward, IoMdClose } from 'react-icons/io';
import { MdPayments } from 'react-icons/md';

import { Loader } from 'rsuite';
import { useUser } from '../../../../../../hooks/useUser';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignMaskAndValidate } from './utils/assings';

import { parseCurrencyToNumber } from './utils/parseCurrencyToNumber';

import { useOrder } from '../../../../../../hooks/useOrder';

import { useHistory } from 'react-router-dom';

import { usePayment } from '../../../../../../hooks/usePayment';
import {
  IconAlertKey,
  IconArrow,
  IconCalendar,
  IconDocumentPerson,
  IconKey,
  IconPhone,
} from './icons';
import { IconBook, IconDate, IconValue } from '../PaymentBank/icons';
import { IconLike, IconMoney, IconPerson, IconProof } from '../icons';
import { addHoursAndFormat } from '../PaymentTransfer/utils/addHoursAndFormat';
import { convertDate } from '../PaymentTransfer/utils/convertDate';

const Validate = yup.object().shape({
  key: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-key', 'Chave não valida', function (value) {
      if (value) {
        return true;
      }
    })
    .required('Chave é obrigatória'),
  amount: yup
    .string()
    .typeError('O campo deve receber um valor.')
    .required('Valor é obrigatório')
    .test(
      'is-name-ag',
      'O campo deve receber um Valor em real Valido e maior que R$ 0,01.',
      (value) => {
        const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

        const testRegex = regex.test(value);

        const numericValue = parseCurrencyToNumber(value);
        if (!isNaN(numericValue) && numericValue >= 0.01 && testRegex) {
          return true;
        } else {
          return false;
        }
      },
    ),
});

const PaymentPix = () => {
  const [checkKey, setCheckKey] = useState(false);
  const [clear] = useState(false);
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,
    banks,
  } = usePayment();

  const today = new Date().toISOString().split('T')[0];
  const { user } = useUser();
  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );
  const {
    fetchTransferOrder,
    fetchOrderKeyValidation,
    fetchOrderKey,
    fetchOrderPreview,
  } = useOrder();
  const history = useHistory();
  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${finishPayment.key}`,
      state: {
        ...finishPayment,
      },
    });
    clearStatePayment();
  };
  const handleOrderKey = () => {
    history.push({
      pathname: `/menu/order/${finishPayment.orderKey}`,
    });
    clearStatePayment();
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(Validate),
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
    },
  });

  var formType = assignMaskAndValidate(watch('key'), checkKey).id;
  const formDate = watch('date');
  const formKey = watch('key');

  const onSubmit = async (values) => {
    try {
      setLoading({ paymentOrder: true });

      const { key, amount, date, description } = values;
      const keyFormatted =
        Number(formType) === 1 || Number(formType) === 2
          ? key.replace(/\D/g, '')
          : Number(formType) === 4
          ? `+55${key.replace(/\D/g, '')}`
          : key;

      const data = await fetchTransferOrder({
        body: {
          description: description,
          movements: [
            {
              additional_data: description,
              amount: parseCurrencyToNumber(amount),
              pix_key: keyFormatted,
              ...(date !== today && {
                date: addHoursAndFormat(date, 3),
              }),
            },
          ],
        },
        isManual: true,
        isSalary: false,
      });
      if (!!data) {
        await fetchOrderPreview(data?.key);
        const { movements, order } = await fetchOrderKey(data.key);

        if (movements[0]) {
          const paymentInfo = {
            ...movements[0],
            amount: formatMoney(movements[0]?.amount, 'code'),
            name: movements[0]?.name,
            bank_code: movements[0].bank_code,
            document: movements[0].document,
            date: movements[0].date,
            description: movements[0].additional_data,
            key: key,
            movementKey: movements[0].movementKey,
            orderKey: order?.key,
          };
          setPayment(paymentInfo);
          setStepPayment(2);
          setErrosSteps({
            status: 'process',
          });
        }
      } else {
        setPayment({ document, amount, date, description });
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Chave não encontrada',
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    setLoading({ paymentOrder: true });

    try {
      if (payment.orderKey) {
        await fetchOrderKeyValidation(payment.orderKey, 3);
        const { movements } = await fetchOrderKey(payment?.orderKey);

        if (payment) {
          setFinishPayment({
            ...movements[0],
            key: payment.key,
            orderKey: payment.orderKey,
          });
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="key"
              label={
                <>
                  <strong>Chave: </strong>
                  {assignMaskAndValidate(formKey, checkKey)?.type}
                </>
              }
              type="text"
              data={{ value: payment?.key }}
              placeholder={'CPF, CNPJ, celular, e-mail ou aleatória'}
              disabled={!loading?.paymentOrder ? false : true}
              mask={
                assignMaskAndValidate(formKey, checkKey)?.validator &&
                assignMaskAndValidate(formKey, checkKey)?.mask
              }
              register={register}
              errors={errors}
              setValue={setValue}
              clean={clear}
              Icon={IconKey}
            />
            <FormGroup
              name="amount"
              label={
                <>
                  <strong>Valor: </strong>
                </>
              }
              type="text"
              data={{ value: payment?.amount }}
              mask={[
                '9,99',
                '99,99',
                '999,99',
                '9.999,99',
                '99.999,99',
                '999.999,99',
                '9.999.999,99',
              ]}
              placeholder="R$ 0,00"
              disabled={!loading?.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconValue}
            />

            <FormGroup
              name="date"
              label={
                <>
                  <strong>Quando: </strong>{' '}
                  {today === formDate ? 'Hoje' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              data={{
                value: payment?.date
                  ? convertDate(payment?.date)
                  : new Date().toISOString().split('T')[0],
              }}
              disabled={!loading?.paymentOrder ? false : true}
              register={register}
              min={new Date().toISOString().split('T')[0]}
              errors={errors}
              setValue={setValue}
              Icon={IconCalendar}
            />
            {formType === 6 && (
              <div className={styles.key_allert}>
                <div className={styles.key_allert_header}>
                  <span>
                    <IconAlertKey />
                  </span>

                  <span>
                    O número que você digitou é uma chave CPF ou Telefone?
                  </span>
                </div>
                <div
                  className={styles.key_allert_box}
                  onClick={() => {
                    setCheckKey(1);
                  }}
                >
                  <div>
                    <IconDocumentPerson />
                    <span>CPF</span>
                  </div>
                  <IconArrow />
                </div>
                <div
                  className={styles.key_allert_box}
                  onClick={() => {
                    setCheckKey(4);
                  }}
                >
                  <div>
                    <IconPhone />
                    <span>Telefone</span>
                  </div>
                  <IconArrow />
                </div>
              </div>
            )}
          </div>
          <div className={styles.form_wrapper_}>
            <FormGroup
              name="description"
              label={
                <>
                  <strong>Descrição: </strong>
                </>
              }
              type="text"
              data={{ value: payment?.description }}
              placeholder="Pagamento"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconBook}
            />
            <div className={styles.buttons_form}>
              <button
                type="button"
                disabled={loading?.paymentOrder}
                className={styles.button_cancel}
                onClick={clearStatePayment}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={styles.button}
                disabled={loading}
              >
                Confirmar
                {loading ? <Loader size="xs " /> : <IoIosArrowForward />}
              </button>
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>
            Foi criada uma <strong> transferência para:</strong>
          </h1>

          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description}>
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>
                        Pessoa/Instituição:
                      </div>
                      {payment?.name === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(parseCurrencyToNumber(payment.amount))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Data:</div>

                        <div className={styles.title_description}>
                          {payment.date}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Banco:
                      <p>
                        {banks
                          ? payment?.bank_code === '31680151'
                            ? 'ISPB: 31680151 - Swap Ip S.A.'
                            : payment?.bank_code
                            ? banks.find(
                                (item) =>
                                  item.value === payment?.bank_code ||
                                  item.ispb === payment?.bank_code,
                              )?.label ?? '---'
                            : '---'
                          : '---'}
                      </p>
                    </span>
                    <span>
                      Documento:
                      <p>{payment?.document}</p>
                    </span>

                    {payment?.error_description && (
                      <span>
                        Erro:
                        <p className={styles.error_preview}>
                          {payment.error_description}
                        </p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(
                        parseCurrencyToNumber(payment.amount) +
                          (user?.company?.transfer_fee
                            ? user?.company?.transfer_fee
                            : 0),
                      )}
                    </span>
                    <div className={styles.fee}>
                      <span>* Taxa:</span>
                      <p>
                        {formatMoney(
                          user?.company?.transfer_fee
                            ? user?.company?.transfer_fee
                            : 0,
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={() => setStepPayment(1)}
                    >
                      Cancelar
                    </button>
                    {payment?.status?.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Transferência finalizada!'
                    : 'Transferência Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar
                      seus pagamentos.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa transação.
                    </>
                  )}
                </p>
                <div className={styles.buttons_finish}>
                  {finishPayment && finishPayment?.status?.id === 6 && (
                    <button
                      type="button"
                      disabled={loading.paymentOrder}
                      className={styles.button}
                      onClick={handleProof}
                    >
                      <MdPayments />
                      Comprovante
                    </button>
                  )}

                  {finishPayment &&
                    ![6].includes(finishPayment?.status?.id) && (
                      <button
                        type="button"
                        disabled={loading.paymentOrder}
                        className={styles.button}
                        onClick={handleOrderKey}
                      >
                        <MdPayments />
                        Ver Pagamento
                      </button>
                    )}
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentPix };
