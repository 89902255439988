import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoReloadOutline } from 'react-icons/io5';
import { useDate } from '../../hooks/useDate';
import { useUser } from '../../hooks/useUser';
import { usePagination } from '../../hooks/usePagination';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { DatePicker } from '../../components/DatePiker';
import styles from './styles.module.scss';
import { usePayment } from '../../hooks/usePayment';
import StepsPayment from './Components/StepsPayment';
import PaymentTable from './Components/PaymentTable';
import { useFetchMovementsBankSlip } from '../../hooks/useQuery';
import { addDays, differenceInDays, subDays } from 'date-fns';
import { useNotification } from '../../hooks/useNotifications';
import { CheckPicker, Loader } from 'rsuite';
import { useQueryClient } from 'react-query';
import { AiOutlinePlus } from 'react-icons/ai';
import { CardAmountAwait } from './Components/CardAmountAwait';
import { CardAmountPeople } from './Components/CardAmountPeople';
import { CardAmountMoney } from './Components/CardAmountMoney';
import { InputSearch } from '../../components/UI/InputSearch';
import { RiSearch2Line } from 'react-icons/ri';
import { lower } from '../../utils/lower';
import { useTransfer } from '../../hooks/useTransfer';
import { statusData } from './data';
import { useOrder } from '../../hooks/useOrder';
import { CardResumeSelect } from './Components/CardResumeSelect';
import { FiCheck } from 'react-icons/fi';
import { ButtonMenuActions } from './Components/ButtonMenuActions';

const Payment = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [search, setSearch] = useState(false);
  const [, setFilter] = useState(0);
  const { statusTransferSelect, setStatusTransferSelect } = useTransfer();
  const [filter_expirate_date, set_filter_espirate_date] = useState(false);
  const { addNotification } = useNotification();
  const { dateRanger, setDateRanger, dateRangerBankSlip, setRangerBankSlip } =
    useDate();
  const { clearStatePayment } = usePayment();
  const [viewSteps, setViewSteps] = useState(false);
  const [time] = useState(false);
  const { fetchOrderKeyValidation, fetchOrderKeyCancel } = useOrder();
  const [listSelect, setListSelect] = useState([]);
  const [firstClick, setFirstClick] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleViewSteps = () => {
    setViewSteps(!viewSteps);
    clearStatePayment();
  };
  const handleStatusChange = (value) => {
    setStatusTransferSelect(value);
    set_filter_espirate_date(false);
  };
  const history = useHistory();
  useEffect(() => {
    if (search) {
      setListSelect([]);
      setFirstClick([]);
      setStatusTransferSelect([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  function handlePushRouter(id, status) {
    history.push({
      pathname: `/menu/order/${id}`,
      state: {
        status,
      },
    });
  }
  function handleFilterExpireDate() {
    set_filter_espirate_date(!filter_expirate_date);
    statusTransferSelect([]);
  }
  useEffect(() => {
    if (
      !dateRangerBankSlip ||
      differenceInDays(
        new Date(dateRangerBankSlip[1]),
        new Date(dateRangerBankSlip[0]),
      ) < 90
    ) {
      let startDateString = addDays(dateRangerBankSlip[0], 1)
        .toISOString()
        .substring(0, 10);

      let endDateString = addDays(dateRangerBankSlip[1], 1)
        .toISOString()
        .substring(0, 10);

      let startDate = new Date(startDateString);

      startDate.setHours(-3, 0, 0);
      startDate = startDate.toISOString();

      let endDate = new Date(endDateString);

      endDate.setHours(20, 59, 59);
      endDate = endDate.toISOString();

      setDateRanger({
        dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
        dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
      });
    } else {
      addNotification(
        'A data não pode ter intervalo maior que 90 dias ',
        1,
        'Atenção',
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangerBankSlip]);

  const { data: listMovement, isFetching } = useFetchMovementsBankSlip(
    dateRanger?.dateInitial
      ? dateRanger
      : setRangerBankSlip([subDays(new Date(), 6), new Date()]),
    time,
  );
  const dataCards = listMovement ?? [];
  const list =
    listMovement &&
    listMovement
      .filter((item) => {
        if (!search || /\d/.test(search)) return item;

        return lower(`${item['name']}`).includes(lower(search));
      })
      .filter((item) => {
        if (!search) return item;
        return (
          item['document'].includes(search.replace(/\D/g, '')) ||
          [item['id']].includes(search)
        );
      })
      .filter((item) => {
        if (statusTransferSelect.length === 0) return item;

        return statusTransferSelect.includes(item.status.id) && item;
      })
      .filter((item) => {
        if (filter_expirate_date === false) return true;
        if (
          filter_expirate_date &&
          [1, 3].includes(item?.status?.id) &&
          item?.amount &&
          differenceInDays(new Date(item?.expiration_date), new Date()) <= 2 // Verifica se a data de expiração é em 2 dias ou menos
        ) {
          return true;
        }

        return false;
      });

  async function handleReload() {
    await queryClient.invalidateQueries(['bankSlip']);
  }
  const toggleSelect = (itemId) => {
    if (listSelect.includes(itemId)) {
      setListSelect(listSelect.filter((id) => id !== itemId));
    } else {
      setListSelect([...listSelect, itemId]);
    }
  };
  const selectAll = () => {
    const ids = list
      .filter((item) => [1, 3].includes(item?.status?.id))
      .filter((item) => item?.amount > 0)
      .map((item) => item.order_key);

    if (firstClick) {
      setListSelect([]);
      setListSelect(ids);
    } else {
      setListSelect([]);
    }

    // Toggle the firstClick state
    setFirstClick(!firstClick);
  };
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    !!list ? list : [],
    10,
  );
  const resume_listSelect =
    listSelect.length > 0 &&
    listSelect
      .map((item) => {
        return list.find((order) => order.order_key === item);
      })
      .reduce(
        (acc, item) => {
          if ([1, 3].includes(item?.status?.id)) {
            return {
              total_amount: acc.total_amount + (item?.amount || 0),
              total_order: acc.total_order + 1,
            };
          }
          return acc;
        },
        { total_amount: 0, total_order: 0 },
      );

  async function handleSendPayment() {
    setLoading({ approve: true, cancel: false });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          const type = 0;

          await fetchOrderKeyValidation(key, type);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento dos pagamentos:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      setListSelect([]);
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de aprovação',
        false,
      );
    }
  }
  async function handleSendCancel() {
    setLoading({ approve: false, cancel: true });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          const type = 0;

          await fetchOrderKeyCancel(key, type);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento dos pagamentos:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      setListSelect([]);
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de cancelamento',
        false,
      );
    }
  }
  return (
    <div className={styles.container}>
      <section className={styles.sectionContainer}>
        <div className={styles.header_style_}>
          <div className={styles.title_style}>Boletos</div>
          {[10].every((item) => user?.permission.includes(item)) && (
            <button onClick={handleViewSteps} className={styles.send}>
              <AiOutlinePlus
                className={styles.icon}
                size="1.125rem"
                style={viewSteps && { rotate: '45deg' }}
              />
              {viewSteps ? 'Fechar' : 'Novo Pagamento'}
            </button>
          )}
        </div>

        {viewSteps && <StepsPayment setViewSteps={setViewSteps} />}
        <div className={styles.wrapper_cards_resume}>
          <CardAmountMoney
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Boletos Pagos'}
          />
          <CardAmountPeople
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Boletos a Vencer'}
            filter_expirate_date={filter_expirate_date}
            handleFilterExpireDate={handleFilterExpireDate}
          />
          <CardAmountAwait
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Em aprovação'}
            handleStatusChange={handleStatusChange}
            statusTransferSelect={statusTransferSelect}
          />
        </div>
        <div className={styles.wrapper_cards}>
          <div className={styles.title_list}>
            <header>
              <h3>Lista de Boletos</h3>
              <p>{listMovement?.length > 0 ? listMovement?.length : 0}</p>
            </header>
            <InputSearch
              label="Pesquisar por nome ou documento"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
              width="50%"
              filter={setFilter}
            />
            <div className={styles.container_arrow_box}>
              <PaginationArrow
                pages={pages}
                page={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </div>
          <div className={styles.selectReload}>
            <div className={styles.wrapper_buttons_select}>
              <DatePicker
                setDate={setRangerBankSlip}
                value={dateRangerBankSlip}
                style={{ width: '22rem' }}
                type={2}
                disabled={isFetching}
              />
              <CheckPicker
                data={isFetching ? [] : statusData}
                // style={{ width: '22rem', minWidth: '12rem' }}
                placeholder="Status"
                onChange={handleStatusChange}
                disabled={isFetching}
                cleanable={isFetching}
                defaultValue={statusTransferSelect}
                value={statusTransferSelect}
              />

              <div className={styles.wrapper_acions}>
                {resume_listSelect?.total_order > 0 && (
                  <>
                    <CardResumeSelect resume={resume_listSelect} />
                    <button
                      onClick={handleSendPayment}
                      className={styles.send_approve}
                    >
                      {loading.approve ? (
                        <Loader />
                      ) : (
                        <FiCheck size="1.125rem" color="var(--white)" />
                      )}
                      Aprovar
                    </button>
                  </>
                )}
                {list && list.length > 0 && (
                  <ButtonMenuActions
                    orders={list}
                    listSelect={listSelect}
                    handleSendPayment={handleSendPayment}
                    handleSendCancel={handleSendCancel}
                  />
                )}
              </div>
              <button
                disabled={isFetching}
                className={styles.button_load_style}
                onClick={handleReload}
              >
                {isFetching ? (
                  <Loader />
                ) : (
                  <IoReloadOutline size="1.3rem" color="var(--white)" />
                )}
              </button>
            </div>
            <div className={styles.wrapper_table}>
              <PaymentTable
                payments={currentItens}
                isFetching={isFetching}
                handlePushRouter={handlePushRouter}
                selectAll={selectAll}
                firstClick={firstClick}
                toggleSelect={toggleSelect}
                listSelect={listSelect}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payment;
