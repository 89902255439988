import React, { useEffect, useState } from 'react';
import styles from '../../styles.module.scss';
import axios from 'axios';
import { saveAs } from 'file-saver';
import ReactImageMagnify from 'react-image-magnify';

const imgDefault =
  'https://bootstrap-cheatsheet.themeselection.com/assets/images/bs-images/img-3x4.png';

const Image = ({
  src,
  alt,
  self = false,
  downloadName,
  documentType,
  preview = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState({ src: imgDefault, stage: false });
  const name = `${alt}-${downloadName}-${documentType}`;

  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      src: img.src,
      height: preview ? 200 : 480,
      objectFit: 'cover',
      width: preview ? 200 : 352,
    },
    largeImage: {
      src: img.src,
      width: preview ? 200 : 1200,
      height: preview ? 200 : 1800,
    },
    enlargedImageContainerStyle: {
      background: '#fff',
      zIndex: 99999999999,
      width: '200%',
      height: '100%',
    },
  };

  useEffect(() => {
    async function pickImage() {
      try {
        setLoading(true);
        const result = await axios.get(src);
        if (result) {
          setImg({ src, stage: true });
        }
      } catch (error) {
        setImg({ src: imgDefault, stage: false });
      } finally {
        setLoading(false);
      }
    }

    pickImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(img.src, { responseType: 'blob' });
      saveAs(response.data, name);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  if (self) {
    return (
      <div className={styles.img_wrapper}>
        {loading ? (
          <img
            style={{ opacity: 0.3 }}
            className={preview ? styles.img__ : styles.img_}
            src={img.src}
            alt={alt}
          />
        ) : (
          <img
            className={preview ? styles.img__ : styles.img_}
            src={img.src}
            alt={alt}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.img_wrapper}>
        {loading || !img.stage ? (
          <img className={styles.img} src={img.src} alt={alt} />
        ) : (
          <div className={styles.box_img}>
            <ReactImageMagnify {...imageProps} />
            <button onClick={handleDownload}>Baixar</button>
          </div>
        )}
      </div>
    );
  }
};

export default Image;
