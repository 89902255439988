import styles from '../styles/styles.module.scss';
import { useFormData } from '../../../hooks/useFormData';
import { useForm } from 'react-hook-form';
import { BiMenu, BiPlus } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';
import { FaApplePay, FaGooglePlay } from 'react-icons/fa';
import { useState } from 'react';
const Card = ({ link, Icon, isCopied, handleCopyClick }) => {
  return (
    <div className={styles.card_box}>
      {isCopied === link && (
        <div className={styles['check-wrap-box']}>
          <div className={styles['check-wrap']}></div>
        </div>
      )}

      <div className={styles.card_copy}>
        <CopyToClipboard text={link}>
          <button type="button" onClick={() => handleCopyClick(link)}>
            <FiCopy size={'1.2rem'} color={'var(--gray-400)'} /> Copiar
          </button>
        </CopyToClipboard>
      </div>

      <Icon />
    </div>
  );
};
export default function FormCompleted({ clearFormSteps }) {
  const [isCopied, setIsCopied] = useState(false);

  const { setClearedForm } = useFormData();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const handleCopyClick = (text) => {
    setIsCopied(text);
  };
  const onSubmit = () => {
    clearFormSteps();
    setClearedForm();
  };
  return (
    <div className={styles.showFormComplete}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.step_three}>
          <header>
            <h2>Cadastro concluído com Sucesso!</h2>
            <p>
              Colaborador deverá acessar o App para concluir as próximas etapas
              do cadastro. Copie o link abaixo e peça para que ele instale o
              aplicativo no celular e crie uma conta. É super rápido!
            </p>
          </header>
          <div className={styles.cards_box}>
            <Card
              link="https://bit.ly/oxpay"
              Icon={() => (
                <FaGooglePlay size={'1.875rem'} color={'var(--primary)'} />
              )}
              handleCopyClick={handleCopyClick}
              isCopied={isCopied}
            />
            <Card
              link="https://apple.co/3qIa8DE"
              Icon={() => (
                <FaApplePay size={'3.5rem'} color={'var(--primary)'} />
              )}
              handleCopyClick={handleCopyClick}
              isCopied={isCopied}
            />
          </div>
        </div>
        <div className={styles.next_button}>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            <BiMenu color="var(--white)" size={20} />
            Ver Lista de Colaboradores
          </button>
          <button type="submit">
            <BiPlus color="var(--white)" size={20} />
            Novo Colaborador
          </button>
        </div>
      </form>
    </div>
  );
}
