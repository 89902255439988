import { getCookie, removeCookie, setCookie } from './store';

const expires = new Date();
expires.setFullYear(expires.getFullYear() + 1);

const options = { expires };

export const COOKIE_USER = '@user--fixed-onload';
export const COOKIE_TOKEN = 'token--fixed-onload';
export const COOKIE_COMPANY = '@company--itens-onload';
export const COOKIE_COMPANIES = '@companies--itens-onload';
export const COOKIE_COMPANY_ID = '@company-id--onload';
export const COOKIE_NPS_SYSTEM = '@system-nps-onload';

export const setCookieUser = (account) => {
  setCookie(COOKIE_USER, account, options);
};
export const getCookieUser = () => getCookie(COOKIE_USER, options);
export const removeCookieUser = () => removeCookie(COOKIE_USER, options);

export const setToken = (token) => setCookie(COOKIE_TOKEN, token, options);
export const getToken = () => getCookie(COOKIE_TOKEN, options);
export const removeToken = () => removeCookie(COOKIE_TOKEN, options);

export const setCookieCompanies = (companies) =>
  setCookie(COOKIE_COMPANIES, companies, options);
export const getCookieCompanies = () => getCookie(COOKIE_COMPANIES, options);
export const removeCookieCompanies = () =>
  removeCookie(COOKIE_COMPANIES, options);

export const setCookieCompany = (company) =>
  setCookie(COOKIE_COMPANY, company, options);
export const getCookieCompany = () => getCookie(COOKIE_COMPANY, options);
export const removeCookieCompany = () => removeCookie(COOKIE_COMPANY, options);

export const setCookieCompanyId = (company) =>
  setCookie(COOKIE_COMPANY_ID, company, options);
export const getCookieCompanyId = () => getCookie(COOKIE_COMPANY_ID, options);
export const removeCookieCompanyId = () =>
  removeCookie(COOKIE_COMPANY_ID, options);

export const getCookieNpsSystem = () => getCookie(COOKIE_NPS_SYSTEM, options);
export const setCookieNpsSystem = (npsControl) =>
  setCookie(COOKIE_NPS_SYSTEM, npsControl, options);
