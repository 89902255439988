import React from 'react';
import styles from './styles.module.scss';
import { IconLogoOxPay, IconPix, IconBox, IconTransfer } from './icons/index';

const PaymentMenu = ({ setTypePayment, setStepPayment }) => {
  return (
    <div className={styles.container}>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(3);
          setStepPayment(1);
        }}
      >
        <IconBox />
        <p>Pagar Lote</p>
      </button>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(4);
          setStepPayment(1);
        }}
      >
        <IconTransfer />
        <p>Dados Bancários</p>
      </button>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(1);
          setStepPayment(1);
        }}
      >
        <IconPix />
        <p>Chave Pix</p>
      </button>
      <button
        className={styles.item}
        onClick={() => {
          setTypePayment(2);
          setStepPayment(1);
        }}
      >
        <IconLogoOxPay />
        <p>Entre contas OxPay</p>
      </button>
    </div>
  );
};

export { PaymentMenu };
