export function nameAbbreviate(nome) {
  const palavras = nome.split(' ').filter((v) => v.length && v);

  if (palavras.length < 6) {
    return formatName(nome);
  }

  const nomeAbreviado = palavras.map((palavra, index) => {
    if (index === 0 || index === palavras.length - 1) {
      return palavra;
    } else if (
      palavra.toUpperCase() === 'DA' ||
      palavra.toUpperCase() === 'DE' ||
      palavra.toUpperCase() === 'DO'
    ) {
      return '';
    }

    return palavra[0] + '.';
  });

  return formatName(nomeAbreviado.join(' '));
}

export function formatName(fullName) {
  return fullName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
