import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';

export function useTransfer() {
  const { statusTransferSelect, setStatusTransferSelect } =
    useContext(StoreContext);

  return {
    statusTransferSelect,
    setStatusTransferSelect,
  };
}
