// import React from 'react';
// import styles from './styles.module.scss';
// import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

// function PaginationArrow({ page, setPage, pages }) {
//   return (
//     <div className={styles.wrapper_pages}>
//       {page === 0 ? (
//         <div className={styles.pointer}>
//           0
//           <IoIosArrowBack size={'1.5rem'} color={'var(--gray-50)'} disabled />
//         </div>
//       ) : (
//         <>
//           <div className={styles.pointer_p}>
//             {page}
//             <IoIosArrowBack
//               size={'1.5rem'}
//               color={'var(--gray-200)'}
//               onClick={() => setPage(page - 1)}
//             />
//           </div>
//         </>
//       )}
//       <span className={styles.center}>{page + 1}</span>
//       {page + 1 === pages || pages === 0 ? (
//         <div className={styles.pointer__}>
//           <IoIosArrowForward size={'1.5rem'} color={'var(--gray-50)'} /> 0
//         </div>
//       ) : (
//         <div className={styles.pointer_}>
//           <IoIosArrowForward
//             size={'1.5rem'}
//             color={'var(--gray-200)'}
//             onClick={() => setPage(page + 1)}
//           />
//           {page + 2}
//         </div>
//       )}
//     </div>
//   );
// }

// export default PaginationArrow;

import React from 'react';
import styles from './styles.module.scss';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

const PaginationArrow = ({ page, setPage, pages }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = '...';

    if (pages <= 5) {
      for (let i = 1; i <= pages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`${styles.page_number} ${
              page + 1 === i ? styles.active : ''
            }`}
            onClick={() => setPage(i - 1)}
          >
            {i}
          </button>,
        );
      }
    } else {
      if (page < 3) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(
            <button
              key={i}
              className={`${styles.page_number} ${
                page + 1 === i ? styles.active : ''
              }`}
              onClick={() => setPage(i - 1)}
            >
              {i}
            </button>,
          );
        }
        pageNumbers.push(
          <span key="ellipsis1" className={styles.ellipsis}>
            {ellipsis}
          </span>,
        );
        pageNumbers.push(
          <button
            key={pages}
            className={`${styles.page_number} ${
              page + 1 === pages ? styles.active : ''
            }`}
            onClick={() => setPage(pages - 1)}
          >
            {pages}
          </button>,
        );
      } else if (page >= pages - 3) {
        pageNumbers.push(
          <button
            key={1}
            className={`${styles.page_number} ${
              page + 1 === 1 ? styles.active : ''
            }`}
            onClick={() => setPage(0)}
          >
            {1}
          </button>,
        );
        pageNumbers.push(
          <span key="ellipsis2" className={styles.ellipsis}>
            {ellipsis}
          </span>,
        );
        for (let i = pages - 2; i <= pages; i++) {
          pageNumbers.push(
            <button
              key={i}
              className={`${styles.page_number} ${
                page + 1 === i ? styles.active : ''
              }`}
              onClick={() => setPage(i - 1)}
            >
              {i}
            </button>,
          );
        }
      } else {
        pageNumbers.push(
          <button
            key={1}
            className={`${styles.page_number} ${
              page + 1 === 1 ? styles.active : ''
            }`}
            onClick={() => setPage(0)}
          >
            {1}
          </button>,
        );
        pageNumbers.push(
          <span key="ellipsis3" className={styles.ellipsis}>
            {ellipsis}
          </span>,
        );
        for (let i = page; i <= page + 2; i++) {
          pageNumbers.push(
            <button
              key={i + 1}
              className={`${styles.page_number} ${
                page + 1 === i + 1 ? styles.active : ''
              }`}
              onClick={() => setPage(i)}
            >
              {i + 1}
            </button>,
          );
        }
        pageNumbers.push(
          <span key="ellipsis4" className={styles.ellipsis}>
            {ellipsis}
          </span>,
        );
        pageNumbers.push(
          <button
            key={pages}
            className={`${styles.page_number} ${
              page + 1 === pages ? styles.active : ''
            }`}
            onClick={() => setPage(pages - 1)}
          >
            {pages}
          </button>,
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className={styles.wrapper_pages}>
      <button
        className={styles.back}
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
      >
        <IoIosArrowBack size={'1.5rem'} />
        <p>Anterior</p>
      </button>
      {renderPageNumbers()}
      <button
        className={styles.next}
        onClick={() => setPage(page + 1)}
        disabled={page + 1 === pages}
      >
        <p>Próxima</p>
        <IoIosArrowForward size={'1.5rem'} />
      </button>
    </div>
  );
};

export default PaginationArrow;
