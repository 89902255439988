import CopyToClipboard from 'react-copy-to-clipboard';
import styles from '../../styles.module.scss';
import { PiKeyBold } from 'react-icons/pi';
import TooTipCopy from '../../../../../../components/UI/ToolTipCopy';
import { WrapperToolTip } from '../WrapperToolTip';

export const CardCopy = ({ text, isCopied, handleCopyClick, id }) => {
  return (
    <div className={styles.card_box}>
      {isCopied && (
        <div className={styles['check-wrap-box']}>
          <div className={styles['check-wrap']}></div>
        </div>
      )}

      <div className={styles.key}>
        <TooTipCopy
          text={isCopied ? 'Copiado' : false}
          id={id}
          keyMovements={id}
        >
          <WrapperToolTip>
            <CopyToClipboard text={text}>
              <button
                className={styles.button_icon}
                onClick={() => handleCopyClick(text)}
              >
                <PiKeyBold size={'1rem'} color={'var(--blue-500)'} />
              </button>
            </CopyToClipboard>
          </WrapperToolTip>
        </TooTipCopy>
      </div>
    </div>
  );
};
