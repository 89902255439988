export function formatCardLastNumbers(last4_digits) {
  return `**** **** **** ${last4_digits}`;
}

export function formatCardNumbers(first6_digits, last4_digits) {
  const formattedCard = `${first6_digits.slice(0, 4)} ${first6_digits.slice(
    4,
    6,
  )}** **** ${last4_digits}`;

  return formattedCard;
}
