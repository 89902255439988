export function IconPlus({ size = '1rem', color = 'var(--primary-700)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={color}
        d="M8 15.667A7.173 7.173 0 01.835 8.5a7.173 7.173 0 017.167-7.167A7.173 7.173 0 0115.167 8.5a7.173 7.173 0 01-7.166 7.167zM8 2.333A6.174 6.174 0 001.835 8.5c0 3.4 2.767 6.167 6.167 6.167S14.167 11.9 14.167 8.5s-2.766-6.167-6.166-6.167z"
      ></path>
      <path
        fill={color}
        d="M10.667 9H5.334a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h5.333c.274 0 .5.227.5.5s-.226.5-.5.5z"
      ></path>
      <path
        fill={color}
        d="M8 11.667a.504.504 0 01-.5-.5V5.834c0-.274.227-.5.5-.5s.5.226.5.5v5.333c0 .273-.227.5-.5.5z"
      ></path>
    </svg>
  );
}
