import React, { memo } from 'react';
import { formatDocument } from '../../../../utils/formatDocument';
import styles from './styles.module.scss';

const ModalDeactivate = ({
  setModal,
  fetchGetOnboardingContract,
  user,
  modal,
}) => {
  function handleOutsideClick(e) {
    if (e.target === e.currentTarget) {
      setModal(null);
    }
  }
  function handleConfirm() {
    fetchGetOnboardingContract(user.cpf);
    setModal(false);
  }
  if (modal) {
    return (
      <div className={`${styles.modal}`} onClick={handleOutsideClick}>
        <div className={styles.modalBox}>
          <div className={styles.title}>Desativar Colaborador</div>
          <span className={styles.text}>
            Você está desativando o Colaborador
            <span className={styles.name}>{user?.name}</span>, que possui
            documento
            <span className={styles.name}>{formatDocument(user?.cpf)}</span>
          </span>
          <div className={styles.wrapper_button}>
            <button onClick={handleConfirm}>Sim</button>
            <button
              onClick={() => {
                setModal(false);
              }}
            >
              Não
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default memo(ModalDeactivate);
