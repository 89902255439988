class XMLGenerator {
  constructor(formatMoney, formatAccount) {
    this.formatMoney = formatMoney;
    this.formatAccount = formatAccount;
  }

  generateXML(list) {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
    const xmlBody = list?.map(this._mapItemToXML).join('');
    return `${xmlHeader}<Transactions>${xmlBody}</Transactions>`;
  }

  _mapItemToXML = (item) => {
    return `
      <Transaction>
        ${this._createXMLTag('Data', item?.date)}
        ${this._createXMLTag('SaldoAnterior', item?.before, this.formatMoney)}
        ${this._createXMLTag('Valor', item?.amount, this.formatMoney)}
        ${this._createXMLTag('SaldoPosterior', item?.after, this.formatMoney)}
        ${this._createXMLTag('SaldoTaxa', item?.fee_amount ?? 0, this.formatMoney)}
        ${this._createXMLTag('Servico', item?.service?.label)}
        ${this._createXMLTag('Tipo', item?.title?.description)}
        ${this._createXMLTag('Favorecido', item?.name)}
        ${this._createXMLTag('Documento', item?.document)}
        ${this._createXMLTag('Email', item?.email)}
        ${this._createXMLTag('Descricao', item?.additional_data)}
        ${this._createXMLTag('CodigoBanco', item?.bank_code)}
        ${this._createXMLTag('Agencia', item?.branch)}
        ${this._createXMLTag('DigitoAgencia', item?.branch_digit)}
        ${this._createXMLTag('NumeroConta', item?.account)}
        ${this._createXMLTag('DigitoConta', item?.account_digit)}
        ${this._createXMLTag('TipoConta', item?.account_type, this.formatAccount)}
        ${this._createXMLTag('Conta', item?.fantasy)}
        ${this._createXMLTag('ChavePix', item?.pix_key)}
      </Transaction>
    `;
  };

  _createXMLTag(tag, value, formatFn = (v) => v) {
    return value ? `<${tag}>${formatFn(value)}</${tag}>` : '';
  }
}
export { XMLGenerator };
