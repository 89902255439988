export const totalAmount = (array) => {
  const total = array.reduce(
    (accumulator, item) => {
      if (parseInt(item.status) === 6) {
        return parseFloat(accumulator) + parseFloat(item.amount);
      }

      return accumulator;
    },
    [0]
  );

  return String(total);
};
