import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';
import FormGroup from './FormGroup';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
export const ValidateAddCompany = yup.object().shape({
  street: yup
    .string()
    .typeError('O campo deve receber uma Rua.')
    .required('Rua é obrigatório'),
  neighborhood: yup
    .string()
    .typeError('O campo deve receber um Bairro.')
    .required('Bairro é obrigatório'),
  street_number: yup
    .string()
    .typeError('O campo deve receber um número.')
    .required('Número é obrigatório'),
  city: yup
    .string()
    .typeError('O campo deve receber uma Cidade.')
    .required('Cidade é obrigatória'),
  zipcode: yup
    .string()
    .typeError('O campo deve receber um CEP.')
    .required('CEP é obrigatório'),
});
export default function BillingInfo({ formStep, nextFormStep, prevFormStep }) {
  const { setFormValues } = useFormData();
  const [clear] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({ resolver: yupResolver(ValidateAddCompany) });

  const onSubmit = (values) => {
    setFormValues(values);
    nextFormStep();
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow}>
          <FormGroup
            name="city"
            label="Cidade:"
            type="text"
            data={{ ...register('city') }}
            disabled={false}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="street_complement"
            label="Complemento:"
            type="text"
            data={{ ...register('street_complement') }}
            disabled={false}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="street"
            label="Rua:"
            type="text"
            data={{ ...register('street') }}
            disabled={false}
            register={register}
            errors={errors}
          />

          <FormGroup
            name="neighborhood"
            label="Bairro:"
            type="text"
            data={{ ...register('neighborhood') }}
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            clean={clear}
          />

          <FormGroup
            name="street_number"
            label="Número:"
            type="text"
            data={{ ...register('street_number') }}
            disabled={false}
            register={register}
            errors={errors}
          />
          <FormGroup
            name="zipcode"
            label="CEP:"
            type="text"
            data={{ ...register('zipcode') }}
            disabled={false}
            register={register}
            errors={errors}
            mask={'99999-999'}
            setValue={setValue}
            clean={clear}
          />
        </div>
        <div className={styles.next_button}>
          {formStep > 0 && (
            <button onClick={prevFormStep} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
