import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import styles from './styles.module.scss';
import { useUser } from '../../hooks/useUser';
import { useAllBalancesQuery } from '../../hooks/useQuery';
import { LoadingBall } from '../../components/UI/LoadingBall';
import ButtonGoBack from '../../components/ButtonGoBack';
import { formatMoney } from '../../utils/formatMoney';
import { Details } from '../../components/Details';
import { IconKey } from '../../components/Details/Icons';

export default function ViewCompany() {
  const [company, setCompany] = useState(null);

  const { companyId } = useParams();
  const { data: companiesData, triggerRefetch } = useAllBalancesQuery();
  const { user } = useUser();
  useEffect(() => {
    const getCompany = async () => {
      if (!companiesData?.allBalance) await triggerRefetch();
      const company_ref = companiesData?.allBalance.find(
        (item) => (item.company_id = companyId),
      );
      if (company_ref) {
        setCompany(company_ref);
      }
    };
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul className={styles.container_box}>
      <div className={styles.menu}>
        {!company ? (
          <LoadingBall />
        ) : (
          <>
            <ButtonGoBack backRoute="/menu/balances" />
            <header className={styles.header}>
              <h1>{company?.fantasy_name} </h1>
            </header>

            <div className={styles.document}>
              <strong>Documento</strong>
              <p> {company?.document}</p>
            </div>
            {company?.contact !== company?.accountable && (
              <div className={styles.contact}>
                <strong>Contato</strong>
                {company?.contact !== company?.accountable && (
                  <p>{company?.contact}</p>
                )}

                {company?.contact !== company?.accountable && (
                  <p> {formatPhoneNumber(company?.phone)}</p>
                )}
              </div>
            )}
            <div className={styles.info_}>
              <div className={styles.info}>
                <h3>Dados da conta </h3>
                {company?.accountable && (
                  <li>
                    <strong>Responsável:</strong>
                    <p>{company?.accountable}</p>
                  </li>
                )}
                {company?.email && (
                  <li>
                    <strong>Email:</strong>
                    <p> {company?.email}</p>
                  </li>
                )}
                {company?.cnpj && (
                  <li>
                    <strong>CNPJ:</strong>
                    <p> {company?.cnpj}</p>
                  </li>
                )}
                {company?.accountable_cpf && (
                  <li>
                    <strong>CPF Responsável:</strong>
                    <p> {company?.accountable_cpf}</p>
                  </li>
                )}
                {company?.cpf && (
                  <li>
                    <strong>CNPJ:</strong>
                    <p> {company?.cpf}</p>
                  </li>
                )}
              </div>
              <div className={styles.info}>
                <h3>Endereço </h3>
                {company?.state && (
                  <li>
                    <strong>Estado:</strong>
                    <p>{company?.state}</p>
                  </li>
                )}
                {company?.city && (
                  <li>
                    <strong>Cidade:</strong>
                    <p>{company?.city}</p>
                  </li>
                )}

                {company?.neighborhood && (
                  <li>
                    <strong>Bairro:</strong>
                    <p> {company?.neighborhood}</p>
                  </li>
                )}
                {company?.street && (
                  <li>
                    <strong>Rua:</strong>
                    <p> {company?.street}</p>
                  </li>
                )}
                {company?.street_number && (
                  <li>
                    <strong>Numero:</strong>
                    <p> {company?.street_number}</p>
                  </li>
                )}
                {company?.street_complement && (
                  <li>
                    <strong>Complemento:</strong>
                    <p> {company?.street_complement}</p>
                  </li>
                )}
                {company?.zipcode && (
                  <li>
                    <strong>Cep:</strong>
                    <p> {company?.zipcode}</p>
                  </li>
                )}
              </div>
              <div className={styles.info}>
                <h3>Taxas da conta </h3>
                {company?.transfer_fee >= 0 && (
                  <li>
                    <strong>Taxa de Pagamentos / Boletos:</strong>
                    <p>{formatMoney(company?.transfer_fee)}</p>
                  </li>
                )}

                {company?.internal_transfer_fee >= 0 && (
                  <li>
                    <strong>Transferência Interna(Contas Digitais):</strong>
                    <p>{formatMoney(company?.internal_transfer_fee)}</p>
                  </li>
                )}
              </div>
              {user.customer?.company_id === 4 && (
                <div className={styles.info}>
                  <h3>Antecipação </h3>
                  <li>
                    <strong>Lançamento de Antecipação:</strong>
                    <p>{company?.consignation_enabled ? 'Sim' : 'Não'}</p>
                  </li>
                </div>
              )}
            </div>

            <div className={styles.document}>
              {company?.default_pix_key ? (
                <Details
                  maxWidth={'40rem'}
                  Icon={IconKey}
                  title={'Chave Pix'}
                  value={company?.default_pix_key}
                  copy={true}
                />
              ) : (
                <>
                  <strong>Chave Pix</strong>
                  <p>---</p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </ul>
  );
}
