import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';

import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineMail,
} from 'react-icons/ai';
import { BiPhone } from 'react-icons/bi';
import { FaHandsHelping, FaIdCard } from 'react-icons/fa';
import { useState } from 'react';
import { apiPost } from '../../../../services/api';
import { LoadingBall } from '../../../../components/UI/LoadingBall';
import { formatDocument } from '../../../../utils/formatDocument';
import { useNotification } from '../../../../hooks/useNotifications';
export default function Preview({ currentStep, nextFormStep, prevFormStep }) {
  const { form } = useFormData();
  const { handleSubmit } = useForm();
  const { addNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  function capitalizeName(name) {
    const nameParts = name.toLowerCase().split(' ');

    const capitalizedParts = nameParts.map((part) => {
      if (part.length > 0) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      }
      return part;
    });

    return capitalizedParts.join(' ');
  }

  async function fetchGetOnboardingCustomer(document) {
    setLoading(true);
    let onboardingStart = {};
    try {
      if (form.type.contract_type) {
        onboardingStart = {
          cpf: form.cpf,
          phone_number: form.phone_number,
          email: form.email,
          name: capitalizeName(form.name),
          type: Number(3),
          contract_type: form.type.contract_type,
        };
      } else {
        onboardingStart = {
          cpf: form.cpf,
          phone_number: form.phone_number,
          email: form.email,
          name: capitalizeName(form.name),
          type: Number(3),
        };
      }

      if (form.cnpj) {
        onboardingStart.cnpj = form.cnpj;
      }
      const { error } = await apiPost(`/Onboarding`, {
        ...onboardingStart,
      });

      return error ? false : true;
    } catch (err) {
      const { data } = err.response;
      const error = data?.error;

      if (error.code === 16) {
        addNotification(
          <>
            Já existe um cadastro para este colaborador, aguardando conclusão
            pelo App.
          </>,
          2,
          'Error ao cadastrar colaborador',
          true,
        );
      }
    } finally {
      setLoading(false);
    }
  }
  const onSubmit = async () => {
    const responseOnboarding = await fetchGetOnboardingCustomer();
    responseOnboarding && nextFormStep();
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow_preview}>
          {loading ? (
            <LoadingBall />
          ) : (
            <div className={styles.box_preview}>
              {form?.name && (
                <p className={styles.items_}>
                  <h2> {form?.name}</h2>
                </p>
              )}
              <div className={styles.box_flex_}>
                <div className={styles.box_right_}>
                  {form?.cnpj && (
                    <p className={styles.items_}>
                      <strong>
                        <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                        CNPJ:
                      </strong>
                      <span> {formatDocument(form.cnpj)}</span>
                    </p>
                  )}
                  <p className={styles.items_}>
                    <strong>
                      <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                      CPF:
                    </strong>
                    <span> {formatDocument(form.cpf)}</span>
                  </p>
                  <p className={styles.items_}>
                    <strong>
                      <BiPhone size={'1.2rem'} color="var(--gray-400)" />
                      Celular:
                    </strong>
                    <span>{form.phone_number}</span>
                  </p>
                  <p className={styles.items_}>
                    <strong>
                      <AiOutlineMail size={'1.2rem'} color="var(--gray-400)" />
                      Email:
                    </strong>
                    <span> {form?.email}</span>
                  </p>
                </div>
                <div className={styles.box_left_}>
                  <p className={styles.items}>
                    <strong>
                      <FaHandsHelping size={'1.2rem'} color="var(--gray-400)" />
                      Contrato:
                    </strong>
                    <span>
                      {form?.type?.name ? form?.type?.name : 'Sem contrato'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.next_button}>
          {currentStep > 0 && (
            <button onClick={prevFormStep} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Finalizar <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
