import styled from 'styled-components';

const NpsSurveySvg = styled.svg`
  @-webkit-keyframes animate-svg-nps-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(23, 168, 76);
    }
  }

  @keyframes animate-svg-nps-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(23, 168, 76);
    }
  }

  .nps-icon-1 {
    -webkit-animation: animate-svg-nps-fill-1 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-nps-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.8s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(229, 32, 18);
    }
  }

  @keyframes animate-svg-nps-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(229, 32, 18);
    }
  }

  .nps-icon-2 {
    -webkit-animation: animate-svg-nps-fill-2 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-nps-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.9s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(250, 204, 0);
    }
  }

  @keyframes animate-svg-nps-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(250, 204, 0);
    }
  }

  .nps-icon-3 {
    -webkit-animation: animate-svg-nps-fill-3 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-nps-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-nps-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .nps-icon-4 {
    -webkit-animation: animate-svg-nps-fill-4 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-nps-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.1s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-nps-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .nps-icon-5 {
    -webkit-animation: animate-svg-nps-fill-5 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-nps-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.2000000000000002s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-nps-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .nps-icon-6 {
    -webkit-animation: animate-svg-nps-fill-6 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation: animate-svg-nps-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.3s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(77, 87, 96);
    }
  }

  @keyframes animate-svg-nps-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(77, 87, 96);
    }
  }

  .nps-icon-7 {
    -webkit-animation: animate-svg-nps-fill-7 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
    animation: animate-svg-nps-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.4000000000000001s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-8 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-nps-fill-8 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .nps-icon-8 {
    -webkit-animation: animate-svg-nps-fill-8 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
    animation: animate-svg-nps-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.5s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-9 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-9 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-9 {
    -webkit-animation: animate-svg-nps-fill-9 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
    animation: animate-svg-nps-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.6s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-10 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-10 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-10 {
    -webkit-animation: animate-svg-nps-fill-10 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
    animation: animate-svg-nps-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.7000000000000002s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-11 {
    -webkit-animation: animate-svg-nps-fill-11 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
    animation: animate-svg-nps-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.8s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-12 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-12 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-12 {
    -webkit-animation: animate-svg-nps-fill-12 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
    animation: animate-svg-nps-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.9000000000000001s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-13 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-13 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-13 {
    -webkit-animation: animate-svg-nps-fill-13 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
    animation: animate-svg-nps-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-14 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-14 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-14 {
    -webkit-animation: animate-svg-nps-fill-14 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
    animation: animate-svg-nps-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2.1s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-15 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-15 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-15 {
    -webkit-animation: animate-svg-nps-fill-15 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
    animation: animate-svg-nps-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2.2s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-16 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-16 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-16 {
    -webkit-animation: animate-svg-nps-fill-16 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
    animation: animate-svg-nps-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2.3s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-17 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  @keyframes animate-svg-nps-fill-17 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(98, 107, 114);
    }
  }

  .nps-icon-17 {
    -webkit-animation: animate-svg-nps-fill-17 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
    animation: animate-svg-nps-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2.4000000000000004s both;
  }

  @-webkit-keyframes animate-svg-nps-fill-18 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-nps-fill-18 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .nps-icon-18 {
    -webkit-animation: animate-svg-nps-fill-18 0.7s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
    animation: animate-svg-nps-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
      2.5s both;
  }
`;

export function NpsSurveyAnimated() {
  return (
    <NpsSurveySvg
      xmlns="http://www.w3.org/2000/svg"
      width="510"
      height="278"
      fill="none"
      viewBox="0 0 510 278"
    >
      <g clipPath="url(#clip0_176_138)">
        <path
          fill="#17A84C"
          d="M393.212 255.53h99.553c9.308 0 16.856-7.817 16.239-17.105-5.191-78.139-45.689-146.6-105.641-189.691l-76.584 107.054c27.38 19.746 46.334 50.46 50.352 85.698.919 8.067 7.961 14.044 16.081 14.044z"
          className="nps-icon-1"
        ></path>
        <path
          fill="#E52012"
          d="M17.235 255.53h99.553c8.12 0 15.162-5.976 16.082-14.044 4.018-35.237 22.971-65.951 50.351-85.697L106.636 48.735C46.684 91.827 6.188 160.287.997 238.425c-.617 9.287 6.93 17.105 16.238 17.105z"
          className="nps-icon-2"
        ></path>
        <path
          fill="#FACC00"
          d="M326.778 155.788l76.584-107.054C361.58 18.703 310.374.96 255 .96c-55.375 0-106.582 17.743-148.364 47.775l76.585 107.054c20.204-14.571 44.985-23.185 71.779-23.185 26.793 0 51.574 8.613 71.778 23.184z"
          className="nps-icon-3"
        ></path>
        <path
          fill="#fff"
          d="M279.482 91.316c13.548-13.548 13.548-35.512 0-49.06-13.547-13.547-35.511-13.547-49.059 0-13.547 13.548-13.547 35.512 0 49.06 13.548 13.547 35.512 13.547 49.059 0z"
          className="nps-icon-4"
        ></path>
        <path
          fill="#fff"
          d="M105.492 208.404c13.547-13.548 13.547-35.512 0-49.059-13.548-13.548-35.512-13.548-49.06 0-13.547 13.547-13.547 35.511 0 49.059 13.548 13.547 35.512 13.547 49.06 0z"
          className="nps-icon-5"
        ></path>
        <path
          fill="#fff"
          d="M453.446 208.44c13.548-13.547 13.548-35.512 0-49.059-13.547-13.547-35.511-13.547-49.059 0-13.547 13.547-13.547 35.512 0 49.059 13.548 13.547 35.512 13.547 49.059 0z"
          className="nps-icon-6"
        ></path>
        <path
          fill="#4D5760"
          d="M265.636 274.129c-9.966 5.879-22.796 2.569-28.66-7.371-5.864-9.94-2.554-22.771 7.411-28.649 6.643-3.919 80.01-36.356 127.954-57.445 5.868-2.581 10.561 5.374 5.463 9.261-41.647 31.761-105.525 80.285-112.168 84.204z"
          className="nps-icon-7"
        ></path>
        <path
          fill="#fff"
          d="M263.247 256.119c0 4.563-3.695 8.258-8.247 8.258s-8.247-3.695-8.247-8.258a8.254 8.254 0 012.416-5.831 8.254 8.254 0 015.831-2.416 8.247 8.247 0 015.83 2.417 8.247 8.247 0 012.417 5.83z"
          className="nps-icon-8"
        ></path>
        <rect
          width="34"
          height="6"
          x="238"
          y="78"
          fill="#626B72"
          className="nps-icon-9"
          rx="3"
        ></rect>
        <rect
          width="9"
          height="6"
          x="238"
          y="55"
          fill="#626B72"
          className="nps-icon-10"
          rx="3"
        ></rect>
        <rect
          width="9"
          height="6"
          x="263"
          y="55"
          fill="#626B72"
          className="nps-icon-11"
          rx="3"
        ></rect>
        <path
          fill="#626B72"
          d="M98 202.438c0 2.01-2.17 3.266-3.957 2.345-8.788-4.528-15.348-5.663-26.08-.087-1.772.92-3.963-.312-3.963-2.309 0-.896.469-1.731 1.244-2.18 11.826-6.858 19.125-7.022 31.462-.015A2.577 2.577 0 0198 202.438z"
          className="nps-icon-12"
        ></path>
        <rect
          width="9"
          height="6"
          x="64"
          y="172"
          fill="#626B72"
          className="nps-icon-13"
          rx="3"
        ></rect>
        <rect
          width="9"
          height="6"
          x="89"
          y="172"
          fill="#626B72"
          className="nps-icon-14"
          rx="3"
        ></rect>
        <path
          fill="#626B72"
          d="M412 199.505c0-2.01 2.171-3.266 3.957-2.346 8.788 4.528 15.348 5.664 26.08.088 1.772-.921 3.963.312 3.963 2.308 0 .897-.469 1.731-1.244 2.181-11.826 6.858-19.125 7.022-31.462.014a2.577 2.577 0 01-1.294-2.245z"
          className="nps-icon-15"
        ></path>
        <rect
          width="9"
          height="6"
          x="412"
          y="172"
          fill="#626B72"
          className="nps-icon-16"
          rx="3"
        ></rect>
        <rect
          width="9"
          height="6"
          x="437"
          y="172"
          fill="#626B72"
          className="nps-icon-17"
          rx="3"
        ></rect>
      </g>
      <defs>
        <clipPath id="clip0_176_138">
          <path fill="#fff" d="M0 0H510V278H0z" className="nps-icon-18"></path>
        </clipPath>
      </defs>
    </NpsSurveySvg>
  );
}
