import { useContext, useEffect, useState, useMemo } from 'react';
import { StoreContext } from '../data/storeContext';

/**
 * Hook personalizado para lidar com a paginação de uma lista de itens.
 *
 * @param {Array} list - A lista de itens que você deseja paginar.
 * @param {number} pagesDefault - O número padrão de itens por página.
 * @returns {Object} - Um objeto contendo dados e funções relacionadas à paginação.
 */
export function usePagination(list, pagesDefault = 5, action = false) {
  const { pagesCurrent, setPagesCurrent } = useContext(StoreContext);

  const [currentPage, setCurrentPage] = useState(pagesCurrent[action] ?? 0);
  const [itensPerPage, setItensPerPage] = useState(pagesDefault);

  useEffect(() => {
    if (action && pagesCurrent[action] !== currentPage) {
      setPagesCurrent((prev) => ({ ...prev, [action]: currentPage }));
    }
  }, [currentPage, action, setPagesCurrent, pagesCurrent]);

  const pages = useMemo(
    () => Math.ceil((list ? list.length : 0) / itensPerPage),
    [list, itensPerPage],
  );
  const currentItens = useMemo(() => {
    const startIndex = currentPage * itensPerPage;
    return Array.isArray(list)
      ? list.slice(startIndex, startIndex + itensPerPage)
      : [];
  }, [list, currentPage, itensPerPage]);

  return {
    currentPage,
    pages,
    currentItens,
    itensPerPage,
    setItensPerPage,
    setCurrentPage,
    pagesCurrent,
  };
}
