export const statusData = [
  {
    value: 1,
    orderStatusCode: '01',
    label: 'Criado',
    OrderStatusDescription: null,
  },
  {
    value: 3,
    orderStatusCode: '03',
    label: 'Aguardando Aprovação',
    OrderStatusDescription: null,
  },
  {
    value: 4,
    orderStatusCode: '04',
    label: 'Processando',
    OrderStatusDescription: null,
  },
  {
    value: 6,
    orderStatusCode: '06',
    label: 'Finalizado',
    OrderStatusDescription: null,
  },
  {
    value: 7,
    orderStatusCode: '07',
    label: 'Aguardando Saldo',
    OrderStatusDescription: null,
  },
];
