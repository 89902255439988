import { useState } from 'react';
import { CardCopy } from '../CardCopy';
import styles from '../../styles.module.scss';
import { formatMoney } from '../../../../../../utils/formatMoney';
import Status from '../../../../../../components/UI/Status';
import { IconEyes } from '../../../../../../components/Details/Icons';
import { useUser } from '../../../../../../hooks/useUser';

export default function CardList({
  payment,
  handlePushRouter,
  listSelect,
  toggleSelect,
}) {
  const { user } = useUser();
  const [isCopied, setIsCopied] = useState(false);
  const permissionTags =
    ![13, 14].every((item) => user?.permission.includes(item)) ||
    ![1, 3].includes(payment?.status?.id) ||
    !payment?.total_amount > 0;

  const handleCopyClick = (text) => {
    setIsCopied(text);

    setInterval(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <tr key={payment?.key}>
      <td className={styles.input_select_blue}>
        <input
          type="checkbox"
          checked={listSelect && listSelect.includes(payment?.key)}
          onChange={() => toggleSelect(payment?.key)}
          disabled={permissionTags}
        />
      </td>
      <td>
        <CardCopy
          isCopied={isCopied}
          handleCopyClick={handleCopyClick}
          text={payment?.key}
          id={payment?.key}
        />
      </td>
      <td>{payment?.description ?? '---'}</td>
      <td>{payment?.file_name}</td>
      <td>{payment?.created_at}</td>
      <td>
        {[4, 6].includes(payment?.status?.id)
          ? formatMoney(payment?.total_amount)
          : formatMoney(Number(payment?.total_amount) + Number(payment?.fee))}
      </td>
      <td>
        {[4, 6].includes(payment?.status?.id) ? (
          <p>{`${payment?.total_moviments} / ${payment?.approved}`}</p>
        ) : (
          <p>{payment?.total_moviments} / 0</p>
        )}
      </td>
      <td className={styles.percentagem}>
        {[4, 6].includes(payment?.status?.id)
          ? `${((payment?.approved / payment?.total_moviments) * 100)
              .toFixed(0)
              .toString()}%`
          : '0%'}
        {[6, 4].includes(payment?.status?.id) && (
          <div className={styles.card}>
            <div className={styles['summary-item']}>
              <div className={styles.amount_}>
                <div className={styles.circle_green}></div>
                <span>{formatMoney(payment?.approvedAmount)}</span>
              </div>

              <span>{payment?.approved}</span>
            </div>
            {payment?.erros > 0 && (
              <div className={styles['summary-item']}>
                <div className={styles.amount_}>
                  <div className={styles.circle_red}></div>
                  <span>{formatMoney(payment?.errosAmount)}</span>
                </div>

                <span>{payment?.erros}</span>
              </div>
            )}
            {payment?.status.id === 4 && (
              <div className={styles['summary-item']}>
                <div className={styles.amount_}>
                  <div className={styles.circle_blue}></div>
                  <span>{formatMoney(payment?.processAmount)}</span>
                </div>
                <span>{payment?.process}</span>
              </div>
            )}
            {payment?.awaitingBalance > 0 && (
              <div className={styles['summary-item']}>
                <div className={styles.amount_}>
                  <div className={styles.circle_orange}></div>
                  <span>{formatMoney(payment?.awaitingBalanceAmount)}</span>
                </div>
                <span>{payment?.awaitingBalance}</span>
              </div>
            )}
          </div>
        )}
      </td>
      <td className={styles.status}>
        <Status status={payment?.status} />
      </td>
      <td
        className={styles.eyes}
        onClick={() => handlePushRouter(payment?.key, payment?.status?.key)}
      >
        <IconEyes size="1.3rem" color="var(--gray-500)" />
      </td>
    </tr>
  );
}
