import React from 'react';
import FormGroup from '../FormGroup';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOrder } from '../../../../hooks/useOrder';
import { useBalance } from '../../../../hooks/useBalance';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { validateCNPJ, validateCPF } from 'validations-br';
import { parseCurrencyToNumber } from '../../../../utils/parseCurrencyToNumber';
function FormTransferBalance() {
  const { fetchTransferOrderAddBalanceConsignable, setLoading } = useOrder();
  const { loading, balance } = useBalance();
  const [value, setValue] = React.useState(false);
  const maxValue = balance;
  const Validate = yup.object().shape({
    document: yup
      .string()
      .typeError('O campo deve receber um documento Valido.')
      .test('is-document', 'Documento não é valido', function (value) {
        return validateCPF(value) || validateCNPJ(value);
      })
      .required('Chave é obrigatória'),
    amount: yup
      .string()
      .typeError('O campo deve receber um valor.')
      .required('Valor é obrigatório')
      .max(maxValue, 'O valor é maior que seu saldo atual')
      .test(
        'is-amount',
        'O campo deve receber um valor em real valido e maior que R$ 0,01.',
        (value) => {
          const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

          const testRegex = regex.test(value);

          const numericValue = parseFloat(
            value.replace(/[.,]/g, '').replace(',', '.'),
          );
          if (!isNaN(numericValue) && numericValue >= 0.01 && testRegex) {
            return true;
          } else {
            return false;
          }
        },
      ),
  });
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Validate),
  });
  function handleValue(data) {
    setValue(data);
  }
  async function fetchSubmit(data) {
    const { amount, document } = data;
    setLoading({ balance: true });

    try {
      const addedBalance = {
        description: 'Aporte de Antecipação',
        movements: [
          {
            additional_data: 'Aporte de Antecipação',
            amount: parseCurrencyToNumber(amount),
            date: new Date(),
            bank_code: 'CONSIGN',
            document: document.replace(/[^\d]/g, ''),
          },
        ],
      };

      const res = await fetchTransferOrderAddBalanceConsignable(addedBalance);

      if (res) {
        reset();
        setValue(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(!value ? handleValue : fetchSubmit)}
    >
      <h1>Informe o valor para deposito</h1>
      <p>
        Esse valor será retirado do saldo da sua conta e transferido para o
        saldo de antecipação. <br /> Que sera identificado no seu extrato como
        serviço: APORTE.
      </p>
      {value && (
        <div className={styles.preview}>
          <h1>Valor: R$ {value?.amount}</h1>
          <h1>Documento: {value.document}</h1>
        </div>
      )}
      {!value && (
        <>
          <FormGroup
            name="amount"
            label={
              <>
                <strong>Valor</strong>
              </>
            }
            mask={[
              '9,99',
              '99,99',
              '999,99',
              '9.999,99',
              '99.999,99',
              '999.999,99',
              '9.999.999,99',
            ]}
            type="text"
            placeholder="R$ 0,00"
            register={register}
            errors={errors}
            setValue={setFormValue}
          />
          <FormGroup
            name="document"
            label={
              <>
                <strong>Documento </strong> (CPF / CNPJ):
              </>
            }
            type="text"
            placeholder={'999.999.999-99'}
            disabled={!loading?.paymentOrder ? false : true}
            mask={['999.999.999-99', '99.999.999/9999-99']}
            register={register}
            errors={errors}
            setValue={setFormValue}
          />
        </>
      )}
      {!loading && value && (
        <div className={styles.wrapper_buttons_}>
          <button className={styles.button} type="submit" disabled={loading}>
            <FaCheck size={'0.8rem'} color={'var(--white)'} />
            {'Confirmar'}
          </button>
          <button
            className={styles.button}
            type="button"
            disabled={loading}
            onClick={() => setValue(false)}
          >
            <IoMdClose size={'1.2rem'} color={'var(--white)'} />
            {'Cancelar'}
          </button>
        </div>
      )}
      {!value && (
        <button className={styles.button} type="submit" disabled={loading}>
          <FaPlus size={'1rem'} color={'var(--white)'} />
          {'Adicionar'}
        </button>
      )}
    </form>
  );
}

export { FormTransferBalance };
