import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import styles from './styles.module.scss';
import {
  IconAlert,
  IconAlertDuplicate,
  IconCheck,
  IconError,
  IconStart,
} from '../Icons';
import { formatMoney } from '../../../utils/formatMoney.js';
import { containsWord } from '../utils/containWord';
function IconStatus(id) {
  switch (id) {
    case 2:
      return <IconCheck size={'1.1rem'} />;
    case 3:
      return <IconError size={'1.1rem'} />;
    case 4:
      return <IconStart size={'1.1rem'} />;
    case 6:
      return <IconAlert size={'1.1rem'} />;
    case 7:
      return <IconAlertDuplicate size={'1.1rem'} />;
    default:
      return <> </>;
  }
}
const hasKeyInUsers = (users, key, str) => {
  for (const user of users) {
    if (containsWord(str, Object.keys(user).join(','), key)) {
      return true;
    }
  }
  return false;
};
const UserTable = (props) => {
  const keyToCheckDaily = 'VALOR_DA_DIARIA';
  const keyToCheckTags = 'TAG';
  const keyToCheckAgent = 'NOME_DO_AGENCIADOR';
  const keyToCheckServices = 'VALOR_DO_SERVICO';
  const keyToCheckComodato = 'COMODATO';
  const isExistAgent = hasKeyInUsers(
    props.users,
    keyToCheckAgent,
    'agenciador',
  );
  const isExistDaily = hasKeyInUsers(props.users, keyToCheckDaily, 'diaria');
  const isExistServices = hasKeyInUsers(
    props.users,
    keyToCheckServices,
    'valor_do_servico',
  );

  const isExitComodato = hasKeyInUsers(
    props.users,
    keyToCheckComodato,
    'comodato',
  );

  const isExitTags = hasKeyInUsers(props.users, keyToCheckTags, 'tag');

  return (
    <table className={styles['table-container']}>
      <thead>
        <tr>
          <th style={{ cursor: 'pointer', padding: 'none' }}>
            <input
              type="checkbox"
              checked={props.listDeleteUser.length === props.users.length}
              onChange={() => props.selectAllUsers()}
            />
          </th>
          <th>Nome ({props.users?.length})</th>
          <th>E-mail</th>
          <th>CPF</th>
          <th>CNPJ</th>
          <th>Telefone</th>
          <th>Contrato</th>
          {isExitTags && (
            <>
              <th>Tag</th>
            </>
          )}
          {isExistAgent && (
            <>
              <th>Agenciador</th>
              <th>AG - Documento</th>
            </>
          )}
          {isExistDaily && (
            <>
              <th>Diária</th>
              <th>Diária Ajudante</th>
            </>
          )}
          {isExistServices && (
            <>
              <th>Valor</th>
            </>
          )}
          {isExitComodato && (
            <>
              <th>Certificado N°</th>
            </>
          )}

          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {props.users?.length > 0 ? (
          props.users
            .sort((a, b) => {
              // Se ambos têm type.id igual a 6, ordene alfabeticamente pelo nome
              if (a.type.id === 6 && b.type.id === 6) {
                const nomeA = a.name || ''; // Garante que o nome não seja undefined
                const nomeB = b.name || ''; // Garante que o nome não seja undefined
                return nomeA.localeCompare(nomeB);
              }
              // Se apenas 'a' tem type.id igual a 6, coloque 'a' no início
              else if (a.type.id === 6) {
                return -1;
              }
              // Se apenas 'b' tem type.id igual a 6, coloque 'b' no início
              else if (b.type.id === 6) {
                return 1;
              }
              // Para outros casos, não altere a ordem
              else {
                return 0;
              }
            })
            .map((user, index) => (
              <tr key={user?.id}>
                <td className={styles['check_table']}>
                  <input
                    type="checkbox"
                    checked={props?.listDeleteUser?.includes(user?.id)}
                    onChange={() => props?.toggleSelectUsers(user?.id)}
                  />
                </td>
                <td
                  className={
                    user?.erros?.includes('name') ? styles.color_error : ''
                  }
                >
                  {user?.name ?? '---'}
                </td>
                <td
                  className={
                    user?.erros?.includes('email') ? styles.color_error : ''
                  }
                >
                  {user?.email ?? ' ---'}
                </td>
                <td
                  className={
                    user?.erros?.includes('cpf') ||
                    user?.erros?.includes('dup-cpf')
                      ? styles.color_error
                      : ''
                  }
                >
                  {user?.cpf ?? '---'}
                </td>
                <td
                  className={
                    user?.erros?.includes('cnpj') ||
                    user?.erros?.includes('dup-cnpj')
                      ? styles.color_error
                      : ''
                  }
                >
                  {user?.cnpj ?? '---'}
                </td>
                <td
                  className={
                    user?.erros?.includes('phone_number')
                      ? styles.color_error
                      : ''
                  }
                >
                  {user?.phone_number ?? '---'}
                </td>
                <td
                  className={
                    user?.erros?.includes('contract_type')
                      ? `${styles.color_error} ${styles.contract}`
                      : styles.contract
                  }
                >
                  {user?.contract_type ?? '---'}
                </td>
                {isExitTags && (
                  <>
                    <td className={styles.tags}>
                      {' '}
                      <span>
                        {user?.tags?.length > 0 ? (
                          <>
                            <strong>{user?.tags[0]}</strong>
                            {user?.tags?.length > 1 && (
                              <p>{`+${user?.tags?.length - 1}`}</p>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        <div className={styles.card_tag}>
                          {user?.tags?.length > 1 &&
                            user?.tags
                              ?.slice(1)
                              .map((item, index) => (
                                <strong key={index}>{item}</strong>
                              ))}
                        </div>
                      </span>
                    </td>
                  </>
                )}
                {isExistAgent && (
                  <>
                    <td
                      className={
                        user?.erros?.includes('name-ag')
                          ? styles.color_error
                          : ''
                      }
                    >
                      {user?.NOME_DO_AGENCIADOR}
                    </td>

                    <td
                      className={
                        user?.erros?.includes([
                          'AGENCIADOR-PF',
                          'AGENCIADOR-PJ',
                        ])
                          ? styles.color_error
                          : ''
                      }
                    >
                      {user?.DOCUMENTO_DO_AGENCIADOR}
                    </td>
                  </>
                )}
                {isExistDaily && (
                  <>
                    <td>
                      {user?.VALOR_DA_DIARIA >= 0
                        ? formatMoney(user?.VALOR_DA_DIARIA)
                        : ''}
                    </td>

                    <td>
                      {user?.VALOR_DA_DIARIA_AJUDANTE >= 0
                        ? formatMoney(user?.VALOR_DA_DIARIA_AJUDANTE)
                        : ''}
                    </td>
                  </>
                )}
                {isExitComodato && (
                  <>
                    <td>{user?.COMODATO}</td>
                  </>
                )}
                {isExistServices && (
                  <>
                    <td>
                      {user?.VALOR_DO_SERVICO
                        ? formatMoney(user?.VALOR_DO_SERVICO)
                        : ''}
                    </td>
                  </>
                )}
                <td className={styles.erros}>
                  {(user?.alert?.length > 0 ||
                    user?.erros?.length > 0 ||
                    user.response) && (
                    <div className={styles.card}>
                      {user?.response && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Erro</span>
                          </div>
                          <span>{user?.response?.message}</span>
                        </div>
                      )}
                      {user?.alert?.includes('dup-active-cpf') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Existente</span>
                          </div>
                          <span>Cadastro PF ativo com esse documento</span>
                        </div>
                      )}
                      {user?.erros?.includes('dup-active-cnpj') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Existente</span>
                          </div>
                          <span>Cadastro PJ ativo com esse documento</span>
                        </div>
                      )}
                      {user?.erros?.includes('name') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Nome</span>
                          </div>
                          <span>Deve possuir Sobrenome</span>
                        </div>
                      )}
                      {user?.erros?.includes('name-ag') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Nome AGENCIADOR</span>
                          </div>
                          <span>Deve possuir Sobrenome</span>
                        </div>
                      )}
                      {user?.erros?.includes('cpf') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CPF</span>
                          </div>
                          <span>Invalido </span>
                        </div>
                      )}
                      {user?.erros?.includes('dup-cpf') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CPF</span>
                          </div>
                          <span>Duplicado </span>
                        </div>
                      )}
                      {user?.erros?.includes('cnpj') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CNPJ</span>
                          </div>
                          <span>Invalido </span>
                        </div>
                      )}
                      {user?.erros?.includes('dup-cnpj') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CNPJ</span>
                          </div>
                          <span>Duplicado </span>
                        </div>
                      )}
                      {user?.erros?.includes('email') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>e-mail</span>
                          </div>
                          <span>Invalido </span>
                        </div>
                      )}
                      {user?.erros?.includes('duplicate') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>Duplicado</span>
                          </div>
                          <span> Cadastros duplicados </span>
                        </div>
                      )}
                      {user?.erros?.includes('AGENCIADOR-PF') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CPF AGENCIADOR</span>
                          </div>
                          <span> Invalido </span>
                        </div>
                      )}
                      {user?.erros?.includes('AGENCIADOR-PJ') && (
                        <div className={styles['summary-item']}>
                          <div className={styles.amount_}>
                            <IconError size={'1rem'} />
                            <span>CNPJ AGENCIADOR</span>
                          </div>
                          <span> Invalido </span>
                        </div>
                      )}
                    </div>
                  )}
                  {IconStatus(user?.alert?.length > 0 ? 7 : user?.type?.id)}
                </td>
                <td className={styles['actions-container']}>
                  <button
                    onClick={() => {
                      props.editRow(user);
                    }}
                    disabled={user?.type?.id === 2 || props.editing}
                    className={styles.edit_button}
                  >
                    <BiEdit color="var(--gray-300)" size={'1.1875rem'} />
                  </button>
                  <button
                    disabled={props.editing}
                    onClick={() => props.deleteUser(user?.id)}
                    className={styles.delete_button}
                  >
                    <MdDeleteForever
                      color="var(--gray-300)"
                      size={'1.1875rem'}
                    />
                  </button>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={9} className={styles['no-users']}>
              Sem colaboradores
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default UserTable;
