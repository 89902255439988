import axios from 'axios';
import { getCookieCompanyId, getToken } from '../Helpers/cookies';
import { version } from '../config';
const URL = process.env.REACT_APP_API;

export const getApiUrl = (path) => {
  return `${URL}${path}`;
};

export const getHeaders = (type = `application/json`) => {
  const token = getToken();
  const companyId = getCookieCompanyId();

  if (!!companyId) {
    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: companyId,
      web: version,
    };
  }
  if (!companyId && !token) {
    return {
      'Content-Type': type,
      web: version,
    };
  }
  if (!token) {
    return {
      'Content-Type': type,
      web: version,
    };
  }
};

export const getHeadersSalary = (type = `application/json`) => {
  const token = getToken();
  const companyId = getCookieCompanyId();

  if (!!companyId) {
    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: companyId,
      isSalary: true,
      web: version,
    };
  } else {
    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      web: version,
      isSalary: true,
    };
  }
};
export const getHeadersBalance = (id) => {
  const token = getToken();
  if (!token) return {};

  return {
    'Content-Type': `application/json`,
    Authorization: `Bearer ${token}`,
    companyId: id,
    web: version,
  };
};

export const apiGetBalance = (path, id) => {
  const url = getApiUrl(path);
  const options = {
    headers: getHeadersBalance(id),
  };

  return axios.get(url, options);
};

export const apiGet = (path) => {
  const url = getApiUrl(path);
  const options = {
    headers: getHeaders(),
  };

  return axios.get(url, options);
};
export const apiGetCNPJ = (url) => {
  const options = {
    headers: {
      'Content-Type': `application/json`,
    },
  };

  return axios.get(url, options);
};

export const apiPost = (path, data) => {
  const url = getApiUrl(path);

  const options = { headers: getHeaders() };

  return axios.post(url, data, options);
};
export const apiPut = (path, data) => {
  const url = getApiUrl(path);

  const options = { headers: getHeaders() };

  return axios.put(url, data, options);
};
export const apiDelete = (path) => {
  const url = getApiUrl(path);

  const options = { headers: getHeaders() };
  return axios.delete(url, options);
};
export const apiOptions = (path) => {
  const url = getApiUrl(path);

  const options = { headers: getHeaders() };

  return axios.options(url, options);
};
export const apiMutationCompany = (path, id) => {
  const getHeadersMutations = (type = `application/json`) => {
    const token = getToken();
    if (!token) return {};

    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: id,
      web: version,
    };
  };

  const data = { company: id };

  const url = getApiUrl(path);

  const options = { headers: getHeadersMutations() };
  return axios.put(url, data, options);
};

export const apiPutEditUser = (path, data, id) => {
  const getHeadersEditUser = (type = `application/json`) => {
    const token = getToken();
    if (!token) return {};

    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: id,
      web: version,
    };
  };
  const url = getApiUrl(path);

  const options = { headers: getHeadersEditUser() };
  return axios.put(url, data, options);
};
export const apiGetCustomersCompany = (path, id) => {
  const getHeadersCustomersCompany = (type = `application/json`) => {
    const token = getToken();
    if (!token) return {};

    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: id,
      web: version,
    };
  };

  const url = getApiUrl(path);

  const options = { headers: getHeadersCustomersCompany() };

  return axios.get(url, options);
};

export const apiFormData = (path, data, type) => {
  const url = getApiUrl(path);

  const options = type
    ? { headers: getHeadersSalary(`multipart/form-data`) }
    : { headers: getHeaders(`multipart/form-data`) };

  return axios.post(url, data, options);
};

export const apiFormDataPut = (path, data) => {
  const url = getApiUrl(path);

  const options = { headers: getHeaders(`multipart/form-data`) };

  return axios.put(url, data, options);
};
export const apiFormDataPutCustomers = (path, data, id) => {
  const getHeadersPutCustomersCompany = (type = `multipart/form-data`) => {
    const token = getToken();
    if (!token) return {};

    return {
      'Content-Type': type,
      Authorization: `Bearer ${token}`,
      companyId: id,
      web: version,
    };
  };

  const url = getApiUrl(path);

  const options = { headers: getHeadersPutCustomersCompany() };

  return axios.put(url, data, options);
};

export const fetchInfoCompany = async (path, ids) => {
  const url = getApiUrl(path);
  const token = getToken();

  return ids.map(async (item) => {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        companyId: item,
        web: version,
      },
    };
    return await axios.get(url, options);
  });
};

export const apiGetCompanies = (path, ids) => {
  const getHeadersCustomersCompany = (type = `application/json`) => {
    const token = getToken();
    if (!token) return {};
    const companiesHeader = ids.reduce((acc, id, index) => {
      return `${acc} "companyId${index + 1}" : ${id},`;
    }, ',');

    const headerCpnpaniesJSON = `{"Content-Type": "${type}",
    "Authorization": "Bearer ${token}"
       ${companiesHeader.slice(0, -1)} }`;

    const head = JSON.parse(headerCpnpaniesJSON);

    return head;
  };

  const url = getApiUrl(path);

  const options = { headers: getHeadersCustomersCompany() };

  return axios.get(url, options);
};
