import { Tooltip, Whisper } from 'rsuite';

export const WrapperToolTip = ({ children }) => {
  return (
    <Whisper
      trigger="hover"
      placement={'right'}
      controlId={`control-id-click`}
      speaker={<Tooltip>Copiar chave de Lote</Tooltip>}
    >
      {children}
    </Whisper>
  );
};
