import { useHistory } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { IoEyeSharp } from 'react-icons/io5';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { formatAccount } from '../../../../../../utils/formatAccount';
import { Details } from '../../../../../../components/Details';
import { useUser } from '../../../../../../hooks/useUser';
import { useAnticipation } from '../../../../../../hooks/useAnticipation';
import styles from './styles.module.scss';
import {
  IconCalendar,
  IconCollaborator,
  IconDocumentPerson,
  IconKey,
  IconOutCurrency,
  IconStatus,
  IconDigit,
  IconAccount,
  IconEmail,
  IconArrow,
} from '../../../../../../components/Details/Icons';
import { useView } from '../../../../../../hooks/useView';
import { usePayment } from '../../../../../../hooks/usePayment';

const DetailsFlyout = ({
  visible,
  setVisible,
  name,
  document,
  bank_slip,
  expiration_date,
  email,
  additional_data,
  bank_code,
  account,
  account_digit,
  account_type,
  branch,
  date,
  amount,
  error_description,
  status,
  method,
  id,
  pix_key,
  pix_qrcode,
  type,
  isFetching,
  bank_autentication_code,
  pix_end_to_end_id,
  isDate,
  index,
  orderKey,
  movementKey,
  gateway,
  setFilter,
  collected_consignation_amount,
  min_amount,
  max_amount,
}) => {
  const { user } = useUser();
  const history = useHistory();
  const { fetchOrderCancelKeyMovement, loading } = useAnticipation();
  const { banks } = usePayment();
  const { view, ref } = useView({
    rootMargin: '100px 0px 0px 0px',
    root: null,
    threshold: 0.5,
  });

  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${id}`,
    });
  };

  const detailsBank =
    bank_code === '31680151'
      ? 'ISPB: 31680151 - Swap Ip S.A.'
      : banks
        ? banks.find(
            (item) => item.value === bank_code || item.ispb === bank_code,
          )?.label ?? `Código: ${bank_code}`
        : bank_code
          ? `Código: ${bank_code}`
          : false;
  const handleCancel = async () => {
    await fetchOrderCancelKeyMovement(id);

    setFilter(0);
  };
  return visible ? (
    <div className={styles.flexCard}>
      <div className={styles.header_flexCard}>
        <h2>Detalhes</h2>

        <button
          className={styles.close}
          onClick={() => {
            setVisible(false);
          }}
        >
          fechar
          <MdClose size={'1.5rem'} />
        </button>
      </div>

      <div className={styles.details}>
        {!view && (
          <div className={styles.ico}>
            <IconArrow />
          </div>
        )}
        {id && (
          <Details Icon={IconKey} title={'Movimento:'} value={id} copy={true} />
        )}
        {pix_end_to_end_id && (
          <Details
            Icon={IconKey}
            title={'ID/Transação:'}
            value={pix_end_to_end_id}
            copy={true}
          />
        )}
        {name && (
          <Details Icon={IconCollaborator} title={'Favorecido'} value={name} />
        )}
        {document && document !== '---' && (
          <Details
            Icon={IconDocumentPerson}
            title={'CPF / CNPJ'}
            value={document}
            copy={true}
          />
        )}
        {amount > 0 && (
          <Details
            Icon={IconOutCurrency}
            title={'Valor'}
            value={formatMoney(amount)}
          />
        )}
        {min_amount > 0 && (
          <Details
            Icon={IconOutCurrency}
            title={'Valor Minimo'}
            value={formatMoney(min_amount)}
          />
        )}
        {max_amount > 0 && (
          <Details
            Icon={IconOutCurrency}
            title={'Valor Máximo'}
            value={formatMoney(max_amount)}
          />
        )}
        {method === 'OX' && (
          <Details
            Icon={IconOutCurrency}
            title={'Desconto Antecipação'}
            value={formatMoney(collected_consignation_amount)}
          />
        )}

        {email && (
          <Details Icon={IconEmail} title={'Email'} value={email} copy={true} />
        )}
        {detailsBank && bank_code && (
          <Details Icon={IconStatus} title={'Banco'} value={detailsBank} />
        )}
        {method && (
          <Details Icon={IconStatus} title={'Método'} value={method} />
        )}
        {status && (
          <Details Icon={IconStatus} title={'Status'} value={status.text} />
        )}

        {date && (
          <Details Icon={IconCalendar} title={'Lançamento'} value={date} />
        )}
        {expiration_date && (
          <Details
            Icon={IconCalendar}
            title={'Vencimento'}
            value={formatDate(expiration_date, 'dd/MM/yyyy')}
          />
        )}

        {pix_key && (
          <Details
            Icon={IconKey}
            title={'Chave Pix'}
            value={pix_key}
            copy={true}
          />
        )}
        {bank_slip && (
          <Details
            Icon={IconKey}
            title={'Linha digitável'}
            value={bank_slip}
            copy={true}
          />
        )}

        {account && (
          <Details Icon={IconAccount} title={'Conta'} value={account} />
        )}
        {account_digit && (
          <Details
            Icon={IconDigit}
            title={'Digito Conta:'}
            value={account_digit}
          />
        )}
        {account_type && (
          <Details
            Icon={IconDigit}
            title={'Tipo'}
            value={formatAccount(account_type)}
          />
        )}

        {branch && (
          <Details Icon={IconDigit} title={'Agencia'} value={branch} />
        )}

        {pix_qrcode && (
          <span className={styles.movement_key}>
            <p>QR CODE:</p>
            {pix_qrcode}
          </span>
        )}

        {pix_qrcode && (
          <Details
            Icon={IconKey}
            title={'QR CODE'}
            value={pix_qrcode}
            copy={true}
          />
        )}
        {additional_data && (
          <Details
            Icon={IconDocumentPerson}
            title={'Descrição:'}
            value={additional_data}
            description={true}
          />
        )}
        {error_description && (
          <Details
            Icon={IconDocumentPerson}
            title={status.id === 7 ? 'Erro:' : 'Atenção:'}
            value={error_description}
            description={true}
          />
        )}
        {status.id === 6 && type !== 5 && (
          <div className={styles.button} onClick={handleProof}>
            <IoEyeSharp size={24} />
            <p>Comprovante</p>
          </div>
        )}
        <div ref={ref} className={styles.bottom}></div>

        {[32].every((item) => user?.permission.includes(item)) && (
          <>
            {[1, 2, 3, 8, 9].includes(status?.id) && (
              <button
                onClick={handleCancel}
                className={styles.cancel}
                disabled={loading?.movement || isFetching}
              >
                {loading?.movement || isFetching
                  ? 'Carregando ...'
                  : 'Cancelar'}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default DetailsFlyout;
