import { useContext } from 'react';

import { FormContext } from '../data/formContext';

export function useFormData() {
  const {
    form,
    setForm,
    type,
    setType,
    setOnboarding,
    isOnboarding,
    setTypePayment,
    formPayment,
    setFormPayment,
    payment,
    setPayment,
    typePayment,
  } = useContext(FormContext);

  const setClearedForm = () => {
    setForm(false);
    setOnboarding(false);
    setType(false);
  };

  const removerPropriedadesForm = (props) => {
    const newForm = { ...form };
    props.forEach((item) => {
      delete newForm[item];
    });
    setForm(newForm);
  };
  const setFormValues = (values) => {
    setForm({
      ...form,
      ...values,
    });
  };
  const setClearedFormPayment = () => {
    setFormPayment(false);
    setPayment(false);
    setTypePayment(false);
  };

  const setFormValuesPayment = (values) => {
    setFormPayment({
      ...formPayment,
      ...values,
    });
  };
  return {
    form,
    type,
    setType,
    setOnboarding,
    isOnboarding,
    payment,
    typePayment,
    setTypePayment,
    setClearedForm,
    setClearedFormPayment,
    setFormValuesPayment,
    setFormValues,
    removerPropriedadesForm,
  };
}
