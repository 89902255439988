import styled from 'styled-components';

export const Container = styled.iframe`
  width: 100%;
  height: 100vh;
  margin-bottom: auto;
  margin-top: 0;
  padding: 0;
  display: block;
  }
`;
