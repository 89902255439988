import { useEffect, useState } from 'react';
import { doc, query, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebase';
import { version } from '../config';
import { useNotification } from './useNotifications';

export default function useVerifyVersion() {
  const [notify, setNotify] = useState({
    prod: Number(version.replace(/\D/g, '')),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    if (notify?.prod > Number(version.replace(/\D/g, ''))) {
      addNotification(
        <>
          Atualize sua versão para a mais recente para continuar utilizando o
          sistema de maneira eficiente. Clique em reload ou aperte
          <strong> F5</strong> para atualizar.
        </>,
        1,
        'Notificação de atualização',
        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify]);

  useEffect(() => {
    let isMounted = true; // Verifica se o componente está montado

    const notificacoesRef = doc(db, 'web_version', 'version');
    const q = query(notificacoesRef);

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        if (isMounted) {
          setNotify(querySnapshot.data());
          setLoading(false);
        }
      },
      (error) => {
        if (isMounted) {
          setError(error);
          setLoading(false);
        }
      },
    );

    return () => {
      isMounted = false; // Define como desmontado ao sair
      unsubscribe(); // Cancela o snapshot listener
    };
  }, []);

  return { loading, data: notify, error };
}
