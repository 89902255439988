import { useState } from 'react';
import { createContext } from 'react';

export const CustomersContext = createContext();

const { Provider } = CustomersContext;

export default function CustomersProvider({ children }) {
  const [usersCollaborator, setUsersCollaborator] = useState([]);
  const [error, setError] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [allCapabilities, setAllCapabilities] = useState(false);
  const [allProfiles, setAllProfiles] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState({ customers: false, add: false });
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isNewCustomerUpload, setIsNewCustomerUpload] = useState(false);
  const [edit, setEdit] = useState(true);
  if (error || message) {
    setTimeout(() => {
      setError(false);
      setMessage(false);
    }, 4000);
  }

  return (
    <Provider
      value={{
        loading,
        setLoading,
        //customers
        customers,
        setCustomers,
        allCapabilities,
        setAllCapabilities,
        allProfiles,
        setAllProfiles,
        //errors
        error,
        setError,
        //message
        message,
        setMessage,
        //is New Customer global
        setIsNewCustomer,
        isNewCustomer,

        //edit form
        edit,
        setEdit,
        // is new costumer upload global
        isNewCustomerUpload,
        setIsNewCustomerUpload,
        //collaborators
        usersCollaborator,
        setUsersCollaborator,
      }}
    >
      {children}
    </Provider>
  );
}
