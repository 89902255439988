import * as yup from 'yup';

export const ValidateFormPasswordCreated = yup.object().shape({
  password: yup
    .string()
    .typeError('O campo deve receber a Senha.')
    .required('O campo Senha é obrigatório.')
    .max(6, 'A nova senha deve ter no máximo 6 caracteres.'),
  password_confirm: yup
    .string()
    .equals([yup.ref('password')], 'As Senhas devem ser iguais.')
    .typeError('O campo deve receber a confirmação da senha nova.')
    .required('Confirmação de Senha é obrigatório')
    .max(6, 'A nova senha deve ter no máximo 6 caracteres.'),
});
