import { useFormData } from '../../../hooks/useFormData';
import styles from '../styles/Form.module.scss';

export default function FormCard({ children, currentStep }) {
  const { isOnboarding } = useFormData();

  const stepOne = isOnboarding
    ? {
        step: 1,
        title: 'Este é o seu colaborador?',

        description:
          ' IMPORTANTE: Confira as informações antes de confirmar. Caso o nome esteja incorreto, volte e verifique se digitou o número do documento corretamente.',
      }
    : {
        step: 1,
        title: 'Insira as informações do colaborador',

        description:
          'O cadastro será criado, mas a ativação da conta será concluída quando o colaborador enviar os demais documentos e informações solicitadas pelo App.',
      };

  const header = [
    {
      step: 0,
      title: 'Início',
      description:
        'Insira o número de CPF ou CNPJ do seu colaborador para começar. ',
    },
    stepOne,
    {
      step: 2,
      title: 'Escolha o tipo de Contrato',
      description: (
        <>Informe se o colaborador deve assinar o contrato digital.</>
      ),
    },
    {
      step: 3,
      title: ' Confirme as informações ',
      description: (
        <>
          IMPORTANTE: Confirme os dados antes de avançar. Caso o algo esteja
          incorreto, volte e corrija as informações nas etapas anteriores.
        </>
      ),
    },
    {
      step: 4,
      title: <></>,
      description: (
        <>
          <br />
        </>
      ),
    },
  ];

  return (
    <div className={styles.formCard}>
      {header
        .filter((item) => item.step === currentStep)
        .map((item) => (
          <div className={styles.header}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
          </div>
        ))}
      {children}
    </div>
  );
}
