import { useState, useEffect, useRef } from 'react';

/**
 * Cria uma instância de IntersectionObserver.
 *
 * @param {Function} callback - Função a ser chamada quando a visibilidade do elemento mudar.
 * @param {Object} options - Opções para o IntersectionObserver.
 * @returns {IntersectionObserver} - A instância do IntersectionObserver.
 */
function createObserver(callback, options) {
  return new IntersectionObserver(callback, {
    root: options.root || null,
    rootMargin: options.rootMargin || '0px',
    threshold: options.threshold || 0,
  });
}

/**
 * Hook customizado para verificar se um elemento está visível na viewport.
 *
 * @param {Object} options - Opções para o IntersectionObserver.
 * @returns {Object} - Retorna um objeto contendo a visibilidade do elemento e a referência do elemento.
 */
export function useView(options = {}) {
  const [view, setView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = createObserver(
      ([entry]) => setView(entry.isIntersecting),
      options,
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return { view, ref };
}
