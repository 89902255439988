import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Loader } from 'rsuite';
import { useTags } from '../../../../../../hooks/useTags';
import { IconTags } from '../../../../../Collaborators/Components/TagInput/icon';
import { IconPlus } from './icon';
import styles from './styles.module.scss';

const TagInput = ({ tags, setTags, handleUpdateTags, isLoading }) => {
  const { tagsRef: availableTags, loading } = useTags();

  const [inputValue, setInputValue] = useState('');
  const [showNewTagInput, setShowNewTagInput] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (inputValue || value) {
      const filtered = availableTags.filter(
        (tag) =>
          tag.toLowerCase().includes(inputValue.toLowerCase()) &&
          !tags.includes(tag.toLowerCase()),
      );
      setIsDropdownVisible(true);
      setFilteredTags(filtered);
      setShowNewTagInput(filtered.length === 0 && inputValue.trim() !== '');
    } else {
      setFilteredTags(availableTags.filter((tag) => !tags.includes(tag)));
      setShowNewTagInput(false);
    }
  };

  const addTag = (tag) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
      setFilteredTags(availableTags.filter((t) => !tags.includes(t)));
      setIsDropdownVisible(false);
    }
  };

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
    setFilteredTags(availableTags.filter((t) => !tags.includes(t)));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (filteredTags.length === 0 && inputValue.trim() !== '') {
        setShowNewTagInput(true);
        addTag(inputValue);
      } else if (filteredTags.length > 0) {
        addTag(filteredTags[0]);
      }
    }
  };

  const handleNewTagSubmit = () => {
    if (!availableTags.includes(inputValue) && inputValue.trim() !== '') {
      availableTags.push(inputValue);
      addTag(inputValue);
      setInputValue('');
      setShowNewTagInput(false);
    }
  };

  const handleInputFocus = () => {
    setIsDropdownVisible(true);
    setFilteredTags(availableTags.filter((tag) => !tags.includes(tag)));
  };

  const handleInputBlur = () => {
    // Delay hiding the dropdown to allow click events to register
    setTimeout(() => {
      setIsDropdownVisible(false);
    }, 200);
  };

  return (
    <div className={styles['tag-input-container']}>
      <div className={styles.wrapper_input}>
        <div className={styles['selected-tags']}>
          {tags?.length > 0 &&
            tags.map((tag) => (
              <div key={tag} className={styles.tag}>
                {tag}
                <span
                  className={styles['remove-tag']}
                  onClick={() => removeTag(tag)}
                >
                  <MdClose color={'var(--gray-400'} size={'1rem'} />
                </span>
              </div>
            ))}
        </div>
        <input
          id="tags"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="|Adicione uma tag"
          autocomplete="off"
        />
        {isDropdownVisible && filteredTags.length > 0 && (
          <div className={styles.dropdown}>
            {filteredTags.length > 0 &&
              filteredTags.map((tag) => (
                <div
                  key={tag}
                  className={styles.dropdown_item}
                  onClick={() => addTag(tag)}
                >
                  {tag}
                </div>
              ))}
          </div>
        )}
      </div>
      {showNewTagInput && inputValue.trim() !== '' ? (
        <div className={styles.dropdown_added}>
          <button onClick={handleNewTagSubmit}>
            <IconPlus /> Criar Tag <strong>‘{inputValue}’</strong>
          </button>
        </div>
      ) : (
        <button className={styles.button_include} onClick={handleUpdateTags}>
          {loading || isLoading ? (
            <>
              <Loader /> <p> ... Carregando</p>{' '}
            </>
          ) : (
            <>
              <IconTags /> <p>Atualizar</p>{' '}
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default TagInput;
