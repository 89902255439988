import { useState } from 'react';

import ButtonGoBack from '../../components/ButtonGoBack';

import { AddCompany } from '../AddCompany';
import { AddCompanyPeople } from '../AddCompanyPeople';
import { IconBusinessLine, IconUserLine } from './icon';
import styles from './styles.module.scss';

export default function Account() {
  const [people, setPeople] = useState(false);

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <ButtonGoBack backRoute={'/menu/balances'} />
      </div>

      {!people && (
        <div className={styles.wrapper_button}>
          <h1 className={styles.box_title}>
            Deseja criar qual tipo de conta ?
          </h1>
          <div className={styles.wrapper_button_navigation}>
            <button
              className={people === 'PF' ? styles['bg'] : styles['bg_']}
              onClick={() => setPeople('PF')}
            >
              <IconUserLine />
              Pessoa Física
            </button>
            <button
              className={people === 'PJ' ? styles['bg'] : styles['bg_']}
              onClick={() => setPeople('PJ')}
            >
              <IconBusinessLine />
              Pessoa Jurídica
            </button>
          </div>
        </div>
      )}
      {people === 'PF' && <AddCompanyPeople />}
      {people === 'PJ' && <AddCompany />}
    </section>
  );
}
