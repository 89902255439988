/**
 * Converte uma string de data no formato 'DD/MM/YYYY - HH:MM:SS'
 * para o formato 'YYYY-MM-DD' aceito por um input de data HTML.
 *
 * @param {string} dateString - A data no formato 'DD/MM/YYYY - HH:MM:SS'.
 * @returns {string} A data no formato 'YYYY-MM-DD'.
 */
export function convertDate(dateString) {
  const datePart = getDatePart(dateString);
  const [day, month, year] = splitDatePart(datePart);

  return formatDateForInput(year, month, day);
}

/**
 * Extrai a parte da data de uma string no formato 'DD/MM/YYYY - HH:MM:SS'.
 *
 * @param {string} dateString - A data no formato 'DD/MM/YYYY - HH:MM:SS'.
 * @returns {string} A parte da data no formato 'DD/MM/YYYY'.
 */
function getDatePart(dateString) {
  return dateString.split(' - ')[0];
}

/**
 * Divide a parte da data em dia, mês e ano.
 *
 * @param {string} datePart - A parte da data no formato 'DD/MM/YYYY'.
 * @returns {Array<string>} Um array contendo o dia, mês e ano.
 */
function splitDatePart(datePart) {
  return datePart.split('/');
}

/**
 * Formata a data no formato 'YYYY-MM-DD'.
 *
 * @param {string} year - O ano.
 * @param {string} month - O mês.
 * @param {string} day - O dia.
 * @returns {string} A data no formato 'YYYY-MM-DD'.
 */
function formatDateForInput(year, month, day) {
  return `${year}-${month}-${day}`;
}
