import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-people-svg-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-people-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(236, 253, 243);
    }
  }

  .svg-elem-1 {
    animation: animate-people-svg-stroke-1 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-people-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-people-svg-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-people-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4129_14700');
    }
  }

  .svg-elem-2 {
    animation: animate-people-svg-stroke-2 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-people-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-people-svg-stroke-3 {
    0% {
      stroke-dashoffset: 128.2389373779297px;
      stroke-dasharray: 128.2389373779297px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.2389373779297px;
    }
  }

  .svg-elem-3 {
    animation: animate-people-svg-stroke-3 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-people-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-people-svg-stroke-4 {
    0% {
      stroke-dashoffset: 57.78428268432617px;
      stroke-dasharray: 57.78428268432617px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.78428268432617px;
    }
  }

  @keyframes animate-people-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-4 {
    animation: animate-people-svg-stroke-4 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-people-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }

  @keyframes animate-people-svg-stroke-5 {
    0% {
      stroke-dashoffset: 28.880611419677734px;
      stroke-dasharray: 28.880611419677734px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 28.880611419677734px;
    }
  }

  @keyframes animate-people-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-5 {
    animation: animate-people-svg-stroke-5 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-people-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }

  @keyframes animate-people-svg-stroke-6 {
    0% {
      stroke-dashoffset: 72.88581848144531px;
      stroke-dasharray: 72.88581848144531px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 72.88581848144531px;
    }
  }

  @keyframes animate-people-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-6 {
    animation: animate-people-svg-stroke-6 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-people-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
        both;
  }

  @keyframes animate-people-svg-stroke-7 {
    0% {
      stroke-dashoffset: 25.414119720458984px;
      stroke-dasharray: 25.414119720458984px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 25.414119720458984px;
    }
  }

  @keyframes animate-people-svg-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-7 {
    animation: animate-people-svg-stroke-7 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-people-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.4000000000000001s both;
  }
`;

const IconPeoplePayment = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="6.125rem"
      height="3.125rem"
      fill="none"
      viewBox="0 0 98 50"
    >
      <path
        fill="#ECFDF3"
        d="M97 1C81.403 2.3 80.437 33.416 65 37c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V1z"
        className="svg-elem-1"
      ></path>
      <path
        fill="url(#paint0_linear_4129_14700)"
        d="M97 1C81.403 2.3 80.437 33.416 65 37c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V1z"
        className="svg-elem-2"
      ></path>
      <path
        stroke="#0F4098"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 49c13.64-4.546 18.09-22.026 32-24 12.824-1.82 19.27 14.955 32 12C80.437 33.416 81.403 2.3 97 1"
        className="svg-elem-3"
      ></path>
      <path
        fill="#0F4098"
        d="M60.56 11.37h-.08a.543.543 0 00-.18 0c-2.9-.09-5.09-2.37-5.09-5.18C55.21 3.33 57.54 1 60.4 1c2.86 0 5.19 2.33 5.19 5.19-.01 2.81-2.21 5.09-5 5.18h-.03zM60.4 2.5a3.7 3.7 0 00-3.69 3.69c0 2 1.56 3.61 3.55 3.68.06-.01.19-.01.32 0 1.96-.09 3.5-1.7 3.51-3.68A3.7 3.7 0 0060.4 2.5z"
        className="svg-elem-4"
      ></path>
      <path
        fill="#0F4098"
        d="M67.94 11.5c-.03 0-.06 0-.09-.01-.41.04-.83-.25-.87-.66-.04-.41.21-.78.62-.83.12-.01.25-.01.36-.01 1.46-.08 2.6-1.28 2.6-2.75 0-1.52-1.23-2.75-2.75-2.75a.74.74 0 01-.75-.74c0-.41.34-.75.75-.75a4.26 4.26 0 014.25 4.25c0 2.3-1.8 4.16-4.09 4.25h-.03z"
        className="svg-elem-5"
      ></path>
      <path
        fill="#0F4098"
        d="M60.57 22.3c-1.96 0-3.93-.5-5.42-1.5-1.39-.92-2.15-2.18-2.15-3.55 0-1.37.76-2.64 2.15-3.57 3-1.99 7.86-1.99 10.84 0 1.38.92 2.15 2.18 2.15 3.55 0 1.37-.76 2.64-2.15 3.57-1.5 1-3.46 1.5-5.42 1.5zm-4.59-7.36c-.96.64-1.48 1.46-1.48 2.32 0 .85.53 1.67 1.48 2.3 2.49 1.67 6.69 1.67 9.18 0 .96-.64 1.48-1.46 1.48-2.32 0-.85-.53-1.67-1.48-2.3-2.49-1.66-6.69-1.66-9.18 0z"
        className="svg-elem-6"
      ></path>
      <path
        fill="#0F4098"
        d="M69.74 20.5c-.35 0-.66-.24-.73-.6a.76.76 0 01.58-.89c.63-.13 1.21-.38 1.66-.73.57-.43.88-.97.88-1.54 0-.57-.31-1.11-.87-1.53-.44-.34-.99-.58-1.64-.73a.756.756 0 01-.57-.9c.09-.4.49-.66.9-.57.86.19 1.61.53 2.22 1 .93.7 1.46 1.69 1.46 2.73s-.54 2.03-1.47 2.74c-.62.48-1.4.83-2.26 1-.06.02-.11.02-.16.02z"
        className="svg-elem-7"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4129_14700"
          x1="49"
          x2="49"
          y1="1"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.641" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconPeoplePayment };
