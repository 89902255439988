import React from 'react';
import styles from '../styles.module.scss';
import { IconDownload } from '../../../../../components/Details/Icons';

export const ExportButton = ({ title, onClick }) => (
  <button className={styles.button} onClick={onClick} name="export">
    <IconDownload color="var(--gray-400)" size={'1.2rem'} />
    {title}
  </button>
);
