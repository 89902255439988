export const formatStatus = (status, error_description) => {
  switch (status) {
    case '01':
      return {
        text: 'Criado',
        color: 'var(--blue-status)',
        colorText: 'var(--blue-text)',
        id: 1,
      };
    case '02':
      return {
        text: 'Aguardando processamento',
        color: 'var(--purple-status)',
        colorText: 'var(--purple-text)',
        id: 2,
      };
    case '03':
      return {
        text: 'Aguardando aprovação',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 3,
      };
    case '04':
      return {
        text: 'Processando',
        color: 'var(--blue-dark-status)',
        colorText: 'var(--blue-dark-text)',
        id: 4,
      };
    case '05':
      return {
        text: 'Cancelado',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 5,
      };
    case '06':
      return {
        text: 'Finalizado',
        color: 'var(--green-status)',
        colorText: 'var(--green-text)',
        id: 6,
      };
    case '07':
      return { text: 'Erro', color: 'var(--red-status)', id: 7 };
    case '08':
      return {
        text: 'Aguardando Saldo',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 8,
      };
    case '09':
      return {
        text: 'Aguardando Cobrança',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 9,
      };
    case '10':
      return {
        text: 'Em Análise',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 10,
      };
    case '11':
      return {
        text: 'Carregando Prévia',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 11,
      };
    case '99':
      return {
        text: 'Valor Indefinido',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 99,
      };
    default:
      return {
        text: error_description || 'indefinido',
        color: 'var(--red-status)',
        colorText: 'var(--red-text)',
        id: 98,
      };
  }
};
export const formatStatusAnticipation = (status, error_description) => {
  switch (status) {
    case '01':
      return { text: 'Criado', color: 'var(--blue-status)', id: 1 };
    case '02':
      return {
        text: 'Aguardando processamento',
        color: 'var(--purple-status)',
        colorText: 'var(--purple-text)',
        id: 2,
      };
    case '03':
      return {
        text: 'Aguardando aprovação',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 3,
      };
    case '04':
      return {
        text: 'Processando',
        color: 'var(--blue-dark-status)',
        colorText: 'var(--blue-dark-text)',
        id: 4,
      };
    case '05':
      return {
        text: 'Cancelado',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 5,
      };
    case '06':
      return {
        text: 'Liberado',
        color: 'var(--blue-status)',
        colorText: 'var(--blue-text)',
        id: 6,
      };
    case '07':
      return {
        text: 'Erro',
        color: 'var(--red-status)',
        colorText: 'var(--red-text)',
        id: 7,
      };
    case '08':
      return {
        text: 'Aguardando Saldo',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 8,
      };
    case '09':
      return {
        text: 'Aguardando Cobrança',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 9,
      };
    case '10':
      return {
        text: 'Aguardando Análise',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 10,
      };
    case '11':
      return {
        text: 'Aguardando Antecipação',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 11,
      };
    case '99':
      return {
        text: 'Antecipado',
        color: 'var(--green-status)',
        colorText: 'var(--green-text)',
        id: 99,
      };
    default:
      return {
        text: error_description || 'indefinido',
        color: 'var(--red-status)',
        colorText: 'var(--red-text)',
        id: 98,
      };
  }
};

export const formatStatusOrder = (status, error_description) => {
  switch (status) {
    case '01':
      return { text: 'Criado', color: 'var(--blue-status)', id: 1 };
    case '02':
      return {
        text: 'Aguardando processamento',
        color: 'var(--purple-status)',
        colorText: 'var(--purple-text)',
        id: 2,
      };
    case '03':
      return {
        text: 'Aguardando aprovação',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 3,
      };
    case '04':
      return {
        text: 'Processando',
        color: 'var(--blue-dark-status)',
        colorText: 'var(--blue-dark-text)',
        id: 4,
      };
    case '05':
      return {
        text: 'Cancelado',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 5,
      };
    case '06':
      return {
        text: 'Finalizado',
        color: 'var(--green-status)',
        colorText: 'var(--green-text)',
        id: 6,
      };
    case '07':
      return {
        text: 'Aguardando Saldo',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 7,
      };
    case '08':
      return {
        text: 'Aguardando Cobrança',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 8,
      };
    case '09':
      return {
        text: 'Aguardando Antecipação',
        color: 'var(--orange-status)',
        colorText: 'var(--orange-text)',
        id: 9,
      };
    default:
      return {
        text: error_description || 'indefinido',
        color: 'var(--red-status)',
        colorText: 'var(--red-colorText)',
        id: 8,
      };
  }
};
