import { decodeJwtToken } from './decodeJWT';

export function formatUserData(
  user,
  dataCompany,
  dataCustomer,
  isToken,
  token,
) {
  const { cnpj, fantasy_name, transfer_fee, internal_transfer_fee } =
    dataCompany;
  const format_internal_transfer_fee = internal_transfer_fee || 0;
  const format_transfer_fee = transfer_fee || 0;

  const formattedUser = {
    ...user,
    cnpj,
    fantasy_name,
    default_company_id: user.company_id,
    permission: decodeJwtToken(token, user.company_id),
    company: {
      ...dataCompany,
      transfer_fee: format_transfer_fee,
      internal_transfer_fee: format_internal_transfer_fee,
      social_name:
        dataCompany?.social_name ||
        dataCompany?.fantasy_name ||
        dataCompany?.accountable,
    },
    customer: {
      ...user,
      ...dataCustomer,
      social_name:
        dataCustomer?.social_name ||
        `${dataCustomer.first_name} ${dataCustomer.last_name}`,
    },
    transfer_fee: format_transfer_fee,
    internal_transfer_fee: format_internal_transfer_fee,
  };

  return {
    formattedUser,
    cookieData: {
      twoFactor: Boolean(!isToken),
      ...formattedUser,
    },
  };
}
