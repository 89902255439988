import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import * as yup from 'yup';
import { v4 as uid } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiChevronDown } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { apiGet } from '../../../../services/api';
const ValidateSelect = yup.object().shape({
  type: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .test('is-type', 'Selecione um tipo de contrato ', (value) => {
      return value !== '0';
    })
    .required('document é obrigatório'),
  is_contract_required: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .required('document é obrigatório'),
});
const ValidateNoContractSelect = yup.object().shape({
  is_contract_required: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .required('document é obrigatório'),
});

export default function ConfirmPurchase({
  formStep,
  nextFormStep,
  prevFormStep,
}) {
  const { setFormValues, removerPropriedadesForm, form } = useFormData();

  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState();
  const [isRequired, setIsRequired] = useState(
    form?.is_contract_required ?? true,
  );
  const [isContract, setIsContract] = useState(
    form?.is_contract_required ?? true,
  );
  async function fetchGetListContract(document) {
    setLoading(true);
    try {
      const { data } = await apiGet(`/Contract/template/list`);
      setContract(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handlerBack() {
    removerPropriedadesForm(['type', 'is_contract_required']);
    prevFormStep();
  }
  function handlerIsContract(value) {
    setIsContract(value);
    setIsRequired(value);
  }

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(
      isRequired ? ValidateSelect : ValidateNoContractSelect,
    ),
  });
  useEffect(() => {
    fetchGetListContract();
    if (form?.type) {
      setValue('type', form?.type?.contract_type);
    }
    if (form?.is_contract_required === false) {
      removerPropriedadesForm(['type']);
      setValue('is_contract_required', form?.is_contract_required);
    }
    if (form?.is_contract_required === true) {
      setValue('is_contract_required', form?.is_contract_required);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (values) => {
    removerPropriedadesForm(['type']);

    if (isRequired) {
      const type = contract?.find((item) => item.contract_type === values.type);
      delete type?.template;

      if (type) {
        setFormValues({
          type,
          is_contract_required: true,
        });
      }

      nextFormStep();
    } else {
      setFormValues({
        type: false,
        is_contract_required: false,
      });

      nextFormStep();
    }
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow}>
          <div className={styles.radio_type}>
            <label className={styles.label} for="is_contract_required">
              A assinatura do contrato é obrigatória?
            </label>
            <br />
            <div className={styles.wrapper_radio}>
              <p>
                <input
                  type="radio"
                  id="is_contract_required-true"
                  name="is_contract_required"
                  value={'true'}
                  checked={isContract === true}
                  onClick={() => handlerIsContract(true)}
                  {...register('is_contract_required', {
                    required: true,
                    message: 'O campo deve ser selecionado.',
                  })}
                />
                <label
                  htmlFor="is_contract_required"
                  for="is_contract_required-true"
                >
                  sim
                </label>
              </p>
              <p>
                <input
                  type="radio"
                  id="is_contract_required-false"
                  name="is_contract_required"
                  value={'false'}
                  checked={isContract === false}
                  onClick={() => handlerIsContract(false)}
                  {...register('is_contract_required', {
                    required: true,
                    message: 'O campo deve ser selecionado.',
                  })}
                />

                <label
                  htmlFor="is_contract_required"
                  for="is_contract_required-false"
                >
                  não
                </label>
              </p>
            </div>

            {errors.is_contract_required && (
              <p className={styles.errorText}>
                {errors.is_contract_required.message}
              </p>
            )}
          </div>
          {isRequired === true && (
            <div className={styles.select_type}>
              <div className={styles.select}>
                <select
                  name="type"
                  {...register('type')}
                  disabled={loading}
                  value={form?.type?.contract_type}
                >
                  <option value="0">
                    {loading ? 'Carregando ...' : ' Selecione o contrato:'}
                  </option>
                  {contract?.map((type) => (
                    <option key={uid()} value={type.contract_type}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <BiChevronDown color="var(--primary-700)" size={'2rem'} />
              </div>

              {errors.type && (
                <p className={styles.errorText}>{errors.type.message}</p>
              )}
            </div>
          )}
        </div>

        <div className={styles.next_button}>
          {formStep > 0 && (
            <button onClick={handlerBack} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
