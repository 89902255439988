import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOrder } from '../../../../hooks/useOrder';
import { useUser } from '../../../../hooks/useUser';
import { formatDate } from '../../../../utils/formatDate';
import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

export default function CardsApproved({
  value,
  accounts,
  date,
  id,
  setFilterApproved,
  filterApproved,
  description,
}) {
  const [currentValue, setCurrentValue] = useState(0);
  const [account, setAccountValue] = useState(0);
  const targetValue = value;
  const targetAccounts = accounts;
  const duration = 600; // Duração da animação em milissegundos
  const decimalNumber = value - Math.floor(value);
  const history = useHistory();
  const { fetchOrderKeyValidation, fetchOrderKeyCancel, fetchOrderList } =
    useOrder();
  const handleOrderKey = () => {
    history.push({
      pathname: `/menu/order/${id}`,
    });
  };
  const { user } = useUser();
  useEffect(() => {
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * targetValue);

      setCurrentValue(nextValue + decimalNumber);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * targetAccounts);

      setAccountValue(nextValue);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApproved = async () => {
    await fetchOrderKeyValidation(id, 2);
    await fetchOrderList(400);
  };

  const handleCancel = async () => {
    fetchOrderKeyCancel(id, 2);

    await fetchOrderList(400);
  };

  return (
    <section
      className={filterApproved === id ? styles.container_ : styles.container}
    >
      <div className={styles.box}>
        <h4>
          <span>{formatDate(date, 'dd/MM/yyyy')}</span>
          <div>
            <strong>{account}</strong>
            <span>Contas</span>
          </div>
        </h4>
        <h1>{formatMoney(currentValue)}</h1>
        <h5>{description}</h5>
        <div className={styles.wrapper_button}>
          <button className={styles.details} onClick={handleOrderKey}>
            Ver detalhes
          </button>

          {[13].every((item) => user?.permission.includes(item)) ? (
            <button className={styles.send} onClick={handleApproved}>
              Aprovar
            </button>
          ) : [12].every((item) => user?.permission.includes(item)) ? (
            <button className={styles.send} onClick={handleApproved}>
              Validar
            </button>
          ) : (
            <></>
          )}
          {[14].every((item) => user?.permission.includes(item)) && (
            <button className={styles.send} onClick={handleCancel}>
              Cancelar
            </button>
          )}
        </div>
      </div>
    </section>
  );
}
