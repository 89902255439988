import styled from 'styled-components';

export const Span = styled.span`
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.colorText};
  background: ${(props) => props.color};
  padding: 0.4rem 1rem;
  border-radius: 0.8rem;
  text-align: center;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  font-size: 0.8rem;
  font-weight: 600;
  text-overflow: ellipsis;
  margin: 0;
`;
