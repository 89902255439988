import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiPost } from '../services/api';
import { useUser } from './useUser';
import { useQueryClient } from 'react-query';
import { useNotification } from './useNotifications';

export function useAnticipation() {
  const { addNotification } = useNotification();

  const queryClient = useQueryClient();

  const {
    setLoading,
    setError,
    loading,
    statusAnticipationSelect,
    setStatusAnticipationSelect,
  } = useContext(StoreContext);

  const { userLogout } = useUser();

  async function fetchAnticipationCancelKeyMovement(id) {
    try {
      setLoading({ movement: true });
      setError(false);
      const { data } = await apiPost(`/Movement/cancel/${id}`);

      if (data) {
        await queryClient.invalidateQueries(['ConsignableList']);

        addNotification(
          <>Antecipação cancelada com sucesso</>,
          3,
          'Antecipação',
          true,
        );
        setLoading({ movement: false });
      }
    } catch (error) {
      if (error?.response) {
        const { status } = await error.response;
        if (status === 401) {
          userLogout();

          setError({ order: 'Você não está autenticado' });
        }
        if (status === 500) {
          setError({ order: 'Error servidor' });
        }
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchOrderCancelKeyMovement(id) {
    try {
      setLoading({ movement: true });
      setError(false);
      const { data } = await apiPost(`/Movement/cancel/${id}`);

      if (data) {
        await queryClient.invalidateQueries(['OrdersListPayment']);

        addNotification(
          <>Pagamento cancelado com sucesso</>,
          3,
          'Pagamento',
          true,
        );
        setLoading({ movement: false });
      }
    } catch (error) {
      if (error?.response) {
        const { status } = await error.response;
        if (status === 401) {
          userLogout();

          setError({ order: 'Você não está autenticado' });
        }
        if (status === 500) {
          setError({ order: 'Error servidor' });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    fetchAnticipationCancelKeyMovement,
    fetchOrderCancelKeyMovement,
    loading,
    statusAnticipationSelect,
    setStatusAnticipationSelect,
  };
}
