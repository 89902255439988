import TableItem from '../TableItem';
import * as S from './styles';

export const TableArea = ({ list, currentPage, setModal }) => {
  return (
    <>
      <S.Table key={list.orderKey}>
        <tbody>
          <S.Header>
            <S.TableHeadColumn>Data</S.TableHeadColumn>
            <S.TableHeadColumn>Serviço</S.TableHeadColumn>
            <S.TableHeadColumn>Tipo</S.TableHeadColumn>
            <S.TableHeadColumn>Favorecido</S.TableHeadColumn>
            <S.TableHeadColumn>Documento</S.TableHeadColumn>
            <S.TableHeadColumn>Valor</S.TableHeadColumn>
            <S.TableHeadColumn>Saldo </S.TableHeadColumn>
            <S.TableHeadColumn> Taxa </S.TableHeadColumn>
            <S.TableHeadColumn></S.TableHeadColumn>
          </S.Header>
        </tbody>
        <S.Box>
          {list &&
            list.map((item, index) => (
              <TableItem
                item={item}
                index={index}
                key={index}
                currentPage={currentPage}
                setModal={setModal}
              />
            ))}
        </S.Box>
      </S.Table>
      {list.length === 0 && <S.Warning>...</S.Warning>}
    </>
  );
};
