import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Loader, Tooltip, Whisper } from 'rsuite';
import { IconEdit } from '../../../../components/Details/Icons';
import TooTipCopy from '../../../../components/UI/ToolTipCopy';
import { useTags } from '../../../../hooks/useTags';
import { IconClose } from '../../../Collaborators/Components/TagInput/icon';
import TagInput from './Components/TagInput';
import { IconCopy } from './Icons';
import styles from './styles.module.scss';
import { useEffect } from 'react';
const WrapperToolTip = ({ children }) => {
  return (
    <Whisper
      trigger="hover"
      placement={'left'}
      controlId={`control-id-click`}
      speaker={<Tooltip>Copiar</Tooltip>}
    >
      {children}
    </Whisper>
  );
};
export function DetailsEdit({
  Icon,
  title,
  value,
  copy = false,
  description = false,
  maxWidth = '100%',
  border = true,
  Context = false,
  tags,
  handleReload,
  id,
  isLoading,
}) {
  const [tagsEdit, setTagsEdit] = useState(tags);
  const [isCopied, setIsCopied] = useState(false);
  const [edit, setEdit] = useState(false);
  const { updateTags } = useTags();

  useEffect(() => {
    setTagsEdit(tags);
  }, [tags]);

  const handleCopyClick = (text) => {
    setIsCopied(text);

    setInterval(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleUpdateTags = async () => {
    try {
      const result = await updateTags(tags, tagsEdit, id);
      if (result) {
        setTagsEdit([]);
        setEdit(false);
      }
    } catch (error) {
    } finally {
      await handleReload();
    }
  };

  const CardCopy = ({ text, isCopied, handleCopyClick, id }) => {
    return (
      <div className={styles.card_box}>
        {isCopied && (
          <div className={styles['check-wrap-box']}>
            <div className={styles['check-wrap']}></div>
          </div>
        )}

        <div className={styles.key}>
          <TooTipCopy
            text={isCopied ? 'Copiado' : false}
            id={id}
            keyMovements={id}
          >
            <WrapperToolTip>
              <CopyToClipboard text={text}>
                <button onClick={() => handleCopyClick(text)}>
                  <IconCopy />
                </button>
              </CopyToClipboard>
            </WrapperToolTip>
          </TooTipCopy>
        </div>
      </div>
    );
  };

  if (description) {
    return (
      <div
        className={styles.box_}
        style={
          border
            ? { maxWidth: maxWidth }
            : { maxWidth: maxWidth, border: 'none' }
        }
      >
        <div className={styles.header_}>
          {Icon && <Icon />}
          <p className={styles.title}>{title}</p>
          <button className={styles.edit} onClick={() => setEdit(!edit)}>
            {!edit ? <IconEdit /> : <IconClose />}
          </button>
        </div>
        {edit && (
          <TagInput
            tags={tagsEdit}
            setTags={setTagsEdit}
            handleUpdateTags={handleUpdateTags}
            isLoading={isLoading}
          />
        )}
        {!edit && (
          <div className={styles.left_card_}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {tagsEdit?.length > 0 ? (
                  <p className={styles.value}>
                    {Context ? <Context /> : value}
                  </p>
                ) : (
                  <p className={styles.value}> Ainda não contém tags. </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className={styles.box}
      style={
        border ? { maxWidth: maxWidth } : { maxWidth: maxWidth, border: 'none' }
      }
    >
      <div className={styles.header}>
        {Icon && <Icon />}
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.left_card}>
        <p className={styles.value}>{value}</p>

        {copy && (
          <p>
            <CardCopy
              isCopied={isCopied}
              handleCopyClick={handleCopyClick}
              text={value}
              id={value}
            />
          </p>
        )}
      </div>
    </div>
  );
}
