import React, { useState } from 'react';
import { useUser } from '../../hooks/useUser';
import styles from './styles.module.scss';
import logo from '../../Assets/logotextblack.svg';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { BiInfoCircle, BiRightArrowAlt } from 'react-icons/bi';
import { version } from '../../config';
const Login = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [visible, setVisible] = useState(true);
  const [isToken, setIsToken] = useState(false);
  const [token, setToken] = useState(false);
  const { userLogin, loading } = useUser();

  async function handleSubmit(event) {
    event.preventDefault();

    const { token: verifyToken } = await userLogin(email, password, token);

    if (verifyToken) {
      setIsToken(true);
    }
  }
  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h1 htmlFor="email">Faça seu login</h1>
          <input
            className={styles.input}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="e-mail ou CPF"
            required
            autoFocus
          />
          <div className={styles.password}>
            <input
              type={visible ? 'password' : 'text'}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              placeholder="senha"
              className={styles.input}
              required
            />
            {visible ? (
              <BsFillEyeFill
                className={styles.eyes}
                onClick={() => setVisible(!visible)}
                size={'1.375rem'}
              />
            ) : (
              <BsFillEyeSlashFill
                className={styles.eyes}
                onClick={() => setVisible(!visible)}
                size={'1.375rem'}
              />
            )}
          </div>
          {isToken && (
            <>
              <label htmlFor="token">
                Insira o token{' '}
                <BiInfoCircle color={'var(--primary-700'} size={'1rem'} />{' '}
                <div>
                  O Token é gerado na tela inicial do aplicativo da OxPay
                </div>
              </label>
              <input
                className={styles.input}
                id="token"
                onChange={(e) => setToken(e.target.value)}
                type="token"
                placeholder="Token"
                required
                autoFocus
              />
            </>
          )}
          {loading ? (
            <button className={styles.loading} disabled>
              Carregando ...
            </button>
          ) : (
            <button type="submit">Acessar</button>
          )}
        </form>
        <span className={styles.resetEmail}>
          <BiRightArrowAlt size="1rem" />
          <a
            href="https://oxpay.com.br/recuperacao-de-senha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            recuperar senha
          </a>
        </span>
      </div>
      <footer className={styles.footer}>
        <span>© 2024 .OxPay. Todos os direitos reservados.</span>
        <span> {version}v </span>
      </footer>
    </section>
  );
};

export default Login;
