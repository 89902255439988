import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

/**
 * Componente de botão de retorno com um ícone de seta para voltar à página anterior.
 *
 * Este componente renderiza um botão com um ícone de seta e a palavra "Voltar". Quando clicado,
 * ele utiliza o hook useHistory do React Router para retornar à página anterior.
 *
 * @component
 * @example
 * // Exemplo de uso:
 * import ButtonGoBack from './ButtonGoBack';
 *
 * function MinhaPagina() {
 *   return (
 *     <div>
 *       <h1>Minha Página</h1>
 *       <ButtonGoBack />
 *     </div>
 *   );
 * }
 *
 * @returns {JSX.Element} Um botão de retorno para a página anterior.
 */
export default function ButtonGoBack({ backRoute }) {
  const history = useHistory();

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <button
          onClick={() => {
            backRoute && history.action === 'POP' ? history.push(backRoute) : history.goBack();
          }}
        >
          <BiArrowBack size="1.125rem" />
          Voltar
        </button>
      </div>
    </section>
  );
}
