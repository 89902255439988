import { useQuery } from 'react-query';
import { useUser } from './useUser';
import { useOrder } from './useOrder';
import { useDate } from './useDate';
import { useCustomers } from './useCustomers';

const DEFAULT_STALE_TIME = 1000 * 60;
const DEFAULT_OPTIONS = {
  staleTime: DEFAULT_STALE_TIME,
  refetchOnMount: true,
};

export const handleReload = async ({
  queryData,
  setLoading,
  setValue,
  checkKey,
  checkValue,
  onDoesntExists,
}) => {
  try {
    setLoading(true);

    const data = queryData?.allBalance.find(
      (item) => String(item[checkKey]) === checkValue,
    );
    if (!data) {
      onDoesntExists();
      return;
    }
    setValue(data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const useConsignableCollaboratorQuery = () => {
  const { fetchGetListConsignable } = useUser();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    'ConsignableCollaborator',
    () => fetchGetListConsignable(),
    DEFAULT_OPTIONS,
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useCustomerListDataQuery = () => {
  const { fetchCustomers } = useCustomers();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery('customerListData', () => fetchCustomers(), DEFAULT_OPTIONS);

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
export const useFetchOrderQuery = (body, time) => {
  const { fetchDataListOrder } = useOrder();
  const { dateRanger } = useDate();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['OrdersList', dateRanger],
    () => fetchDataListOrder(body ?? dateRanger),
    { ...DEFAULT_OPTIONS, scaleTime: time },
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useFetchMovementsBankSlip = (body, time) => {
  const { fetchMovementList } = useOrder();
  const { dateRanger } = useDate();
  const dateSince = body.dateInitial ?? dateRanger.dateInitial;
  const dateUntil = body.dateFinally ?? dateRanger.dateFinally;
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['bankSlip', dateRanger],
    () => fetchMovementList('BankSlip', dateSince, dateUntil),
    { ...DEFAULT_OPTIONS, scaleTime: time },
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
export const useAllBalancesQuery = (value) => {
  const { fetchGetAllBalances, user } = useUser();

  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    [
      'Companies',
      [0].every((item) => user?.permission.includes(item)) && value,
    ],
    () => fetchGetAllBalances(value),
    DEFAULT_OPTIONS,
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
