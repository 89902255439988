import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Renderiza o aplicativo React na div com id 'root'
ReactDOM.render(<App />, document.getElementById('root'));

// Registra o service worker para garantir que o aplicativo use sempre a versão mais recente
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registrado com sucesso:', registration);

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Nova versão disponível, força a atualização da página
                window.location.reload();
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Falha ao registrar o Service Worker:', error);
      });
  });
}

// Monitoramento de performance (opcional)
reportWebVitals();
