import { useContext, useEffect } from 'react';
import { StoreContext } from '../data/storeContext';

/**
 * Hook personalizado para gerenciamento de notificações.
 *
 * @returns {Object} - Um objeto contendo funções e dados relacionados a notificações.
 */
export function useNotification() {
  /**
   * Lista de notificações atuais.
   * @type {Array.<Object>}
   */
  const { notifications, setNotifications } = useContext(StoreContext);

  /**
   * Adiciona uma nova notificação à lista de notificações.
   *
   * @param {string} message - Mensagem da notificação.
   * @param {number} type - Tipo da notificação (0: Informação, 1: Atenção, 2: Erro, 3: Concluído).
   * @param {string} title - Título da notificação.
   * @param {boolean} timer - Define se a notificação deve ser automaticamente removida após um período.
   */
  const addNotification = (message, type, title, timer = false, id = false) => {
    const newNotification = {
      id: id ? id : Date.now(),
      type: type,
      message: message,
      timer: timer, // Adicione o campo timer
      title: title,
    };
    setNotifications((prevNotifications) => {
      const filter_notification = prevNotifications.filter(
        (item) => item.id !== newNotification.id,
      );

      return [...filter_notification, newNotification];
    });

    if (timer) {
      // Inicie o temporizador se timer for verdadeiro
      setTimeout(() => {
        removeNotification(newNotification.id);
      }, 5000); // 10 segundos em milissegundos
    }
  };

  /**
   * Remove uma notificação da lista de notificações.
   *
   * @param {number} id - ID da notificação a ser removida.
   */
  const removeNotification = (id) => {
    setNotifications((prevNotifications) => {
      const updatedNotifications = prevNotifications.filter(
        (notification) => notification.id !== id,
      );

      // Limpe o temporizador correspondente se o campo timer for verdadeiro
      const removedNotification = prevNotifications.find(
        (notification) => notification.id === id,
      );
      if (removedNotification && removedNotification.timer) {
        clearTimeout(removedNotification.id);
      }

      return updatedNotifications;
    });
  };

  useEffect(() => {
    // Limpe todos os temporizadores quando o componente for desmontado
    return () => {
      notifications.forEach((notification) => {
        if (notification.timer) {
          clearTimeout(notification.id);
        }
      });
    };
  }, [notifications]);

  return { notifications, addNotification, removeNotification };
}
