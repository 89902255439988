import { BlobProvider } from '@react-pdf/renderer';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../../../services/api';
import Contract from '../Contract';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function MobileContract() {
  const [loading, setLoading] = useState(true);
  const [errorContract, setErrorContract] = useState(false);
  const [contract, setContract] = useState('');
  const { id, number } = useParams();

  const contractNumber = number || '1';

  async function fetchGetOnboardingContract(document) {
    try {
      const { data } = await apiGet(`/contract/${document}`);

      setContract(data?.value);
    } catch (error) {
      setErrorContract(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchGetOnboardingContract(id);
  }, [id, number]);

  if (loading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
          backgroundColor: '#fff',
        }}
      >
        <img src="/search-file.gif" alt="Search File" width={120} />
        <p style={{ marginTop: 20 }}>
          Processando as informações, por favor aguarde...
        </p>
      </div>
    );
  }

  if (errorContract) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: 20,
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
        }}
      >
        Desculpe, algo deu errado. Tente novamente, mais tarde.
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
      }}
    >
      {contract && (
        <BlobProvider
          width="100%"
          height="100%"
          document={
            <Contract data={contract} id={id} contractNumber={contractNumber} />
          }
        >
          {({ blob, url, loading, error }) => {
            if (loading) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    backgroundColor: '#fff',
                  }}
                >
                  <img src="/search-file.gif" alt="Search File" width={120} />
                  <p style={{ marginTop: 20 }}>
                    Processando as informações, por favor aguarde...
                  </p>
                </div>
              );
            }

            if (error) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    padding: 20,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#777',
                  }}
                >
                  Desculpe, algo deu errado. Tente novamente, mais tarde.
                </div>
              );
            }

            return <PdfViewer pdfBlobUrl={url} />;
          }}
        </BlobProvider>
      )}
    </div>
  );
}

const PdfViewer = ({ pdfBlobUrl }) => {
  const containerRef = useRef(null);

  const renderPage = async (pdfDoc, pageNum) => {
    const page = await pdfDoc.getPage(pageNum);
    const scale = 2;
    const viewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    canvas.style.width = `${viewport.width / 4}px`;
    canvas.style.height = `${viewport.height / 4}px`;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    containerRef.current.appendChild(canvas);
  };

  const renderAllPages = async (pdfDoc) => {
    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      await renderPage(pdfDoc, pageNum);
    }
  };

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const pdfDoc = await pdfjsLib.getDocument(pdfBlobUrl).promise;

        if (containerRef.current) {
          containerRef.current.innerHTML = '';
          await renderAllPages(pdfDoc);
        }
      } catch (err) {
        console.error('Erro ao carregar o PDF: ', err);
      }
    };

    loadPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfBlobUrl]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 5,
        }}
      ></div>
    </div>
  );
};
