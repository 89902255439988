import React from 'react';
import styles from './styles.module.scss';

function CardHeader() {
  return (
    <div className={styles.cardOrder}>
      <span className={styles.date}>DATA</span>
      <span className={styles.name}>FAVORECIDO</span>
      <span className={styles.date}>DOCUMENTO</span>
      <span className={styles.value}>VALOR</span>
      <span className={styles.description}>MÉTODO</span>
      <span className={styles.status}>STATUS</span>
      <span></span>
    </div>
  );
}

export default CardHeader;
