import { addDays, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoReloadOutline } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { useQuery, useQueryClient } from 'react-query';
import { CheckPicker, Loader } from 'rsuite';
import { ButtonMenuDownload } from '../../components/ButtonMenuDownload';
import { DatePicker } from '../../components/DatePiker';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { InputSearch } from '../../components/UI/InputSearch';
import { LoadingBall } from '../../components/UI/LoadingBall';
import { useAnticipation } from '../../hooks/useAnticipation';
import { useDate } from '../../hooks/useDate';
import { useOrder } from '../../hooks/useOrder';
import { usePagination } from '../../hooks/usePagination';
import { usePayment } from '../../hooks/usePayment';
import { useUser } from '../../hooks/useUser';
import { formatListAnticipation } from '../../utils/formatListAnticipation';
import { handleDataRanger } from '../../utils/handleDateRanger';
import { lower } from '../../utils/lower';
import CardConsignment from './Components/CardConsignment';
import CardHeader from './Components/CardHeader';
import Cards from './Components/Cards';
import CardsApproved from './Components/CardsApproved';
import { ExportExcelConsignable } from './Components/ExportExcelConsignable';
import StepsPayment from './Components/StepsPayment/';
import { statusData } from './data';
import styles from './styles.module.scss';

export default function Anticipation() {
  const [viewSteps, setViewSteps] = useState(false);
  const [filterApproved, setFilterApproved] = useState(false);
  const [, setFilter] = useState(false);
  const { clearStatePayment } = usePayment();
  const [showTooltip, setShowTooltip] = useState({ text: false, key: false });

  const [search, setSearch] = useState(false);
  const queryClient = useQueryClient();

  const { user } = useUser();
  const { statusAnticipationSelect, setStatusAnticipationSelect } =
    useAnticipation();
  const { fetchDataListOrderConsignment } = useOrder();
  const { dateAnticipation, setDateAnticipation } = useDate();

  const [dateRanger, setDateRanger] = useState(
    dateAnticipation
      ? handleDataRanger(dateAnticipation[0], dateAnticipation[1])
      : handleDataRanger(subDays(new Date(), 6), new Date()),
  );

  useEffect(() => {
    if (dateAnticipation) {
      let startDateString = addDays(dateAnticipation[0], 1)
        .toISOString()
        .substring(0, 10);

      let endDateString = addDays(dateAnticipation[1], 1)
        .toISOString()
        .substring(0, 10);

      let startDate = new Date(startDateString);

      startDate.setHours(-3, 0, 0);
      startDate = startDate.toISOString();

      let endDate = new Date(endDateString);

      endDate.setHours(20, 59, 59);
      endDate = endDate.toISOString();

      setDateRanger({
        dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
        dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateAnticipation]);

  const { data, isFetching } = useQuery(
    [
      'ConsignableList',
      dateRanger?.dateInitial
        ? dateRanger
        : setDateAnticipation([subDays(new Date(), 6), new Date()]),
    ],
    () => fetchDataListOrderConsignment(dateRanger),
    {
      staleTime: 1000 * 60,
    },
  );

  async function handleReload() {
    await queryClient.invalidateQueries(['ConsignableList']);
  }

  useEffect(() => {}, [data]);
  const awaitApproved =
    data && data.filter((item) => ['01', '03'].includes(item.status));
  const approvedResume =
    awaitApproved &&
    awaitApproved?.reduce(
      (acc, item) => {
        return {
          total_amount: item.total_amount + acc.total_amount,
          total_moviments: item.total_moviments + acc.total_moviments,
        };
      },
      {
        total_amount: 0,
        total_moviments: 0,
      },
    );

  function copyText(key) {
    navigator.clipboard.writeText(key);
    setShowTooltip({ text: 'Copiado', key: key });

    setTimeout(() => {
      setShowTooltip({ text: false, key: key });
    }, 2000);
  }

  const list = formatListAnticipation(data);

  const listFormat = list
    ? list
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;

          return lower(`${item['name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search) return item;
          return (
            item['document']
              .replace(/\D/g, '')
              .includes(search.replace(/\D/g, '')) ||
            [item['id'], item['order']].includes(search)
          );
        })
        .filter((item) => {
          if (statusAnticipationSelect.length === 0) return item;

          return statusAnticipationSelect.includes(item.statusRef.id) && item;
        })
    : [];

  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    listFormat,
    50,
    '/menu/consignment/',
  );
  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusAnticipationSelect]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleFilter = (value) => {
    setFilter(value);
  };

  const handleStatusChange = (value) => {
    setStatusAnticipationSelect(value);
  };

  const handleClearSelection = () => {
    setFilterApproved(false);
  };
  const handleViewSteps = () => {
    clearStatePayment();
    setViewSteps(!viewSteps);
    if (viewSteps) {
      handleReload();
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_style}>
          <h1 className={styles.title_style}>Antecipações</h1>

          <div className={styles.form}>
            {[26].every((item) => user?.permission.includes(item)) && (
              <button onClick={handleViewSteps} className={styles.send}>
                <AiOutlinePlus
                  className={styles.icon}
                  size="1.125rem"
                  style={viewSteps && { rotate: '45deg' }}
                />
                {viewSteps ? 'Fechar' : 'Nova Antecipação'}
              </button>
            )}
          </div>
        </div>

        {viewSteps && <StepsPayment setViewSteps={setViewSteps} />}
      </div>
      {[16].every((item) => user?.permission.includes(item)) &&
        awaitApproved?.length > 0 && (
          <div className={styles.header_approved}>
            {/* <h1>Aprovar</h1> */}
            <article>
              <Cards
                value={approvedResume.total_amount}
                accounts={approvedResume.total_moviments}
                title="Aguardando Aprovação"
              />

              {filterApproved && (
                <button
                  className={styles.button}
                  onClick={handleClearSelection}
                >
                  Limpar Seleção
                </button>
              )}
            </article>
            <div className={styles.approved}>
              {awaitApproved?.map(
                ({
                  total_amount,
                  total_moviments,
                  created_at,
                  key,
                  description,
                  order,
                }) => (
                  <CardsApproved
                    date={created_at}
                    value={total_amount}
                    accounts={total_moviments}
                    id={key}
                    key={key}
                    order={order}
                    description={description}
                    setFilterApproved={setFilterApproved}
                    filterApproved={filterApproved}
                  />
                ),
              )}
            </div>
          </div>
        )}
      <div className={styles.list}>
        <div className={styles.list_header}>
          <div className={styles.list_header_search}>
            <DatePicker
              setDate={setDateAnticipation}
              value={dateAnticipation}
              style={{ width: 500 }}
              type={3}
              disabled={isFetching}
            />
            <CheckPicker
              data={statusData}
              style={{ width: 300 }}
              placeholder="Status"
              onChange={handleStatusChange}
              disabled={isFetching}
              defaultValue={statusAnticipationSelect}
            />
            <InputSearch
              label="Pesquisar por nome ou documento"
              type="text"
              setValue={handleSearch}
              value={search}
              Icon={RiSearch2Line}
              width="100%"
              filter={handleFilter}
              disabled={isFetching}
            />
          </div>
          <div className={styles.list_header_arrow}>
            <ButtonMenuDownload model={[4]}>Modelos</ButtonMenuDownload>
            {data && (
              <ExportExcelConsignable
                isFetching={isFetching}
                list={listFormat}
              />
            )}
            <button
              disabled={!isFetching ? false : true}
              className={styles.button_load_style}
              onClick={handleReload}
            >
              {isFetching ? (
                <Loader />
              ) : (
                <IoReloadOutline size="1.125rem" color="var(--white)" />
              )}
            </button>
          </div>
        </div>
        <div className={styles.container_arrow_box}>
          <PaginationArrow
            pages={pages}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
        <CardHeader />

        {currentItens?.length >= 1 ? (
          currentItens?.map((item) => (
            <CardConsignment
              {...item}
              key={item.id}
              copyText={copyText}
              showTooltip={showTooltip}
            />
          ))
        ) : (
          <div className={styles.listLoader}>
            {isFetching ? (
              <LoadingBall />
            ) : (
              <div className={styles.listLoader}>
                {[16].every((item) => user?.permission.includes(item)) ? (
                  <h5>Nenhuma antecipação encontrada</h5>
                ) : (
                  <div>
                    Seu usuário não tem permissão para visualizar as
                    antecipações
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
