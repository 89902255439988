import React from 'react';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import fileBank from '../../Assets/template/transferencia-bank.xlsx';
import filePix from '../../Assets/template/transferencia-pix.xlsx';
import fileInternal from '../../Assets/template/transferencia_interna.xlsx';
import consignation from '../../Assets/template/antecipacao.xlsx';
import styles from './styles.module.scss';
import { IconDownload } from '../Details/Icons';

/**
 * Componente de botão de menu suspenso para download de modelos de arquivo.
 *
 * Este componente renderiza um botão que, quando clicado, exibe um menu suspenso com links para download de modelos de arquivo. É projetado para fornecer acesso conveniente a diferentes modelos de arquivo, como modelos de transferência bancária, Pix, transferência interna e antecipação.
 *
 * @component
 * @param {React.ReactNode} children - Os elementos filhos a serem renderizados no botão. Pode ser usado para personalizar o conteúdo do botão.
 * @param {number[]} model - Um array de números que representa os modelos de arquivo disponíveis para download. Cada número corresponde a um modelo específico.
 * @returns {JSX.Element} Um botão de menu suspenso para download de modelos de arquivo.
 *
 * @example
 * // Exemplo de uso:
 * import { ButtonMenuDownload } from './ButtonMenuDownload';
 *
 * function MeuComponente() {
 *   return (
 *     <ButtonMenuDownload model={[1, 2, 3]}>
 *       <span>Modelos de Arquivo</span>
 *     </ButtonMenuDownload>
 *   );
 * }
 */

export function ButtonMenuDownload({ children, model = [1, 2, 3, 4] }) {
  const [active, setActive] = useState(false);

  const templates = [
    {
      id: 1,
      title: 'Dados Bancário',
      href: fileBank,
      name: 'transferencia-bank',
    },
    {
      id: 2,
      title: 'Pix',
      href: filePix,
      name: 'transferencia-pix',
    },
    {
      id: 3,
      title: 'Transação Interna',
      href: fileInternal,
      name: 'transferencia-interna',
    },
    {
      id: 4,
      title: 'Antecipação',
      href: consignation,
      name: 'antecipacao',
    },
  ];

  const filter = templates?.filter((item) => model.includes(item.id));
  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      {children}
      <IoIosArrowDown color="var(--gray-400)" size="1.125rem" />
      {active && (
        <ul className={styles.menu}>
          {filter &&
            filter.map(({ title, id, href, name }) => (
              <a
                key={id}
                className={styles.button__menu}
                href={href}
                download={name}
              >
                <IconDownload color="var(--gray-400)" size={'1.5rem'} />
                <span> {title}</span>
              </a>
            ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
