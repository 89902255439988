export function formatDocument(document) {
  const formatDocument = document.replace(/\D/g, '');
  if (formatDocument?.length === 11) {
    return formatDocument.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4',
    );
  } else if (formatDocument?.length === 14) {
    return formatDocument.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  } else {
    return document;
  }
}

export function formatDocumentNumbers(document) {
  return document.replace(/\D/g, '');
}

export function documentLength(document) {
  const formatDocument = document.replace(/\D/g, '');
  return formatDocument.length;
}
