import React, { useReducer, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdCheck } from 'react-icons/md';
import { Loader } from 'rsuite';
import {
  IconDropMenu,
  IconEdit,
  IconMove,
} from '../../../../components/Details/Icons';
import { useTags } from '../../../../hooks/useTags';
import { IconClose } from '../TagInput/icon';
import styles from './styles.module.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'MOVE': {
      if (!action.to) return state;
      if (action.to === action.from && action.toIndex === action.fromIndex)
        return state;
      const newState = Array.from(state.tasks);
      const [removed] = newState.splice(action.fromIndex, 1);
      newState.splice(action.toIndex, 0, removed);
      const formatNewState = newState
        .reverse()
        .map((item, index) => {
          return {
            id: item.id,
            title: item.title,
            priority: index,
          };
        })
        .sort((a, b) => b.priority - a.priority);
      return { tasks: formatNewState };
    }
    case 'SET_LOADING': {
      return { ...state, loading: action.payload };
    }
    case 'SET_TASKS': {
      return { ...state, tasks: action.payload };
    }
    default:
      return state?.sort((a, b) => b.priority - a.priority);
  }
};

function DraggableList({ handleReload }) {
  const { tagsPriority, priorityTagCompany } = useTags();

  const initialState = {
    tasks: tagsPriority,
    loading: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [active, setActive] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    dispatch({ type: 'SET_TASKS', payload: tagsPriority });
  }, [tagsPriority]);

  const setLoading = (isLoading) => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  };
  const handlePriorityTagCompany = async () => {
    setLoading(true);
    try {
      const { tasks } = state;
      const formatTags = tasks.reverse().map((item, index) => {
        return {
          priority: index,
          tag: item.title,
        };
      });
      const result = await priorityTagCompany(formatTags);

      if (result) {
        await handleReload();
      }
    } catch (error) {
    } finally {
      handleDisableAll();
      setLoading(false);
    }
  };

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    dispatch({
      type: 'MOVE',
      from: result.source.droppableId,
      to: result.destination.droppableId,
      fromIndex: result.source.index,
      toIndex: result.destination.index,
    });
  }, []);
  const handleDisableAll = () => {
    setModal(false);
    setActive(false);
  };
  return (
    <>
      <div className={styles.container} onClick={() => setActive(!active)}>
        <IconDropMenu color="var(--primary)" />
        {active && modal === false && (
          <ul className={styles.menu}>
            <li onClick={() => setModal(!modal)}>
              <IconEdit /> Ordenar Tags
            </li>
          </ul>
        )}

        {(active || modal) && (
          <div
            onClick={() => handleDisableAll()}
            className={styles.overlay}
          ></div>
        )}
      </div>
      {modal && active === false && (
        <div className={styles.card}>
          <div className={styles.wrapper_buttons}>
            <button
              className={styles.button}
              onClick={handlePriorityTagCompany}
            >
              <MdCheck /> Salvar
            </button>
            <button
              className={styles.button_close}
              onClick={() => handleDisableAll()}
            >
              <IconClose />
            </button>
          </div>
          {state?.loading ? (
            <Loader />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="tasks">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={styles.wrapper_items}
                  >
                    {state.tasks
                      .sort((a, b) => b.priority - a.priority)
                      .map((task, index) => (
                        <Draggable
                          key={task.id}
                          draggableId={task.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.item}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <p>{task.title}</p>
                              <IconMove />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
    </>
  );
}

export default DraggableList;
