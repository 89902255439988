export function formatOrderType(type) {
  switch (type) {
    case 1:
      return { id: 1, title: 'Transferência' };
    case 2:
      return { id: 2, title: 'Cobrança' };
    case 3:
      return { id: 3, title: 'Validação' };
    case 4:
      return { id: 4, title: 'Depósito' };
    case 5:
      return { id: 5, title: 'Cobrança QR Code' };
    case 6:
      return { id: 6, title: 'Antecipação' };
    default:
      return '---';
  }
}
