import { useEffect, useState } from 'react';

import { formatMoney } from '../../../../utils/formatMoney';
import { IconAwaitPayment } from '../animation/IconAwaitPayment';

import styles from './styles.module.scss';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
export function CardAmountAwait({
  data,
  loading,
  title,
  handleStatusChange,
  statusTransferSelect,
}) {
  const [currentValue, setCurrentValue] = useState(0);
  const duration = 600; // Duração da animação em milissegundos

  const total_amount_ox = data.reduce((acc, item) => {
    if ([1, 3].includes(item?.status?.id) && item?.amount) {
      return acc + 1;
    }
    return acc;
  }, 0);

  useEffect(() => {
    const totalAmount = data.reduce((acc, item) => {
      if ([1, 3].includes(item?.status?.id)) {
        let value = item.amount ?? 0;
        return acc + value;
      }
      return acc;
    }, 0);

    const decimalNumber = totalAmount.toFixed(2);
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 0); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * totalAmount);

      setCurrentValue(nextValue + decimalNumber);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const handleStatusChangeSelect = () => {
    if ([1, 3].every((item) => statusTransferSelect.includes(item))) {
      handleStatusChange([]);
    } else {
      handleStatusChange([1, 3]);
    }
  };

  return (
    <div
      className={styles.container}
      onClick={handleStatusChangeSelect}
      style={
        [1, 3].every((item) => statusTransferSelect.includes(item))
          ? { border: '1px solid var(--gray-100)' }
          : {}
      }
    >
      <div className={styles.card}>
        <ul>
          <li className={styles.clients}>{title} </li>
        </ul>
        <div className={styles.wrapper_balance}>
          {data?.length >= 1 ? (
            <div className={styles.balance}>
              {loading ? (
                <LoadingBall />
              ) : currentValue >= 0 && typeof totalAmount !== 'boolean' ? (
                formatMoney(currentValue)
              ) : (
                typeof totalAmount === 'boolean' && <span> - - - </span>
              )}
            </div>
          ) : (
            <div className={styles.balance_}>{formatMoney(0)}</div>
          )}
        </div>
        <div className={styles.name}>
          <div className={styles.wrapper_footer}>
            {!loading && <strong>{total_amount_ox}</strong>}
            <span>Boletos Aguardando</span>
          </div>
        </div>
      </div>
      <div className={styles.icon}>
        <IconAwaitPayment />
      </div>
    </div>
  );
}
