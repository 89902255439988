import React, { memo, useCallback, useEffect } from 'react';
import styles from './styles.module.scss';
import { mask as masker, unMask } from 'remask';
import { useState } from 'react';

const FormGroup = ({
  name,
  label,
  type,
  disabled,
  register,
  errors,
  mask,
  onBlur,
  setValue,
  autofocus,
  clean,
  data,
  placeholder,
  min,
  Icon,
}) => {
  const [formValue, setFormValue] = useState(
    mask ? masker(unMask(data?.value ?? ''), mask) : data?.value,
  );

  const handleChange = (ev) => {
    const originalValue = unMask(ev.target.value);

    const maskedValue = masker(originalValue, mask);

    setFormValue(maskedValue);
    setValue(name, maskedValue);
  };

  useEffect(() => {
    if (clean) {
      setFormValue(' ');
    }
  }, [clean]);

  useEffect(() => {
    if (formValue) {
      setValue(name, formValue);
    }
    // eslint-disable-next-line
  }, [formValue]);
  const inputFocus = useCallback((inputElement) => {
    if (inputElement && autofocus) {
      inputElement.focus();
    }
    // eslint-disable-next-line
  }, []);

  if (mask) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.label_form}>
          {Icon && <Icon />} <label className="font-weight-bold">{label}</label>
        </div>
        <input
          type={type}
          disabled={disabled}
          value={formValue}
          {...register(name)}
          onChange={handleChange}
          placeholder={placeholder}
          //maxLength={mask.length}
          min={min ?? false}
          onBlur={onBlur}
          ref={inputFocus}
          maxLength="21"
        />
        {errors[name] && (
          <span className={styles.error__input}>{errors[name].message}</span>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={styles.label_form}>
          {Icon && <Icon />} <label className="font-weight-bold">{label}</label>
        </div>
        <input
          type={type}
          disabled={disabled}
          {...register(name)}
          onBlur={onBlur}
          autofocus={autofocus}
          placeholder={placeholder}
          onChange={(e) => setFormValue(e.target.value)}
          value={formValue}
          min={min ?? false}
        />
        {errors[name] && (
          <span className={styles.error__input}>{errors[name].message}</span>
        )}
      </div>
    );
  }
};

export default memo(FormGroup);
