import React from 'react';

export function IconStart({ size = '512' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
    >
      <mask
        id="mask0_2293_1286"
        style={{ maskType: 'luminance' }}
        width="470"
        height="470"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="42.667"
          d="M256.001 469.333a212.653 212.653 0 00150.848-62.485A212.657 212.657 0 00469.335 256a212.657 212.657 0 00-62.486-150.848 212.646 212.646 0 00-150.848-62.486 212.674 212.674 0 00-150.848 62.486A212.672 212.672 0 0042.668 256a212.659 212.659 0 0062.485 150.848 212.678 212.678 0 00150.848 62.485z"
        ></path>
      </mask>
      <g mask="url(#mask0_2293_1286)">
        <path fill="#A1A0BD" d="M0 0h512v512H0V0z"></path>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M160 256l64 64 128-128"
      ></path>
    </svg>
  );
}

export function IconError({ size = '512' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
    >
      <mask
        id="mask0_2293_1293"
        style={{ maskType: 'luminance' }}
        width="470"
        height="470"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="42.667"
          d="M256.001 469.333a212.653 212.653 0 00150.848-62.485A212.657 212.657 0 00469.335 256a212.657 212.657 0 00-62.486-150.848 212.646 212.646 0 00-150.848-62.486 212.674 212.674 0 00-150.848 62.486A212.672 212.672 0 0042.668 256a212.659 212.659 0 0062.485 150.848 212.678 212.678 0 00150.848 62.485z"
        ></path>
      </mask>
      <g mask="url(#mask0_2293_1293)">
        <path fill="red" d="M0 0h512v512H0V0z"></path>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M181.707 330.906l50.44-49.026L330.2 181"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M181.707 181.707l49.026 50.44L331.614 330.2"
      ></path>
    </svg>
  );
}

export function IconAlert({ size = '512' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
    >
      <mask
        id="mask0_2293_1279"
        style={{ maskType: 'luminance' }}
        width="470"
        height="470"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="42.667"
          d="M256.001 469.333a212.653 212.653 0 00150.848-62.485A212.657 212.657 0 00469.335 256a212.657 212.657 0 00-62.486-150.848 212.646 212.646 0 00-150.848-62.486 212.674 212.674 0 00-150.848 62.486A212.672 212.672 0 0042.668 256a212.659 212.659 0 0062.485 150.848 212.678 212.678 0 00150.848 62.485z"
        ></path>
      </mask>
      <g mask="url(#mask0_2293_1279)">
        <path fill="#ff9602" d="M0 0h512v512H0V0z"></path>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M253 295l1-46.667L252 155M254.21 355.299l.797.74.754-2.039"
      ></path>
    </svg>
  );
}
export function IconAlertDuplicate({ size = '512' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
    >
      <mask
        id="mask0_2293_1279"
        style={{ maskType: 'luminance' }}
        width="470"
        height="470"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="42.667"
          d="M256.001 469.333a212.653 212.653 0 00150.848-62.485A212.657 212.657 0 00469.335 256a212.657 212.657 0 00-62.486-150.848 212.646 212.646 0 00-150.848-62.486 212.674 212.674 0 00-150.848 62.486A212.672 212.672 0 0042.668 256a212.659 212.659 0 0062.485 150.848 212.678 212.678 0 00150.848 62.485z"
        ></path>
      </mask>
      <g mask="url(#mask0_2293_1279)">
        <path fill="#f86115" d="M0 0h512v512H0V0z"></path>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M253 295l1-46.667L252 155M254.21 355.299l.797.74.754-2.039"
      ></path>
    </svg>
  );
}

export function IconCheck({ size = '512' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 512 512"
    >
      <mask
        id="mask0_2293_980"
        style={{ maskType: 'luminance' }}
        width="470"
        height="470"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="42.667"
          d="M256.001 469.333a212.653 212.653 0 00150.848-62.485A212.657 212.657 0 00469.335 256a212.657 212.657 0 00-62.486-150.848 212.646 212.646 0 00-150.848-62.486 212.674 212.674 0 00-150.848 62.486A212.672 212.672 0 0042.668 256a212.659 212.659 0 0062.485 150.848 212.678 212.678 0 00150.848 62.485z"
        ></path>
      </mask>
      <g mask="url(#mask0_2293_980)">
        <path fill="#70C41A" d="M0 0h512v512H0V0z"></path>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="42.667"
        d="M160 256l64 64 128-128"
      ></path>
    </svg>
  );
}
export function IconPlus({ size = '1.5rem', color = 'var(--white)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 22.745c-5.93 0-10.75-4.82-10.75-10.75S6.07 1.245 12 1.245s10.75 4.82 10.75 10.75-4.82 10.75-10.75 10.75zm0-20c-5.1 0-9.25 4.15-9.25 9.25s4.15 9.25 9.25 9.25 9.25-4.15 9.25-9.25-4.15-9.25-9.25-9.25z"
      ></path>
      <path
        fill={color}
        d="M16 12.745H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path
        fill={color}
        d="M12 16.745c-.41 0-.75-.34-.75-.75v-8c0-.41.34-.75.75-.75s.75.34.75.75v8c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}

export function IconList({ size = '1.5rem', color = 'var(--white)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M15 22.75H9c-5.43 0-7.75-2.32-7.75-7.75V9c0-5.43 2.32-7.75 7.75-7.75h5c.41 0 .75.34.75.75s-.34.75-.75.75H9C4.39 2.75 2.75 4.39 2.75 9v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 5.43-2.32 7.75-7.75 7.75z"
      ></path>
      <path
        fill={color}
        d="M22 10.75h-4c-3.42 0-4.75-1.33-4.75-4.75V2c0-.3.18-.58.46-.69.28-.12.6-.05.82.16l8 8a.751.751 0 01-.53 1.28zm-7.25-6.94V6c0 2.58.67 3.25 3.25 3.25h2.19l-5.44-5.44zM13 13.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75zM11 17.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
    </svg>
  );
}
export function IconClose({ size = '1rem', color = 'var(--red-500)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={color}
        d="M8 16.5c-4.413 0-8-3.587-8-8s3.587-8 8-8 8 3.587 8 8-3.587 8-8 8zM8 1.616A6.892 6.892 0 001.116 8.5 6.892 6.892 0 008 15.384 6.892 6.892 0 0014.884 8.5 6.892 6.892 0 008 1.616z"
      ></path>
      <path
        fill={color}
        d="M5.894 11.164a.552.552 0 01-.394-.163.562.562 0 010-.79L9.712 6a.562.562 0 01.789 0 .562.562 0 010 .788l-4.213 4.213a.54.54 0 01-.394.163z"
      ></path>
      <path
        fill={color}
        d="M10.106 11.164a.552.552 0 01-.394-.163L5.5 6.788A.561.561 0 015.5 6a.561.561 0 01.788 0l4.213 4.212a.562.562 0 010 .789.552.552 0 01-.395.163z"
      ></path>
    </svg>
  );
}
