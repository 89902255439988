import React from 'react';
import { useState } from 'react';

import { IconDownload } from '../../../../components/Details/Icons';
import { ExportExcelCollaborators } from '../ExportExcelCollaborators';
import { ExportExcelPayment } from '../ExportExcelPayment';
import styles from './styles.module.scss';

export function ButtonMenuDownload({ list }) {
  const [active, setActive] = useState(false);

  const templates = [
    {
      id: 1,
      title: 'Lista de colaboradores ',
      Component: ({ title }) => (
        <ExportExcelCollaborators collaborators={list} title={title} />
      ),
    },
    {
      id: 2,
      title: 'Modelo de pagamentos',
      Component: ({ title }) => (
        <ExportExcelPayment collaborators={list} title={title} />
      ),
    },
  ];

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      <IconDownload color="var(--primary)" size={'2rem'} />
      {active && (
        <ul className={styles.menu}>
          {templates?.map(({ id, title, Component }) => (
            <Component key={id} title={title} />
          ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
