import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiGet, apiGetCompanies } from '../services/api';
import { useUser } from './useUser';
import { formatExtractDataGeneral } from '../utils/formatExtract';
/**
 * O `useBalance` é um hook personalizado que fornece funções e estados relacionados ao saldo e ao extrato da empresa.
 *
 * @property {function} fetchGetBalance - Função para buscar o saldo da empresa.
 * @property {number|boolean} balance - O saldo da empresa ou `false` em caso de erro.
 * @property {string|boolean} dataBalance - A data do saldo da empresa ou `false` em caso de erro.
 * @property {boolean} loading - Um estado indicando se está ocorrendo um carregamento de dados.
 * @property {function} fetchGetExtract - Função para buscar o extrato da empresa.
 * @property {function} setExtract - Função para definir o extrato da empresa.
 * @property {function} setDateExtract - Função para definir a data do extrato.
 * @property {Array.<number>} checkPickerCompanies - Um array contendo os IDs das empresas selecionadas.
 * @property {function} setCheckPickerCompanies - Função para definir as empresas selecionadas.
 * @property {Array} companies - Um array contendo informações das empresas.
 * @property {function} setCompanies - Função para definir as informações das empresas.
 * @property {Array} checkPickerDocuments - Um array contendo os documentos selecionados.
 * @property {function} setCheckPickerDocuments - Função para definir os documentos selecionados.
 * @property {Array} checkPickerServices - Um array contendo os serviços selecionados.
 * @property {function} setCheckPickerServices - Função para definir os serviços selecionados.
 * @property {number} pageExtractSelect - O número da página do extrato selecionada.
 * @property {function} setPageExtractSelect - Função para definir o número da página do extrato selecionada.
 * @property {boolean} startExtract - Um estado indicando se o extrato deve ser iniciado.
 * @property {function} setStartExtract - Função para definir se o extrato deve ser iniciado.
 * @property {function} fetchGetBalanceConsignable - Função para buscar o saldo de antecipação da empresa.
 * @property {number} balanceConsignable - O saldo de antecipação da empresa.
 * @property {Array} checkPickerTypes - Um array contendo os tipos selecionados.
 * @property {function} setCheckPickerTypes - Função para definir os tipos selecionados.
 * @returns {Object} - Um objeto contendo funções e estados relacionados ao saldo e ao extrato da empresa.
 */
export function useBalance() {
  const {
    setBalance,
    balance,
    setDataBalance,
    setLoading,
    dataBalance,
    loading,
    setError,
    setExtract,
    setDateExtract,
    checkPickerCompanies,
    setCheckPickerCompanies,
    checkPickerTypes,
    setCheckPickerTypes,
    checkPickerNames,
    setCheckPickerNames,
    companies,
    setCompanies,
    checkPickerDocuments,
    setCheckPickerDocuments,
    checkPickerServices,
    setCheckPickerServices,
    pageExtractSelect,
    setPageExtractSelect,
    startExtract,
    setStartExtract,
    balanceConsignable,
    setBalanceConsignable,
    limits,
    setLimits,
  } = useContext(StoreContext);

  const { userLogout } = useUser();

  /**
   * Função para buscar o saldo da empresa
   * @throws {Error} - Lança um erro em caso de falha na busca do saldo.
   */
  async function fetchGetBalance() {
    try {
      setLoading({
        balance: true,
        balance_header: true,
      });
      const { data } = await apiGet('/company/balance');
      const { after, created_at } = await data;
      if (after) {
        setBalance(after);
        setDataBalance(created_at);
      } else {
        setBalance(0.0);
        setDataBalance(false);
      }
    } catch (error) {
      setBalance(false);
      const status = await error.response?.status;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      setLoading(false);
    }
  }
  /**
   * Função para buscar o saldo da empresa
   * @throws {Error} - Lança um erro em caso de falha na busca do saldo.
   */
  async function fetchGetLimits() {
    try {
      setLoading({ limit: true });
      const { data } = await apiGet('/Company/transfer/limits');
      setLimits(data);
    } catch (error) {
      setLimits(false);
      const status = await error.response?.status;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      setLoading(false);
    }
  }
  /**
   *Função para buscar o saldo antecipação da empresa
   * @throws {Error} - Lança um erro em caso de falha na busca do saldo.
   */
  async function fetchGetBalanceConsignable() {
    try {
      setLoading({ balanceConsignable: true });
      const { data } = await apiGet('/Company/balance/consignable');
      const { after } = await data;
      if (after) {
        setBalanceConsignable(after);
      } else {
        setBalanceConsignable(0.0);
      }
    } catch (error) {
      setBalanceConsignable(false);
      const status = await error.response?.status;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      setLoading(false);
    }
  }

  /**
   * Função assíncrona para buscar o extrato da empresa.
   *
   * @param {Object} dateRange - Objeto contendo as datas de início e término do período desejado.
   * @param {string} dateRange.dateInitial - Data inicial do período.
   * @param {string} dateRange.dateFinally - Data final do período.
   * @param {Array.<number>} ids - IDs das empresas.
   * @param {Array.<number>} companies - Empresas.
   * @returns {Array} - Um array contendo o extrato da empresa.
   * @throws {Error} - Lança um erro em caso de falha na busca do extrato.
   */
  async function fetchGetExtract({ dateInitial, dateFinally }, ids, companies) {
    try {
      if (checkPickerCompanies?.length === 0) {
        setExtract([]);
      } else {
        const { data } = await apiGetCompanies(
          `/Company/Balances/v2?count=50000&createdFrom=${dateInitial}&createdUntil=${dateFinally}`,
          ids,
          companies,
        );

        const extractList = await formatExtractDataGeneral(data, companies);

        setExtract(extractList);

        return extractList;
      }
    } catch (error) {
      console.log(error);
      setBalance(false);

      const status = await error.response?.status;

      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
      if (status === 400) {
        setError({ order: 'Documento inválido, Revise os campos' });
      }
      if (status === 409) {
        setError({
          order: 'Documento Apresenta erros de formatação, Revise os campos',
        });
      }
    }
  }
  return {
    fetchGetBalance,
    balance,
    dataBalance,
    loading,
    fetchGetExtract,
    setExtract,
    setDateExtract,
    checkPickerCompanies,
    setCheckPickerCompanies,
    companies,
    setCompanies,
    checkPickerDocuments,
    setCheckPickerDocuments,
    checkPickerServices,
    setCheckPickerServices,
    checkPickerNames,
    setCheckPickerNames,
    pageExtractSelect,
    setPageExtractSelect,
    startExtract,
    setStartExtract,
    fetchGetBalanceConsignable,
    balanceConsignable,
    checkPickerTypes,
    setCheckPickerTypes,
    setBalance,
    setBalanceConsignable,
    fetchGetLimits,
    limits,
  };
}
