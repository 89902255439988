import styles from '../../styles/styles.module.scss';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../../../hooks/useFormData';
import FormGroup from './FormGroup';
import { useEffect, useState } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCPF } from 'validations-br';
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineMail,
} from 'react-icons/ai';
import { FaIdCard } from 'react-icons/fa';
import { BiPhone } from 'react-icons/bi';
import { formatDocument } from '../../../../utils/formatDocument';
const ValidateAddCompany = yup.object().shape({
  cpf: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-cpf', 'CPF não é valido', (value) => validateCPF(value))
    .required('document é obrigatório'),
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .min(15, 'O numero de telefone invalido')
    .max(15, 'O numero de telefone invalido'),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório'),
  email: yup
    .string()
    .typeError('O campo deve receber um email.')
    .required('Email é obrigatório')
    .email('E-mail deve ser valido'),
});
const ValidateAddPerson = yup.object().shape({
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .min(15, 'O numero de telefone invalido')
    .max(15, 'O numero de telefone invalido'),
  email: yup
    .string()
    .typeError('O campo deve receber um email.')
    .required('Email é obrigatório')
    .email('E-mail deve ser valido'),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório'),
});
const ValidateOnboarding = yup.object().shape({});

export default function PersonalInfo({
  formStep,
  nextFormStep,
  prevFormStep,
  currentStep,
}) {
  const { setFormValues, removerPropriedadesForm, type, isOnboarding, form } =
    useFormData();

  const [clear] = useState(false);

  const ResolverCheck = isOnboarding
    ? ValidateOnboarding
    : type === 'cpf'
    ? ValidateAddPerson
    : ValidateAddCompany;
  const handlerBack = () => {
    if (form?.cnpj) {
      removerPropriedadesForm(['phone_number', 'name', 'email', 'cpf']);
    } else {
      removerPropriedadesForm(['phone_number', 'name', 'email']);
    }
    prevFormStep();
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({ resolver: yupResolver(ResolverCheck) });
  useEffect(() => {
    if (form?.cpf) {
      setValue('document', form?.cpf);
    }
    if (form?.phone_number) {
      setValue('phone_number', form?.phone_number);
    }
    if (form?.name) {
      setValue('name', form?.name);
    }
    if (form?.email) {
      setValue('email', form?.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (values) => {
    const { cpf, phone_number, name, email, cnpj } = isOnboarding;
    isOnboarding
      ? setFormValues({ cpf, phone_number, name, email, cnpj })
      : setFormValues(values);
    nextFormStep();
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow}>
          {isOnboarding && (
            <div className={styles.box_preview}>
              <div className={styles.box_right}>
                {isOnboarding?.name && (
                  <p className={styles.items}>
                    <h2> {isOnboarding.name}</h2>
                  </p>
                )}
                {isOnboarding?.cnpj && (
                  <p className={styles.items}>
                    <strong>
                      <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                      CNPJ:
                    </strong>
                    <span>{formatDocument(isOnboarding.cnpj)}</span>
                  </p>
                )}

                <p className={styles.items}>
                  <strong>
                    <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                    CPF:
                  </strong>
                  <span> {formatDocument(isOnboarding.cpf)}</span>
                </p>
                <p className={styles.items}>
                  <strong>
                    <BiPhone size={'1.2rem'} color="var(--gray-400)" />
                    Celular:
                  </strong>
                  <span>{isOnboarding.phone_number}</span>
                </p>
                <p className={styles.items}>
                  <strong>
                    <AiOutlineMail size={'1.2rem'} color="var(--gray-400)" />
                    Email:
                  </strong>
                  <span> {isOnboarding?.email}</span>
                </p>
              </div>
            </div>
          )}
          {type === 'cpf' && !isOnboarding && (
            <>
              <FormGroup
                name="name"
                label="Nome:"
                type="text"
                placeholder="Nome completo"
                disabled={false}
                register={register}
                setValue={setValue}
                errors={errors}
              />
              <FormGroup
                name="email"
                label="E-mail:"
                type="email"
                placeholder="exemple@mail.com"
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <FormGroup
                name="phone_number"
                label="Celular:"
                type="tel"
                placeholder="(99) 99999-9999"
                data={{ value: form?.phone_number }}
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
                mask={'(99) 99999-9999'}
                clean={clear}
              />
            </>
          )}
          {type === 'cnpj' && !isOnboarding && (
            <>
              <FormGroup
                name="name"
                label="Nome:"
                type="text"
                placeholder="Nome completo"
                data={{ value: form?.name }}
                disabled={false}
                register={register}
                errors={errors}
              />
              <FormGroup
                name="cpf"
                label="CPF:"
                type="text"
                placeholder="999.999.999-99"
                data={{ value: form?.cpf }}
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={['999.999.999-99']}
                setValue={setValue}
                clean={clear}
              />

              <FormGroup
                name="email"
                label="email:"
                type="email"
                placeholder="exemple@mail.com"
                data={{ value: form?.email }}
                disabled={false}
                register={register}
                errors={errors}
              />
              <FormGroup
                name="phone_number"
                label="Celular:"
                type="tel"
                placeholder="(99) 99999-9999"
                data={{ value: form?.phone_number }}
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
                mask={'(99) 99999-9999'}
                clean={clear}
              />
            </>
          )}
        </div>
        <div className={styles.next_button}>
          {currentStep > 0 && (
            <button onClick={handlerBack} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
