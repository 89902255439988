import React, { useState } from 'react';
import { useUser } from '../../hooks/useUser';
import styles from './styles.module.scss';
import logo from '../../Assets/logotextblack.svg';
import { Link, Redirect } from 'react-router-dom';
import { apiPost } from '../../services/api';
import { useHistory } from 'react-router-dom';

const RecoverDocument = () => {
  const [document, setDocument] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, setError, setMessage } = useUser();
  const history = useHistory();

  if (login) {
    return <Redirect to="/menu/transfer" />;
  }
  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setLoading(true);
      await apiPost('/customer/recover/password', {
        document,
      });
      setMessage({ login: 'Email de recuperação enviado', styles: 'bg_' });
      history.push('/token');
    } catch (error) {
      const { data } = await error.response;
      const { status } = await data;

      if (status === 400) {
        setError({ order: 'Documento deve ser válido' });
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <section
      className={
        loading ? `${styles.loading} ${styles.container}` : styles.container
      }
    >
      <div className={styles.box}>
        <div className={styles.wrapper}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label htmlFor="document">Preencha seu documento</label>
          <p>Vamos enviar um email para alteração da sua senha!</p>
          <input
            className={styles.input}
            id="document"
            onChange={(e) => setDocument(e.target.value)}
            type="text"
            placeholder="CPF"
          />
          {loading ? (
            <button className={styles.loading} disabled>
              Carregando ...
            </button>
          ) : (
            <button type="submit">Enviar</button>
          )}
        </form>

        <Link className={styles.token} to="/token">
          Quero validar validar token!
        </Link>
      </div>
    </section>
  );
};

export default RecoverDocument;
