import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

export default function CardResume({ resume }) {
  const total_amount = resume?.movements?.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);
  const total = resume?.movements?.length;

  return (
    <section className={styles.container}>
      <div className={styles.box_resume}>
        <div className={styles['summary']}>
          <p>Total - ({total}):</p>
          <span>{formatMoney(total_amount)}</span>
        </div>
      </div>
    </section>
  );
}
