import { useUser } from './useUser';
import { useCustomers } from './useCustomers';
import { setCookieCompanyId, getToken } from '../Helpers/cookies';
import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiGet } from '../services/api';
import { useBalance } from './useBalance';
import { useQueryClient } from 'react-query';
import { useNotification } from './useNotifications';
import { decodeJwtToken } from '../utils/decodeJWT';
import { useTags } from './useTags';
import { useLocalStorage } from './useLocalStorage';

export function useCompany() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const { setValue } = useLocalStorage('');
  const clearAllExceptQuery = (value) => {
    const queryCache = queryClient.getQueryCache();

    queryCache.getAll().forEach((query) => {
      const queryKey = query.queryKey;

      if (queryKey[0] !== value) {
        queryClient.removeQueries(queryKey);
      }
    });
  };

  const { loadingCompany, setLoadingCompany } = useContext(StoreContext);
  const { fetchGetBalance, fetchGetBalanceConsignable, fetchGetLimits } =
    useBalance();

  const { setUser, user, fetchGetAllBalances, fetchCustomersListData } =
    useUser();
  const { fetchCustomers } = useCustomers();
  const { fetchTags } = useTags();
  async function userSelectCompanyId({ company_id, fantasy_name, cnpj }) {
    try {
      setLoadingCompany({ company: true });
      setCookieCompanyId(company_id);
      const token = getToken();
      const permission = decodeJwtToken(token, company_id);

      const { data: dataCompany } = await apiGet('/company');
      const conpany_id_user = user?.default_company_id;
      user.fantasy_name = fantasy_name;
      user.company_id = company_id;
      user.cnpj = cnpj;
      user.company = dataCompany;
      user.permission = permission;

      await setValue('user', user);
      setUser(user);
      let promises = [];
      if (conpany_id_user === 4) {
        promises.push([apiGet('/company')]);
        clearAllExceptQuery('companies');
      } else {
        promises.push([apiGet('/company')]);
        clearAllExceptQuery('---');
        await fetchGetAllBalances();
      }
      if ([40].every((item) => user?.permission.includes(item))) {
        await fetchTags();
      }
      if ([6].every((item) => user?.permission.includes(item))) {
        await fetchCustomers();
        await fetchCustomersListData();
      }
      if ([5].every((item) => user?.permission.includes(item))) {
        await fetchGetBalance();
        await fetchGetBalanceConsignable();
      }
      if ([58].every((item) => user?.permission.includes(item))) {
        await fetchGetLimits();
      }

      await Promise.all(promises);

      addNotification(
        <>
          Sucesso ao conectar a conta <br /> <h1>{fantasy_name} </h1>
        </>,
        3,
        'Conta conectada',
        true,
      );
    } catch (error) {
      addNotification(<>Error ao trocar de empresa.</>, 2, 'Trocar de conta');
    } finally {
      setLoadingCompany({ company: false });
    }
  }

  return {
    userSelectCompanyId,
    loadingCompany,
  };
}
