import { useForm } from 'react-hook-form';
import FormGroup from '../../components/Forms/FormGroup';
import { Loading } from '../../components/UI/Loading';
import styles from './styles.module.scss';

import { ValidateFormPasswordCreated } from '../../Helpers/validatePasswordCreate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '../../hooks/useUser';
import { useParams, Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiPost } from '../../services/api';
import { useHistory } from 'react-router-dom';

const RecoverToken = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidateFormPasswordCreated),
  });

  const [loading, setLoading] = useState();
  const { setError, setMessage } = useUser();
  const [verifyToken, setVerifyToken] = useState(false);
  const { token } = useParams();
  const history = useHistory();

  async function validateToken(token) {
    setLoading(true);
    try {
      const { status } = await apiPost('/customer/recover/password/check', {
        token,
      });

      if (status === 204) {
        throw new Error('Token Invalido');
      }
      setVerifyToken(true);
    } catch (error) {
      setError({ login: error.message });
      setVerifyToken(false);
    } finally {
      setLoading(false);
    }
  }
  async function recoverPasswordToken(data, token) {
    setLoading(true);
    try {
      const { status } = await apiPost('/customer/recover/password/complete', {
        token,
        password: data.password,
      });

      if (status === 204) {
        throw new Error('Não foi possível alterar senha');
      }

      if (status === 200) {
        setMessage({ login: 'Senha alterada com sucesso', styles: 'bg_' });
        history.push('/');
      }
    } catch (error) {
      setError({ login: error.message });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    validateToken(token);
    // eslint-disable-next-line
  }, []);

  function fetchDataSubmit(data) {
    recoverPasswordToken(data, token);
  }
  return (
    <div className={styles.container}>
      {verifyToken ? (
        <div className={styles.card}>
          <h1>Criar nova senha</h1>
          <form
            onSubmit={handleSubmit(fetchDataSubmit)}
            className={styles.form}
          >
            <FormGroup
              name="password"
              label="Nova Senha"
              type="password"
              data={{ password_old: null }}
              disabled={false}
              eyes={true}
              register={register}
              errors={errors}
            />
            <FormGroup
              name="password_confirm"
              label="Confirmar Senha"
              type="password"
              data={{ password_confirm: null }}
              disabled={false}
              register={register}
              errors={errors}
            />
            {!loading ? (
              <div className={styles.wrapper_button}>
                <button
                  style={
                    errors === false
                      ? { display: 'none' }
                      : { display: 'inline' }
                  }
                  className={styles.button}
                  type="submit"
                >
                  Confirmar
                </button>
                <Link className={styles.button} to="/">
                  Cancelar
                </Link>
              </div>
            ) : (
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loading />
              </div>
            )}
          </form>
        </div>
      ) : (
        <NavLink className={styles.button_nav} to="/">
          Voltar pra tela de login
        </NavLink>
      )}
    </div>
  );
};

export default RecoverToken;
