import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${(props) => props.bg || '#e0e0e0'};
  border-radius: 0.125rem;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.color || '#4caf50'};
  border-radius: inherit;
  transition: width 0.3s ease-in-out;
`;

const ProgressBar = ({ value, total, color, bg }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (value === 0) return setPercentage(0);

    const newPercentage = (value / total) * 100;

    setPercentage(newPercentage);
  }, [value, total]);

  return (
    <ProgressBarContainer bg={bg}>
      <ProgressBarFill percentage={percentage} color={color} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
