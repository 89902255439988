import React from 'react';
import styled from 'styled-components';

const TooltipCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 0;
  position: relative;
  margin: 0 auto;
  padding: 0;
  box-sizing: none;
  pointer-events: all;

  ::after {
    width: 6rem !important;
    pointer-events: none;
    display: ${(props) => (!!props.text ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    content: '${(props) => props.text}';
    position: absolute;
    top: -3.2rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 0.5rem 2rem;
    height: 2rem;
    color: var(--gray-600);
    background: var(--white);
    border-radius: 0.5rem;
    border: 2px solid var(--gray-200);
    font-size: 0.9rem !important;
  }
`;

export default function TooTipCopy({ children, keyMovements, id, text }) {
  return (
    <TooltipCard text={id === keyMovements ? text : false}>
      {children}
    </TooltipCard>
  );
}
