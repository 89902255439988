import styles from './styles.module.scss';

export function ButtonFilterSelect({
  isActive,
  setIsActive,
  setCurrentPage,
  title,
  account,
  value,
}) {
  return (
    <button
      className={
        isActive.toString() === value.toString()
          ? styles.isActive_true
          : styles.isActive_
      }
      onClick={() => {
        setIsActive(value);
        setCurrentPage && setCurrentPage(0);
      }}
    >
      <p className={styles.title_active}>{title}</p>
      <span className={styles.customerStatus}>{account}</span>
    </button>
  );
}
