import React from 'react';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOrder } from '../../../../hooks/useOrder';
import { formatMoney } from '../../../../utils/formatMoney';
import { useBalance } from '../../../../hooks/useBalance';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import FormGroup from '../FormGroup';
function FormAddedBalance() {
  const { fetchTransferOrderAddedAdminBalanceConsignable, setLoading } =
    useOrder();
  const { loading } = useBalance();
  const [value, setValue] = React.useState(false);
  const Validate = yup.object().shape({
    value: yup
      .string()
      .typeError('O campo deve receber um valor.')
      .required('Valor é obrigatório')
      .matches(
        /^(-?[0-9]+(\.[0-9]+)?)$/,
        'O valor deve conter apenas números e pontos.',
      ),
  });
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Validate),
  });
  function handleValue(data) {
    setValue(data.value);
  }
  async function fetchSubmit(data) {
    setLoading({ balance: true });

    try {
      const addedBalance = data.value;

      const res = await fetchTransferOrderAddedAdminBalanceConsignable(
        addedBalance,
      );

      if (res) {
        reset();
        setValue(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(!value ? handleValue : fetchSubmit)}
    >
      <h1>Adicionar Saldo Gestão</h1>
      <p>
        Esse valor será adicionado no saldo de antecipação da empresa e não será
        identificado no extrato do cliente.
      </p>
      {value && <h1>{formatMoney(value)}</h1>}
      {!value && (
        <FormGroup
          name="value"
          label="Insira o valor"
          type="text"
          placeholder={'R$ 0,00'}
          register={register}
          errors={errors}
          setValue={setFormValue}
        />
      )}
      {!loading && value && (
        <div className={styles.wrapper_buttons_}>
          <button className={styles.button} type="submit" disabled={loading}>
            <FaCheck size={'0.8rem'} color={'var(--white)'} />
            {'Confirmar'}
          </button>
          <button
            className={styles.button}
            type="button"
            disabled={loading}
            onClick={() => setValue(false)}
          >
            <IoMdClose size={'1.2rem'} color={'var(--white)'} />
            {'Cancelar'}
          </button>
        </div>
      )}
      {!value && (
        <button className={styles.button} type="submit" disabled={loading}>
          <FaPlus size={'1rem'} color={'var(--white)'} />
          {'Adicionar'}
        </button>
      )}
    </form>
  );
}

export { FormAddedBalance };
