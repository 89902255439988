import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import styles from './styles.module.scss';
import { IconDownload } from '../../../../components/Details/Icons';
import { formatDate } from '../../../../utils/formatDate';
import { formatMoney } from '../../../../utils/formatMoney';

export const ExportExcelConsignable = ({ list, isFetching }) => {
  const exportToExcel = () => {
    const data = list?.map((item) => ({
      'Chave Antecipação': item?.id,
      Nome: item?.name,
      CPF: item?.document,
      'Data de Criação': item?.date ? item?.date : '--',
      'Data de Liberação': item?.valid_since ? item?.valid_since : '--',
      'Data de Pagamento': item?.valid_until ? item?.valid_until : '--',
      'Data de Liquidação':
        item?.useds.length > 0
          ? formatDate(
              item.useds[item.useds.length - 1]?.billed_at,
              'dd/MM/yyyy',
            )
          : '---',
      'Valor Pago':
        item?.useds.length > 0
          ? formatMoney(
              item?.useds.reduce((acc, use) => {
                return acc + (use?.billed_amount || 0);
              }, 0),
            )
          : '---',
      'Valor Liberado': item?.amount,
      'Valor Utilizado': item?.used_amount ? item?.used_amount : 0,
      'Valor Efetivo': item?.is_consignable,
      Custo: item?.is_consignable_fee,
      Taxa:
        item?.is_consignable_fee > 0
          ? `${((item?.is_consignable_fee / item?.used_amount) * 100)
              .toFixed(0)
              .toString()}%`
          : '---',
      'Chave do Arquivo': item?.order,
      'Descrição do Arquivo': item?.description ?? '---',
      'Nome do  Arquivo': item?.file_name,
      'Id de Liberação': item?.id_consignable,
      Status: item?.statusRef?.text,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blobData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    FileSaver.saveAs(blobData, 'Relatório_antecipação.xlsx');
  };

  return (
    <button
      className={styles.button}
      onClick={exportToExcel}
      name="export"
      disabled={isFetching}
    >
      <IconDownload color="var(--primary)" size={'2rem'} />
    </button>
  );
};
