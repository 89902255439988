import React from 'react';
import * as FileSaver from 'file-saver';
import styles from './styles.module.scss';
import * as XLSX from 'xlsx';
import { formatDocument } from '../../../../utils/formatDocument';
import { IconDownload } from '../../../../components/Details/Icons';

const ExportExcelPayment = ({ collaborators, title }) => {
  const header =
    'Mantenha sempre o cabeçalho original da planilha e esta linha, mantendo os titulos e a ordem dos campos';
  const headerTemplate = [
    'Nome ou Razão Social',
    'CPF ou CNPJ',
    'Email (opcional)',
    'Descrição ',
    'Banco ',
    'Agência ',
    'Dígito da agência',
    'Conta ',
    'Dígito da conta',
    'Tipo de Conta (Corrente = CC  ou Poupança = PP)',
    'Valor ',
    'ID integração (opcional)',
    'Data de agendamento (opcional)',
    'Chave pix',
  ];
  const data = collaborators
    .filter((item) => item.status.id === 33)
    .map((items) => {
      return [
        items?.name,
        formatDocument(items?.document),
        items?.email,
        items?.description,
        'OX',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ];
    });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  async function exportToCSV(csvData, fileName) {
    const wh = XLSX.utils.book_new();

    wh.Props = {
      Title: 'Lista de pagamento interna de colaboradores',
      Subject: 'Lista de pagamento',
      Author: 'Ox Pay',
      CreatedDate: new Date(),
    };

    wh.SheetNames.push('erros');

    const dados = [[header], headerTemplate, ...csvData];

    const ws = XLSX.utils.aoa_to_sheet(dados);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }
  return (
    <button
      className={styles.button}
      onClick={(e) =>
        exportToCSV(data, 'lista-de-pagamento-interna-de-colaboradores')
      }
      name="export"
    >
      <IconDownload color="var(--gray-400)" size={'1.5rem'} />
      {title}
    </button>
  );
};

export { ExportExcelPayment };
