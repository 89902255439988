/**
 * Converte uma string no formato de moeda para um número.
 * @param {string} currencyString - A string no formato de moeda a ser convertida.
 * @returns {number} O número convertido.
 */
export function parseCurrencyToNumber(currencyString) {
  // Remove any dots and replace commas with dots for correct parsing
  const numericString = currencyString.replace(/\./g, '').replace(/,/g, '.');
  // Parse the string into a floating-point number
  const numericValue = parseFloat(numericString);
  return numericValue;
}
