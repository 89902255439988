import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
export function useFeedback() {
  const { feedBackModal, setFeedBackModal, openFeedModal, setOpenFeedModal } =
    useContext(StoreContext);

  function handleOpenModal(modal) {
    if (modal) {
      setOpenFeedModal(modal);
    }
  }
  return {
    feedBackModal,
    setFeedBackModal,
    openFeedModal,
    setOpenFeedModal,
    handleOpenModal,
  };
}
