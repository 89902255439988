import { BlobProvider } from '@react-pdf/renderer';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconDownload } from '../../../../components/Details/Icons';
import { getToken } from '../../../../Helpers/cookies';
import { useNotification } from '../../../../hooks/useNotifications';
import { getApiUrl } from '../../../../services/api';
import {
  cardSchema,
  receiveSchema,
  slipSchema,
  transferSchema,
} from '../Proof/proof-schema';
import ProofTemplate from '../ProofTemplate';
import ShareButton from '../Share';
import { processData } from '../Utils';
import styles from './styles.module.scss';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function ProofHandle() {
  const [loading, setLoading] = useState(true);
  const [movement, setMovement] = useState();
  const [proof, setProof] = useState();
  const params = useParams();
  const [errorProof, setErrorProof] = useState(null);
  const { addNotification } = useNotification();
  const token = getToken();

  useEffect(() => {
    const fetchData = async () => {
      await getMovementKey();
    };

    fetchData();

    return;
    // eslint-disable-next-line
  }, []);

  async function getMovementKey() {
    try {
      setLoading(true);

      const { data } = await axios.get(
        getApiUrl(`/Movement/${params.movementKey}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setMovement(data.value.movement);

      const listData = await processData(
        data.value.order.type === 4 || data.value.order.type === 6
          ? receiveSchema
          : data.value.movement.method === '13'
          ? cardSchema
          : data.value.movement.method === '08'
          ? slipSchema
          : transferSchema,
        data.value,
        token,
      );

      setProof(listData);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        if (
          error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 405 ||
          error.response.status === 409
        ) {
          setErrorProof({
            status: 1,
            message: 'Sem autorização para essa operação.',
          });
        } else {
          setErrorProof({
            status: 0,
            message: 'Desculpe, algo deu errado. Tente novamente, mais tarde.',
          });
        }
      } else {
        setErrorProof({
          status: 0,
          message: 'Desculpe, algo deu errado. Verifique sua conexão.',
        });
      }

      setLoading(false);
      if (params.platform !== 'mobile') {
        addNotification(<>Não foi possível gerar o comprovante</>, 2, '', true);
      }
    }
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
          backgroundColor: '#fff',
        }}
      >
        <img src="/search-file.gif" alt="Search File" width={120} />
        <p style={{ marginTop: 20 }}>
          Processando as informações, por favor aguarde...
        </p>
      </div>
    );
  }

  if (errorProof) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#777',
          backgroundColor: '#fff',
        }}
      >
        <img
          src={errorProof?.status === 0 ? '/find-values.gif' : '/lock-file.gif'}
          alt="Search File"
          width={120}
        />
        <p style={{ marginTop: 20 }}>{errorProof?.message}</p>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
      }}
    >
      {
        <BlobProvider
          width="100%"
          height="100%"
          document={
            <ProofTemplate
              platform={params.platform}
              proof={proof}
              movement={movement}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            if (loading) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    backgroundColor: '#fff',
                  }}
                >
                  <img src="/search-file.gif" alt="Search File" width={120} />
                  <p style={{ marginTop: 20 }}>
                    Processando as informações, por favor aguarde...
                  </p>
                </div>
              );
            }

            if (error) {
              return (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    padding: 20,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#777',
                  }}
                >
                  Desculpe, algo deu errado. Tente novamente, mais tarde.
                </div>
              );
            }

            return (
              <PdfViewer platform={params.platform} blob={blob} url={url} />
            );
          }}
        </BlobProvider>
      }
    </div>
  );
}

const PdfViewer = ({ platform, blob, url }) => {
  const containerRef = useRef(null);
  const history = useHistory();

  const renderPage = async (pdfDoc, pageNum) => {
    const page = await pdfDoc.getPage(pageNum);
    const scale = platform === 'mobile' ? 2 : 4;
    const size = platform === 'mobile' ? 24 : 44;
    const viewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    canvas.style.width = `${viewport.width / size}rem`;
    canvas.style.height = `${viewport.height / size}rem`;

    if (platform === 'mobile') {
      canvas.style.position = 'relative';
      canvas.style.overflowX = 'hidden';
      canvas.style.top = '-1rem';
      canvas.style.left = '50%';
      canvas.style.transform = 'translateX(-50%)';
    }
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    containerRef.current.appendChild(canvas);
  };

  const renderAllPages = async (pdfDoc) => {
    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      await renderPage(pdfDoc, pageNum);
    }
  };

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'comprovante.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const pdfDoc = await pdfjsLib.getDocument(url).promise;

        if (containerRef.current) {
          containerRef.current.innerHTML = '';
          await renderAllPages(pdfDoc);
        }
      } catch (err) {
        console.error('Erro ao carregar o PDF: ', err);
      }
    };

    loadPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <div className={styles.container}>
      <div className={styles.footerActions}>
        <div
          className={styles.actionsRow}
          style={
            platform === 'mobile'
              ? { visibility: 'hidden', position: 'absolute', top: '0' }
              : null
          }
        >
          <button className={styles.back} onClick={history.goBack}>
            {'<'}
          </button>
          <div style={{ display: 'flex', gap: 5 }}>
            {navigator.share ? (
              <ShareButton pdfImage={url} platform={platform} />
            ) : (
              ''
            )}
            <button
              className={styles.download}
              onClick={() => downloadFile(url)}
            >
              <IconDownload color="var(--black-800)" size={'1.5rem'} />
            </button>
          </div>
        </div>
      </div>
      <div ref={containerRef}></div>
    </div>
  );
};
