import axios from 'axios';
import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getApiUrl } from '../../../../services/api';
import { formatAccount } from '../../../../utils/formatAccount';
import { formatCardLastNumbers } from '../../../../utils/formatCard';
import {
  formatDocument,
  formatDocumentNumbers,
} from '../../../../utils/formatDocument';
import { formatMethodPayment } from '../../../../utils/formatMethodPayment';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatName, nameAbbreviate } from '../../../../utils/formatName';
import { formatStatus } from '../../../../utils/formatStatus';

export const transferSchema = {
  details: [
    {
      object: 'movement',
      field: 'amount',
      translate: 'Valor',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'method',
      translate: 'Tipo de transação',
      handle: (v) => formatMethodPayment(v),
    },
    {
      object: 'movement',
      field: 'pix_end_to_end_id',
      translate: 'E2E',
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
      handle: (v) => v,
    },
    {
      object: 'order',
      field: 'description',
      translate: 'Descrição',
    },
  ],
  destiny: [
    {
      object: 'movement',
      field: 'name',
      translate: 'Nome',
      handle: (v) => nameAbbreviate(v),
    },
    {
      object: 'movement',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'movement',
      field: ['bank_name', 'bank_code'],
      translate: 'Instituição',
      handle: (v) => {
        if (v.length > 9) return v;
        else return getBank(v);
      },
    },
    {
      field: 'pix_key',
      translate: 'Chave PIX',
      handle: (v) => {
        if (v.length >= 11 || v.length <= 14) {
          return formatDocument(v);
        }
        return v;
      },
    },
    {
      field: 'branch',
      translate: 'Agência',
    },
    {
      field: 'account',
      translate: 'Conta',
    },
    {
      field: 'account_type',
      translate: 'Tipo de conta',
      handle: (v) => formatAccount(v),
    },
  ],
  origin: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v);
      },
    },
  ],
};

export const slipSchema = {
  details: [
    {
      object: 'movement',
      field: 'amount',
      translate: 'Valor',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'method',
      translate: 'Tipo de transação',
      handle: (v) => formatMethodPayment(v),
    },
    {
      field: 'expiration_date',
      translate: 'Vencimento',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      field: 'fee_amount',
      translate: 'Acréscimo',
      handle: (v) => formatMoney(v),
    },
    {
      field: 'amount',
      translate: 'Valor total',
      handle: (v) => formatMoney(v),
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
    },
    {
      object: 'order',
      field: 'description',
      translate: 'Descrição',
    },
  ],
  destiny: [
    {
      object: 'movement',
      field: 'name',
      translate: 'Nome',
      handle: (v) => nameAbbreviate(v),
    },
    {
      object: 'movement',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'movement',
      field: ['bank_name', 'bank_code'],
      translate: 'Instituição',
      handle: (v) => {
        if (v.length > 9) return v;
        else return getBank(v);
      },
    },
    {
      field: 'branch',
      translate: 'Agência',
    },
    {
      field: 'account',
      translate: 'Conta',
    },
    {
      field: 'account_type',
      translate: 'Tipo de conta',
      handle: (v) => formatAccount(v),
    },
  ],
  origin: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v, 'fullName');
      },
    },
  ],
};

export const receiveSchema = {
  details: [
    {
      object: 'movement',
      field: 'amount',
      translate: 'Valor',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'method',
      translate: 'Tipo de transação',
      handle: (v) => formatMethodPayment(v),
    },
    {
      object: 'movement',
      field: 'pix_end_to_end_id',
      translate: 'E2E',
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
      handle: (v) => v,
    },
    {
      object: 'order',
      field: 'description',
      translate: 'Descrição',
    },
  ],
  origin: [
    {
      object: 'movement',
      field: 'name',
      translate: 'Nome',
      handle: (v) => nameAbbreviate(v),
    },
    {
      object: 'movement',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'movement',
      field: ['bank_name', 'bank_code'],
      translate: 'Instituição',
      handle: (v) => {
        if (v.length > 9) return v;
        else return getBank(v);
      },
    },
    {
      field: 'pix_key',
      translate: 'Chave PIX',
      handle: (v) => {
        if (v.length >= 11 || v.length <= 14) {
          return formatDocument(v);
        }
        return v;
      },
    },
    {
      field: 'branch',
      translate: 'Agência',
    },
    {
      field: 'account',
      translate: 'Conta',
    },
    {
      field: 'account_type',
      translate: 'Tipo de conta',
      handle: (v) => formatAccount(v),
    },
  ],
  destiny: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v);
      },
    },
  ],
};

export const cardSchema = {
  details: [
    {
      object: 'movement',
      field: 'status',
      translate: 'Status',
      handle: (v) => {
        return formatStatus(v).text;
      },
    },
    {
      object: 'card',
      field: ['last4_digits', 'card_id'],
      translate: 'Cartão',
      handle: (v) => {
        return v.length > 1 ? formatCardLastNumbers(v) : v;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
  ],
};

async function getBank(bank_code) {
  try {
    const bankName = handleInstitutionName(bank_code?.bank_code || bank_code);

    if (!!bankName) return bankName;
    else {
      const { data } = await axios.get(`https://brasilapi.com.br/api/banks/v1`);

      let nameBank = data.find((bank) => {
        if (bank_code.length === 5 || bank_code?.bank_code?.length === 5) {
          return (
            bank.code === Number(bank_code) ||
            bank.code === Number(bank_code?.bank_code) ||
            bank.ispb?.slice(0, 5) === bank_code ||
            bank.ispb.slice(0, 5) === bank_code?.bank_code
          );
        } else {
          return (
            bank.code === Number(bank_code) ||
            bank.code === Number(bank_code?.bank_code) ||
            bank.ispb === bank_code ||
            bank.ispb === bank_code?.bank_code
          );
        }
      })?.['name'];

      return nameBank;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getPixSimulate(document, token) {
  try {
    const { name, bank_name, bank_isbp } = await axios
      .get(getApiUrl(`/Simulate/Pix/Key/${formatDocumentNumbers(document)}`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);

    return {
      name,
      bank_name,
      bank_code: bank_isbp,
    };
  } catch (error) {
    console.error(error);
    throw new error(error);
  }
}

export async function getBankSlipSimulate(barcode, token) {
  try {
    const { barcode_details } = await axios
      .get(getApiUrl(`/Simulate/bankslip/${barcode}`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);

    return {
      bank_name: barcode_details.bank_name,
    };
  } catch (error) {
    console.error(error);
    throw new error(error);
  }
}

function handleName(bank_code) {
  const name = bank_code?.bank_code || bank_code;

  if (bank_code?.company_name) return formatName(bank_code.company_name);

  switch (name) {
    case '31680151':
      return 'Swap';
    case 'OX':
      return 'Oxpay';

    default:
      return formatName(bank_code.company_name);
  }
}

function handleInstitutionName(code) {
  switch (code) {
    case '31680151':
      return 'Swap Meios de Pagamentos S.A';
    case 'OX':
    case 'CONSI':
    case 'CONSIGN':
      return 'Ox Serviços de tecnologia LTDA';

    default:
      return null;
  }
}

export function proofHandleTitle(type) {
  switch (type) {
    case '08':
      return 'Pagamento';
    case '11':
      return 'Estorno';
    default:
      return 'Transferência';
  }
}
