import React from 'react';
import { useUser } from '../../../../hooks/useUser';
import styles from './styles.module.scss';

/**
 * Um componente React que representa o cabeçalho da tabela de colaboradores.
 * Ele inclui colunas para Nome, Documento, Telefone, Data e Status.
 *
 * @returns {JSX.Element} O cabeçalho da tabela de colaboradores.
 */
const HeaderCollaborator = ({ selectAll, firstClick }) => {
  const { user } = useUser();
  const permissionTags = ![40].every((item) => user?.permission.includes(item));

  return (
    <ul className={styles.container}>
      <li className={styles.name}>
        <span className={styles.input}>
          <input
            type="checkbox"
            checked={firstClick === false}
            onChange={selectAll}
            disabled={permissionTags}
          />
        </span>
      </li>
      <li className={styles.name}>
        <span>Nome</span>
      </li>
      <li className={styles.type}>
        <span>Tipo</span>
      </li>
      <li className={styles.document}>
        <span>Documento</span>
      </li>
      <li className={styles.phone}>
        <span>Telefone</span>
      </li>
      <li className={styles.date}>
        <span>Tag</span>
      </li>
      <li className={styles.date}>
        <span>Data</span>
      </li>

      <li className={styles.status}>
        <span>Status</span>
      </li>
      <li className={styles.status}>
        <span></span>
      </li>
    </ul>
  );
};

export { HeaderCollaborator };
