import React from 'react';

function IconLogoOxPay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.5625rem"
      height="6.5625rem"
      fill="none"
      viewBox="0 0 105 105"
    >
      <rect
        width="104"
        height="104"
        x="0.174"
        y="0.875"
        fill="#ECF8FF"
        rx="52"
      ></rect>
      <path
        fill="#2D4C9B"
        d="M58.367 40.744a4.325 4.325 0 013.079-1.277 4.32 4.32 0 013.078 1.277 4.323 4.323 0 011.274 3.077 4.326 4.326 0 01-1.274 3.08l-9.34 9.342 3.153 3.154 9.34-9.342a8.855 8.855 0 002.287-3.95 8.864 8.864 0 000-4.565 8.802 8.802 0 00-2.282-3.954 8.866 8.866 0 00-3.95-2.287 8.89 8.89 0 00-4.572 0 8.849 8.849 0 00-3.95 2.287l-.026.027 3.157 3.158.026-.027z"
      ></path>
      <path
        fill="#2D4C9B"
        d="M36.59 50.055l.027.027 3.153-3.154-.027-.027a4.32 4.32 0 01-1.274-3.08c0-1.163.452-2.255 1.274-3.077a4.326 4.326 0 013.078-1.277c1.162 0 2.256.452 3.079 1.277l9.34 9.341 3.157-3.157-9.34-9.342a8.861 8.861 0 00-3.949-2.287 8.891 8.891 0 00-4.572 0 8.86 8.86 0 00-3.95 2.287 8.802 8.802 0 00-2.282 3.954 8.869 8.869 0 000 4.566 8.863 8.863 0 002.287 3.95l-.001-.001zM69.966 60.162a8.851 8.851 0 00-2.287-3.95l-.027-.027-3.157 3.158.027.027a4.36 4.36 0 010 6.157 4.358 4.358 0 01-6.157 0L49.03 56.19l-3.153 3.154 9.336 9.337a8.86 8.86 0 003.95 2.287 8.9 8.9 0 004.565 0 8.8 8.8 0 003.952-2.282 8.857 8.857 0 002.287-3.95c.4-1.496.4-3.078 0-4.574z"
      ></path>
      <path
        fill="#2D4C9B"
        d="M45.903 65.527a4.358 4.358 0 01-6.157 0 4.36 4.36 0 010-6.157l9.336-9.337-3.157-3.159-9.337 9.338a8.861 8.861 0 00-2.287 3.95 8.847 8.847 0 000 4.574 8.852 8.852 0 002.287 3.95 8.8 8.8 0 006.235 2.58c.768 0 1.536-.1 2.282-.298a8.85 8.85 0 003.95-2.287l.027-.027-3.154-3.154-.027.027h.002z"
      ></path>
    </svg>
  );
}

function IconPix() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.5rem"
      height="6.5rem"
      fill="none"
      viewBox="0 0 104 104"
    >
      <rect width="104" height="104" fill="#ECF8FF" rx="20"></rect>
      <path
        fill="#31519A"
        fillRule="evenodd"
        d="M40.246 63.13a6.13 6.13 0 004.363-1.807l6.299-6.3c.442-.443 1.213-.441 1.655 0l6.323 6.324a6.133 6.133 0 004.363 1.806h1.24l-7.978 7.978a6.378 6.378 0 01-9.022 0l-8.001-8.001h.758zm23.003-22.283a6.13 6.13 0 00-4.363 1.807l-6.322 6.324c-.456.454-1.199.457-1.655-.002l-6.3-6.299a6.128 6.128 0 00-4.363-1.807h-.759l8.001-8a6.38 6.38 0 019.024 0l7.978 7.977H63.25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#31519A"
        fillRule="evenodd"
        d="M32.87 47.488l4.765-4.766h2.61c1.137 0 2.25.46 3.054 1.265l6.299 6.299c.59.59 1.363.884 2.138.884.774 0 1.549-.294 2.137-.883l6.323-6.323a4.346 4.346 0 013.053-1.264h3.093l4.788 4.788a6.38 6.38 0 010 9.024l-4.788 4.789h-3.093c-1.137 0-2.25-.46-3.053-1.265l-6.322-6.323c-1.143-1.141-3.135-1.141-4.276.001l-6.3 6.298a4.348 4.348 0 01-3.053 1.266h-2.61l-4.766-4.766a6.38 6.38 0 010-9.024"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function IconBox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.5625rem"
      height="6.5625rem"
      fill="none"
      viewBox="0 0 105 105"
    >
      <rect
        width="104"
        height="104"
        x="0.174"
        y="0.875"
        fill="#ECF8FF"
        rx="52"
      ></rect>
      <path
        fill="#2D4C9B"
        d="M68.239 58.552l4.502 2.252a.784.784 0 010 1.402l-19.866 9.936a1.57 1.57 0 01-1.402 0l-19.866-9.936a.785.785 0 010-1.402l4.505-2.252 13.957 6.98a4.715 4.715 0 004.21 0l13.957-6.982.003.002zM51.473 32.04a1.57 1.57 0 011.402 0l19.866 9.933a.788.788 0 010 1.404l-19.866 9.93a1.57 1.57 0 01-1.402 0l-19.866-9.93a.784.784 0 010-1.404l19.866-9.933z"
      ></path>
      <path
        fill="#2D4C9B"
        d="M68.24 49.137l4.501 2.252a.784.784 0 010 1.402l-19.866 9.933a1.57 1.57 0 01-1.402 0L31.607 52.79a.785.785 0 010-1.402l4.505-2.252 13.957 6.98a4.715 4.715 0 004.21 0l13.96-6.98z"
      ></path>
    </svg>
  );
}

function IconTransfer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.5625rem"
      height="6.5625rem"
      fill="none"
      viewBox="0 0 105 105"
    >
      <rect
        width="104"
        height="104"
        x="0.174"
        y="0.875"
        fill="#ECF8FF"
        rx="52"
      ></rect>
      <path
        fill="#31519A"
        d="M54.011 60.312h-3.29c-2.327 0-4.235-1.96-4.235-4.375 0-.718.595-1.313 1.313-1.313.717 0 1.312.595 1.312 1.313 0 .962.718 1.75 1.61 1.75h3.29c.683 0 1.225-.613 1.225-1.365 0-.945-.262-1.085-.857-1.295l-5.268-1.838c-1.12-.402-2.625-1.207-2.625-3.78 0-2.187 1.733-3.99 3.85-3.99h3.29c2.328 0 4.235 1.96 4.235 4.375 0 .718-.595 1.313-1.312 1.313a1.322 1.322 0 01-1.313-1.313c0-.962-.717-1.75-1.61-1.75h-3.29c-.682 0-1.225.613-1.225 1.365 0 .945.263 1.085.858 1.295l5.267 1.838c1.12.402 2.625 1.207 2.625 3.78 0 2.205-1.732 3.99-3.85 3.99z"
      ></path>
      <path
        fill="#31519A"
        d="M52.174 62.062a1.322 1.322 0 01-1.313-1.313V45c0-.717.595-1.312 1.313-1.312.717 0 1.312.595 1.312 1.313v15.75c0 .717-.595 1.312-1.312 1.312z"
      ></path>
      <path
        fill="#31519A"
        d="M52.174 71.687c-10.378 0-18.813-8.435-18.813-18.813 0-10.377 8.435-18.812 18.813-18.812.717 0 1.312.595 1.312 1.313 0 .717-.595 1.312-1.312 1.312-8.925 0-16.188 7.263-16.188 16.188 0 8.925 7.263 16.187 16.188 16.187 8.925 0 16.187-7.262 16.187-16.188 0-.717.595-1.312 1.313-1.312.717 0 1.312.595 1.312 1.313 0 10.377-8.435 18.812-18.812 18.812zM69.674 43.687a1.322 1.322 0 01-1.313-1.313v-5.687h-5.687a1.322 1.322 0 01-1.313-1.313c0-.717.595-1.312 1.313-1.312h7c.717 0 1.312.595 1.312 1.313v7c0 .717-.595 1.312-1.312 1.312z"
      ></path>
      <path
        fill="#31519A"
        d="M60.923 45.437c-.332 0-.665-.122-.927-.385a1.32 1.32 0 010-1.855l8.75-8.75a1.32 1.32 0 011.855 0 1.32 1.32 0 010 1.855l-8.75 8.75a1.298 1.298 0 01-.928.385z"
      ></path>
    </svg>
  );
}

export { IconLogoOxPay, IconPix, IconBox, IconTransfer };
