import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { formatMoney } from '../../../utils/formatMoney';

export function exportEcxel({ orders }) {
  console.log('exportEcxel');
  const data = orders.map((items) => {
    return {
      Chave: items?.key,
      Descrição: items?.description,
      Arquivo: items?.file_name ?? '--',
      Valor: formatMoney(items?.total_amount),
      Status: items?.status?.text,
      Data: items?.created_at,
      'Total de Pagamentos': items?.total_moviments,
      Sucesso: items.approved ?? '--',
      'Montante Sucesso': formatMoney(items?.approvedAmount) ?? '--',
      Erros: items?.erros ?? '--',
      'Montante Erros': items?.errosAmount
        ? formatMoney(items?.errosAmount)
        : '--',
    };
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportToCSV(data, 'relatorio_lotes_de_pagamentos');
}
