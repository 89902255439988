import { formatOrderType } from './formatOrderType';
import { formatDate } from './formatDate';
import { formatStatusOrder } from './formatStatus';
export const formatOrder = (item) => {
  const consignment = !item?.movements?.find(
    (itemRef) => itemRef?.method === '10',
  );
  const {
    approved,
    erros,
    process,
    approvedAmount,
    errosAmount,
    processAmount,
    awaitingBalance,
    awaitingBalanceAmount,
  } = item.movements.reduce(
    (acc, cur) => {
      const amount = Number(cur?.amount) || 0;

      if (cur?.status === '06') {
        acc.approved++;
        acc.approvedAmount += amount;
      } else if (cur?.status === '07') {
        acc.erros++;
        acc.errosAmount += amount;
      } else if (cur?.status === '08') {
        acc.awaitingBalance++;
        acc.awaitingBalanceAmount += amount;
      } else if (!['06', '07', '01', '05', '08'].includes(cur?.status)) {
        acc.process++;
        acc.processAmount += amount;
      }
      return acc;
    },
    {
      approved: 0,
      erros: 0,
      process: 0,
      approvedAmount: 0,
      errosAmount: 0,
      processAmount: 0,
      awaitingBalance: 0,
      awaitingBalanceAmount: 0,
    },
  );

  return {
    key: item.key,
    created_at: formatDate(item.created_at, 'dd/MM/yyyy - HH:mm:ss'),
    date: item.created_at.slice(0, 10),
    status: formatStatusOrder(item.status),
    total_amount: item.total_amount ?? 0,
    total_moviments: item.total_moviments,
    description: !!item?.description ? item?.description : 'Pagamento',
    type: formatOrderType(item.type),
    company_id: item.company_id,
    user_id: item.user_id,
    cancelled_at: item.cancelled_at,
    file_name: item?.file_name ?? '---',
    approved,
    approvedAmount,
    errosAmount,
    processAmount,
    erros,
    process,
    awaitingBalance,
    awaitingBalanceAmount,
    consignment,
  };
};
