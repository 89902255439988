import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'RobertsonAlternate',
  src: '/robertson-alternate.ttf',
});
Font.register({
  family: 'RobotoBold',
  src: '/roboto-bold.ttf',
});

export const style_contract = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 11,
    fontWeight: 'normal',
  },
  section: {
    margin: 1,
    padding: 1,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'RobotoBold',
    fontSize: 14,
    marginBottom: 10,
  },
  subTitle: {
    fontFamily: 'RobotoBold',
    textAlign: 'center',
    fontSize: 12,
  },
  text: {
    marginBottom: 2,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  textCenter: {
    textAlign: 'center',
  },
  textBold: {
    fontFamily: 'RobotoBold',
    marginBottom: 0,
  },
});

export const style_signatures = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 16,
    alignItems: 'center',
  },
  section: {
    margin: 1,
    padding: 1,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 50,
    fontSize: 9,
  },
  headerAuth: {},
  textAuth: {
    alignSelf: 'flex-end',
    color: '#888',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: 50,
    width: '80%',
    alignSelf: 'center',
    marginTop: 80,
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: '#aab232',
  },
  text: {
    marginBottom: 5,
    textAlign: 'center',
    lineHeight: 1.4,
    fontSize: 12,
  },
  textBold: {
    fontFamily: 'RobotoBold',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.4,
    fontSize: 12,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 50,
    fontFamily: 'RobotoBold',
  },
  signature: {
    textAlign: 'center',
    fontFamily: 'RobertsonAlternate',
    fontSize: 32,
    marginBottom: -25,
    color: '#051532',
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 50,
  },
  footerContent: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  footerText: {
    fontSize: 9,
    color: '#888',
    flexWrap: 'wrap',
  },
  logoOxpay: {
    width: 120,
  },
  iconOx: {
    width: 30,
  },
});

export const style_history = StyleSheet.create({
  history: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 11,
  },
  section: {
    margin: 1,
    padding: 1,
  },
  header: {
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    borderBottom: 2,
    borderBottomColor: '#999',
    paddingLeft: 10,
    paddingBottom: 6,
    fontFamily: 'RobotoBold',
    width: '100%',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  text: {
    fontSize: 11,
    lineHeight: 1.4,
    marginBottom: 5,
    paddingVertical: 5,
    paddingHorizontal: 8,
  },
  textBold: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.4,
    fontFamily: 'RobotoBold',
    textAlign: 'center',
  },
  infoDate: {
    lineHeight: 1.4,
    paddingVertical: 5,
    paddingHorizontal: 8,
  },
  date: {
    justifyContent: 'flex-end',
    fontFamily: 'RobotoBold',
  },
  hour: {
    alignSelf: 'flex-end',
    fontSize: 10,
  },
  iconContainer: {
    width: 30,
    paddingVertical: 5,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  info: {
    flexDirection: 'row',
    width: '80%',
  },
  infoTextBold: {
    fontFamily: 'RobotoBold',
  },
});

export const style_tables = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderColor: '#bfbfbf',
    marginVertical: 14,
  },
  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    flexWrap: 'nowrap',
    fontSize: 6,
  },
  tableColHeader: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    backgroundColor: '#d3d3d3',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 1,
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    paddingVertical: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableRowSeparate: {
    padding: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableColSeparate: {
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderColor: '#bfbfbf',
  },
});
