import * as yup from 'yup';

export const ValidateFormCustomer = yup.object().shape({
  password: yup
    .string()
    .typeError('O campo deve receber a nova senha.')
    .required('Nova senha é obrigatório.')
    .max(6, 'A nova senha deve ter no máximo 6 caracteres.'),
  password_confirm: yup
    .string()
    .equals([yup.ref('password')], 'As senhas devem ser iguais.')
    .typeError('O campo deve receber a confirmação da senha nova.')
    .required('Confirmação de senha é obrigatório')
    .max(6, 'A nova senha deve ter no máximo 6 caracteres.'),
  password_old: yup
    .string()
    .typeError('O campo deve receber a senha atual.')
    .required('Senha antiga é obrigatório.'),
});
