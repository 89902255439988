import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';

/**
  Hook para manipular dos ranger de datas
  @param {object} dateExtract - data de extrato
  @param {object} datePayment - data de pagamento
  @param {object} dateAnticipation - data de antecipação
  @param {function} setDateExtract - função para setar a data de extrato
  @param {function} setDatePayment - função para setar a data de pagamento
  @param {function} setDateAnticipation - função para setar a data de antecipação
**/
export function useDate() {
  const {
    dateExtract,
    setDateExtract,
    datePayment,
    setDatePayment,
    dateAnticipation,
    setDateAnticipation,
    dateRanger,
    setDateRanger,
    dateRangerExtract,
    setDateRangerExtract,
    dateRangerBankSlip,
    setRangerBankSlip,
  } = useContext(StoreContext);

  return {
    dateExtract,
    setDateExtract,
    datePayment,
    setDatePayment,
    dateAnticipation,
    setDateAnticipation,
    dateRanger,
    setDateRanger,
    dateRangerExtract,
    setDateRangerExtract,
    dateRangerBankSlip,
    setRangerBankSlip,
  };
}
