import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiGet, apiPost } from '../services/api';
import { formatMovements } from '../utils/formatMovements';

import { useUser } from './useUser';

import { fireStoreAdd } from '../utils/firestoreActions';
import { useBalance } from './useBalance';
import { useNotification } from './useNotifications';
import { formatSingleMovement } from '../utils/formatExtract';

import { useQueryClient } from 'react-query';

export function useMovement() {
  const { error, setError, loading, setLoading, user } =
    useContext(StoreContext);

  const { addNotification } = useNotification();
  const { fetchGetBalance } = useBalance();
  const queryClient = useQueryClient();

  const { userLogout } = useUser();

  async function fetchMovement(movementKey, companies) {
    try {
      const { data } = await apiGet(`/Movement/${movementKey}`);
      const formattedData = await formatSingleMovement(data?.value?.movement, data?.value?.order, companies)
      return formattedData;
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Erro interno de sistema, tente novamente.' });
      }
      setError({ order: 'Comprovante inexistente.' });
      return {
        error
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchAnalyzeMovement() {
    try {
      const { data } = await apiGet(`/Movement/analyze?count=1000`);

      const { orders, movements, customers, companies } = await data;

      const formatPaymentAnalyze = formatMovements(movements)
        .map((item) => {
          return {
            ...item,
            order: orders.find((order) => item.order_key === order.key),
          };
        })
        .map((item) => {
          return {
            ...item,
            company: companies.find(
              (company) => item.order.company_id === company.company_id,
            ),
          };
        })
        .map((item) => {
          return {
            ...item,
            customer: customers.find(
              (customer) => item.order.user_id === customer.customer_id,
            ),
          };
        });

      return {
        movements: formatPaymentAnalyze,
      };
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
      setError({ order: 'Error Inesperado!' });
    } finally {
      setLoading(false);
    }
  }

  async function fetchMovementKeyCancel(body) {
    try {
      setLoading({ movement: true });
      setError(false);
      await apiPost(`/Movement/analyze/reprove/${body}`);

      await queryClient.invalidateQueries([`AnalyzeMovement`]);

      await fireStoreAdd(
        {
          action: 'REPROVE_TRANSFER_ANALYZE',
          date: new Date(),
        },
        user,
      );

      addNotification(
        <>Pagamento foi cancelado com sucesso.</>,
        3,
        'Pagamento',
        true,
      );
    } catch (error) {
      setError({ order: 'Não foi possível Cancelar!' });
    } finally {
      setLoading(false);
    }
  }

  async function fetchMovementKeyApprove(body) {
    try {
      setLoading({ movement: true });

      setError(false);

      const { status } = await apiPost(`/Movement/analyze/approve/${body}`);

      if (status === 200) {
        addNotification(
          <>Pagamento foi aprovado com sucesso.</>,
          3,
          'Pagamento',
          true,
        );
      }
      await queryClient.invalidateQueries([`AnalyzeMovement`]);

      await fetchGetBalance();

      await fireStoreAdd(
        {
          action: 'APROVE_TRANSFER_ANALYZE',
          date: new Date(),
        },
        user,
      );
    } catch (error) {
      setError({ order: 'Não foi possível aprovar!' });
    } finally {
      setLoading(false);
    }
    return true;
  }

  return {
    setLoading,
    loading,
    error,

    fetchMovement,
    
    fetchMovementKeyCancel,

    fetchMovementKeyApprove,

    fetchAnalyzeMovement,
  };
}
