import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'RobotoBold',
  src: '/roboto-bold.ttf',
});

export const style_proof = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 11,
    fontWeight: 'normal',
    alignItems: 'center',
    backgroundColor: '#f7f7fa',
  },
  proof: {
    backgroundColor: 'white',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#dfdfdf',
    borderRadius: 40,
    paddingVertical: 40,
    paddingHorizontal: 14,
    justifyContent: 'center',
    gap: 10,
    flexDirection: 'column',
  },
  iconOx: {
    width: 110,
    minHeight: 26,
    alignSelf: 'center',
    marginBottom: 20,
  },
  iconCardSuccess: {
    width: 48,
    minHeight: 48,
    alignSelf: 'center',
    backgroundColor: '#E4FFE8',
    borderRadius: '50%',
    padding: 12,
  },
  iconCardFailed: {
    width: 48,
    minHeight: 48,
    alignSelf: 'center',
    backgroundColor: '#FFE4E4',
    borderRadius: '50%',
    padding: 12,
  },
  iconSuccess: {
    width: 58,
    minHeight: 58,
    alignSelf: 'center',
  },
  iconCardMaster: {
    width: 12,
    height: 12,
  },
  statusBlue: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#1761EB',
  },
  statusGreen: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#17EB76',
  },
  statusRed: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#EB171B',
  },
  headerTitle: {
    marginVertical: 5,
    flexDirection: 'column',
    gap: 5,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'RobotoBold',
    fontSize: 24,
    color: '#001A5D',
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 11,
    color: '#10B981',
  },
  subTitleBlue: {
    textAlign: 'center',
    color: '#001A5D',
    marginVertical: 20,
  },
  text: {
    marginBottom: 5,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  textCenter: {
    textAlign: 'center',
  },
  rowContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 6,
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
    marginVertical: 6,
    textAlign: 'justify',
    gap: 6,
  },
  label: {
    fontSize: 11,
    color: '#8E8E93',
  },
  value: {
    fontFamily: 'RobotoBold',
    fontSize: 11,
    color: '#242424',
    fontWeight: 'bold',
  },
  detailsSection: {
    marginTop: 14,
  },
  section: {},
  sectionTitle: {
    fontSize: 18,
    color: '#001A5D',
    marginBottom: 14,
    marginTop: 10,
  },
  separator: {
    borderTopWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#ccc',
    marginVertical: 14,
  },
  footerText: {
    width: '80%',
    fontSize: 12,
    color: '#8E8E93',
    textAlign: 'center',
    marginTop: 10,
    alignSelf: 'center',
  },
});
