import { useEffect, useState } from 'react';
import { formatMoney } from '../../../../utils/formatMoney';
import { IconMoneyPayment } from '../animation/IconMoneyPayment';

import styles from './styles.module.scss';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
export function CardAmountMoney({ data, loading, title }) {
  const [currentValue, setCurrentValue] = useState(0);
  const duration = 600; // Duração da animação em milissegundos

  const total_amount_ox = data.reduce((acc, item) => {
    const movementsTotal = item.movements.reduce((movAcc, movement) => {
      if (movement?.bank_code === 'OX' && movement?.status?.id === 6) {
        return movAcc + 1;
      }
      return movAcc;
    }, 0);
    return acc + movementsTotal;
  }, 0);

  useEffect(() => {
    const totalAmount = data.reduce((acc, item) => {
      const movementsTotal = item.movements.reduce((movAcc, movement) => {
        if (movement?.status?.id === 6) {
          return movAcc + movement.amount;
        }
        return movAcc;
      }, 0);
      return acc + movementsTotal;
    }, 0);

    const decimalNumber = totalAmount.toFixed(2);
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 0); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * totalAmount);

      setCurrentValue(nextValue + decimalNumber);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ul>
          <li className={styles.clients}>{title} </li>
        </ul>
        <div className={styles.wrapper_balance}>
          {data?.length >= 1 ? (
            <div className={styles.balance}>
              {loading ? (
                <LoadingBall />
              ) : currentValue >= 0 && typeof totalAmount !== 'boolean' ? (
                formatMoney(currentValue)
              ) : (
                typeof totalAmount === 'boolean' && <span> - - - </span>
              )}
            </div>
          ) : (
            <div className={styles.balance_}>{formatMoney(0)}</div>
          )}
        </div>
        <div className={styles.name}>
          <div className={styles.wrapper_footer}>
            {!loading && <strong>{total_amount_ox}</strong>}
            <span>Pagamentos Ox Pay</span>
          </div>
        </div>
      </div>
      <div className={styles.icon}>
        <IconMoneyPayment />
      </div>
    </div>
  );
}
