import * as yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';

export const Validate = yup.object().shape({
  document: yup
    .string()
    .typeError('O campo deve receber um Documento Valido.')
    .test('is-key', 'Documento não é valido', function (value) {
      return validateCPF(value) || validateCNPJ(value);
    })
    .required('Chave é obrigatória'),
  description: yup.string().required('Descrição é obrigatória'),
  bank_code: yup
    .string()
    .typeError('O campo deve receber um Banco Valido.')
    .test('is-bank-code', 'Mensagem de erro', function (value) {
      const regex = /.+/;

      return regex.test(value);
    })
    .required('Banco é obrigatório'),
  name: yup.string().required('Nome ou Razão Social  é obrigatório'),
  account: yup.string().required('Conta é obrigatório'),
  account_digit: yup
    .string()
    .test(
      'is-branch_digit',
      'Digito Conta deve ser um número ou a letra X',
      (value) => {
        const regex = /^[0-9xX]$/;

        return regex.test(value);
      },
    )
    .required('Digíto da Conta é obrigatório'),
  branch: yup.string().required('Agência  é obrigatório'),
  branch_digit: yup
    .string()
    .test(
      'is-branch_digit',
      'Digito Agência deve ser um número ou a letra X',
      (value) => {
        if (!value) {
          return true;
        }
        const regex = /^[0-9xX]$/;

        return regex.test(value);
      },
    )
    .notRequired(),
  account_type: yup.string().required('Tipo de conta é obrigatório'),
  amount: yup
    .string()
    .typeError('O campo deve receber um valor.')
    .required('Valor é obrigatório')
    .test(
      'is-name-ag',
      'O campo deve receber um Valor em real Valido e maior que R$ 0,01.',
      (value) => {
        const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

        const testRegex = regex.test(value);

        const numericValue = parseFloat(
          value.replace(/[.,]/g, '').replace(',', '.'),
        );

        if (!isNaN(numericValue) && numericValue >= 0.01 && testRegex) {
          return true;
        } else {
          return false;
        }
      },
    ),
});
