import { Document, Image, Page, Text, View } from '@react-pdf/renderer';

import iconCard from '../../../../Assets/iconCard.png';
import iconCardMaster from '../../../../Assets/iconCardMaster.png';
import iconCheck from '../../../../Assets/iconCheck.png';
import oxpay from '../../../../Assets/oxpay.png';
import { formatMoney } from '../../../../utils/formatMoney';
import { proofHandleTitle } from '../Proof/proof-schema';
import { style_proof } from './styles';

export default function ProofTemplate({ platform, proof, movement }) {
  return (
    <Document>
      <Page
        size={{ width: platform === 'mobile' ? 380 : 500, height: 'auto' }}
        style={style_proof.page}
      >
        <View style={style_proof.proof}>
          <Image src={oxpay} style={style_proof.iconOx} />
          {movement && movement.method === '13' ? (
            <Image
              src={iconCard}
              style={
                movement.status === '06'
                  ? style_proof.iconCardSuccess
                  : style_proof.iconCardFailed
              }
            />
          ) : (
            <Image src={iconCheck} style={style_proof.iconSuccess} />
          )}
          {movement && movement.method !== '13' ? (
            <Text style={style_proof.subTitle}>Pagamento realizado</Text>
          ) : (
            ''
          )}
          {movement && movement.method === '13' ? (
            <View style={style_proof.headerTitle}>
              <Text style={style_proof.title}>
                {formatMoney(movement.amount)}
              </Text>
              <Text style={style_proof.subTitleBlue}>{movement.name}</Text>
              <View style={style_proof.separator} />
            </View>
          ) : (
            <View style={style_proof.headerTitle}>
              <Text style={style_proof.title}>
                {movement && 'Comprovante de'}
              </Text>
              <Text style={style_proof.title}>
                {movement && proofHandleTitle(movement.method)}
              </Text>
            </View>
          )}
          <View style={style_proof.detailsSection}>
            {proof?.details
              ? proof.details.map((item, i) => (
                  <View
                    key={i}
                    style={
                      item.field === 'Id da transação' ||
                      item.field === 'Descrição'
                        ? style_proof.column
                        : style_proof.row
                    }
                  >
                    <Text style={style_proof.label}>{item.field}</Text>
                    {item.field === 'Status' && movement.method === '13' ? (
                      <View style={style_proof.rowContent}>
                        <View
                          style={
                            item.value === 'Finalizado'
                              ? style_proof.statusGreen
                              : item.value === 'Cancelado' ||
                                item.value === 'Erro'
                              ? style_proof.statusRed
                              : style_proof.statusBlue
                          }
                        />
                        <Text style={style_proof.value}>{item.value}</Text>
                      </View>
                    ) : item.field === 'Cartão' && movement.method === '13' ? (
                      <View style={style_proof.rowContent}>
                        <Image
                          src={iconCardMaster}
                          style={style_proof.iconCardMaster}
                        />
                        <Text style={style_proof.value}>{item.value}</Text>
                      </View>
                    ) : (
                      <Text style={style_proof.value}>{item.value}</Text>
                    )}
                  </View>
                ))
              : ''}
          </View>
          {proof?.destiny ? (
            <View style={style_proof.section}>
              <View style={style_proof.separator} />
              <Text style={style_proof.sectionTitle}>Quem Recebeu</Text>
              {proof.destiny.map((item, i) => (
                <View key={i} style={style_proof.row}>
                  <Text style={style_proof.label}>{item.field}</Text>
                  <Text style={style_proof.value}>{item.value}</Text>
                </View>
              ))}
            </View>
          ) : (
            ''
          )}
          {proof?.origin ? (
            <View style={style_proof.section}>
              <View style={style_proof.separator} />
              <Text style={style_proof.sectionTitle}>Quem Pagou</Text>
              {proof.origin.map((item, i) => (
                <View key={i} style={style_proof.row}>
                  <Text style={style_proof.label}>{item.field}</Text>
                  <Text style={style_proof.value}>{item.value}</Text>
                </View>
              ))}
            </View>
          ) : (
            ''
          )}
          {movement.method !== '13' ? (
            <View style={style_proof.section}>
              <View style={style_proof.separator} />
              <Text style={style_proof.footerText}>
                Transação efetuada através da instituição de pagamentos Oxpay
              </Text>
            </View>
          ) : (
            ''
          )}
        </View>
      </Page>
    </Document>
  );
}
