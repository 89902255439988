import { useState } from 'react';
import { Loader, Message } from 'rsuite';
import { apiPost } from '../../../../services/api';
import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

export function IsModal({ modal, handleCloseModal }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCloseIsModal = async (id) => {
    if (!id) {
      setError('Erro: Não foi possivel realizar essa ação!');
      return;
    }
    try {
      setLoading(true);
      setError('');
      await apiPost(`/Operations/refund/balance/${id}`);
    } catch (error) {
      setError('Ocorreu um erro ao processar a solicitação.');
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  return (
    <div
      style={{ visibility: modal ? 'visible' : 'hidden' }}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <h2>Atenção</h2>
        <p>
          Ao marcar a opção de reverter desconto de antecipação, você está
          ciente que o colaborador receberá acréscimo no valor{' '}
          {formatMoney(modal?.amount)}.
          <br />
          <br />
          Deseja prosseguir mesmo assim?
        </p>
        {error && <Message type="error" description={error} />}
        <div>
          {loading ? (
            <Loader />
          ) : (
            <>
              <button className={styles.cancel} onClick={handleCloseModal}>
                Cancelar
              </button>
              <button
                className={styles.button}
                onClick={() => handleCloseIsModal(modal?.id)}
              >
                Confirmar
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
