import React from 'react';

function IconSun({ size = '1.5rem', color = 'var(--primary)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 16.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0 1.5a6 6 0 100-12 6 6 0 000 12zm0-18a.75.75 0 01.75.75v3a.75.75 0 11-1.5 0v-3A.75.75 0 0112 0zm0 19.5a.75.75 0 01.75.75v3a.75.75 0 11-1.5 0v-3a.75.75 0 01.75-.75zM24 12a.75.75 0 01-.75.75h-3a.75.75 0 110-1.5h3A.75.75 0 0124 12zM4.5 12a.75.75 0 01-.75.75h-3a.75.75 0 110-1.5h3a.75.75 0 01.75.75zm15.985-8.486a.75.75 0 010 1.061l-2.12 2.122a.75.75 0 11-1.061-1.061l2.121-2.122a.75.75 0 011.06 0zM6.696 17.304a.75.75 0 010 1.06l-2.121 2.121a.75.75 0 01-1.06-1.06l2.12-2.121a.75.75 0 011.061 0zm13.79 3.181a.75.75 0 01-1.061 0l-2.121-2.12a.75.75 0 011.06-1.061l2.121 2.121a.75.75 0 010 1.06zM6.695 6.698a.75.75 0 01-1.06 0L3.514 4.574a.75.75 0 111.061-1.06l2.121 2.12a.75.75 0 010 1.063z"
      ></path>
    </svg>
  );
}

function IconMoon({ size = '1.5rem', color = 'var(--primary)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M9 .417a1.152 1.152 0 01.12 1.287 10.812 10.812 0 00-1.317 5.19c0 6.031 4.917 10.915 10.977 10.915.79 0 1.56-.082 2.3-.24a1.18 1.18 0 011.215.475 1.1 1.1 0 01-.047 1.339A12.524 12.524 0 0112.516 24C5.601 24 0 18.429 0 11.565 0 6.399 3.171 1.968 7.686.09A1.128 1.128 0 019 .417z"
      ></path>
      <path
        fill={color}
        d="M16.191 4.722a.325.325 0 01.618 0l.58 1.743c.26.777.869 1.386 1.646 1.645l1.743.581a.326.326 0 010 .618l-1.743.58a2.6 2.6 0 00-1.645 1.646l-.581 1.743a.326.326 0 01-.618 0l-.58-1.743a2.601 2.601 0 00-1.646-1.646l-1.743-.58a.326.326 0 010-.618l1.743-.58a2.6 2.6 0 001.646-1.646l.58-1.743zM20.795.148a.217.217 0 01.41 0l.388 1.161c.172.52.579.926 1.098 1.098l1.16.387a.217.217 0 010 .411l-1.16.387a1.734 1.734 0 00-1.098 1.098l-.387 1.161a.218.218 0 01-.412 0l-.387-1.16a1.734 1.734 0 00-1.098-1.099l-1.16-.387a.217.217 0 010-.41l1.16-.388a1.732 1.732 0 001.098-1.098L20.794.15V.148z"
      ></path>
    </svg>
  );
}
function IconPen({ size = '1.5rem', color = 'var(--primary)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M18.219.22a.75.75 0 011.062 0l4.5 4.5a.75.75 0 010 1.061l-15 15a.75.75 0 01-.252.165l-7.5 3a.75.75 0 01-.975-.975l3-7.5a.75.75 0 01.165-.252l15-15zM16.81 3.75l3.44 3.44 1.94-1.94-3.44-3.44-1.94 1.94zm2.38 4.5l-3.44-3.44L6 14.56V15h.75a.75.75 0 01.75.75v.75h.75a.75.75 0 01.75.75V18h.44l9.75-9.75zM4.547 16.013l-.159.159-2.292 5.731 5.731-2.292.16-.159a.75.75 0 01-.488-.702V18h-.75a.75.75 0 01-.75-.75v-.75h-.75a.75.75 0 01-.702-.487z"
      ></path>
    </svg>
  );
}
export { IconMoon, IconSun, IconPen };
