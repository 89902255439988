import React from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useBalance } from '../../hooks/useBalance';
import { formatMoney } from '../../utils/formatMoney';
import { LoadingBalance } from '../UI/LoadingBalance';
import { IconNotEyes, IconEyes } from '../Details/Icons';
import { IconAddBalance } from '../TabMenu/Icon';
import { Loader } from 'rsuite';

/**
 * Componente de botão de menu suspenso para exibir informações de saldo.
 *
 * Este componente renderiza um botão que, quando clicado, exibe um menu suspenso com informações de saldo, incluindo o saldo atual e o limite de antecipação. É projetado para ser usado em um cabeçalho ou barra de navegação para exibir informações financeiras ao usuário.
 *
 * @component
 * @param {React.ReactNode} children - Os elementos filhos a serem renderizados no botão. Pode ser usado para personalizar o conteúdo do botão.
 * @param {boolean} visible - Um valor booleano que indica se as informações de saldo estão visíveis ou não.
 * @param {function} setVisible - Uma função que permite alternar a visibilidade das informações de saldo.
 * @returns {JSX.Element} Um botão de menu suspenso para exibir informações de saldo.
 *
 * @example
 * // Exemplo de uso:
 * import { ButtonMenuBalances } from './ButtonMenuBalances';
 *
 * function MeuComponente() {
 *   const [saldoVisivel, setSaldoVisivel] = useState(false);
 *
 *   return (
 *     <ButtonMenuBalances
 *       visible={saldoVisivel}
 *       setVisible={setSaldoVisivel}
 *     >
 *       <span>Meu Saldo</span>
 *     </ButtonMenuBalances>
 *   );
 * }
 */
export function ButtonMenuBalances({ visible, setVisible }) {
  const { user, loading } = useUser();
  const { balance } = useBalance();
  const { balanceConsignable } = useBalance();
  const permission_balance = [5].every((item) =>
    user?.permission.includes(item),
  );
  const permission_balance_consignable = [33].every((item) =>
    user?.permission.includes(item),
  );
  return (
    <div
      className={
        permission_balance_consignable ? styles.container : styles.container_
      }
    >
      <div className={styles.box_eys}>
        {!visible ? (
          <button className={styles.eyes_} onClick={() => setVisible(!visible)}>
            <IconNotEyes color="var(--gray-850)" size="1.5rem" />
          </button>
        ) : (
          <button className={styles.eyes_} onClick={() => setVisible(!visible)}>
            <IconEyes color="var(--gray-850)" size="1.5rem" />
          </button>
        )}
      </div>
      <div className={styles.balance}>
        {permission_balance ? (
          <>
            <p className={styles.title_pay}>Saldo</p>
            {!visible ? (
              <div className={styles.view_balance}>
                <p>******</p>
              </div>
            ) : (
              <div className={styles.view_balance}>
                <p>
                  {(balance && !loading.balance) || balance === 0 ? (
                    formatMoney(balance)
                  ) : (
                    <LoadingBalance />
                  )}
                </p>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
      {permission_balance_consignable && (
        <>
          <div className={styles.separeted}></div>
          <div className={styles.balance_consignable}>
            <p className={styles.title_pay}>Limite Antecipação</p>

            {!visible ? (
              <div className={styles.view_balance_consignable}>
                <p>******</p>
              </div>
            ) : (
              <div className={styles.view_balance_consignable}>
                {(balanceConsignable && !loading.balanceConsignable) ||
                balanceConsignable === 0 ? (
                  formatMoney(balanceConsignable)
                ) : (
                  <Loader size="sm" />
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div className={styles.pay}>
        <Link id="link" to="/menu/added_balance">
          <IconAddBalance color="var(--white)" size="1.8rem" />
        </Link>
      </div>
    </div>
  );
}
