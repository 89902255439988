import React from 'react';
import styles from './styles.module.scss';

function CardHeader() {
  return (
    <div className={styles.cardOrder}>
      <label className={styles.date}>LANÇAMENTO</label>
      <label className={styles.name}>NOME</label>
      <span className={styles.date}>DOCUMENTO</span>
      <span className={styles.value}>PAGAMENTO</span>
      <span className={styles.description}>DESCRIÇÃO</span>
      <span className={styles.percent}>VALOR</span>
      <span className={styles.status}>UTILIZADO</span>
      <span className={styles.status}>STATUS</span>
    </div>
  );
}

export default CardHeader;
