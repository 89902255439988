import React, { useEffect, useState } from 'react';
import { IoReloadOutline } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { useQuery, useQueryClient } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import ButtonGoBack from '../../components/ButtonGoBack';
import CardMovements from './Components/CardMovements';

import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { ButtonFilterSelect } from '../../components/UI/ButtonFilterSelect';
import { InputSearch } from '../../components/UI/InputSearch';
import { LoadingBall } from '../../components/UI/LoadingBall';
import { useOrder } from '../../hooks/useOrder';
import { usePagination } from '../../hooks/usePagination';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import CardResume from './Components/CardResume';
import styles from './styles.module.scss';
import { Loader } from 'rsuite';
import CardHeader from './Components/CardHeader';

const Order = () => {
  const { user, loading } = useUser();
  const [search, setSearch] = useState(false);

  const {
    order,
    orderKey,
    fetchOrderKeyValidation,
    fetchOrderKeyCancel,
    fetchDataOrderKey,
  } = useOrder();

  const { id } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState(history.location.state?.status ?? 6);

  const [filter, setFilter] = useState(0);
  const queryClient = useQueryClient();
  const [time, setTime] = useState(status === 4 ? 1000 * 10 : false);

  if (!id) {
    <Redirect to="/transfer" />;
  }

  const { data, isFetching } = useQuery(
    ['OrdersListPayment', id, time],
    () => fetchDataOrderKey(id),
    {
      staleTime: 1000 * 60,
      refetchInterval: time,
    },
  );

  const list =
    data && parseInt(filter) !== 99
      ? data?.movements
          .filter((item) => {
            if (!search || /\d/.test(search)) return item;

            return lower(`${item['name']}`).includes(lower(search));
          })
          .filter((item) => {
            if (!search) return item;

            return (
              item['document'].includes(search.replace(/\D/g, '')) ||
              [item['id']].includes(search)
            );
          })
          .filter((item) => {
            if (parseInt(filter) > 0) {
              return item.status.id === parseInt(filter);
            }
            return item;
          })
      : parseInt(filter) === 99
      ? data?.duplicate?.data
      : [];
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    50,
    '/menu/order/',
  );

  useEffect(() => {
    if (status === 4) {
      setStatus(data?.order?.status.id);

      setTime(1000 * 30);
    }
    if (status !== 4) {
      setStatus(data?.order?.status.id);
      setTime(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [search]);

  async function handleApproved() {
    await fetchOrderKeyValidation(orderKey.key, 1);
    handleReload();
    handleClick();
  }
  async function handleCancel() {
    await fetchOrderKeyCancel(orderKey.key, 1);
    handleReload();
    handleClick();
  }

  async function handleClick() {
    await queryClient.invalidateQueries(['OrdersListPayment']);
  }
  async function handleReload() {
    await queryClient.invalidateQueries(['OrdersList']);
  }
  return (
    <section className={styles.sectionContainer}>
      <main>
        <div className={styles.upload}>
          <div className={styles.button}>
            <span className={styles.back}>
              <ButtonGoBack
                backRoute={
                  data?.order?.type.id === 1
                    ? '/menu/transfer'
                    : '/menu/consignment'
                }
              />
            </span>
            <InputSearch
              label="Pesquisar por nome ou documento"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
              width="80%"
              filter={setFilter}
            />
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.wrapper_resume}>
              {data && (
                <CardResume
                  {...data?.order}
                  author={data?.author}
                  author_approve={data?.author_approve}
                  duplicate={data?.duplicate}
                  fee={data?.fee}
                />
              )}
              <div className={styles.description_order}>
                {[1, 3, 7].includes(data?.order?.status?.id) && data && (
                  <>
                    {[13, 12].every((item) =>
                      user?.permission.includes(item),
                    ) ? (
                      <button
                        disabled={data && !isFetching ? false : true}
                        className={styles.button_fix_style}
                        onClick={handleApproved}
                      >
                        Aprovar
                      </button>
                    ) : (
                      <>
                        {[12].every((item) =>
                          user?.permission.includes(item),
                        ) &&
                          ![3].includes(data?.order?.status?.id) && (
                            <button
                              disabled={data && !isFetching ? false : true}
                              className={styles.button_fix_style}
                              onClick={handleApproved}
                            >
                              Validar
                            </button>
                          )}
                      </>
                    )}
                    {[14].every((item) => user?.permission.includes(item)) && (
                      <button
                        disabled={data && !isFetching ? false : true}
                        className={styles.button_fix_style}
                        onClick={handleCancel}
                      >
                        Cancelar
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {data && (
        <div className={styles.arowPages}>
          <div className={styles.wrapper__buttons}>
            <ButtonFilterSelect
              setCurrentPage={setCurrentPage}
              title="Todos"
              value={0}
              setIsActive={setFilter}
              account={data?.all ?? '--'}
              isActive={filter}
            />
            {data?.approved > 0 && (
              <ButtonFilterSelect
                setCurrentPage={setCurrentPage}
                title="Aprovado"
                value={6}
                setIsActive={setFilter}
                account={data?.approved ?? '--'}
                isActive={filter}
              />
            )}
            {data?.duplicate?.res && (
              <ButtonFilterSelect
                setCurrentPage={setCurrentPage}
                title="Duplicados"
                value={99}
                setIsActive={setFilter}
                account={data?.duplicate.data.length ?? '--'}
                isActive={filter}
              />
            )}
            {data?.erros > 0 && (
              <ButtonFilterSelect
                setCurrentPage={setCurrentPage}
                title="Erros"
                value={[7, 8]}
                setIsActive={setFilter}
                account={data?.erros ?? '--'}
                isActive={filter}
              />
            )}
          </div>
          <div className={styles.wrapper__selection}>
            <ButtonMenuDownload list={data.movements} error={data.erros}>
              Exportar
            </ButtonMenuDownload>

            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
            <button
              disabled={!loading ? false : true}
              className={styles.button_load_style}
              onClick={handleClick}
            >
              {isFetching ? (
                <Loader size="sm" />
              ) : (
                <IoReloadOutline size="1.3rem" color="var(--white)" />
              )}
            </button>
          </div>
        </div>
      )}
      <div>
        <CardHeader />

        {currentItens.length >= 1 ? (
          currentItens.map((movement) => (
            <CardMovements
              key={movement.id}
              orderKey={id}
              back={id}
              {...movement}
              type={order && order?.order?.type?.id}
              isFetching={isFetching}
              setFilter={setFilter}
            />
          ))
        ) : (
          <div className={styles.listLoader}>
            {isFetching ? (
              <LoadingBall />
            ) : (
              <h5>
                <RiSearch2Line size={12} color="#222" /> Nenhum resultado
                encontrado!
              </h5>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Order;
