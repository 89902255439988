import { useState } from 'react';

import styles from './styles.module.scss';

import { MdPayments } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Status from '../../../../components/UI/Status';
import TooTip from '../../../../components/UI/ToolTip';
import { formatDocument } from '../../../../utils/formatDocument';
import { formatMoney } from '../../../../utils/formatMoney';
import DetailsFlyout from './Components/DetailsFlyout';

const CardMovements = ({
  name,
  document,
  bank_code,
  status,
  email,
  account,
  branch,
  account_type,
  account_digit,
  index,
  error_description,
  date,
  amount,
  id,
  method,
  bank_autentication_code,
  isDate,
  back,
  pix_qrcode,
  pix_key,
  type,
  pix_end_to_end_id,
  // eslint-disable-next-line
  isFetching,
  additional_data,
  bank_slip,
  expiration_date,
  orderKey,
  movementKey,
  gateway,
  setFilter,
  collected_consignation_amount,
  min_amount,
  max_amount,
}) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${id}`,
    });
  };
  return (
    <div key={id} className={styles.cardMovements}>
      <div className={styles.heading}>
        <span className={styles.heading__cards}>{date}</span>
        <span className={styles.heading__name} onClick={() => setVisible(true)}>
          {name}
        </span>
        <span className={styles.heading__cards}>
          {formatDocument(document)}
        </span>
        <span className={styles.heading__cards}>
          {amount ? formatMoney(amount.toFixed(2)) : '---'}
        </span>
        <span className={styles.heading__cards}> {method}</span>

        <span
          className={styles.heading__status}
          onClick={() => setVisible(true)}
        >
          {error_description && status.id === 7 ? (
            <TooTip text={error_description}>
              <Status status={status} />
            </TooTip>
          ) : (
            <Status status={status} />
          )}
        </span>
        {status.id === 6 && type !== 5 ? (
          <span className={styles.proof} onClick={handleProof}>
            {<MdPayments size="1rem" color={'var(--primary)'} />}
          </span>
        ) : (
          <span className={styles.proof} style={{ cursor: 'no-drop' }}>
            {<MdPayments size="1rem" color={'var(--gray-300)'} />}
          </span>
        )}
      </div>

      <DetailsFlyout
        visible={visible}
        setVisible={setVisible}
        name={name}
        document={document}
        bank_slip={bank_slip}
        expiration_date={expiration_date}
        email={email}
        additional_data={additional_data}
        bank_code={bank_code}
        account={account}
        account_digit={account_digit}
        account_type={account_type}
        branch={branch}
        date={date}
        amount={amount}
        error_description={error_description}
        status={status}
        method={method}
        id={id}
        pix_key={pix_key}
        pix_qrcode={pix_qrcode}
        type={type}
        isFetching={isFetching}
        bank_autentication_code={bank_autentication_code}
        isDate={isDate}
        back={back}
        pix_end_to_end_id={pix_end_to_end_id}
        orderKey={orderKey}
        movementKey={movementKey}
        gateway={gateway}
        setFilter={setFilter}
        collected_consignation_amount={collected_consignation_amount}
        min_amount={min_amount}
        max_amount={max_amount}
      />
    </div>
  );
};

export default CardMovements;
