export function containsWord(value, str) {
  if (str && value) {
    const lowercaseString = str.toString().toLowerCase();
    if (lowercaseString.includes(value)) {
      return true; // The word was found in the string
    } else {
      return false; // The word was not found in the string
    }
  } else {
    return false;
  }
}
