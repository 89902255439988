import { useEffect, useState } from 'react';
import { IconPeoplePayment } from '../animation/IconPeoplePayment';

import styles from './styles.module.scss';

function LoadingBall() {
  return (
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
export function CardAmountPeople({ data, loading, status, title }) {
  const [currentValue, setCurrentValue] = useState(0);
  const duration = 600; // Duração da animação em milissegundos

  const total_amount_account_ox = data.reduce((acc, item) => {
    const movementsTotal = item.movements
      .map((movement) => {
        if (movement?.bank_code === 'OX' && movement?.status?.id === 6) {
          return movement?.document;
        }
        return null;
      })
      .filter(Boolean); // Filtra os valores nulos
    return [...acc, ...movementsTotal];
  }, []);

  const uniqueDocuments = [...new Set(total_amount_account_ox)].length;

  useEffect(() => {
    const total_amount_account = data.reduce((acc, item) => {
      const movementsTotal = item.movements
        .map((movement) => {
          if (movement?.status?.id === 6) {
            return movement?.document;
          }
          return null;
        })
        .filter(Boolean); // Filtra os valores nulos
      return [...acc, ...movementsTotal];
    }, []);

    const totalAmount = [...new Set(total_amount_account)]?.length;

    const decimalNumber = totalAmount - Math.floor(totalAmount);
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * totalAmount);

      setCurrentValue(nextValue + decimalNumber);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ul>
          <li className={styles.clients}>{title} </li>
        </ul>
        <div className={styles.wrapper_balance}>
          {data?.length >= 1 ? (
            <div className={styles.balance}>
              {loading ? (
                <LoadingBall />
              ) : currentValue >= 0 && typeof totalAmount !== 'boolean' ? (
                currentValue
              ) : (
                typeof totalAmount === 'boolean' && <span> - - - </span>
              )}
            </div>
          ) : (
            <div className={styles.balance_}>0</div>
          )}
        </div>
        <div className={styles.name}>
          <div className={styles.wrapper_footer}>
            {!loading && <strong>{uniqueDocuments}</strong>}
            <span>Contas Ox Pay</span>
          </div>
        </div>
      </div>
      <div className={styles.icon}>
        <IconPeoplePayment />
      </div>
    </div>
  );
}
