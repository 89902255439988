import React from 'react';

import styles from './styles.module.scss';

export const InfoProfile = () => {
  return (
    <div className={styles.container_box}>
      <div className={styles.wrapper__container}>
        <div className={styles.header_style}>
          <h1 className={styles.title_style}>Permissões de usuários</h1>
        </div>

        <table>
          <thead>
            <tr>
              <th>Permissão</th>
              <th>Administrador </th>
              <th>Gestor Financeiro</th>
              <th>Financeiro</th>
              <th>Gestor de Frota</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Gestão de Usuário</td>
              <td>X</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Gestão de Colaboradores</td>
              <td>X</td>
              <td></td>
              <td></td>
              <td>X</td>
            </tr>
            <tr>
              <td>Criar pagamento/antecipação</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Aprovar pagamento/antecipação</td>
              <td>X</td>
              <td>X</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Cancelar pagamento/antecipação</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Visualizar Extrato</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Visualizar Antecipação</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Visualizar Pagamento</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Visualizar saldo da conta</td>
              <td>X</td>
              <td>X</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
