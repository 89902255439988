import React, { useState } from 'react';
import { MdPayments } from 'react-icons/md';
import { formatMoney } from '../../../../../../utils/formatMoney';
import styles from './styles.module.scss';
import FormGroup from './FormGroup';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { Loader } from 'rsuite';
import { useUser } from '../../../../../../hooks/useUser';
import { IconLike, IconMoney, IconPerson, IconProof } from './../icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { parseCurrencyToNumber } from './utils/parseCurrencyToNumber';
import { useOrder } from '../../../../../../hooks/useOrder';

import { useHistory } from 'react-router-dom';
import { usePayment } from '../../../../../../hooks/usePayment';
import { useNotification } from '../../../../../../hooks/useNotifications';

import { formatDocument } from '../../../../../../utils/formatDocument';
import { IconDocument, IconValue, IconDate, IconBook } from './icons';
import { convertDate } from './utils/convertDate';
import { formatDate } from '../../../../../../utils/formatDate';
import { apiPut } from '../../../../../../services/api';
import { useQueryClient } from 'react-query';

const PaymentTransfer = () => {
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,
  } = usePayment();
  const queryClient = useQueryClient();

  async function handleReload() {
    await queryClient.invalidateQueries(['bankSlip']);
  }
  const Validate = yup.object().shape({
    amount: yup
      .string()
      .typeError('O campo deve receber um valor.')
      .required('Valor é obrigatório')
      .test(
        'is-amount',
        `O campo deve receber um valor em real valido entre ${formatMoney(
          payment.min_amount === 0 ? 0.01 : payment.min_amount,
        )} e ${formatMoney(payment.max_amount)}.`,
        (value) => {
          const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

          const testRegex = regex.test(value);

          const numericValue = parseFloat(
            value.replace(/[.,]/g, '').replace(',', '.'),
          );
          const min_value = payment.min_amount;
          const max_value = payment.max_amount;
          const number_value = parseCurrencyToNumber(value);

          if (min_value === max_value) return true;
          if (
            !isNaN(numericValue) &&
            number_value >= min_value &&
            number_value <= max_value &&
            testRegex
          ) {
            return true;
          } else {
            return false;
          }
        },
      ),
  });
  const ValidatePrimary = yup.object().shape({
    bank_slip: yup
      .string()
      .typeError('O campo deve conter um boleto válido.')
      .test('is-boleto', 'O boleto informado não é válido.', function (value) {
        if (!value) return false; // Verifica se o campo está vazio
        return (
          value.replace(/\D/g, '').length === 48 ||
          value.replace(/\D/g, '').length === 47
        ); // Remove espaços e testa contra a regex
      })
      .required('O campo boleto é obrigatório.'),
    description: yup.string().required('Descrição é obrigatória'),
  });
  const today = new Date().toISOString().split('T')[0];
  const { addNotification } = useNotification();

  const [clear] = useState(false);
  const { user } = useUser();

  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );

  const {
    fetchTransferOrder,
    fetchOrderKeyValidation,
    fetchOrderKeyCancel,
    fetchOrderKey,
    fetchOrderPreview,
  } = useOrder();
  const history = useHistory();

  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${finishPayment.key}`,
      state: {
        ...finishPayment,
      },
    });
    clearStatePayment();
  };

  const handleOrderKey = () => {
    history.push({
      pathname: `/menu/order/${finishPayment.key}`,
    });
    clearStatePayment();
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(stepPayment === 0 ? ValidatePrimary : Validate),
    defaultValues: {
      date: payment?.date
        ? convertDate(payment?.date)
        : new Date().toISOString().split('T')[0],
      bank_slip: payment?.bank_slip
        ? payment?.bank_slip.replace(/\D/g, '')
        : '',
      amount: payment?.amount ? payment?.amount : '',
      description: payment?.description || '',
    },
  });

  const formDate = watch('date');

  const onBackStepCancelOrder = async () => {
    try {
      setLoading({ paymentOrderCancel: true });
      if (payment) {
        if (payment.status.id !== 7) {
          await fetchOrderKeyCancel(payment.key, 3);
        }
      }
      handleReload();
      clearStatePayment();
    } catch (error) {
      console.log('🚀 error:', error);
    }
  };

  const onSubmitPrimary = async (values) => {
    try {
      setLoading({ paymentOrder: true });

      const { bank_slip, description } = values;

      const bank_slip_formatted = bank_slip.replace(/\D/g, '');

      const data = await fetchTransferOrder({
        body: {
          description: description,
          movements: [
            {
              additional_data: description,
              bank_slip: bank_slip_formatted,
            },
          ],
        },
        isManual: true,
      });

      if (!!data) {
        await fetchOrderPreview(data?.key);
        const { movements, order } = await fetchOrderKey(data.key);
        if (movements[0].status.id === 7) {
          setPayment({ bank_slip, description });

          addNotification(
            <>{movements[0].error_description}</>,
            2,
            'Error',
            true,
          );
          return;
        }
        if (movements[0]) {
          const paymentInfo = {
            ...movements[0],
            amount: formatMoney(movements[0]?.max_amount, 'code'),
            max_amount: movements[0]?.max_amount,
            min_amount: movements[0]?.min_amount,
            name: movements[0]?.name,
            value_disabled:
              movements[0]?.min_amount === movements[0]?.max_amount,
            bank_code: movements[0].bank_code,
            document: movements[0].document,
            date: movements[0].date,
            expiration_date: movements[0].expiration_date,
            description: movements[0].additional_data,
            key: order?.key,
            orderKey: order?.key,
          };

          setPayment(paymentInfo);
          setStepPayment(1);
          setErrosSteps({
            status: 'process',
          });
        }
      } else {
        setPayment({ bank_slip, description });
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Documento não encontrado',
        });

        addNotification(
          <>
            Não foi encontrado dados do documento informado! <br />
            Por favor, verifique o CPF/CNPJ e tente novamente.
          </>,
          2,
          'Conta não encontrada',
          true,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleReload();
    }
  };
  const onSubmit = async (values) => {
    try {
      setLoading({ paymentOrder: true });

      const { amount, date } = values;
      let amountRes;
      let dateRes;
      if (date !== today) {
        amountRes = await apiPut('/Movement/amount', {
          movement_key: payment?.movementKey,
          order_key: payment?.orderKey,
          amount: parseCurrencyToNumber(amount),
        });
        dateRes = await apiPut('/Movement/date', {
          movement_key: payment?.movementKey,
          order_key: payment?.orderKey,
          date: `${date}T03:00:00.000Z`,
        });
      } else {
        amountRes = await apiPut('/Movement/amount', {
          movement_key: payment?.movementKey,
          order_key: payment?.orderKey,
          amount: parseCurrencyToNumber(amount),
        });
      }

      if (amountRes || dateRes) {
        const { movements, order } = await fetchOrderKey(payment.key);

        if (movements[0]) {
          const paymentInfo = {
            ...movements[0],
            amount: formatMoney(movements[0]?.amount, 'code'),
            name: movements[0]?.name,
            bank_code: movements[0].bank_code,
            document: movements[0].document,
            date: movements[0].date,
            description: movements[0].additional_data,
            key: order?.key,
            orderKey: order?.key,
            value_disabled:
              movements[0]?.min_amount === movements[0]?.max_amount,
          };

          setPayment(paymentInfo);
          setStepPayment(2);
          setErrosSteps({
            status: 'process',
          });
        }
      } else {
        setPayment({ document, amount, date });
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Documento não encontrado',
        });

        addNotification(
          <>
            Não foi encontrado dados do documento informado! <br />
            Por favor, verifique o CPF/CNPJ e tente novamente.
          </>,
          2,
          'Conta não encontrada',
          true,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleReload();
    }
  };
  const onPayment = async () => {
    setLoading({ paymentOrder: true });

    try {
      if (payment.key) {
        await fetchOrderKeyValidation(payment.key, 3);
        const { movements } = await fetchOrderKey(payment?.key);

        if (payment) {
          setFinishPayment({
            ...movements[0],
            value_disabled:
              movements[0]?.min_amount === movements[0]?.max_amount,
            key: payment.key,
            orderKey: payment.orderKey,
          });
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleReload();
    }
  };
  return (
    <div className={styles.container}>
      {stepPayment === 0 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmitPrimary)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="bank_slip"
              label={
                <>
                  <strong>Código de Barras </strong>
                </>
              }
              type="text"
              data={{ value: payment?.bank_slip }}
              placeholder={
                '00000.00000 00000.000000 00000.000000 00 00000000000000'
              }
              disabled={!loading?.paymentOrder ? false : true}
              mask={[
                '99999.99999 99999.999999 99999.999999 9 99999999999999',
                '99999.99999 99999.999999 99999.999999 99 99999999999999',
              ]}
              register={register}
              errors={errors}
              setValue={setValue}
              clean={clear}
              Icon={IconDocument}
            />
            <FormGroup
              name="description"
              label={
                <>
                  <strong>Descrição: </strong>
                </>
              }
              type="text"
              placeholder="Pagamento"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              data={{ value: payment?.description }}
              errors={errors}
              setValue={setValue}
              Icon={IconBook}
            />
            <div className={styles.buttons_form}>
              <button
                type="submit"
                className={styles.button}
                disabled={loading.paymentOrder}
              >
                Confirmar
                {loading.paymentOrder && <Loader size="xs " />}
              </button>
            </div>
          </div>
        </form>
      )}
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="amount"
              label={
                <>
                  <strong>Valor: </strong>
                </>
              }
              data={{ value: payment?.amount }}
              type="text"
              mask={[
                '9,99',
                '99,99',
                '999,99',
                '9.999,99',
                '99.999,99',
                '999.999,99',
                '9.999.999,99',
              ]}
              placeholder="R$ 0,00"
              disabled={
                !loading.paymentOrder
                  ? payment?.value_disabled
                  : payment?.value_disabled
                    ? true
                    : false
              }
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconValue}
            />
            <FormGroup
              name="date"
              label={
                <>
                  <strong>Quando: </strong>{' '}
                  {today === formDate ? 'Hoje' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              min={new Date().toISOString().split('T')[0]}
              errors={errors}
              setValue={setValue}
              data={{
                value: payment?.date
                  ? convertDate(payment.date)
                  : new Date().toISOString().split('T')[0],
              }}
              Icon={IconDate}
            />
            <div className={styles.buttons_form}>
              <button
                type="button"
                disabled={loading.paymentOrder}
                className={styles.button_cancel}
                onClick={clearStatePayment}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={styles.button}
                disabled={loading.paymentOrder}
              >
                Confirmar
                {loading.paymentOrder && <Loader size="xs " />}
              </button>
            </div>
          </div>

          <div className={styles.form_wrapper__footer}>
            <div className={styles.header_info}>
              <div className={styles.icon}>
                <IconPerson />
              </div>

              <div className={styles.info}>
                <div className={styles.title_info}>Pessoa/Instituição:</div>
                {payment.name === '---' ? (
                  <div className={styles.preview}>* Dados não carregados</div>
                ) : (
                  <div className={styles.title_description}>{payment.name}</div>
                )}
              </div>
            </div>
            <div className={styles.header_info}>
              <div className={styles.icon}>
                <IconDate />
              </div>

              <div className={styles.info}>
                <div className={styles.title_info}>Data Vencimento:</div>

                <div className={styles.title_description}>
                  {formatDate(payment.expiration_date, 'dd/MM/yyyy - HH:mm:ss')}
                </div>
              </div>
            </div>
            <div className={styles.box_info_}>
              <span>
                Documento: <p> {formatDocument(payment.document)} </p>
              </span>
              {payment?.value_disabled ? (
                <span>
                  Valor: <p> {formatMoney(payment.max_amount)} </p>
                </span>
              ) : (
                <>
                  <span>
                    Valor Minimo: <p> {formatMoney(payment.min_amount)} </p>
                  </span>
                  <span>
                    Valor Máximo: <p> {formatMoney(payment.max_amount)} </p>
                  </span>
                </>
              )}

              {payment?.error_description && (
                <div className={styles.error_preview}>
                  {payment.error_description}
                </div>
              )}
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>Foi criada uma transferência para:</h1>
          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description_}>
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>
                        Pessoa/Instituição:
                      </div>
                      {payment.name === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(parseCurrencyToNumber(payment.amount))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Data:</div>

                        <div className={styles.title_description}>
                          {payment.date}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>
                      <div className={styles.info}>
                        <div className={styles.title_info}>
                          Data Vencimento:
                        </div>

                        <div className={styles.title_description}>
                          {formatDate(
                            payment.expiration_date,
                            'dd/MM/yyyy - HH:mm:ss',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Documento: <p> {formatDocument(payment.document)} </p>
                    </span>
                    {payment?.value_disabled ? (
                      <span>
                        Valor: <p> {formatMoney(payment.max_amount)} </p>
                      </span>
                    ) : (
                      <>
                        <span>
                          Valor Minimo:{' '}
                          <p> {formatMoney(payment.min_amount)} </p>
                        </span>
                        <span>
                          Valor Máximo:{' '}
                          <p> {formatMoney(payment.max_amount)} </p>
                        </span>
                      </>
                    )}
                    {payment?.error_description && (
                      <div className={styles.error_preview}>
                        {payment.error_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(
                        parseCurrencyToNumber(payment.amount) +
                          (user?.company?.internal_transfer_fee
                            ? user?.company?.internal_transfer_fee
                            : 0),
                      )}
                    </span>
                    <div className={styles.fee}>
                      <span>* Taxa:</span>
                      <p>
                        {formatMoney(
                          user?.company?.internal_transfer_fee
                            ? user?.company?.internal_transfer_fee
                            : 0,
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={onBackStepCancelOrder}
                    >
                      {loading.paymentOrderCancel && <Loader size="xs " />}
                      Cancelar
                    </button>
                    {payment.status.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Transferência finalizada!'
                    : 'Transferência Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar
                      seus pagamentos.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa transação.
                    </>
                  )}
                </p>
                <div className={styles.buttons_finish}>
                  {finishPayment && finishPayment?.status?.id === 6 && (
                    <button
                      type="button"
                      disabled={loading.paymentOrder}
                      className={styles.button}
                      onClick={handleProof}
                    >
                      <MdPayments />
                      Comprovante
                    </button>
                  )}

                  {finishPayment &&
                    ![6].includes(finishPayment?.status?.id) && (
                      <button
                        type="button"
                        disabled={loading.paymentOrder}
                        className={styles.button}
                        onClick={handleOrderKey}
                      >
                        <MdPayments />
                        Ver Pagamento
                      </button>
                    )}
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentTransfer };
