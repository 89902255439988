export function IconPlus({ size = '1rem', color = 'var(--primary-700)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={color}
        d="M8 15.667A7.173 7.173 0 01.835 8.5a7.173 7.173 0 017.167-7.167A7.173 7.173 0 0115.167 8.5a7.173 7.173 0 01-7.166 7.167zM8 2.333A6.174 6.174 0 001.835 8.5c0 3.4 2.767 6.167 6.167 6.167S14.167 11.9 14.167 8.5s-2.766-6.167-6.166-6.167z"
      ></path>
      <path
        fill={color}
        d="M10.667 9H5.334a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h5.333c.274 0 .5.227.5.5s-.226.5-.5.5z"
      ></path>
      <path
        fill={color}
        d="M8 11.667a.504.504 0 01-.5-.5V5.834c0-.274.227-.5.5-.5s.5.226.5.5v5.333c0 .273-.227.5-.5.5z"
      ></path>
    </svg>
  );
}
export function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.5625rem"
      height="0.5625rem"
      fill="none"
      viewBox="0 0 9 9"
    >
      <path
        fill="#686868"
        fillRule="evenodd"
        d="M.264.264a.9.9 0 011.272 0l7.2 7.2a.9.9 0 01-1.272 1.272l-7.2-7.2a.9.9 0 010-1.272z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#686868"
        fillRule="evenodd"
        d="M.264 8.736a.9.9 0 010-1.272l7.2-7.2a.9.9 0 011.272 1.272l-7.2 7.2a.9.9 0 01-1.272 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
export function IconTags({ size = '1rem', color = 'var(--white)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <g clipPath="url(#clip0_3551_8153)">
        <path
          fill={color}
          d="M15.446 11.24l-2.465-2.474a2.003 2.003 0 00-1.679-.57l-2.049.27a1.588 1.588 0 00-1.366 1.44l-.159 1.985a2 2 0 00.577 1.57l2.472 2.482c.356.355.829.55 1.332.55h.008c.506-.002.98-.201 1.335-.561l2.003-2.03a1.892 1.892 0 00-.009-2.661zm-.94 1.727l-2.003 2.028a.551.551 0 01-.392.166h-.002a.55.55 0 01-.389-.16l-2.47-2.48a.662.662 0 01-.192-.524l.159-1.986a.247.247 0 01.212-.224s2.106-.276 2.134-.276a.67.67 0 01.474.197l2.466 2.476a.556.556 0 01.004.783zM12 11.5a1 1 0 11-2 0 1 1 0 012 0zm-6.667-3c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6.667A2.67 2.67 0 018 4.5a2.67 2.67 0 01-2.667 2.667A2.67 2.67 0 012.667 4.5a2.67 2.67 0 012.666-2.667zM6.667 10.5a.666.666 0 01-.667.667 4.672 4.672 0 00-4.667 4.666.666.666 0 11-1.333 0c0-3.308 2.692-6 6-6 .368 0 .667.298.667.667z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3551_8153">
          <path
            fill={color}
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
