import { formatDate } from './formatDate';
import { formatMethodPayment } from './formatMethodPayment';
import { formatStatus } from './formatStatus';

export const formatMovements = (array) => {
  return array.map((item) => {
    return {
      ...item,
      id: item?.key,
      additional_data: item?.additional_data,
      amount: Number(item?.amount) || 0,
      date: item?.date
        ? formatDate(item?.date, "dd/MM/yyyy '-' HH:mm:ss")
        : formatDate(item?.start_date, "dd/MM/yyyy '-' HH:mm:ss"),
      isDate: item?.finish_date
        ? formatDate(item?.finish_date, "dd/MM/yyyy '-' HH:mm:ss")
        : null,
      order_id: item?.order_id,
      bank_code: item?.bank_code,
      document: item?.document ?? '---',
      branch: item?.branch,
      branch_digit: item?.branch_digit,
      account: item?.account,
      account_digit: item?.account_digit,
      account_type: item?.account_type,
      name: item?.name ?? '---',
      email: item?.email,
      status: formatStatus(item?.status, item?.error_description),
      bank_autentication_code: item?.bank_autentication_code,
      start_date: item?.start_date,
      finish_date: item?.finish_date,
      error_code: item?.error_code,
      error_description: item?.error_description,
      method: formatMethodPayment(item?.method),
      pix_key: item?.pix_key,
      pix_end_to_end_id: item?.pix_end_to_end_id ?? false,
      order_key: item?.order_key,
      bank_slip: item?.bank_slip,
      expiration_date: item?.expiration_date,
      movementKey: item?.key,
      gateway: item?.gateway,
      refundable_fee_amount: Number(item?.refundable_fee_amount) || 0,
      collected_consignation_amount:
        Number(item?.collected_consignation_amount) || 0,
    };
  });
};
