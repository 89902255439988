import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html, fileName = 'form.pdf') => {
    savePDF(html, {
      paperSize: 'A4',
      fileName: fileName,
      scale: 0.7,
      margin: 5,
      forcePageBreak: '.page-break',
      keepTogether: '.keep-together',
    });
  };
}

const Doc = new DocService();
export default Doc;
