import React, { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { Loading } from '../../components/UI/Loading';
import { usePagination } from '../../hooks/usePagination';
import styles from './styles.module.scss';
import { useSearch } from '../../hooks/useSearch';
import { InputDebounce } from '../../components/UI/InputDebounce';
import ButtonGoBack from '../../components/ButtonGoBack';
import CardMovements from '../Order/Components/CardMovements';
import CardHeader from './Components/CardHeader';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import { useHistory, useParams } from 'react-router-dom';

const SearchOrder = () => {
  const history = useHistory();
  const { id } = useParams();

  const { location } = history;
  const [search, setSearch] = useState(id ? id : location?.state?.value || '');
  const { loading, fetchSearchDocument, searchDocument } = useSearch();

  function removeMaskDocument(document) {
    return document.replace(/\D/g, '');
  }

  useEffect(() => {
    fetchSearchDocument(removeMaskDocument(search));
    // eslint-disable-next-line
  }, [search]);
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    searchDocument,
    50,
    '/menu/search/',
  );

  return (
    <section
      className={
        loading.search
          ? `${styles.loading} ${styles.sectionContainer}`
          : styles.sectionContainer
      }
    >
      <main>
        <div className={styles.button}>
          <ButtonGoBack />
        </div>
        <div className={styles.upload}>
          <div className={styles.header}>
            <h1 className={styles.title_style}> Busca de Pagamentos </h1>
            <p className={styles.description}>
              Busque por um documento para visualizar os todos pagamentos
              realizados.
            </p>
          </div>
          <div className={styles.wrapper_search}>
            <InputDebounce
              label="Pesquisar por documento"
              type="text"
              setValue={setSearch}
              value={search}
              Icon={RiSearch2Line}
            />
          </div>
        </div>
      </main>
      {currentItens.length === 0 || loading?.search ? (
        ''
      ) : (
        <div className={styles.wrapper__selection}>
          {currentItens.length >= 0 && (
            <>
              <ButtonMenuDownload list={searchDocument} error={0}>
                Exportar
              </ButtonMenuDownload>

              <PaginationArrow
                pages={pages}
                page={currentPage}
                setPage={setCurrentPage}
              />
            </>
          )}
        </div>
      )}
      <div>
        {search.length >= 1 && currentItens.length === 0 && !loading?.search ? (
          <h5 className={styles.payment_info}>
            <RiSearch2Line size={'0.75rem'} color="#222" /> Nenhum pagamento
            encontrado!
          </h5>
        ) : (
          <>{currentItens.length > 0 && <CardHeader />}</>
        )}

        {currentItens.length >= 0 ? (
          currentItens.map((movement) => (
            <CardMovements key={movement.id} back={movement.id} {...movement} />
          ))
        ) : (
          <div className={styles.listLoader}>
            {loading?.search && <Loading />}
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchOrder;
