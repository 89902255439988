import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  background: var(--white);
  main {
    border-radius: 0.5rem;
    padding: 0 2rem;
    padding-top: 2rem;
    display: flex;
    height: auto;
    width: 100%;
    background: var(--white);
    flex-direction: column;
  }
`;

export const BoxPicker = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 768px) and (max-width: 1090px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export const BoxPickerDate = styled.div`
  .rs-btn-close .rs-icon {
    display: none;
  }
`;

export const BoxTableArea = styled.div`
  width: calc(100%);

  margin: 0 auto;
  background: transparent;

  display: flex;
  flex-direction: column;

  main {
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    padding-top: 5rem;
  }
`;

export const Menu = styled.div`
  width: 12rem;
  background: var(--gray-850);
  margin-top: 2.5rem;
  border-radius: 0 1rem 1rem 0;
  padding: 1rem 0 0 1rem;

  color: var(--gray-100);
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ReloadButton = styled.button`
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: var(--primary-700);
  svg {
    stroke: var(--white);
    fill: var(--white);
  }
  &:disabled {
    opacity: 0.5;
    svg {
      animation: ${rotate} 1s linear infinite;
    }
  }
  &:hover {
    opacity: 0.75;
  }
`;
