import * as XLSX from 'xlsx';

export class ExcelGenerator {
  static generateExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    const currencyFormat = '[$R$] #,##0.00';

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let row = range.s.r + 1; row <= range.e.r; row++) {
      ['B', 'C', 'D', 'E'].forEach((col) => {
        const cellAddress = `${col}${row + 1}`;
        const cell = worksheet[cellAddress];

        if (cell && !isNaN(cell.v)) {
          cell.v = parseFloat(cell.v);
          cell.z = currencyFormat;
          cell.t = 'n';
        }
      });
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    return XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  }
}
