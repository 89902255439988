import { useState } from 'react';
import styles from './styles.module.scss';

import appStore from '../../Assets/appstore.png';
import btnAppStore from '../../Assets/btn-appstore.png';
import btnGooglePlay from '../../Assets/btn-googleplay.png';
import googlePlay from '../../Assets/googleplay.png';
import logo from '../../Assets/logotextblack.svg';
import { getCookieUser } from '../../Helpers/cookies';
import { useUser } from '../../hooks/useUser';
import { formatDocument } from '../../utils/formatDocument';

export function AppTutorialModal() {
  const [step, setStep] = useState(0);
  const { user, userLogout } = useUser();

  const { twoFactor } =
    process.env.REACT_APP_ENV === 'prod' ? user : getCookieUser() || false;

  function nextStep(nextStep) {
    setStep(nextStep);
  }

  return (
    <div
      style={{ visibility: twoFactor ? 'visible' : 'hidden' }}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <img src={logo} alt="Oxpay" width={150} />
        <h2>Autenticação de dois fatores (2FA)</h2>
        <p>
          Para acessar a plataforma da Oxpay com mais segurança é necessário
          ativar a autenticação de dois fatores, por favor siga os passos
          abaixo:
        </p>
        <ul>
          <li onClick={() => nextStep(0)} className="active">
            <p>
              <strong>1º passo: </strong>
              {step >= 0 && (
                <p>
                  Baixe o aplicativo <strong>Oxpay</strong> disponível nas lojas
                  abaixo:
                </p>
              )}
            </p>
            {step === 0 && (
              <div className="step-active">
                <div>
                  <img src={googlePlay} alt="Google Play" height={120} />
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.oxpay.digital_account"
                    target="_blank"
                    rel="oxpay dashboard noreferrer"
                  >
                    <img src={btnGooglePlay} alt="Google Play" height={32} />
                  </a>
                </div>
                <div>
                  <img src={appStore} alt="App Store" height={120} />
                  <a
                    href="https://apps.apple.com/us/app/conta-oxpay/id6447763834"
                    target="_blank"
                    rel="oxpay dashboard noreferrer"
                  >
                    <img src={btnAppStore} alt="Google Play" height={32} />
                  </a>
                </div>
              </div>
            )}
          </li>
          <li onClick={() => nextStep(1)}>
            <p>
              <strong>2º passo: </strong>
              {step >= 1 && (
                <p>Acesse o app e clique em "Acessar minha conta"</p>
              )}
            </p>
            {step === 1 && (
              <div>
                <ul>
                  <li>
                    Informe o CPF cadastrado;
                    <span>
                      ( <strong>{formatDocument(user.document) || ''}</strong> )
                    </span>
                  </li>
                  <li>Clique em "Esqueci minha senha";</li>
                  <li>Informe o token de validação enviado no seu e-mail;</li>
                  <li>
                    Cadastre sua nova senha; <br />
                    <span>
                      Sua nova senha será utilizada para acessar o{' '}
                      <strong>app</strong> e a <strong>plataforma</strong>.
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li onClick={() => nextStep(2)}>
            <p>
              <strong>3º passo: </strong>
              {step >= 2 && <p>Agora você tem autenticação de dois fatores.</p>}
            </p>
            {step === 2 && (
              <div>
                <h4>Parabéns, sua conta está mais segura!</h4>
                <p>
                  <strong>Obs: </strong>Sempre que acessar a plataforma, deve-se
                  inserir o <strong>Token</strong> temporário de segurança, que
                  é encontrado no App em:
                  <strong> menu {'>'} token</strong>.
                </p>
              </div>
            )}
          </li>
        </ul>
        <div className="footer">
          <button className={styles.button} onClick={userLogout}>
            Finalizar
          </button>
        </div>
      </div>
    </div>
  );
}
