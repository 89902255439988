import { useUser } from '../../hooks/useUser';
import styles from './styles.module.scss';
import { getFormData } from '../../Helpers/dataForm';
import { BiRightArrowAlt } from 'react-icons/bi';
import logo from '../../Assets/logowhite.svg';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const { userResetPassword, loading, error } = useUser();

  function handleSubmit(event) {
    event.preventDefault();

    const data = getFormData(event);

    userResetPassword(data);
  }
  return (
    <div className={styles.MessageContainer}>
      {error?.login && <h2 className={styles.error}> {error.login}</h2>}

      <section
        className={
          loading ? `${styles.loading} ${styles.container}` : styles.container
        }
      >
        <div>
          <div className={styles.shadow}>
            <div className={styles.logoLeft}>
              <img className={styles.logo} src={logo} alt="logo" />
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Reset Password</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="e-mail"
              />

              {loading ? (
                <button className={styles.loading} disabled>
                  Carregando
                </button>
              ) : (
                <button type="submit">Enviar</button>
              )}
              <span className={styles.login}>
                <BiRightArrowAlt size="1rem" />
                <Link to="/">fazer login</Link>
              </span>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
