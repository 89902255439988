// import styles from './styles.module.scss';
// import { AiOutlineBarcode, AiOutlineCaretDown } from 'react-icons/ai';

const Vouchers = () => {
  return (
    <div />
    // <div className={styles.container}>
    //   <h1 className={styles.title}>Lista de faturas</h1>
    //   <section className={styles.section}>
    //     <div className={styles.card}>
    //       <div className={styles.left}>
    //         <AiOutlineBarcode size={32} />
    //         <span className={styles.number}>234564</span>
    //         <div className={styles.date}>
    //           <p>Vencimento</p>
    //           <p>09/10/2021</p>
    //         </div>
    //         <div className={styles.value}>
    //           <p>Valor total</p>
    //           <p>R$ 700,90</p>
    //         </div>
    //       </div>
    //       <div className={styles.up}>
    //         <span>Detalhes</span>
    //         <AiOutlineCaretDown size="1rem" />
    //       </div>
    //     </div>
    //     <div className={styles.card}>
    //       <div className={styles.left}>
    //         <AiOutlineBarcode size={32} />
    //         <span className={styles.number}>234564</span>
    //         <div className={styles.date}>
    //           <p>Vencimento</p>
    //           <p>09/10/2021</p>
    //         </div>
    //         <div className={styles.value}>
    //           <p>Valor total</p>
    //           <p>R$ 700,90</p>
    //         </div>
    //       </div>
    //       <div className={styles.up}>
    //         <span>Detalhes</span>
    //         <AiOutlineCaretDown size="1rem" />
    //       </div>
    //     </div>
    //     <div className={styles.card}>
    //       <div className={styles.left}>
    //         <AiOutlineBarcode size={32} />
    //         <span className={styles.number}>234564</span>
    //         <div className={styles.date}>
    //           <p>Vencimento</p>
    //           <p>09/10/2021</p>
    //         </div>
    //         <div className={styles.value}>
    //           <p>Valor total</p>
    //           <p>R$ 700,90</p>
    //         </div>
    //       </div>
    //       <div className={styles.up}>
    //         <span>Detalhes</span>
    //         <AiOutlineCaretDown size="1rem" />
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
};

export default Vouchers;
