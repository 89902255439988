export function IconReload({ size = '2.5rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" y="-0.005" fill="#ECF8FF" rx="20"></rect>
      <path
        stroke="#31519A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.063"
        d="M12.238 20.074a7.636 7.636 0 017.636-7.636 8.272 8.272 0 015.718 2.325l1.918 1.917m0 0v-4.242m0 4.242h-4.242m4.242 3.394a7.636 7.636 0 01-7.636 7.636 8.272 8.272 0 01-5.718-2.325l-1.918-1.917m0 0h4.242m-4.242 0v4.242"
      ></path>
    </svg>
  );
}

export function IconPlus({ size = '1.5rem', color = 'var(--white)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 22.745c-5.93 0-10.75-4.82-10.75-10.75S6.07 1.245 12 1.245s10.75 4.82 10.75 10.75-4.82 10.75-10.75 10.75zm0-20c-5.1 0-9.25 4.15-9.25 9.25s4.15 9.25 9.25 9.25 9.25-4.15 9.25-9.25-4.15-9.25-9.25-9.25z"
      ></path>
      <path
        fill={color}
        d="M16 12.745H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path
        fill={color}
        d="M12 16.745c-.41 0-.75-.34-.75-.75v-8c0-.41.34-.75.75-.75s.75.34.75.75v8c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}

export function IconReason({
  size = '1rem',
  color = 'var(--white)',
  fill = 'var(--orange-text)',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.82891 1.61141C7.67844 0.932033 7.01984 0.309689 6.33859 0.177502C5.92359 0.094533 4.76562 -0.00796694 4 1.80834e-06C3.23438 -0.00843569 2.07641 0.0937518 1.66141 0.177033C0.980156 0.309689 0.321562 0.932033 0.171875 1.61141C0.0853125 2.02391 0.00125 2.58516 0 3.35125C0.00125 4.11688 0.0853125 4.67828 0.171875 5.09094C0.322344 5.77031 0.980937 6.39266 1.66219 6.52485C1.88625 6.56953 2.32687 6.62016 2.80547 6.65578C2.85964 6.74089 2.91844 6.83151 2.98187 6.92766C3.23594 7.31235 3.43016 7.58031 3.57734 7.76703C3.82234 8.07766 4.17906 8.07766 4.42406 7.76703C4.57125 7.58031 4.76547 7.31235 5.01953 6.92766C5.08297 6.83141 5.14177 6.74078 5.19594 6.65578C5.67453 6.62016 6.11531 6.56953 6.33938 6.52485C7.02063 6.39266 7.67922 5.77031 7.82969 5.09094C7.91531 4.67828 7.99953 4.11719 8.00078 3.35125C7.99875 2.585 7.91453 2.02406 7.82891 1.61141Z"
        fill={color}
      />
      <path
        d="M4.56609 6.22697C4.55275 6.2893 4.52167 6.34645 4.47659 6.39153C4.43152 6.43661 4.37437 6.46769 4.31203 6.48102C4.10518 6.51306 3.89464 6.51306 3.68779 6.48102C3.62544 6.46773 3.56826 6.43667 3.52318 6.39158C3.47809 6.3465 3.44703 6.28932 3.43374 6.22697C3.40197 6.02017 3.40197 5.80973 3.43374 5.60293C3.44711 5.54067 3.47821 5.48361 3.52329 5.43864C3.56837 5.39367 3.6255 5.3627 3.68779 5.34947C3.89464 5.31744 4.10518 5.31744 4.31203 5.34947C4.3744 5.36274 4.43159 5.3938 4.47667 5.43889C4.52176 5.48398 4.55282 5.54116 4.56609 5.60353C4.59785 5.81033 4.59785 6.02077 4.56609 6.22757V6.22697ZM3.43213 0.927778C3.43213 0.777194 3.49195 0.632778 3.59843 0.526299C3.70491 0.41982 3.84933 0.360001 3.99991 0.360001C4.1505 0.360001 4.29491 0.41982 4.40139 0.526299C4.50787 0.632778 4.56769 0.777194 4.56769 0.927778V4.11242C4.56769 4.263 4.50787 4.40742 4.40139 4.5139C4.29491 4.62037 4.1505 4.68019 3.99991 4.68019C3.84933 4.68019 3.70491 4.62037 3.59843 4.5139C3.49195 4.40742 3.43213 4.263 3.43213 4.11242V0.927778Z"
        fill={fill}
      />
    </svg>
  );
}
