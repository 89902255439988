import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-svg-trans-pay-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-money-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(236, 253, 243);
    }
  }

  .svg-elem-1 {
    animation:
      animate-svg-trans-pay-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
        both,
      animate-money-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-money-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4129_14704');
    }
  }

  .svg-elem-2 {
    animation:
      animate-svg-trans-pay-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.12s both,
      animate-money-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-3 {
    0% {
      stroke-dashoffset: 128.23894500732422px;
      stroke-dasharray: 128.23894500732422px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.23894500732422px;
    }
  }

  .svg-elem-3 {
    animation:
      animate-svg-trans-pay-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.24s both,
      animate-money-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-svg-trans-pay-stroke-4 {
    0% {
      stroke-dashoffset: 72.27583312988281px;
      stroke-dasharray: 72.27583312988281px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 72.27583312988281px;
    }
  }

  @keyframes animate-money-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-4 {
    animation:
      animate-svg-trans-pay-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.36s both,
      animate-money-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-5 {
    0% {
      stroke-dashoffset: 35.49003219604492px;
      stroke-dasharray: 35.49003219604492px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 35.49003219604492px;
    }
  }

  @keyframes animate-money-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-5 {
    animation:
      animate-svg-trans-pay-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.48s both,
      animate-money-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }

  @keyframes animate-svg-trans-pay-stroke-6 {
    0% {
      stroke-dashoffset: 148.59646606445312px;
      stroke-dasharray: 148.59646606445312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 148.59646606445312px;
    }
  }

  @keyframes animate-money-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-6 {
    animation:
      animate-svg-trans-pay-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s
        both,
      animate-money-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
        both;
  }
`;

const IconMoneyPayment = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="6.125rem"
      height="3.25rem"
      fill="none"
      viewBox="0 0 98 52"
    >
      <path
        fill="#ECFDF3"
        d="M97 3C81.403 4.3 80.437 35.416 65 39c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V3z"
        className="svg-elem-1"
      ></path>
      <path
        fill="url(#paint0_linear_4129_14704)"
        d="M97 3C81.403 4.3 80.437 35.416 65 39c-12.73 2.955-19.176-13.82-32-12-13.91 1.974-18.36 19.454-32 24h96V3z"
        className="svg-elem-2"
      ></path>
      <path
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 51c13.64-4.546 18.09-22.026 32-24 12.824-1.82 19.27 14.955 32 12C80.437 35.416 81.403 4.3 97 3"
        className="svg-elem-3"
      ></path>
      <path
        fill="#12B76A"
        d="M61.633 19.323h-2.928c-1.913 0-3.465-1.61-3.465-3.593 0-.478.397-.875.875-.875s.875.397.875.875c0 1.015.77 1.843 1.715 1.843h2.928c.759 0 1.389-.676 1.389-1.493 0-1.015-.362-1.213-.957-1.423L57.364 13c-.91-.315-2.136-.992-2.136-3.08 0-1.797 1.412-3.243 3.139-3.243h2.928c1.913 0 3.465 1.61 3.465 3.593a.881.881 0 01-.875.875.881.881 0 01-.875-.875c0-1.015-.77-1.843-1.715-1.843h-2.928c-.758 0-1.389.676-1.389 1.493 0 1.015.362 1.213.957 1.423L62.637 13c.91.315 2.135.992 2.135 3.08-.012 1.785-1.412 3.243-3.139 3.243z"
        className="svg-elem-4"
      ></path>
      <path
        fill="#12B76A"
        d="M60 20.875a.881.881 0 01-.875-.875V6c0-.478.397-.875.875-.875s.875.397.875.875v14a.881.881 0 01-.875.875z"
        className="svg-elem-5"
      ></path>
      <path
        fill="#12B76A"
        d="M60 25.542c-6.918 0-12.542-5.624-12.542-12.542S53.082.458 60 .458 72.542 6.082 72.542 13 66.918 25.542 60 25.542zm0-23.334C54.05 2.208 49.208 7.05 49.208 13S54.05 23.792 60 23.792 70.792 18.95 70.792 13 65.95 2.208 60 2.208z"
        className="svg-elem-6"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4129_14704"
          x1="49"
          x2="49"
          y1="3"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.641" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconMoneyPayment };
