import styles from './styles.module.scss';
import logo from '../../Assets/logotextblack.svg';

import { useFeedback } from '../../hooks/useFeedBack';
import { useForm } from 'react-hook-form';
import { useUser } from '../../hooks/useUser';
import { memo } from 'react';
import { fireStoreSetModalFeedback } from '../../utils/firestoreActions';
const FeedBackModal = () => {
  const { openFeedModal, setOpenFeedModal } = useFeedback();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { user } = useUser();

  async function handleSubmitFormFeedBack(values) {
    setOpenFeedModal(false);
    reset();

    await fireStoreSetModalFeedback(user, values.accepted);
  }

  if (openFeedModal)
    return (
      <div className={styles.modal}>
        <form
          className={styles.modalBox}
          onSubmit={handleSubmit(handleSubmitFormFeedBack)}
        >
          <img className={styles.logo} src={logo} alt="logo ox-pay" />

          <div className={styles.container}>
            <h1>TERMOS DE USO SISTEMA OXPAY</h1>
            <p className={styles.text}>
              Por este instrumento particular, de um lado,
              <span>
                {user.fantasy_name} - {user.cnpj}
              </span>{' '}
              , doravante designada simplesmente CONVENIADA e; de outro lado, OX
              SERVIÇOS DE TECNOLOGIA LTDA, pessoa jurídica de direito privado,
              inscrita no CNPJ sob o nº 38.927.293/0001-20, situada na Av
              Paulista, 726 – Bela Vista, em São Paulo – SP, CEP 01310-910, por
              seu representante legal ao fim assinado, doravante designada
              simplesmente OXPAY; Ao aderir à Proposta Comercial, assinar o
              presente Contrato por meio físico ou eletrônico e/ou acessar a
              Plataforma e utilizar as funcionalidades disponibilizadas no
              Sistema OXPAY, a CONVENIADA estará automaticamente concordando com
              os termos e condições deste Contrato. Com a adesão a este
              Contrato, a CONVENIADA, de forma automática, também adere e aceita
              o Termo de Abertura de Conta de Pagamento. A OXPAY poderá
              periodicamente alterar as condições deste Contrato para adequar a
              prestação dos Serviços ou em razão de exigência legais e/ou dos
              órgãos reguladores. A utilização dos Serviços prestados pela OXPAY
              será considerada como a anuência do USUÁRIO/PORTADOR às alterações
              realizadas no Contrato, devendo o USUÁRIO/PORTADOR.
              <iframe
                title="política de privacidade"
                src="https://oxpay.com.br/politicas-de-privacidade"
                allowfullscreen
              ></iframe>
              <br />
              <p>
                {'  '} Link das politicas e privacidades:{' '}
                <a
                  href="https://oxpay.com.br/politicas-de-privacidade"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {' '}
                  Política e privacidade
                </a>
              </p>
            </p>
          </div>

          <div className={styles.accept}>
            <input
              type="checkbox"
              className={styles.checkbox}
              {...register('accepted', {
                required: 'Campo não pode está preenchido como vazio.',
              })}
              name="accepted"
              id="accepted"
            />
            Eu aceito os termos e condições apresentados neste documento.
          </div>

          <span className={styles.errors}>
            {errors?.accepted && errors.accepted.message}
          </span>
          <div className={styles.wrapper__button}>
            <button type="submit" disabled={errors?.accepted}>
              Confirmar
            </button>
          </div>
        </form>
      </div>
    );

  return <div style={{ display: 'none' }}></div>;
};

export default memo(FeedBackModal);
