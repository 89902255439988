import { useState } from 'react';

import styles from './styles.module.scss';
import { MdClose } from 'react-icons/md';

import { formatDocument } from '../../../../utils/formatDocument';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatAccount } from '../../../../utils/formatAccount';
import Status from '../../../../components/UI/Status';
import TooTip from '../../../../components/UI/ToolTip';
import { useUser } from '../../../../hooks/useUser';
import { useMovement } from '../../../../hooks/useMovement';

const CardMovements = ({
  name,
  document,
  bank_code,
  status,
  email,
  account,
  branch,
  account_type,
  account_digit,
  index,
  error_description,
  date,
  amount,
  id,
  method,
  bank_autentication_code,
  isDate,
  back,
  pix_qrcode,
  pix_key,
  order,
  company,
  customer,
  pix_end_to_end_id,
  // eslint-disable-next-line
  isFetching,
  additional_data,
}) => {
  const [card, setCard] = useState({ index: false, status: false });
  // eslint-disable-next-line
  const { fetchMovementKeyCancel, fetchMovementKeyApprove, loading } =
    useMovement();

  const { user } = useUser();
  // eslint-disable-next-line
  const handleCancel = async () => {
    await fetchMovementKeyCancel(id);
    setCard({ index: false, status: true });
  };
  const handleApprove = async () => {
    await fetchMovementKeyApprove(id);
    setCard({ index: false, status: true });
  };

  return (
    <div key={id} className={styles.cardMovements}>
      <div
        className={styles.heading}
        onClick={() => setCard({ index, status: true })}
      >
        <span className={styles.heading__cards}>{date}</span>
        <span className={styles.heading__name}>{name}</span>
        <span className={styles.heading__cards}>
          {formatDocument(document)}
        </span>
        <span className={styles.heading__cards}>
          {formatMoney(amount.toFixed(2))}
        </span>
        <span className={styles.heading__cards}> {method}</span>

        <span className={styles.heading__cards}>
          {error_description && status.id === 7 ? (
            <TooTip text={error_description}>
              <Status status={status} />
            </TooTip>
          ) : (
            <Status status={status} />
          )}
        </span>
      </div>
      {card.index === index && card.status ? (
        <div className={styles.flexCard}>
          <p className={styles.close}>
            <MdClose
              size={32}
              onClick={() => setCard({ index: false, status: true })}
            />
          </p>
          <div className={styles.wrapper_cards}>
            <div className={styles.payment}>
              <span>
                <h2>Pagamento</h2>
              </span>
              {name && (
                <span>
                  <p>Favorecido:</p> {name}
                </span>
              )}
              {document && (
                <span>
                  <p>Documento:</p> {document}
                </span>
              )}
              {email && (
                <span>
                  <p>Email:</p> {email}
                </span>
              )}
              {additional_data && (
                <span>
                  <p>Descrição:</p> {additional_data}
                </span>
              )}
              {bank_code && (
                <span>
                  <p>Código Banco:</p> {bank_code}
                </span>
              )}
              {account && (
                <span>
                  <p>Conta:</p> {account}
                </span>
              )}
              {account_digit && (
                <span>
                  <p>Digito Conta:</p> {account_digit}
                </span>
              )}
              {account_type && (
                <span>
                  <p>Tipo:</p>
                  {formatAccount(account_type)}
                </span>
              )}
              {branch && (
                <span>
                  <p>Agencia:</p>
                  {branch}
                </span>
              )}
              {date && (
                <span>
                  <p>Data:</p>
                  {date}
                </span>
              )}
              {amount && (
                <span>
                  <p>Valor:</p>
                  {formatMoney(amount.toFixed(2))}
                </span>
              )}

              {error_description && (
                <span>
                  <p>{status.id === 7 ? 'Erro:' : 'Atenção:'}</p>
                  {error_description}
                </span>
              )}

              {status && (
                <span>
                  <p>Status:</p>
                  {status.text}
                </span>
              )}
              {method && (
                <span>
                  <p>Método:</p>
                  {method}
                </span>
              )}
              {id && (
                <span className={styles.movement_key}>
                  <p>Movimento:</p>
                  {id}
                </span>
              )}
              {pix_key && (
                <span className={styles.movement_key}>
                  <p>Chave Pix:</p>
                  {pix_key}
                </span>
              )}
              {pix_qrcode && (
                <span className={styles.movement_key}>
                  <p>QR CODE:</p>
                  {pix_qrcode}
                </span>
              )}
            </div>

            {company && (
              <div className={styles.payment}>
                {order && (
                  <>
                    <span>
                      <h2>Lote</h2>
                    </span>
                    {order.description && (
                      <span>
                        <p> Descrição:</p> {order.description}
                      </span>
                    )}
                    {order.file_name && (
                      <span>
                        <p>Arquivo:</p> {order.file_name}
                      </span>
                    )}
                    {order.key && (
                      <span>
                        <p>Chave:</p> {order.key}
                      </span>
                    )}
                  </>
                )}
                <br />
                <span>
                  <h2>Empresa</h2>
                </span>
                {company.social_reason && (
                  <span>
                    <p>Nome:</p> {company.social_reason}
                  </span>
                )}
                {company.cnpj && (
                  <span>
                    <p>CNPJ:</p> {company.cnpj}
                  </span>
                )}
                <br />
                {customer && (
                  <>
                    <span>
                      <h2>Usuário</h2>
                    </span>

                    {customer.first_name && (
                      <span>
                        <p>Nome:</p>{' '}
                        {`${customer.first_name} ${customer.last_name}`}
                      </span>
                    )}
                    {customer.email && (
                      <span>
                        <p> Email :</p> {company.email}
                      </span>
                    )}
                    {customer.document && (
                      <span>
                        <p> Documento :</p> {formatDocument(customer.document)}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {[49].every((item) => user?.permission.includes(item)) && (
            <>
              {[10].includes(status?.id) && (
                <div className={styles.wrapper_button}>
                  <button
                    onClick={handleCancel}
                    className={styles.button}
                    disabled={loading?.movement || isFetching}
                  >
                    {loading?.movement || isFetching
                      ? 'Carregando ...'
                      : 'Reprovar'}
                  </button>
                  <button
                    onClick={handleApprove}
                    className={styles.button}
                    disabled={loading?.movement || isFetching}
                  >
                    {loading?.movement || isFetching
                      ? 'Carregando ...'
                      : 'Aprovar'}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CardMovements;
