export function checkDuplicateObjects(array) {
  if (array.length === 1) {
    return { res: false, data: [] };
  }

  const seen = {};
  const duplicates = [];
  const uniqueKeys = {};

  array.forEach((obj) => {
    const { id, movementKey, ...rest } = obj;
    const key = JSON.stringify(rest);

    if (
      seen[key] &&
      !uniqueKeys[key] &&
      [3, 1].includes(seen[key]?.status.id)
    ) {
      duplicates.push(seen[key]);
      duplicates.push(obj);
      uniqueKeys[key] = true;
    } else {
      seen[key] = obj;
    }
  });

  const res = duplicates.length > 0 ? true : false;
  return { res, data: duplicates };
}
