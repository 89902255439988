import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonGoBack from '../../components/ButtonGoBack';
import FormGroup from './Components/FormGroup';
import * as yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';

import styles from './styles.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiGet } from '../../services/api';
import { useUser } from '../../hooks/useUser';
import { FaApplePay, FaGooglePlay } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';

import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { IconFinally } from './icon';
import TooTip from '../../components/UI/ToolTip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNotification } from '../../hooks/useNotifications';

const ValidateAddCompany = yup.object().shape({
  document: yup
    .string()
    .typeError('O campo deve receber um cpf.')
    .test(
      'is-cpf',
      'CPF ou CNPJ não é valido',
      (value) => validateCPF(value) || validateCNPJ(value),
    )
    .required('document é obrigatório'),
});

const Card = ({ link, Icon, isCopied, handleCopyClick }) => {
  return (
    <div className={styles.card_box}>
      {isCopied === link && (
        <div className={styles['check-wrap-box']}>
          <div className={styles['check-wrap']}></div>
        </div>
      )}

      <div className={styles.card_copy}>
        <TooTip text={'Copiar'}>
          <CopyToClipboard text={link}>
            <button onClick={() => handleCopyClick(link)}>
              <FiCopy size={'24px'} color={'var(--gray-400)'} />
            </button>
          </CopyToClipboard>
        </TooTip>
      </div>

      <Icon />
    </div>
  );
};
export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const { setMessage } = useUser();
  const [setUser] = useState(false);
  const [clear] = useState(false);
  const [steps, setSteps] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [type, setType] = useState('false');
  const { addNotification } = useNotification();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ resolver: yupResolver(ValidateAddCompany) });

  const isCpfOrCNPJ = (value) => {
    const documentNotMask = value.replace(/[^\d]+/g, '');

    if (documentNotMask.length === 11) {
      return validateCPF(documentNotMask) && 'cpf';
    } else if (documentNotMask.length === 14) {
      return validateCNPJ(documentNotMask) && 'cnpj';
    } else {
      return false;
    }
  };
  const handleCopyClick = (text) => {
    setIsCopied(text);
  };
  const handleVerifyDocument = () => {
    const { document } = getValues();

    const isDocument = isCpfOrCNPJ(document);

    if (isDocument === 'cpf') {
      setType('cpf');
      setSteps(1);
    }
    if (isDocument === 'cnpj') {
      setType('cnpj');
      setSteps(1);
    }
    if (!isDocument) {
      addNotification('Documento não é valido', 2);
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const document = values.document.replace(/[^\d]+/g, '');
      const { data, status } = await apiGet(`/Customer/Document/${document}`);

      if (!data) {
        setValue('document', false);
        setUser(false);
        setSteps(3);
        setMessage({
          login: 'Conta não encontrado, crie a conta para começar!',
        });
      }
      if (data) {
        setSteps(1);
      }

      if (status === 200) {
        setUser({
          user: {
            customer_id: data?.customer_id,
            profile: data?.profile,
            company_id: data?.company_id,
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            telephone: data?.telephone,
            salary: data?.salary,
            is_active: data?.isActive,
            signed_term: data?.signed_term,
            document: data?.document,
            date_added: data?.date_added,

            addresses: data?.addresses,
          },
          bank_account: data?.bank_account,
          vehicles: data?.vehicles,
        });
      }
    } catch (error) {
      setUser(false);
    } finally {
      setLoading(false);
    }
  };
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <section ref={scrollRef} className={styles.container}>
      <div className={styles.back}>
        <ButtonGoBack />
      </div>
      <div className={styles.box}>
        <div className={styles.header}>
          <h2>Cadastrar nova conta</h2>
          <p>
            Adicione as contas que você tera um vinculo, para transferências e
            demais serviços.
          </p>
        </div>
        <div className={styles.list}>
          <div className={styles.list_ranger}>
            <div
              className={
                [0, 1, 2, 3, 4].includes(steps) ? styles.ball_ : styles.ball
              }
            >
              <div> </div>
            </div>
            <div
              className={
                [1, 2, 3, 4].includes(steps) ? styles.ball_ : styles.ball
              }
            >
              <p></p>
              <div> </div>
            </div>
            <div
              className={
                [1, 2, 3, 4].includes(steps) ? styles.ball_ : styles.ball
              }
            >
              <p></p>
              <div> </div>
            </div>
            <div
              className={[2, 4].includes(steps) ? styles.ball_ : styles.ball}
            >
              <p></p>
              <div> </div>
            </div>
            {steps}
          </div>
          <div className={styles.list_ranger_text}>
            <div className={styles.text}>
              <h3>Início </h3>
              <p>Procure a conta para criação de vinculo.</p>
            </div>
            <div className={styles.text}>
              <h3>Criar </h3>
              <p>Criar Pré cadastro </p>
            </div>
            <div className={styles.text}>
              <h3>Confirmar </h3>
              <p>Verifique e confirme as informações</p>
            </div>
            <div className={styles.text}>
              <h3>Resumo </h3>
              <p>Breve resumo na criação de vinculo</p>
            </div>
          </div>
        </div>
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          {steps === 0 && (
            <div className={styles.step_one}>
              <header>
                <h2>Início</h2>
                <p>
                  Vamos verificar se esta pessoa já possui uma cadastro na
                  Oxpay. Insira o número do CPF ou CNPJ do novo colaborador.{' '}
                </p>
              </header>
              <div className={styles.search_user}>
                <FormGroup
                  name="document"
                  label="Documento:"
                  type="text"
                  placeholder="999.999.999-99"
                  data={{ ...register('document') }}
                  disabled={loading}
                  register={register}
                  errors={errors || { document: 'Documento inválido' }}
                  //onBlur={checkCNPJ}
                  mask={['999.999.999-99', '99.999.999/9999-99']}
                  setValue={setValue}
                  clean={clear}
                />
                <button
                  className={styles.button}
                  type="button"
                  onClick={handleVerifyDocument}
                >
                  Próximo <AiOutlineArrowRight color="var(--white)" size={24} />
                </button>
              </div>
            </div>
          )}

          {steps === 1 && (
            <>
              <div className={styles.step_two}>
                <header>
                  <h2>Informação da conta.</h2>
                  <p>
                    Confira as informações antes de confirmar, é muito
                    importante pois essa conta vai ser adicionada a sua lista de
                    contas.
                  </p>
                </header>
                <div>
                  {type === 'cpf' && (
                    <div className={styles.content}>
                      <div>
                        <label>First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          {...register('first_name', {
                            required: 'First name is required',
                            maxLength: {
                              value: 80,
                              message: 'First name is too long',
                            },
                          })}
                        />
                        {errors.first_name && (
                          <p>{errors.first_name.message}</p>
                        )}

                        <label>Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          {...register('last_name', {
                            required: 'Last name is required',
                            maxLength: {
                              value: 100,
                              message: 'Last name is too long',
                            },
                          })}
                        />
                        {errors.last_name && <p>{errors.last_name.message}</p>}

                        {/* Rest of the fields and buttons */}
                      </div>
                    </div>
                  )}
                  {type === 'cnpj' && (
                    <div className={styles.content}>cnpj</div>
                  )}
                </div>
              </div>
            </>
          )}

          {steps === 2 && (
            <div className={styles.step_three}>
              <IconFinally />
              <header>
                <h2>Finalizado com Sucesso!</h2>
                <p>Conta adicionada com sucesso!</p>
              </header>
            </div>
          )}
          {steps === 4 && (
            <div className={styles.step_three}>
              <IconFinally />
              <header>
                <h2>Finalizado com Sucesso!</h2>
                <p>
                  Em breve, agora é só esperar a criação da conta, para que
                  desfrute de todos os recursos e serviços oferecidos pela
                  OxPay!
                </p>
              </header>
            </div>
          )}

          {steps === 1 && (
            <div className={styles.wrapper_button_add}>
              <button
                type="button"
                onClick={() => setSteps(0)}
                className={styles.button_add}
              >
                <AiOutlineArrowLeft color="var(--white)" size={20} />
                Voltar
              </button>
              <button
                type="button"
                onClick={async () => {
                  await trigger('last_name');
                }}
                className={styles.button_add}
              >
                Confirmar
                <AiOutlineArrowRight color="var(--white)" size={20} />
              </button>
            </div>
          )}

          {steps === 3 && (
            <div className={styles.card_links}>
              <header>
                <h2>Criar novo cadastro</h2>
                <p>
                  Verificamos e o seu colaborador ainda não está cadastrado na
                  OxPay. <br /> Copie o link abaixo e peça para que ele instale
                  o aplicativo no celular e crie uma conta. É super rápido!
                </p>
              </header>
              <div className={styles.cards_box}>
                <Card
                  link="https://bit.ly/oxpay"
                  Icon={() => (
                    <FaGooglePlay size={'1.875rem'} color={'var(--primary)'} />
                  )}
                  handleCopyClick={handleCopyClick}
                  isCopied={isCopied}
                />
                <Card
                  link="https://apple.co/3qIa8DE"
                  Icon={() => (
                    <FaApplePay size={'3.5rem'} color={'var(--primary)'} />
                  )}
                  handleCopyClick={handleCopyClick}
                  isCopied={isCopied}
                />
              </div>
            </div>
          )}
          {steps === 3 && (
            <div className={styles.wrapper_button_add}>
              <button
                type="button"
                onClick={() => setSteps(0)}
                className={styles.button_add}
              >
                <AiOutlineArrowLeft color="var(--white)" size={'1rem'} />
                Voltar
              </button>
              <button
                type="button"
                onClick={() => setSteps(4)}
                className={styles.button_add}
              >
                Confirmar
                <AiOutlineArrowRight color="var(--white)" size={'1rem'} />
              </button>
            </div>
          )}
          {[2, 4].includes(steps) && (
            <div className={styles.wrapper_button_add}>
              <button
                type="button"
                onClick={() => setSteps(0)}
                className={styles.button_finally}
              >
                Cadastrar outra conta?
              </button>
            </div>
          )}
        </form>
      </div>
    </section>
  );
}
