import { Steps } from 'rsuite';
import styles from './styles.module.scss';
import { PaymentTransfer } from './Components/PaymentTransfer';
import { PaymentMenu } from './Components/PaymentMenu';
import { usePayment } from '../../../../hooks/usePayment';
import { PaymentFile } from './Components/PaymentFile';
import { memo } from 'react';

function StepsPayment() {
  const {
    stepPayment,
    setStepPayment,
    typePayment,
    setTypePayment,
    errosSteps,
  } = usePayment();

  const typePaymentMethod = [
    {
      id: 0,
      description: 'select-type',
      title: 'Tipo de Liberação',
      Component: () => (
        <PaymentMenu
          setTypePayment={setTypePayment}
          setStepPayment={setStepPayment}
          stepPayment={stepPayment}
        />
      ),
    },
    {
      id: 2,
      description: 'transfer-internal',
      title: 'Antecipação Individual',
      Component: () => <PaymentTransfer />,
    },
    {
      id: 3,
      description: 'transfer-order',
      title: 'Antecipação em Lote',
      Component: () => <PaymentFile />,
    },
  ];

  const CurrentComponent = typePaymentMethod.find(
    (item) => item.id === typePayment,
  )?.Component;

  const title = typePaymentMethod.find(
    (item) => item.id === typePayment,
  )?.title;

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <Steps current={stepPayment} currentStatus={errosSteps.status}>
        <Steps.Item
          status={errosSteps.step === 0 ?? errosSteps.status}
          description={errosSteps.step === 0 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 1 ?? errosSteps.status}
          description={errosSteps.step === 1 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 2 ?? errosSteps.status}
          description={errosSteps.step === 2 ? errosSteps.mensage : false}
        />
        <Steps.Item
          status={errosSteps.step === 3 ?? errosSteps.status}
          description={errosSteps.step === 3 ? errosSteps.mensage : false}
        />
      </Steps>

      {CurrentComponent && <CurrentComponent />}
    </section>
  );
}

export default memo(StepsPayment);
