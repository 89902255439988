export function formatMoney(amount, currencyDisplay) {
  const options = {
    style: 'currency',
    currency: 'BRL',
  };

  if (currencyDisplay === 'code') {
    options.currencyDisplay = 'code';
  }
  return new Intl.NumberFormat('pt-BR', options)
    .format(amount)
    .replace('BRL', '')
    .trim();
}
