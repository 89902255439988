import styles from './styles.module.scss';
import { memo } from 'react';
import { formatMoney } from '../../../../utils/formatMoney';
const Modal = ({ setModal, modal, handleFetchEdit, amount, name }) => {
  function handleOutsideClick(e) {
    if (e.target === e.currentTarget) {
      setModal(null);
    }
  }
  function handleConfirm() {
    handleFetchEdit();
    setModal(false);
  }

  return (
    <div className={`${styles.modal}`} onClick={handleOutsideClick}>
      <div className={styles.modalBox}>
        <div className={styles.title}>Desativar Usuário</div>
        <span className={styles.text}>
          Você está desativando o usuário
          <span className={styles.name}>{name} </span>, que possui saldo devedor
          de
          <span className={styles.amount}>{formatMoney(amount)}</span>. O mesmo
          deve ser descontado na rescisão do colaborador, mediante o
          desligamento.
        </span>
        <div className={styles.description}>
          O valor informado acima será incluído no próximo relatório. Caso haja
          valores enviados no relatório anterior para este colaborador, os
          mesmos também devem ser descontados.
        </div>

        <div className={styles.wrapper_button}>
          <button onClick={handleConfirm}>Sim</button>
          <button
            onClick={() => {
              setModal(false);
            }}
          >
            Não
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Modal);
