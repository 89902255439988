import { subDays } from 'date-fns';
import { createContext, useState } from 'react';
import { handleDataRanger } from '../utils/handleDateRanger';

export const StoreContext = createContext();
const { Provider } = StoreContext;

export default function StoreProvider({ children }) {
  const [user, setUser] = useState(false);
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLinkClicked, setIsLinkClicked] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [error, setError] = useState(false);
  const [orderKey, setOrderKey] = useState({ key: false, status: false });
  const [order, setOrder] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const [listMovement, setListMovement] = useState([]);
  const [orderTransfer, setOrderTransfer] = useState(false);
  const [message, setMessage] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [active, setActive] = useState(false);
  const [expend, setExpend] = useState(false);
  const [bankSlipInfo, setBankSlipInfo] = useState(false);

  //companies
  const [allBalance, setAllBalance] = useState([]);
  const [companies, setCompanies] = useState([]);

  //extract
  const [extract, setExtract] = useState(false);

  //date
  const [dateExtract, setDateExtract] = useState([
    subDays(new Date(), 6),
    new Date(),
  ]);
  const [datePayment, setDatePayment] = useState([
    subDays(new Date(), 6),
    new Date(),
  ]);
  const [dateAnticipation, setDateAnticipation] = useState([
    subDays(new Date(), 6),
    new Date(),
  ]);
  const [checkPickerCompanies, setCheckPickerCompanies] = useState();
  const [checkPickerServices, setCheckPickerServices] = useState([]);
  const [checkPickerNames, setCheckPickerNames] = useState([]);
  const [checkPickerDocuments, setCheckPickerDocuments] = useState([]);
  const [checkPickerTypes, setCheckPickerTypes] = useState([]);
  const [pageExtractSelect, setPageExtractSelect] = useState(false);
  const [startExtract, setStartExtract] = useState(0);

  //balance
  const [dataBalance, setDataBalance] = useState(false);
  const [balance, setBalance] = useState(false);
  const [balanceConsignable, setBalanceConsignable] = useState(false);
  const [limits, setLimits] = useState(false);
  //modal
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [openFeedModal, setOpenFeedModal] = useState(false);
  //search document
  const [searchDocument, setSearchDocument] = useState([]);
  //drop menu
  const [dropMenu, setDropMenu] = useState(false);
  //consignment
  const [listConsignment, setListConsignment] = useState([]);
  const [listConsignable, setListConsignable] = useState([]);
  const [notifications, setNotifications] = useState([]);
  //Collaborator
  const [selectCheckedTags, setSelectCheckedTags] = useState([]);
  const [statusCollaboratorSelect, setStatusCollaboratorSelect] = useState([]);
  //Transfer
  const [statusTransferSelect, setStatusTransferSelect] = useState([]);
  //Anticipation
  const [statusAnticipationSelect, setStatusAnticipationSelect] = useState([]);
  //notify
  const [widgetConsignable, setWidgetConsignable] = useState(false);
  const [widgetTransfer, setWidgetTransfer] = useState(false);
  //tags
  const [tags, setTags] = useState([]);
  const [tagsRef, setTagsRef] = useState([]);
  const [tagsPriority, setTagsPriority] = useState([]);
  //state pages
  const [pagesCurrent, setPagesCurrent] = useState({});
  //customer List
  const [customerListData, setCustomerListData] = useState([]);

  if (error || message) {
    setTimeout(() => {
      setError(false);
      setMessage(false);
    }, 5000);
  }
  //date transfer
  const [dateRanger, setDateRanger] = useState(
    handleDataRanger(subDays(new Date(), 6), new Date()),
  );
  //date extract
  const [dateRangerExtract, setDateRangerExtract] = useState(
    handleDataRanger(subDays(new Date(), 6), new Date()),
  );
  //date bankSlip
  const [dateRangerBankSlip, setRangerBankSlip] = useState([
    subDays(new Date(), 6),
    new Date(),
  ]);
  //nps
  const [beforeLogout, setBeforeLogout] = useState(false);
  return (
    <Provider
      value={{
        //order
        setOrderTransfer,
        orderTransfer,
        orderKey,
        setOrderKey,
        order,
        setOrder,
        listOrder,
        setListOrder,
        listMovement,
        setListMovement,
        bankSlipInfo,
        setBankSlipInfo,
        //user
        user,
        setUser,
        //login
        login,
        setLogin,
        //load
        loading,
        setLoading,
        //errors
        error,
        setError,
        //message
        message,
        setMessage,
        //customers
        customers,
        setCustomers,
        //active
        setActive,
        active,

        //balance
        setBalance,
        balance,
        dataBalance,
        setDataBalance,
        limits,
        setLimits,
        //loading company
        loadingCompany,
        setLoadingCompany,

        //feed back Modal
        feedBackModal,
        setFeedBackModal,
        //modal is open in close
        openFeedModal,
        setOpenFeedModal,
        //search document
        searchDocument,
        setSearchDocument,
        //menu tab
        expend,
        setExpend,
        //all balances companies
        allBalance,
        setAllBalance,
        //drop menu
        dropMenu,
        setDropMenu,

        //companies
        companies,
        setCompanies,
        //extract
        extract,
        setExtract,
        //date
        dateExtract,
        setDateExtract,
        datePayment,
        setDatePayment,
        dateAnticipation,
        setDateAnticipation,
        //consignment
        listConsignment,
        setListConsignment,
        balanceConsignable,
        setBalanceConsignable,
        listConsignable,
        setListConsignable,
        //picker companies
        checkPickerTypes,
        setCheckPickerTypes,
        checkPickerCompanies,
        setCheckPickerCompanies,
        checkPickerDocuments,
        setCheckPickerDocuments,
        checkPickerServices,
        setCheckPickerServices,
        checkPickerNames,
        setCheckPickerNames,
        pageExtractSelect,
        setPageExtractSelect,
        startExtract,
        setStartExtract,
        notifications,
        setNotifications,

        //notify
        widgetTransfer,
        setWidgetTransfer,
        widgetConsignable,
        setWidgetConsignable,
        //tags
        tags,
        setTags,
        tagsRef,
        setTagsRef,
        tagsPriority,
        setTagsPriority,
        //dates Rangers
        dateRanger,
        setDateRanger,
        dateRangerExtract,
        setDateRangerExtract,
        //pages
        pagesCurrent,
        setPagesCurrent,
        //Collaborator
        selectCheckedTags,
        setSelectCheckedTags,
        statusCollaboratorSelect,
        setStatusCollaboratorSelect,
        //Transfer
        statusTransferSelect,
        setStatusTransferSelect,
        //Anticipation
        statusAnticipationSelect,
        setStatusAnticipationSelect,
        //clicked
        isLinkClicked,
        setIsLinkClicked,
        //nps
        beforeLogout,
        setBeforeLogout,
        //customer list data
        customerListData,
        setCustomerListData,
        //data bank slip
        dateRangerBankSlip,
        setRangerBankSlip,
      }}
      s
    >
      {children}
    </Provider>
  );
}
