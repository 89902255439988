import { validateCNPJ, validateCPF } from 'validations-br';
import { formatDocument } from './formatDocument';

export const formatCompaniesValues = (array) => {
  return array.map((item) => ({
    label: `${item.fantasy_name ?? 'SEM NOME'}  - ${
      validateCPF(formatDocument(item.document))
        ? 'CPF:'
        : validateCNPJ(formatDocument(item.document))
        ? 'CNPJ:'
        : ' '
    }  ${item.document}`,
    value: item.company_id,
    title: item.fantasy_name ?? item.accountable,
    social_name: item.social_name,
    document: item.cnpj ?? item.cpf,
  }));
};
