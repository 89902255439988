import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiGet, apiPost, apiPut } from '../services/api';

export function useTags() {
  const {
    tags,
    setTags,
    tagsRef,
    setTagsRef,
    setError,
    setLoading,
    loading,
    tagsPriority,
    setTagsPriority,
    user,
  } = useContext(StoreContext);

  const fetchTags = async () => {
    setLoading(true);

    try {
      if ([62].every((item) => user?.permission.includes(item))) {
        const { data } = await apiGet('/company/tags');

        const tagsFormatPriority = data
          .sort((a, b) => b.priority - a.priority)
          .map((item, index) => {
            return {
              id: `${index}ujhdss`,
              title: item.tag,
              priority: item.priority,
            };
          });
        setTagsPriority(tagsFormatPriority);
        const tagsResult = data.map((item) => item.tag);
        setTagsRef(tagsResult);
      } else {
        const { data } = await apiGet('/Onboarding/tags');
        setTagsRef(data);
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      setLoading(false);
    }
  };

  const AddedTags = async (keys = [], tags = []) => {
    try {
      setLoading(true);
      let statusRes;
      if (keys.length === 0) {
        const tagsArray = tags.map((item) => {
          return { tag: item, priority: 0 };
        });
        const { status } = await apiPut('/Company/tags', {
          tags_to_add: tagsArray,
        });
        statusRes = status;
      } else {
        const { status } = await apiPost('/Onboarding/update/tags', {
          onboarding_keys: keys,
          tags_to_add: tags,
        });
        statusRes = status;
      }
      if (statusRes === 200) {
        return true;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      fetchTags();
      setLoading(false);
    }
  };
  const updateTags = async (userTags = [], newTags = [], keys) => {
    const addedTags = newTags.filter((tag) => !userTags.includes(tag));
    const removedTags = userTags.filter((tag) => !newTags.includes(tag));

    try {
      setLoading(true);
      const { status } = await apiPost('/Onboarding/update/tags', {
        onboarding_keys: [keys],
        tags_to_add: addedTags,
        tags_to_remove: removedTags,
      });
      if (status === 200) {
        return true;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      fetchTags();
      setLoading(false);
    }
  };

  const removeTag = async (tag = [], key = []) => {
    try {
      setLoading(true);
      const { status } = await apiPost('/Onboarding/update/tags', {
        onboarding_keys: key,
        tags_to_add: [],
        tags_to_remove: tag,
      });
      if (status === 200) {
        return true;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      fetchTags();
      setLoading(false);
    }
  };

  const removeTagCompany = async (tag = []) => {
    try {
      setLoading(true);
      const { status } = await apiPut('/Company/tags', {
        tags_to_remove: tag,
      });
      if (status === 200) {
        return true;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      fetchTags();
      setLoading(false);
    }
  };
  const priorityTagCompany = async (tag = []) => {
    try {
      setLoading(true);
      const { status } = await apiPut('/Company/tags', {
        tags_to_add: tag,
      });
      if (status === 200) {
        return true;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
      }
    } finally {
      fetchTags();
      setLoading(false);
    }
  };

  return {
    tagsPriority,
    tagsRef,
    loading,
    tags,
    setTags,
    priorityTagCompany,
    removeTagCompany,
    AddedTags,
    updateTags,
    removeTag,
    fetchTags,
  };
}
