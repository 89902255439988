import React, { useState } from 'react';
import { MdPayments } from 'react-icons/md';
import { formatMoney } from '../../../../../../utils/formatMoney';
import styles from './styles.module.scss';
import FormGroup from './FormGroup';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { Loader } from 'rsuite';
import { useUser } from '../../../../../../hooks/useUser';
import { IconLike, IconMoney, IconPerson, IconProof } from '../icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { parseCurrencyToNumber } from './utils/parseCurrencyToNumber';
import { validateCNPJ, validateCPF } from 'validations-br';
import { useOrder } from '../../../../../../hooks/useOrder';

import { useHistory } from 'react-router-dom';
import { usePayment } from '../../../../../../hooks/usePayment';
import { useNotification } from '../../../../../../hooks/useNotifications';
import { addHoursAndFormat } from './utils/addHoursAndFormat';

import { formatDocument } from '../../../../../../utils/formatDocument';
import { IconDocument, IconValue, IconDate, IconBook } from './icons';
import { convertDate } from './utils/convertDate';

const Validate = yup.object().shape({
  document: yup
    .string()
    .typeError('O campo deve receber um documento Valido.')
    .test('is-document', 'Documento não é valido', function (value) {
      return validateCPF(value) || validateCNPJ(value);
    })
    .required('Chave é obrigatória'),
  description: yup.string().required('Descrição é obrigatória'),
  amount: yup
    .string()
    .typeError('O campo deve receber um valor.')
    .required('Valor é obrigatório')
    .test(
      'is-amount',
      'O campo deve receber um valor em real valido e maior que R$ 0,01.',
      (value) => {
        const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

        const testRegex = regex.test(value);

        const numericValue = parseFloat(
          value.replace(/[.,]/g, '').replace(',', '.'),
        );

        if (!isNaN(numericValue) && numericValue >= 0.01 && testRegex) {
          return true;
        } else {
          return false;
        }
      },
    ),
  is_salary: yup.string().notRequired(),
});

const PaymentTransfer = () => {
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,
    handleSalary,
  } = usePayment();
  const today = new Date().toISOString().split('T')[0];
  const { addNotification } = useNotification();

  const [clear] = useState(false);
  const { user } = useUser();

  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );

  const {
    fetchTransferOrder,
    fetchOrderKeyValidation,
    fetchOrderKeyCancel,
    fetchOrderKey,
    fetchOrderPreview,
  } = useOrder();
  const history = useHistory();

  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${finishPayment.key}`,
      state: {
        ...finishPayment,
      },
    });
    clearStatePayment();
  };

  const handleOrderKey = () => {
    history.push({
      pathname: `/menu/order/${finishPayment.key}`,
    });
    clearStatePayment();
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(Validate),
    defaultValues: {
      date: payment?.date
        ? convertDate(payment?.date)
        : new Date().toISOString().split('T')[0],
      document: payment?.document ? payment?.document.replace(/\D/g, '') : '',
      amount: payment?.amount ? payment?.amount : '',
      description: payment?.description || '',
      is_salary: payment?.is_salary || false,
    },
  });

  const formDate = watch('date');
  const description_form = watch('description');
  const amount_form = watch('amount');
  const document_form = watch('document');

  const onBackStepCancelOrder = async () => {
    try {
      setLoading({ paymentOrderCancel: true });
      if (payment) {
        if (payment.status.id !== 7) {
          await fetchOrderKeyCancel(payment.key, 3);
        }
      }
      setStepPayment(1);
    } catch (error) {
      console.log('🚀 error:', error);
    }
  };
  const onSubmit = async (values) => {
    try {
      setLoading({ paymentOrder: true });

      const { document, amount, date, description } = values;
      const is_salary_format = !Boolean(
        values.is_salary.toLowerCase() === 'true',
      );

      const documentFormatted = document.replace(/\D/g, '');

      const data = await fetchTransferOrder({
        body: {
          description: description,
          movements: [
            {
              additional_data: description,
              document: documentFormatted,
              amount: parseCurrencyToNumber(amount),
              bank_code: 'OX',
              ...(date !== today && {
                date: addHoursAndFormat(date, 3),
              }),
              is_salary: is_salary_format,
            },
          ],
        },
        isManual: true,
        isSalary: is_salary_format,
      });

      if (!!data) {
        await fetchOrderPreview(data?.key);
        const { movements, order } = await fetchOrderKey(data.key);

        if (movements[0]) {
          const paymentInfo = {
            ...movements[0],
            amount: formatMoney(movements[0]?.amount, 'code'),
            name: movements[0]?.name,
            bank_code: movements[0].bank_code,
            bank_name: 'OX PAY',
            document: movements[0].document,
            date: movements[0].date,
            description: movements[0].additional_data,
            key: order?.key,
            orderKey: order?.key,
            is_salary: !is_salary_format,
          };

          setPayment(paymentInfo);
          setStepPayment(2);
          setErrosSteps({
            status: 'process',
          });
        }
      } else {
        setPayment({ document, amount, date, description });
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Documento não encontrado',
        });

        addNotification(
          <>
            Não foi encontrado dados do documento informado! <br />
            Por favor, verifique o CPF/CNPJ e tente novamente.
          </>,
          2,
          'Conta não encontrada',
          true,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    setLoading({ paymentOrder: true });

    try {
      if (payment.key) {
        await fetchOrderKeyValidation(payment.key, 3);
        const { movements } = await fetchOrderKey(payment?.key);

        if (payment) {
          setFinishPayment({
            ...movements[0],
            key: payment.key,
            orderKey: payment.orderKey,
          });
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="document"
              label={
                <>
                  <strong>Documento </strong> (CPF / CNPJ):
                </>
              }
              type="text"
              data={{ value: payment?.document }}
              placeholder={'999.999.999-99'}
              disabled={!loading?.paymentOrder ? false : true}
              mask={['999.999.999-99', '99.999.999/9999-99']}
              register={register}
              errors={errors}
              setValue={setValue}
              clean={clear}
              Icon={IconDocument}
            />
            <FormGroup
              name="amount"
              label={
                <>
                  <strong>Valor: </strong>
                </>
              }
              data={{ value: payment?.amount }}
              type="text"
              mask={[
                '9,99',
                '99,99',
                '999,99',
                '9.999,99',
                '99.999,99',
                '999.999,99',
                '9.999.999,99',
              ]}
              placeholder="R$ 0,00"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconValue}
            />
            <FormGroup
              name="date"
              label={
                <>
                  <strong>Quando: </strong>{' '}
                  {today === formDate ? 'Hoje' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              min={new Date().toISOString().split('T')[0]}
              errors={errors}
              setValue={setValue}
              data={{
                value: payment?.date
                  ? convertDate(payment.date)
                  : new Date().toISOString().split('T')[0],
              }}
              Icon={IconDate}
            />
          </div>

          <div className={styles.form_wrapper_}>
            <FormGroup
              name="description"
              label={
                <>
                  <strong>Descrição: </strong>
                </>
              }
              type="text"
              placeholder="Pagamento"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              data={{ value: payment?.description }}
              errors={errors}
              setValue={setValue}
              Icon={IconBook}
            />

            <div className={styles.buttons_form}>
              <button
                type="button"
                disabled={loading.paymentOrder}
                className={styles.button_cancel}
                onClick={clearStatePayment}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={styles.button}
                disabled={loading.paymentOrder}
              >
                Confirmar
                {loading.paymentOrder && <Loader size="xs " />}
              </button>
            </div>
          </div>
          <div className={styles.form_wrapper}>
            <div className={styles.wrapper_radio}>
              <div>
                <p>
                  <input
                    type="checkbox"
                    id="is_salary"
                    name="is_salary"
                    {...register('is_salary')}
                    value={payment?.is_salary}
                    defaultChecked={payment?.is_salary ?? false}
                    onChange={({ target }) => {
                      handleSalary({
                        date: formDate,
                        is_salary: target.checked,
                        description: description_form,
                        document: document_form,
                        amount: amount_form,
                      });
                    }}
                  />

                  <label htmlFor="is_salary" for="is_salary">
                    Não descontar a antecipação.
                  </label>
                </p>
              </div>
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>Foi criada uma transferência para:</h1>
          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description}>
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>
                        Pessoa/Instituição:
                      </div>
                      {payment.name === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(parseCurrencyToNumber(payment.amount))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Data:</div>

                        <div className={styles.title_description}>
                          {payment.date}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Banco: <p> {payment.bank_name} </p>
                    </span>

                    <span>
                      Documento: <p> {formatDocument(payment.document)} </p>
                    </span>

                    {payment?.error_description && (
                      <div className={styles.error_preview}>
                        {payment.error_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(
                        parseCurrencyToNumber(payment.amount) +
                          (user?.company?.internal_transfer_fee
                            ? user?.company?.internal_transfer_fee
                            : 0),
                      )}
                    </span>
                    <div className={styles.fee}>
                      <span>* Taxa:</span>
                      <p>
                        {formatMoney(
                          user?.company?.internal_transfer_fee
                            ? user?.company?.internal_transfer_fee
                            : 0,
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={onBackStepCancelOrder}
                    >
                      {loading.paymentOrderCancel && <Loader size="xs " />}
                      Cancelar
                    </button>
                    {payment.status.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Transferência finalizada!'
                    : 'Transferência Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar
                      seus pagamentos.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa transação.
                    </>
                  )}
                </p>
                <div className={styles.buttons_finish}>
                  {finishPayment && finishPayment?.status?.id === 6 && (
                    <button
                      type="button"
                      disabled={loading.paymentOrder}
                      className={styles.button}
                      onClick={handleProof}
                    >
                      <MdPayments />
                      Comprovante
                    </button>
                  )}

                  {finishPayment &&
                    ![6].includes(finishPayment?.status?.id) && (
                      <button
                        type="button"
                        disabled={loading.paymentOrder}
                        className={styles.button}
                        onClick={handleOrderKey}
                      >
                        <MdPayments />
                        Ver Pagamento
                      </button>
                    )}
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentTransfer };
