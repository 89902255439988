import { Details } from '../../../../components/Details';
import { formatMoney } from '../../../../utils/formatMoney';
import { IconAlert } from './icons';
import styles from './styles.module.scss';

export default function CardResume({
  approved,
  erros,
  errosAmount,
  process,
  awaitingBalance,
  totalAwaitingBalance,
  approvedAmount,
  processAmount,
  total_amount,
  total_moviments,
  description,
  file_name,
  created_at,
  duplicate,
  fee,
  author,
  author_approve,
}) {
  return (
    <section className={styles.container}>
      <div className={styles.box_description}>
        <div className={styles.box_info_}>
          <Details
            title={'Descrição:'}
            value={description}
            border={true}
            maxWidth="60rem"
            padding="0.39rem 0.89rem"
            left
          />
          {file_name === false ||
            (file_name !== '---' && (
              <Details
                title={'Arquivo:'}
                value={file_name}
                border={true}
                maxWidth="60rem"
                padding="0.39rem 0.89rem"
                left
                copy
              />
            ))}
          <Details
            title={'Data:'}
            value={created_at}
            border={true}
            maxWidth="60rem"
            padding="0.39rem 0.89rem"
            left
          />
        </div>
        {duplicate?.res && (
          <>
            <div className={styles.alert}>
              <span>
                <IconAlert />
                <strong> Atenção:</strong> Dados em duplicidade
              </span>
              <span>
                <strong> Quantidade:</strong>
                <p>{duplicate.data.length}</p>
              </span>
            </div>
          </>
        )}
      </div>
      <div className={styles.box_authors}>
        <div className={styles.box_info__}>
          {author && (
            <Details
              title={'Criado:'}
              value={
                <div>
                  <strong> Nome: </strong> {author?.name}
                  <br />
                  <br />
                  <strong> Documento:</strong> {author?.document}
                  <br />
                  <br />
                  <strong>E-mail:</strong> {author?.email}
                </div>
              }
              description={true}
              padding="0.8rem 0.8rem"
            />
          )}
          {author_approve && (
            <Details
              title={'Aprovado:'}
              value={
                <div>
                  <strong> Nome: </strong> {author_approve?.name}
                  <br />
                  <br />
                  <strong> Documento:</strong> {author_approve?.document}
                  <br />
                  <br />
                  <strong>E-mail:</strong> {author_approve?.email}
                </div>
              }
              description={true}
              padding="0.8rem 0.8rem"
            />
          )}
        </div>
      </div>
      <div className={styles.box_resume}>
        <div className={styles.box_info}>
          {Boolean(fee) !== false && (
            <div className={styles['summary']}>
              <p>Valor da Taxa:</p>
              <span>{formatMoney(fee)}</span>
            </div>
          )}
          <div className={styles['summary']}>
            <p>Total ({total_moviments}):</p>
            <span>{formatMoney(total_amount)}</span>
          </div>
          <div className={styles['summary-item']}>
            <p>Finalizado ({approved}):</p>
            <span>{formatMoney(approvedAmount)}</span>
          </div>
          {erros > 0 && (
            <div className={styles['summary-item-error']}>
              <p>Erro ({erros}):</p>
              <span>{formatMoney(errosAmount)}</span>
            </div>
          )}
          {process > 0 && (
            <div className={styles['summary-item-process']}>
              <p>Processando ({process}):</p>
              <span>{formatMoney(processAmount)}</span>
            </div>
          )}

          {awaitingBalance > 0 && (
            <div className={styles['summary-item-await']}>
              <p>Aguardando Saldo ({awaitingBalance}) :</p>
              <span>{formatMoney(totalAwaitingBalance)}</span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
