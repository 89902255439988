import React from 'react';
import * as FileSaver from 'file-saver';
import styles from './styles.module.scss';
import * as XLSX from 'xlsx';
import { formatDocument } from '../../../../utils/formatDocument';
import { IconDownload } from '../../../../components/Details/Icons';

const ExportExcelCollaborators = ({ collaborators, title }) => {
  const header = 'Relatório de Colaboradores';
  const headerTemplate = [
    'Nome ou Razão Social',
    'Nome Social',
    'Document',
    'CPF',
    'CNPJ',
    'Tipo',
    'Telefone',
    'Email (opcional)',
    'Data',
    'Status',
    'Descrição do Status',
    'Tags',
  ];
  const data = collaborators.map((items) => {
    return [
      `${items?.name}`,
      items?.customer?.social_name ? `${items?.customer?.social_name}` : `---`,
      formatDocument(items?.document),
      items?.cpf ? formatDocument(items?.cpf) : '---',
      items?.cnpj ? formatDocument(items?.cnpj) : '---',
      items?.type_document,
      items?.phone_number,
      items?.email,
      items?.created_at_linked ? items?.created_at_linked : items?.date_added,
      items?.status?.text,
      items?.status?.description,
      items?.tags?.join(', ') ?? '',
    ];
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  async function exportToCSV(csvData, fileName) {
    const wh = XLSX.utils.book_new();

    wh.Props = {
      Title: 'Relatório de Colaboradores',
      Subject: 'Relatório',
      Author: 'Ox Pay',
      CreatedDate: new Date(),
    };

    wh.SheetNames.push('erros');

    const dados = [[header], headerTemplate, ...csvData];

    const ws = XLSX.utils.aoa_to_sheet(dados);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }
  return (
    <button
      className={styles.button}
      onClick={(e) => exportToCSV(data, 'relatorio-colaboradores')}
      name="export"
    >
      <IconDownload color="var(--gray-400)" size={'1.5rem'} />
      {title}
    </button>
  );
};

export { ExportExcelCollaborators };
