import styled from 'styled-components';

const StyledSvg = styled.svg`
  @-webkit-keyframes animate-svg-2-stroke-1 {
    0% {
      stroke-dashoffset: 1082.9560546875px;
      stroke-dasharray: 1082.9560546875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1082.9560546875px;
    }
  }

  @keyframes animate-svg-2-stroke-1 {
    0% {
      stroke-dashoffset: 1082.9560546875px;
      stroke-dasharray: 1082.9560546875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1082.9560546875px;
    }
  }

  @-webkit-keyframes animate-svg-4-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_5006_4822');
    }
  }

  @keyframes animate-svg-4-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_5006_4822');
    }
  }

  .svg-paySlip-1 {
    -webkit-animation:
      animate-svg-2-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-4-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation:
      animate-svg-2-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-4-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }

  @-webkit-keyframes animate-svg-2-stroke-2 {
    0% {
      stroke-dashoffset: 506.9560241699219px;
      stroke-dasharray: 506.9560241699219px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 506.9560241699219px;
    }
  }

  @keyframes animate-svg-2-stroke-2 {
    0% {
      stroke-dashoffset: 506.9560241699219px;
      stroke-dasharray: 506.9560241699219px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 506.9560241699219px;
    }
  }

  .svg-paySlip-2 {
    -webkit-animation:
      animate-svg-2-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-4-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation:
      animate-svg-2-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-4-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  @-webkit-keyframes animate-svg-2-stroke-3 {
    0% {
      stroke-dashoffset: 56.20794677734375px;
      stroke-dasharray: 56.20794677734375px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.20794677734375px;
    }
  }

  @keyframes animate-svg-2-stroke-3 {
    0% {
      stroke-dashoffset: 56.20794677734375px;
      stroke-dasharray: 56.20794677734375px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.20794677734375px;
    }
  }

  @-webkit-keyframes animate-svg-4-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  @keyframes animate-svg-4-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-paySlip-3 {
    -webkit-animation:
      animate-svg-2-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-4-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation:
      animate-svg-2-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-4-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @-webkit-keyframes animate-svg-2-stroke-4 {
    0% {
      stroke-dashoffset: 232.08102416992188px;
      stroke-dasharray: 232.08102416992188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 232.08102416992188px;
    }
  }

  @keyframes animate-svg-2-stroke-4 {
    0% {
      stroke-dashoffset: 232.08102416992188px;
      stroke-dasharray: 232.08102416992188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 232.08102416992188px;
    }
  }

  @-webkit-keyframes animate-svg-4-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  @keyframes animate-svg-4-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-paySlip-4 {
    -webkit-animation:
      animate-svg-2-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-4-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation:
      animate-svg-2-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-4-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
  }

  @-webkit-keyframes animate-svg-2-stroke-55 {
    0% {
      stroke-dashoffset: 670.6080932617188px;
      stroke-dasharray: 670.6080932617188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 670.6080932617188px;
    }
  }

  @keyframes animate-svg-2-stroke-55 {
    0% {
      stroke-dashoffset: 670.6080932617188px;
      stroke-dasharray: 670.6080932617188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 670.6080932617188px;
    }
  }

  @-webkit-keyframes animate-svg-4-fill-55 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  @keyframes animate-svg-4-fill-55 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-paySlip-5 {
    -webkit-animation:
      animate-svg-2-stroke-55 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-4-fill-55 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
    animation:
      animate-svg-2-stroke-55 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-4-fill-55 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }
`;

const IconPeoplePayment = () => {
  return (
    <StyledSvg
      width="6.125rem"
      height="3.25rem"
      viewBox="0 0 390 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M387 3C324.612 8.19897 320.749 132.665 259 147C208.081 158.821 182.297 91.7214 131 99C75.3611 106.895 57.5564 176.815 3 195H387V3Z"
        fill="url(#paint0_linear_5006_4822)"
        fill-opacity="0.15"
        class="svg-paySlip-1"
      ></path>
      <path
        d="M3 195C57.5564 176.815 75.3611 106.895 131 99C182.297 91.7214 208.081 158.821 259 147C320.749 132.665 324.612 8.19897 387 3"
        stroke="#0F4098"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-paySlip-2"
      ></path>
      <path
        d="M253.016 53.5685C253.016 52.4851 252.138 51.6068 251.054 51.6068C249.971 51.6068 249.093 52.4851 249.093 53.5685V65.7747C249.093 66.8581 249.971 67.7364 251.054 67.7364H260.209C261.293 67.7364 262.171 66.8581 262.171 65.7747C262.171 64.6912 261.293 63.813 260.209 63.813H253.016V53.5685Z"
        fill="#0F4098"
        class="svg-paySlip-3"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M251.054 45.5037C245.678 45.5037 240.522 47.6394 236.721 51.4409C232.919 55.2425 230.784 60.3985 230.784 65.7747C230.784 68.4367 231.308 71.0726 232.327 73.532C233.345 75.9914 234.838 78.2261 236.721 80.1084C238.603 81.9908 240.838 83.4839 243.297 84.5026C245.757 85.5213 248.392 86.0457 251.054 86.0457C253.717 86.0457 256.352 85.5213 258.812 84.5026C261.271 83.4839 263.506 81.9908 265.388 80.1084C267.271 78.2261 268.764 75.9914 269.782 73.532C270.801 71.0726 271.325 68.4367 271.325 65.7747C271.325 60.3985 269.19 55.2425 265.388 51.4409C261.587 47.6394 256.431 45.5037 251.054 45.5037ZM239.495 54.2152C242.561 51.1494 246.719 49.4271 251.054 49.4271C255.39 49.4271 259.548 51.1494 262.614 54.2152C265.68 57.281 267.402 61.439 267.402 65.7747C267.402 67.9215 266.979 70.0472 266.158 72.0306C265.336 74.014 264.132 75.8161 262.614 77.3341C261.096 78.8521 259.294 80.0563 257.31 80.8779C255.327 81.6994 253.201 82.1222 251.054 82.1222C248.908 82.1222 246.782 81.6994 244.799 80.8779C242.815 80.0563 241.013 78.8521 239.495 77.3341C237.977 75.8161 236.773 74.014 235.951 72.0306C235.13 70.0472 234.707 67.9215 234.707 65.7747C234.707 61.439 236.429 57.281 239.495 54.2152Z"
        fill="#0F4098"
        class="svg-paySlip-4"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M221.588 3C217.399 3 214 6.39874 214 10.588V90.1734C214 94.3627 217.399 97.7614 221.588 97.7614H280.412C284.601 97.7614 288 94.3627 288 90.1734V46.9205C288 35.272 283.373 24.1007 275.136 15.864C266.899 7.62732 255.728 3 244.08 3H221.588ZM283.205 48.6506V90.1734C283.205 91.7143 281.953 92.9661 280.412 92.9661H221.588C220.047 92.9661 218.795 91.7143 218.795 90.1734V10.588C218.795 9.0471 220.047 7.79529 221.588 7.79529H242.349C245.843 7.79529 249.194 9.1832 251.664 11.6537C254.135 14.1242 255.523 17.4749 255.523 20.9687V27.8892C255.523 29.9016 256.322 31.8317 257.745 33.2547C259.168 34.6777 261.098 35.4772 263.111 35.4772H270.031C273.525 35.4772 276.876 36.8651 279.346 39.3356C281.817 41.8061 283.205 45.1568 283.205 48.6506ZM260.318 20.9687C260.318 16.8901 258.931 12.9545 256.419 9.79213C262.141 11.694 267.401 14.911 271.745 19.2548C276.089 23.5986 279.306 28.8587 281.208 34.5807C278.045 32.0685 274.11 30.6819 270.031 30.6819H263.111C262.37 30.6819 261.66 30.3877 261.136 29.8639C260.612 29.3402 260.318 28.6298 260.318 27.8892V20.9687Z"
        fill="#0F4098"
        class="svg-paySlip-5"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_5006_4822"
          x1="195"
          y1="3"
          x2="195"
          y2="195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007AFF"></stop>
          <stop offset="1" stop-color="#004999" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconPeoplePayment };
