import { useState } from 'react';
import { useEffect } from 'react';
import { LoadingBall } from '../../../../components/UI/LoadingBall';
import { useOrder } from '../../../../hooks/useOrder';
import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

export default function Cards({ value, accounts, title }) {
  const [, setCurrentValue] = useState(0);
  const [values, setValues] = useState(0);
  const [account, setAccountValue] = useState(0);

  const { loading } = useOrder();

  useEffect(() => {
    setValues(value);
    setAccountValue(accounts);
  }, [value, accounts]);

  const targetValue = value;
  const targetAccounts = accounts;
  const duration = 600; // Duração da animação em milissegundos

  useEffect(() => {
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * targetValue);

      setCurrentValue(nextValue);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let startTimestamp = null;
    let animationFrameId = null;

    const updateValue = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      const progress = Math.min(elapsed / duration, 1); // Progresso da animação de 0 a 1
      const nextValue = Math.round(progress * targetAccounts);

      setAccountValue(nextValue);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(updateValue);
      }
    };

    animationFrameId = requestAnimationFrame(updateValue);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <h4>{title} </h4>

        {!loading.order ? (
          <>
            <h1>{values ? formatMoney(values) : formatMoney(0)}</h1>
            <div>
              <strong>{account ? account : 0}</strong>
              <span>Contas</span>
            </div>
          </>
        ) : (
          <div className={styles.loading}>
            <LoadingBall />
          </div>
        )}
      </div>
    </section>
  );
}
