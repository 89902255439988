import React, { useEffect, useState } from 'react';
import DropZone from 'react-drop-zone';
import { useForm } from 'react-hook-form';
import XLSX from 'xlsx';
import { useNotification } from '../../../hooks/useNotifications';
import { apiGet } from '../../../services/api';
import styles from './styles.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uid } from 'uuid';
import { BiChevronDown } from 'react-icons/bi';

import { MdDelete } from 'react-icons/md';
import { formatFileSize, reorganizeFieldsColum } from './utils';
import { lower } from '../../../utils/lower';
import { ValidateNoContractSelect, ValidateSelect } from './utils/validate';
import TagInput from '../TagInput';
import { useTags } from '../../../hooks/useTags';
import { IconClose, IconPlus } from '../Icons';

const ExcelReader = (props) => {
  const [file, setFile] = useState(false);
  const { tags, setTags } = useTags();
  const { addNotification } = useNotification();
  const [contract, setContract] = useState();
  const [loading, setLoading] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isContract, setIsContract] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(
      isRequired ? ValidateSelect : ValidateNoContractSelect,
    ),
  });

  async function fetchGetListContract(document) {
    setLoading(true);
    try {
      const { data } = await apiGet(`/Contract/template/list`);
      setContract(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchGetListContract();
  }, []);

  function handlerIsContract(value) {
    setIsContract(value);
    setIsRequired(value);
  }
  const handleChangeFile = (file) => {
    if (
      [
        'application/vnd.ms-excel.sheet.macroenabled.12',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.ms-exce',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
      ].includes(file.type)
    ) {
      setFile(file);
    } else {
      addNotification(
        <>Aquivo de formato invalido.</>,
        2,
        'Upload de Arquivo',
        true,
      );
      setFile(false);
    }
  };
  const handlerCancel = () => {
    props.setType(false);
  };
  const handleFile = ({ contract_type }) => {
    /* Boilerplate to set up FileReader */
    try {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        });

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const columnsToRead = [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
        ];

        const dataRows = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const newData = [];

        // Itera sobre as linhas até encontrar uma linha onde a coluna A está vazia
        for (let i = 2; i < dataRows.length; i++) {
          const row = dataRows[i];

          const rowData = {};

          // Verifica se a coluna A está preenchida
          if (!row[0]) {
            break;
          }

          // Lê os valores das colunas especificadas
          columnsToRead.forEach((column, index) => {
            rowData[column] = row[index];
          });

          newData.push(rowData);
        }
        function validatorJSON(jsonArray) {
          for (let obj of jsonArray) {
            if (!obj.hasOwnProperty('name') || !obj.hasOwnProperty('cpf')) {
              return false;
            }
          }
          return true;
        }

        const formatKeyData = reorganizeFieldsColum(newData);

        const result = validatorJSON(formatKeyData);

        if (result) {
          // O objeto possui todas as propriedades obrigatórias.
          const formatKeyData = reorganizeFieldsColum(newData)
            .map((user) => {
              return user?.contract_type
                ? { ...user, contract_type: lower(user?.contract_type) }
                : user;
            })
            .map((item) => {
              return {
                ...item,
                ...(tags.length > 0 && { tags }),
              };
            });

          if (formatKeyData.length === 0) {
            addNotification(
              <>
                Não foi possível carregar os arquivos, verifique os campos.{' '}
                <br />
                Use sempre o template como referência{' '}
              </>,
              2,
              'Upload de Arquivo',
              true,
            );
            return;
          }

          if (contract_type) {
            const user_contract_type = formatKeyData.map((item) => {
              return {
                ...item,
                file: file?.name,
                contract_type: contract_type,
              };
            });

            props.addUsers(user_contract_type);
            setTags([]);
          } else {
            const user_data = formatKeyData.map((item) => {
              return {
                file: file?.name,
                ...item,
              };
            });
            props.addUsers(user_data);
            setTags([]);
          }
          addNotification(
            <>Dados carregados com sucesso.</>,
            3,
            'Upload de Arquivo',
            true,
          );
        } else {
          addNotification(
            <>Não foi possível carregar os arquivos, verifique os campos.</>,
            2,
            'Upload de Arquivo',
            true,
          );
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
      setFile(false);
    } catch (error) {
      addNotification(
        <>Não foi possível carregar os arquivos.</>,
        2,
        'Upload de Arquivo',
        true,
      );
    }
  };
  const onSubmit = (values) => {
    if (values?.is_contract_required === 'false') {
      handleFile({ contract_type: false });
      props.setType(false);
    } else {
      if (values.type === '0') {
        handleFile({ contract_type: false });
        props.setType(false);
      } else {
        handleFile({ contract_type: values.type });
        props.setType(false);
      }
      if (isRequired) {
        const type = contract?.find(
          (item) => item.contract_type === values.type,
        );
        delete type?.template;
      }
    }
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="file" className={styles.title_}>
        Adicione o arquivo com os dados dos seus colaboradores, siga os
        templates referente aos seu tipo de contrato.
      </label>

      <div className={styles.wrapper_drop}>
        <DropZone onDrop={(files) => handleChangeFile(files)}>
          {({ over }) => (
            <div className={styles.drop_zone}>
              {over ? (
                'Clique aqui ou arraste o arraste o arquivo'
              ) : file ? (
                <div className={styles.box_file}>
                  <div>
                    <h3> {file.name} </h3>
                    <p> {formatFileSize(file.size)}</p>
                  </div>
                  <button onClick={() => setFile(false)}>
                    <MdDelete size={'1.5rem'} color={'var(--gray-300)'} />
                  </button>
                </div>
              ) : (
                'Arraste o arquivo'
              )}
            </div>
          )}
        </DropZone>
      </div>
      <TagInput />
      <div className={styles.formRow}>
        <div className={styles.radio_type}>
          <label className={styles.label} for="is_contract_required">
            A assinatura do contrato é obrigatória?
          </label>
          <br />
          <div className={styles.wrapper_radio}>
            <p>
              <input
                type="radio"
                id="is_contract_required-true"
                name="is_contract_required"
                value={'true'}
                checked={isContract === true}
                onClick={() => handlerIsContract(true)}
                {...register('is_contract_required', {
                  required: true,
                  message: 'O campo deve ser selecionado.',
                })}
              />
              <label
                htmlFor="is_contract_required"
                for="is_contract_required-true"
              >
                sim
              </label>
            </p>
            <p>
              <input
                type="radio"
                id="is_contract_required-false"
                name="is_contract_required"
                value={'false'}
                checked={isContract === false}
                onClick={() => handlerIsContract(false)}
                {...register('is_contract_required', {
                  required: true,
                  message: 'O campo deve ser selecionado.',
                })}
              />

              <label
                htmlFor="is_contract_required"
                for="is_contract_required-false"
              >
                não
              </label>
            </p>
          </div>

          {errors.is_contract_required && (
            <p className={styles.errorText}>
              {errors.is_contract_required.message}
            </p>
          )}
        </div>
        {isRequired === true && (
          <div className={styles.select_type}>
            <div className={styles.select}>
              <select
                name="type"
                {...register('type')}
                disabled={loading}
                // value={form?.type?.contract_type}
              >
                <option value="0">
                  {loading ? 'Carregando ...' : ' Selecione o contrato:'}
                </option>
                {contract?.map((type) => (
                  <option key={uid()} value={type.contract_type}>
                    {type.name}
                  </option>
                ))}
              </select>
              <BiChevronDown color="var(--primary-700)" size={'2rem'} />
            </div>

            {errors.type && (
              <p className={styles.errorText}>{errors.type.message}</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.wrapper_button}>
        <button
          type="button"
          className={styles.button_canceled}
          onClick={handlerCancel}
        >
          <IconClose /> Cancel
        </button>
        <button
          className={styles.button_save}
          type="submit"
          disabled={file !== false ? false : true}
        >
          <IconPlus size="1rem" /> Carregar Arquivo
        </button>
      </div>
    </form>
  );
};

export default ExcelReader;
