import React, { memo, useEffect, useState } from 'react';
import { useBalance } from '../../hooks/useBalance';
import { TableArea } from './Components/Table/TableArea';
import styles from './styles.module.scss';

import { addDays, differenceInDays, subDays } from 'date-fns';
import { IoReloadOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import { CheckPicker, Loader } from 'rsuite';
import { DatePicker } from '../../components/DatePiker';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { LoadingBall } from '../../components/UI/LoadingBall';
import { usePagination } from '../../hooks/usePagination';
import * as S from './styles';

import { useQuery } from 'react-query';
import { useDate } from '../../hooks/useDate';
import { useNotification } from '../../hooks/useNotifications';
import { useUser } from '../../hooks/useUser';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import { IsModal } from './Components/IsModal';

function Extract() {
  const { id } = useParams();
  const history = useHistory();
  const { addNotification } = useNotification();
  const [modal, setModal] = useState(false);
  const { loading, user } = useUser();
  const {
    fetchGetExtract,
    checkPickerCompanies,
    setCheckPickerCompanies,
    checkPickerTypes,
    setCheckPickerTypes,
    checkPickerDocuments,
    setCheckPickerDocuments,
    checkPickerServices,
    setCheckPickerServices,
    checkPickerNames,
    setCheckPickerNames,
    pageExtractSelect,
  } = useBalance();

  const { dateExtract, setDateExtract, dateRanger, setDateRanger } = useDate();

  const [services, setServices] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [types, setTypes] = useState([]);
  const [names, setNames] = useState([]);
  const [, setList] = useState([]);

  const [companiesList] = useState([user.company]);

  const {
    data,
    isFetching,
    isRefetching,
    refetch: refetchExtract,
  } = useQuery(
    [
      'ExtractList',
      dateRanger,
      dateExtract,
      checkPickerCompanies,
      companiesList,
    ],
    () =>
      fetchGetExtract(
        dateRanger?.dateInitial
          ? dateRanger
          : setDateExtract([subDays(new Date(), 6), new Date()]),
        checkPickerCompanies,
        companiesList,
      ),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!companiesList && !!dateRanger,
    },
  );

  useEffect(() => {
    if (data) {
      const { data: isData, name, service, document, type } = data;

      setList(isData);

      setServices(service);
      setDocuments(document);
      setNames(name);
      setTypes(type);
    }
  }, [data]);

  useEffect(() => {
    if (
      !!dateExtract &&
      differenceInDays(new Date(dateExtract[1]), new Date(dateExtract[0])) < 90
    ) {
      let startDateString = addDays(dateExtract[0], 1)
        .toISOString()
        .substring(0, 10);

      let endDateString = addDays(dateExtract[1], 1)
        .toISOString()
        .substring(0, 10);

      let startDate = new Date(startDateString);

      startDate.setHours(-3, 0, 0);
      startDate = startDate.toISOString();

      let endDate = new Date(endDateString);

      endDate.setHours(20, 59, 59);
      endDate = endDate.toISOString();

      setDateRanger({
        dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
        dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
      });
    } else {
      addNotification(
        'A data não pode ter intervalo maior que 90 dias ',
        1,
        'Atenção',
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateExtract]);

  const result =
    data?.data?.length > 0
      ? data?.data
          .filter((item) => {
            if (checkPickerDocuments.length === 0) return item;

            return checkPickerDocuments.includes(item.document) && item;
          })
          .filter((item) => {
            if (checkPickerServices.length === 0) return item;
            return checkPickerServices.includes(item.service.key) && item;
          })
          .filter((item) => {
            if (checkPickerNames.length === 0) return item;
            return checkPickerNames.includes(item.name) && item;
          })
          .filter((item) => {
            if (checkPickerTypes.length === 0) return item;
            return checkPickerTypes.includes(item.type) && item;
          })
      : [];
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    result,
    50,
    '/menu/extract/',
  );

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPickerDocuments, companiesList, checkPickerServices]);
  async function handlerCompaniesIds() {
    if (history.action === 'POP') {
      //  history.replace(history.location.pathname);
      setCurrentPage(pageExtractSelect);
    } else {
      setCheckPickerCompanies([Number(id)]);
      setCheckPickerServices([]);
      setCheckPickerDocuments([]);
    }
  }
  useEffect(() => {
    handlerCompaniesIds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlerMutation = (setState) => (newValue) => {
    setState(newValue);
    setCurrentPage(0);
  };
  const handleTypesChange = handlerMutation(setCheckPickerTypes);
  const handleServicesChange = handlerMutation(setCheckPickerServices);
  const handleDocumentsChange = handlerMutation(setCheckPickerDocuments);
  const handleNamesChange = handlerMutation(setCheckPickerNames);
  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <div
      className={styles.container}
      style={{ cursor: isFetching ? 'wait' : '' }}
    >
      <S.Container>
        <header className={styles.header_style}>
          <h1 className={styles.title_style}>Extrato</h1>
        </header>
        <IsModal modal={modal} handleCloseModal={handleCloseModal} />
        {companiesList && data && (
          <>
            <S.BoxPicker>
              <S.BoxPickerDate>
                <DatePicker
                  setDate={setDateExtract}
                  value={dateExtract}
                  style={{ width: '100%', marginRight: 'auto' }}
                  disabled={isRefetching || isFetching || loading}
                />
              </S.BoxPickerDate>

              <CheckPicker
                data={types}
                style={{ width: '100%', marginLeft: 'auto' }}
                value={checkPickerTypes}
                placeholder="Tipo"
                onChange={handleTypesChange}
                disabled={!dateExtract || isRefetching || isFetching || loading}
              />
              <CheckPicker
                data={services}
                style={{ width: '100%', marginLeft: 'auto' }}
                value={checkPickerServices}
                placeholder="Serviço"
                onChange={handleServicesChange}
                disabled={!dateExtract || isRefetching || isFetching || loading}
              />

              <CheckPicker
                data={documents}
                style={{ width: '100%', marginRight: 'auto' }}
                value={checkPickerDocuments}
                placeholder="Documento do Favorecido"
                onChange={handleDocumentsChange}
                disabled={!dateExtract || isRefetching || isFetching || loading}
              />
              <CheckPicker
                data={names}
                style={{ width: '100%', marginRight: 'auto' }}
                value={checkPickerNames}
                placeholder="Nome do Favorecido"
                onChange={handleNamesChange}
                disabled={!dateExtract || isRefetching || isFetching || loading}
              />
              <S.Pagination>
                {result && (
                  <ButtonMenuDownload list={result} model={[1, 3]}>
                    Exportar
                  </ButtonMenuDownload>
                )}
                <S.ReloadButton
                  disabled={Boolean(isRefetching || isFetching || loading)}
                  onClick={() => refetchExtract()}
                >
                  {isRefetching || isFetching || loading ? (
                    <Loader />
                  ) : (
                    <IoReloadOutline size="1.125rem" color="var(--white)" />
                  )}
                </S.ReloadButton>
              </S.Pagination>
            </S.BoxPicker>
          </>
        )}
      </S.Container>
      <div className={styles.container_arrow_box}>
        <PaginationArrow
          pages={pages}
          page={currentPage}
          setPage={setCurrentPage}
        />
      </div>
      <S.BoxTableArea>
        {isFetching ? (
          <>
            <main>
              <LoadingBall />
            </main>
          </>
        ) : (
          <TableArea
            setModal={setModal}
            list={currentItens}
            currentPage={currentPage}
          />
        )}
      </S.BoxTableArea>
    </div>
  );
}

export default memo(Extract);
