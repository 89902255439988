import styled from 'styled-components';

const StyledSvg = styled.svg`
  @-webkit-keyframes animate-svg-stroke-11 {
    0% {
      stroke-dashoffset: 1082.9560546875px;
      stroke-dasharray: 1082.9560546875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1082.9560546875px;
    }
  }

  @keyframes animate-svg-stroke-11 {
    0% {
      stroke-dashoffset: 1082.9560546875px;
      stroke-dasharray: 1082.9560546875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1082.9560546875px;
    }
  }

  @-webkit-keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_5006_4828');
    }
  }

  @keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_5006_4828');
    }
  }

  .svg-await-two-1 {
    -webkit-animation:
      animate-svg-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation:
      animate-svg-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }

  @-webkit-keyframes animate-svg-stroke-22 {
    0% {
      stroke-dashoffset: 506.9560241699219px;
      stroke-dasharray: 506.9560241699219px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 506.9560241699219px;
    }
  }

  @keyframes animate-svg-stroke-22 {
    0% {
      stroke-dashoffset: 506.9560241699219px;
      stroke-dasharray: 506.9560241699219px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 506.9560241699219px;
    }
  }

  .svg-await-two-2 {
    -webkit-animation:
      animate-svg-stroke-22 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation:
      animate-svg-stroke-22 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  @-webkit-keyframes animate-svg-stroke-33 {
    0% {
      stroke-dashoffset: 29.684106826782227px;
      stroke-dasharray: 29.684106826782227px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 29.684106826782227px;
    }
  }

  @keyframes animate-svg-stroke-33 {
    0% {
      stroke-dashoffset: 29.684106826782227px;
      stroke-dasharray: 29.684106826782227px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 29.684106826782227px;
    }
  }

  @-webkit-keyframes animate-svg-fill-33 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  @keyframes animate-svg-fill-33 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-await-two-3 {
    -webkit-animation:
      animate-svg-stroke-33 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-fill-33 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation:
      animate-svg-stroke-33 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-fill-33 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @-webkit-keyframes animate-svg-stroke-44 {
    0% {
      stroke-dashoffset: 29.68414306640625px;
      stroke-dasharray: 29.68414306640625px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 29.68414306640625px;
    }
  }

  @keyframes animate-svg-stroke-44 {
    0% {
      stroke-dashoffset: 29.68414306640625px;
      stroke-dasharray: 29.68414306640625px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 29.68414306640625px;
    }
  }

  @-webkit-keyframes animate-svg-fill-44 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  @keyframes animate-svg-fill-44 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-await-two-4 {
    -webkit-animation:
      animate-svg-stroke-44 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-fill-44 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation:
      animate-svg-stroke-44 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-fill-44 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
  }

  @-webkit-keyframes animate-svg-stroke-55 {
    0% {
      stroke-dashoffset: 207.0743408203125px;
      stroke-dasharray: 207.0743408203125px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 207.0743408203125px;
    }
  }

  @keyframes animate-svg-stroke-55 {
    0% {
      stroke-dashoffset: 207.0743408203125px;
      stroke-dasharray: 207.0743408203125px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 207.0743408203125px;
    }
  }

  @-webkit-keyframes animate-svg-fill-55 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  @keyframes animate-svg-fill-55 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-await-two-5 {
    -webkit-animation:
      animate-svg-stroke-55 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-55 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
    animation:
      animate-svg-stroke-55 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-55 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }

  @-webkit-keyframes animate-svg-stroke-66 {
    0% {
      stroke-dashoffset: 670.6082153320312px;
      stroke-dasharray: 670.6082153320312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 670.6082153320312px;
    }
  }

  @keyframes animate-svg-stroke-66 {
    0% {
      stroke-dashoffset: 670.6082153320312px;
      stroke-dasharray: 670.6082153320312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 670.6082153320312px;
    }
  }

  @-webkit-keyframes animate-svg-fill-66 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  @keyframes animate-svg-fill-66 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-await-two-6 {
    -webkit-animation:
      animate-svg-stroke-66 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-66 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation:
      animate-svg-stroke-66 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-66 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
  }
`;

const IconAwaitPayment = () => {
  return (
    <StyledSvg
      width="6.125rem"
      height="3.125rem"
      viewBox="0 0 390 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M387 3C324.612 8.19897 320.749 132.665 259 147C208.081 158.821 182.297 91.7214 131 99C75.3611 106.895 57.5564 176.815 3 195H387V3Z"
        fill="url(#paint0_linear_5006_4828)"
        class="svg-await-two-1"
      ></path>
      <path
        d="M3 195C57.5564 176.815 75.3611 106.895 131 99C182.297 91.7214 208.081 158.821 259 147C320.749 132.665 324.612 8.19897 387 3"
        stroke="#B54708"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-await-two-2"
      ></path>
      <path
        d="M247.337 54.1436C247.895 53.5125 247.836 52.5483 247.205 51.9899C246.574 51.4315 245.61 51.4904 245.051 52.1215C242.855 54.6039 241.401 57.6652 240.856 60.9487C240.718 61.78 241.28 62.5657 242.111 62.7038C242.942 62.8418 243.728 62.2798 243.866 61.4485C244.318 58.7271 245.522 56.1941 247.337 54.1436Z"
        fill="#B54708"
        class="svg-await-two-3"
      ></path>
      <path
        d="M271.058 52.1215C270.499 51.4904 269.535 51.4315 268.904 51.9899C268.273 52.5483 268.214 53.5125 268.772 54.1436C270.587 56.1941 271.791 58.7271 272.243 61.4485C272.381 62.2798 273.167 62.8418 273.998 62.7038C274.829 62.5657 275.391 61.78 275.253 60.9487C274.708 57.6652 273.254 54.6039 271.058 52.1215Z"
        fill="#B54708"
        class="svg-await-two-4"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M258.054 51.6068C254.829 51.6068 251.741 52.9058 249.467 55.2111C247.194 57.5157 245.92 60.637 245.92 63.8873L245.92 65.2337C245.925 68.8488 244.604 72.3334 242.219 75.011C241.884 75.3868 241.756 75.9035 241.877 76.3921C241.998 76.8807 242.352 77.2783 242.823 77.4548C245.513 78.4618 248.326 79.2089 251.233 79.668C251.209 80.1493 251.235 80.6334 251.311 81.1123C251.482 82.1821 251.899 83.1975 252.531 84.0755C253.163 84.9536 253.993 85.6699 254.952 86.1645C255.913 86.6592 256.975 86.9175 258.054 86.9175C259.134 86.9175 260.196 86.6592 261.156 86.1645C262.116 85.6699 262.946 84.9536 263.578 84.0755C264.21 83.1975 264.627 82.1821 264.798 81.1123C264.874 80.6335 264.9 80.1494 264.876 79.6682C267.743 79.2155 270.56 78.4742 273.284 77.4548C273.755 77.2785 274.109 76.8809 274.23 76.3924C274.351 75.9038 274.223 75.387 273.889 75.0111C271.504 72.3333 270.184 68.8488 270.189 65.2339L270.189 65.2316V63.8873C270.189 60.637 268.915 57.5157 266.642 55.2111C264.368 52.9058 261.28 51.6068 258.054 51.6068ZM254.325 80.632C254.294 80.4353 254.277 80.2372 254.276 80.0392C256.79 80.2568 259.319 80.2568 261.833 80.0392C261.832 80.2372 261.815 80.4353 261.784 80.632C261.688 81.2339 261.454 81.8029 261.101 82.2928C260.748 82.7826 260.288 83.1792 259.759 83.4519C259.23 83.7245 258.646 83.866 258.054 83.866C257.463 83.866 256.879 83.7245 256.35 83.4519C255.821 83.1792 255.361 82.7826 255.008 82.2928C254.655 81.8029 254.421 81.2339 254.325 80.632ZM262.924 76.8591C259.689 77.2481 256.42 77.2481 253.185 76.8591C250.671 76.5564 248.227 76.0234 245.875 75.2804C247.887 72.3423 248.976 68.8378 248.972 65.2306V63.8873C248.972 61.4329 249.934 59.0832 251.639 57.354C253.344 55.6255 255.652 54.6583 258.054 54.6583C260.457 54.6583 262.765 55.6255 264.47 57.354C266.175 59.0832 267.137 61.4329 267.137 63.8873V65.2294C267.132 68.8368 268.221 72.3416 270.233 75.2802C267.851 76.0326 265.404 76.5614 262.924 76.8591Z"
        fill="#B54708"
        class="svg-await-two-5"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M228.588 3C224.399 3 221 6.39874 221 10.588V90.1734C221 94.3627 224.399 97.7614 228.588 97.7614H287.412C291.601 97.7614 295 94.3627 295 90.1734V46.9205C295 35.272 290.373 24.1007 282.136 15.864C273.899 7.62732 262.728 3 251.08 3H228.588ZM290.205 48.6506V90.1734C290.205 91.7143 288.953 92.9661 287.412 92.9661H228.588C227.047 92.9661 225.795 91.7143 225.795 90.1734V10.588C225.795 9.0471 227.047 7.79529 228.588 7.79529H249.349C252.843 7.79529 256.194 9.1832 258.664 11.6537C261.135 14.1242 262.523 17.4749 262.523 20.9687V27.8892C262.523 29.9016 263.322 31.8317 264.745 33.2547C266.168 34.6777 268.098 35.4772 270.111 35.4772H277.031C280.525 35.4772 283.876 36.8651 286.346 39.3356C288.817 41.8061 290.205 45.1568 290.205 48.6506ZM267.318 20.9687C267.318 16.8901 265.931 12.9545 263.419 9.79213C269.141 11.694 274.401 14.911 278.745 19.2548C283.089 23.5986 286.306 28.8587 288.208 34.5807C285.045 32.0685 281.11 30.6819 277.031 30.6819H270.111C269.37 30.6819 268.66 30.3877 268.136 29.8639C267.612 29.3402 267.318 28.6298 267.318 27.8892V20.9687Z"
        fill="#B54708"
        class="svg-await-two-6"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_5006_4828"
          x1="195"
          y1="3"
          x2="195"
          y2="195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFFAEB"></stop>
          <stop offset="1" stop-color="#FFFAEB" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconAwaitPayment };
