import { useState } from 'react';
import { CardCopy } from '../CardCopy';
import styles from '../../styles.module.scss';
import Status from '../../../../../../components/UI/Status';
import {
  IconEyes,
  IconNotEyes,
} from '../../../../../../components/Details/Icons';
import { useHistory } from 'react-router-dom';
import { formatStatus } from '../../../../../../utils/formatStatus';
import ToolTip from '../../../../../../components/UI/ToolTip';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { useUser } from '../../../../../../hooks/useUser';

export default function CardList({
  payment,
  handlePushRouter,
  listSelect,
  toggleSelect,
}) {
  const { user } = useUser();

  const [isCopied, setIsCopied] = useState(false);
  const history = useHistory();
  const permissionTags =
    ![13, 14].every((item) => user?.permission.includes(item)) ||
    ![1, 3].includes(payment?.status?.id) ||
    !payment?.amount > 0;

  const handleCopyClick = (text) => {
    setIsCopied(text);

    setInterval(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleProof = () => {
    if (payment?.key) {
      history.push(`/menu/proof/${payment?.id}`);
    }
  };
  return (
    <tr key={payment?.key}>
      <td className={styles.input_select_blue}>
        <input
          type="checkbox"
          checked={listSelect && listSelect.includes(payment?.order_key)}
          onChange={() => toggleSelect(payment?.order_key)}
          disabled={permissionTags}
        />
      </td>
      <td>
        <CardCopy
          isCopied={isCopied}
          handleCopyClick={handleCopyClick}
          text={payment?.key}
          id={payment?.key}
        />
      </td>
      <td>{payment?.name ?? '---'}</td>
      <td>
        {payment?.expiration_date
          ? formatDate(payment?.expiration_date, "dd/MM/yyyy '-' HH:mm:ss")
          : '---'}
      </td>
      <td>{payment?.date}</td>
      <td>{formatMoney(payment?.amount) ?? 0}</td>
      <td>
        {payment.status.id === 6 ? (
          <button
            className={styles.type}
            onClick={() => handleProof()}
            style={{ cursor: 'pointer' }}
          >
            <IconEyes color="var(--gray-850)" size="1.125rem" />
          </button>
        ) : (
          <span className={styles.type_not}>
            <IconNotEyes color="var(--gray-500)" size="1.125rem" />
          </span>
        )}
      </td>
      <td className={styles.status}>
        {payment?.amount === false &&
        [1, 3, 5].includes(payment?.status?.id) ? (
          <ToolTip text="Valor não definido.">
            <Status status={formatStatus('99')} />
          </ToolTip>
        ) : (
          <Status status={payment?.status} />
        )}
      </td>
      <td
        className={styles.eyes}
        onClick={() =>
          handlePushRouter(payment?.order_key, payment?.status?.key)
        }
      >
        <IconEyes size="1.3rem" color="var(--gray-500)" />
      </td>
    </tr>
  );
}
