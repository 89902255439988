import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useTags } from '../../../hooks/useTags';
import { IconPlus } from './icon';
import styles from './styles.module.scss';

const TagInput = () => {
  const { tagsRef: availableTags, tags, setTags } = useTags();

  const [inputValue, setInputValue] = useState('');
  const [showNewTagInput, setShowNewTagInput] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const filtered = availableTags.filter(
        (tag) =>
          tag.toLowerCase().includes(value.toLowerCase()) &&
          !tags.includes(tag),
      );
      setFilteredTags(filtered);
      setShowNewTagInput(filtered.length === 0 && value.trim() !== '');
    } else {
      setFilteredTags(availableTags.filter((tag) => !tags.includes(tag)));
      setShowNewTagInput(false);
    }
  };

  const addTag = (tag) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
      setFilteredTags(availableTags.filter((t) => !tags.includes(t)));
      setIsDropdownVisible(false);
    }
  };

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
    setFilteredTags(availableTags.filter((t) => !tags.includes(t)));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (filteredTags.length === 0 && inputValue.trim() !== '') {
        setShowNewTagInput(true);
      } else if (
        availableTags.includes(inputValue) &&
        !tags.includes(inputValue)
      ) {
        addTag(inputValue);
      }
    }
  };

  const handleNewTagSubmit = () => {
    if (!availableTags.includes(inputValue) && inputValue.trim() !== '') {
      availableTags.push(inputValue);
      addTag(inputValue);
      setInputValue('');
      setShowNewTagInput(false);
    }
  };

  const handleInputFocus = () => {
    setIsDropdownVisible(true);
    setFilteredTags(availableTags.filter((tag) => !tags.includes(tag)));
  };

  const handleInputBlur = () => {
    // Delay hiding the dropdown to allow click events to register
    setTimeout(() => {
      setIsDropdownVisible(false);
    }, 200);
  };

  return (
    <div className={styles['tag-input-container']}>
      <label className={styles.label} htmlFor="tags">
        Tag:
      </label>
      <div className={styles.wrapper_input}>
        <div className={styles['selected-tags']}>
          {tags.map((tag) => (
            <div key={tag} className={styles.tag}>
              {tag}
              <span
                className={styles['remove-tag']}
                onClick={() => removeTag(tag)}
              >
                <MdClose color={'var(--gray-400'} size={'1rem'} />
              </span>
            </div>
          ))}
        </div>
        <input
          id="tags"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="|Adicione uma tag"
          autocomplete="off"
        />
      </div>
      {isDropdownVisible && (
        <div className={styles.dropdown}>
          {filteredTags.length > 0
            ? filteredTags.map((tag) => (
                <div
                  key={tag}
                  className={styles.dropdown_item}
                  onClick={() => addTag(tag)}
                >
                  {tag}
                </div>
              ))
            : showNewTagInput && (
                <div className={styles.dropdown_item}>
                  <button onClick={handleNewTagSubmit}>
                    <IconPlus /> Criar Tag <strong>‘{inputValue}’</strong>
                  </button>
                </div>
              )}
        </div>
      )}
    </div>
  );
};

export default TagInput;
