import React from 'react';

export function IconShare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        fill="#242424"
        d="M12.875 11.25a2.806 2.806 0 00-2.012.85L7.62 10.017a2.798 2.798 0 000-2.033L10.863 5.9a2.812 2.812 0 10-.61-.945l-3.24 2.083a2.813 2.813 0 100 3.925l3.24 2.083a2.813 2.813 0 102.622-1.796zm0-9a1.688 1.688 0 110 3.376 1.688 1.688 0 010-3.376zM5 10.688a1.688 1.688 0 110-3.376 1.688 1.688 0 010 3.376zm7.875 5.062a1.688 1.688 0 110-3.375 1.688 1.688 0 010 3.375z"
      ></path>
    </svg>
  );
}

export function IconCard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="url(#paint0_linear_76_115)"
        d="M36.667 15.416H3.333a1.26 1.26 0 01-1.25-1.25c0-.683.567-1.25 1.25-1.25h33.334c.683 0 1.25.567 1.25 1.25a1.26 1.26 0 01-1.25 1.25z"
      ></path>
      <path
        fill="url(#paint1_linear_76_115)"
        d="M13.334 28.75H10c-.683 0-1.25-.567-1.25-1.25s.567-1.25 1.25-1.25h3.334c.683 0 1.25.567 1.25 1.25a1.26 1.26 0 01-1.25 1.25z"
      ></path>
      <path
        fill="url(#paint2_linear_76_115)"
        d="M24.167 28.75H17.5a1.26 1.26 0 01-1.25-1.25c0-.683.567-1.25 1.25-1.25h6.667c.683 0 1.25.567 1.25 1.25a1.26 1.26 0 01-1.25 1.25z"
      ></path>
      <path
        fill="url(#paint3_linear_76_115)"
        d="M29.267 35.417H10.733c-6.633 0-8.65-2-8.65-8.567v-13.7c0-6.567 2.017-8.567 8.65-8.567H29.25c6.633 0 8.65 2 8.65 8.567v13.683c.017 6.584-2 8.584-8.633 8.584zM10.733 7.083c-5.233 0-6.15.9-6.15 6.067v13.683c0 5.167.917 6.067 6.15 6.067H29.25c5.233 0 6.15-.9 6.15-6.067V13.15c0-5.167-.916-6.067-6.15-6.067H10.733z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_76_115"
          x1="2.083"
          x2="37.916"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001A5D"></stop>
          <stop offset="1" stopColor="#051532"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_76_115"
          x1="2.083"
          x2="37.916"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001A5D"></stop>
          <stop offset="1" stopColor="#051532"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_76_115"
          x1="2.083"
          x2="37.916"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001A5D"></stop>
          <stop offset="1" stopColor="#051532"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_76_115"
          x1="2.083"
          x2="37.916"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001A5D"></stop>
          <stop offset="1" stopColor="#051532"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export function IconCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      fill="none"
      viewBox="0 0 90 90"
    >
      <circle cx="45" cy="44.663" r="44.673" fill="#F3FFF5"></circle>
      <circle cx="45" cy="44.663" r="35.252" fill="#E4FFE8"></circle>
      <circle cx="45" cy="44.663" r="35.252" fill="#E4FFE8"></circle>
      <circle cx="45" cy="44.408" r="25.527" fill="#E4FFE8"></circle>
      <circle cx="45" cy="44.408" r="25.527" fill="#17EB76"></circle>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.148"
        d="M53.59 39.193L41.78 51.005l-5.37-5.369"
      ></path>
    </svg>
  );
}

export function IconOxpay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1030"
      height="259"
      fill="none"
      viewBox="0 0 1030 259"
    >
      <g fill="#051532" clipPath="url(#clip0_1271_3246)">
        <path d="M163.021 150.018a2315.696 2315.696 0 00-6.832 7.305c-4.633-5.182-8.767-10.098-13.233-14.69-2.628-2.702-1.631-4.331.584-6.526a7633.631 7633.631 0 0044.165-44.109c8.522-8.582 10.392-18.168 5.633-27.607-6.059-12.02-21.045-16.213-32.422-9.063-1.391.874-2.744 1.807-4.814 3.176-4.626-4.812-9.185-9.625-13.829-14.355-2.029-2.068-.991-3.4.722-4.91 21.026-18.52 55.504-12.944 69.626 11.288 11.133 19.102 8.33 41.423-7.333 57.222-13.945 14.066-28.007 28.018-42.267 42.269zM98.373 194.606c3.574 3.234 6.896 6.215 10.504 9.453-7.468 7.251-15.159 11.884-24.142 13.407-32.247 5.468-56.548-18.481-55.557-47.548.387-11.359 4.67-21.483 12.629-29.607 15.962-16.296 32.22-32.303 48.369-48.417.338-.339.826-.528.684-.44l16.775 16.559c-3.033 2.938-6.635 6.346-10.146 9.845-12.384 12.344-24.717 24.74-37.111 37.074-6.685 6.652-9.337 14.47-6.65 23.635 2.772 9.455 9.54 14.962 19.06 15.909 4.498.448 10.307-.319 13.638-2.935 4.9-3.847 7.252-1.921 10.278 1.403.448.492.944.94 1.67 1.662z"></path>
        <path d="M77.727 28.186c14.454 1.202 25.364 7.976 34.945 17.981 14.286 14.919 29.087 29.343 44.125 44.435l-18.108 15.651c-6.519-6.7-14.129-14.61-21.839-22.421-7.961-8.065-15.988-16.065-24.04-24.038-9.781-9.684-22.936-10.628-32.32-2.393-9.343 8.199-10.27 20.938-2.329 31.892.286.394.475.857.47.846l-16.087 16.049c-10.666-6.677-16.366-28.497-11.32-45.693 5.642-19.223 24.352-32.355 46.503-32.308zM158.551 189.537c10.345 7.298 21.647 6.896 29.962-.825 8.904-8.268 9.263-19.183.678-33.631 4.614-4.439 9.422-8.969 14.108-13.621 1.957-1.943 3.308-1.485 4.882.448 11.771 14.453 14.545 30.717 7.516 47.691-7.263 17.543-20.92 27.343-40.365 28.442-14.84.839-26.76-4.774-36.982-15.223-14.199-14.514-28.682-28.75-43.034-43.114-1.157-1.157-2.245-2.383-4.292-4.565 5.221-4.875 10.173-9.726 15.468-14.167.703-.59 3.624.422 4.72 1.502 13.993 13.776 27.839 27.701 41.727 41.584a439.65 439.65 0 005.612 5.479z"></path>
      </g>
      <g fill="#051532" clipPath="url(#clip1_1271_3246)">
        <path d="M571.011 54.997h15.056l2.876 22.665c3.278-3.974 5.957-7.745 9.158-11.003 9.502-9.673 21.261-14.032 34.732-14.727 18.052-.931 34.327 3.474 48.074 15.412 14.644 12.716 22.389 28.89 23.122 48.391.536 14.279-.382 28.285-7.296 41.184-11.749 21.921-30.304 33.168-55.008 33.97-7.251.235-14.756.017-21.776-1.598-11.987-2.758-20.728-5.754-31.711-23.566v80.068h-28.8V54.997h11.573zm30.758 37.294c-12.946 15.925-14.184 37.921-3.028 53.795 11.499 16.362 33.764 22.217 52.63 13.838 18.329-8.14 27.903-28.239 23.123-49.12-3.44-15.025-12.584-25.374-27.416-29.572-16.633-4.708-32.001-1.98-45.309 11.059z"></path>
        <path d="M571.011 54.997h15.056l2.876 22.665c3.278-3.974 5.957-7.745 9.158-11.003 9.502-9.673 21.261-14.032 34.732-14.727 18.052-.931 34.327 3.474 48.074 15.412 14.644 12.716 22.389 28.89 23.122 48.391.536 14.279-.382 28.285-7.296 41.184-11.749 21.921-30.304 33.168-55.008 33.97-7.251.235-14.756.017-21.776-1.598-11.987-2.758-20.728-5.754-31.711-23.566v80.068h-28.8V54.997h11.573zm30.758 37.294c-12.946 15.925-14.184 37.921-3.028 53.795 11.499 16.362 33.764 22.217 52.63 13.838 18.329-8.14 27.903-28.239 23.123-49.12-3.44-15.025-12.584-25.374-27.416-29.572-16.633-4.708-32.001-1.98-45.309 11.059zM724.5 87.553c10.4-21.619 27.965-32.267 50.761-35.589 15.378-2.24 30.17-.775 42.795 8.732 6.166 4.643 10.755 11.38 15.903 17.002l1.491-22.638h27.196v132.625h-26.682l-2.342-26.373c-2.781 4.766-4.794 9.108-7.601 12.855-8.159 10.894-19.949 16.038-32.848 17.44-21.716 2.361-41.67-2.503-57.526-18.694-11.048-11.28-15.988-25.517-17.392-40.827-1.383-15.082-.231-30.061 6.245-44.533zm45.875-6.805c-28.85 13.498-29.651 54.079-12.079 72.291 15.835 16.411 44.592 17.768 61.232 2.624 17.464-15.894 19.313-47.507 3.799-64.941-12.565-14.12-32.356-18.037-52.952-9.974z"></path>
        <path d="M724.5 87.553c10.4-21.619 27.965-32.267 50.761-35.589 15.378-2.24 30.17-.775 42.795 8.732 6.166 4.643 10.755 11.38 15.903 17.002l1.491-22.638h27.196v132.625h-26.682l-2.342-26.373c-2.781 4.766-4.794 9.108-7.601 12.855-8.159 10.894-19.949 16.038-32.848 17.44-21.716 2.361-41.67-2.503-57.526-18.694-11.048-11.28-15.988-25.517-17.392-40.827-1.383-15.082-.231-30.061 6.245-44.533zm45.875-6.805c-28.85 13.498-29.651 54.079-12.079 72.291 15.835 16.411 44.592 17.768 61.232 2.624 17.464-15.894 19.313-47.507 3.799-64.941-12.565-14.12-32.356-18.037-52.952-9.974zM337.245 51.138c20.125-.457 38.026 4.403 52.637 18.167 13.4 12.623 20.431 28.222 21.19 46.774.693 16.917-2.054 32.826-12.23 46.756-12.632 17.293-29.8 26.232-51.203 27.189-13.768.615-27.171-.52-39.664-7.043-19.796-10.335-30.946-27.031-34.611-48.605-3.696-21.752.526-41.998 14.73-59.385 12.503-15.305 29.357-22.21 49.151-23.853zm-21.31 104.465c21.072 16.594 51.082 9.877 61.902-14.154 5.876-13.051 6.093-26.536.251-39.73-6.084-13.74-16.333-22.677-31.595-24.425-15.011-1.719-27.622 3.194-36.683 15.655-13.553 18.638-11.007 46.12 6.125 62.654zM416.404 180.42c13.148-18.872 25.978-37.527 39.063-56 2.163-3.053 2.327-4.991.146-8.143-12.693-18.34-25.12-36.862-37.624-55.332-1.098-1.621-2.107-3.303-3.788-5.948 6.391 0 11.909-.044 17.427.012 5.807.058 12.712-1.688 17.112.857 4.398 2.544 6.337 9.394 9.271 14.405 6.472 11.055 12.891 22.14 19.724 33.886 3.27-5.525 6.366-10.685 9.392-15.886 5.773-9.92 11.609-19.808 17.189-29.838 1.502-2.7 3.211-3.578 6.252-3.514 9.992.208 19.99.078 31.022.078l-44.217 65.336 47.107 67.67c-8.589 0-16.517-.649-24.288.178-8.206.872-12.795-2.191-16.885-9.169-7.808-13.319-16.648-26.032-25.417-39.546-1.169 1.682-2.088 2.932-2.933 4.23-8.896 13.672-17.845 27.309-26.604 41.067-1.522 2.389-3.08 3.364-5.949 3.311-9.992-.184-19.989-.071-30.934-.071 1.784-2.772 3.242-5.039 4.934-7.583z"></path>
        <path d="M416.404 180.42c13.148-18.872 25.978-37.527 39.063-56 2.163-3.053 2.327-4.991.146-8.143-12.693-18.34-25.12-36.862-37.624-55.332-1.098-1.621-2.107-3.303-3.788-5.948 6.391 0 11.909-.044 17.427.012 5.807.058 12.712-1.688 17.112.857 4.398 2.544 6.337 9.394 9.271 14.405 6.472 11.055 12.891 22.14 19.724 33.886 3.27-5.525 6.366-10.685 9.392-15.886 5.773-9.92 11.609-19.808 17.189-29.838 1.502-2.7 3.211-3.578 6.252-3.514 9.992.208 19.99.078 31.022.078l-44.217 65.336 47.107 67.67c-8.589 0-16.517-.649-24.288.178-8.206.872-12.795-2.191-16.885-9.169-7.808-13.319-16.648-26.032-25.417-39.546-1.169 1.682-2.088 2.932-2.933 4.23-8.896 13.672-17.845 27.309-26.604 41.067-1.522 2.389-3.08 3.364-5.949 3.311-9.992-.184-19.989-.071-30.934-.071 1.784-2.772 3.242-5.039 4.934-7.583zM1016.11 66.185c-25.381 58.981-50.643 117.596-75.801 176.257-1.149 2.68-2.442 3.732-5.422 3.639-7.48-.234-14.972-.077-23.136-.077.48-1.715.722-3.093 1.239-4.357a61072.465 61072.465 0 0132.081-78.206c14.27-34.725 28.607-69.423 42.753-104.198 1.333-3.277 2.9-4.456 6.458-4.34 8.758.285 17.528.093 26.668.093-1.57 3.61-3.14 7.22-4.84 11.19z"></path>
        <path d="M1016.11 66.185c-25.381 58.981-50.643 117.596-75.801 176.257-1.149 2.68-2.442 3.732-5.422 3.639-7.48-.234-14.972-.077-23.136-.077.48-1.715.722-3.093 1.239-4.357a61072.465 61072.465 0 0132.081-78.206c14.27-34.725 28.607-69.423 42.753-104.198 1.333-3.277 2.9-4.456 6.458-4.34 8.758.285 17.528.093 26.668.093-1.57 3.61-3.14 7.22-4.84 11.19zM899.412 109.638a21276.07 21276.07 0 01-21.335-52.985c-.111-.276.024-.65.085-1.651 10.498 0 21.044-.058 31.585.113.827.013 2.024 1.485 2.384 2.512 9.832 28.05 19.582 56.128 29.307 84.216.307.886.55 2.059.227 2.858-4.477 11.053-9.057 22.064-14.052 34.148a60506.803 60506.803 0 00-28.201-69.211z"></path>
        <path d="M899.412 109.638a21276.07 21276.07 0 01-21.335-52.985c-.111-.276.024-.65.085-1.651 10.498 0 21.044-.058 31.585.113.827.013 2.024 1.485 2.384 2.512 9.832 28.05 19.582 56.128 29.307 84.216.307.886.55 2.059.227 2.858-4.477 11.053-9.057 22.064-14.052 34.148a60506.803 60506.803 0 00-28.201-69.211z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1271_3246">
          <path
            fill="#fff"
            d="M0 0H222V230H0z"
            transform="translate(14 5)"
          ></path>
        </clipPath>
        <clipPath id="clip1_1271_3246">
          <path
            fill="#fff"
            d="M0 0H767V222H0z"
            transform="translate(263 37)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
