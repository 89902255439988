import React from 'react';

import { Link } from 'react-router-dom';

export function ButtonMenu({ children }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <Link to="/menu/profile">{children}</Link>
    </div>
  );
}
