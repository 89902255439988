import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import { apiGet } from '../services/api';
import { formatMovements } from '../utils/formatMovements';

import { useUser } from './useUser';

export function useSearch() {
  const { setError, setLoading, searchDocument, setSearchDocument, loading } =
    useContext(StoreContext);

  const { userLogout } = useUser();

  async function fetchSearchDocument(document) {
    if (document.length === 0) return setSearchDocument([]);
    setLoading({ search: true });
    try {
      const { data } = await apiGet(`/movement?document=${document}`); // fetchOrderKey(id);

      const filter = data
        .filter((item) => item.status === '06')
        .filter((item) => item.method !== '09')
        .reverse();

      const movements = formatMovements(filter);

      setSearchDocument(movements);
    } catch (error) {
      if (error?.response) {
        const { data, status } = await error.response;
        const { errors } = await data;
        const [listErros] = [errors].map(function (obj) {
          return Object.keys(obj);
        });

        const responseErros = listErros.map((item) => {
          const stringItem = item.toString().replace(/[^0-9]/g, '');
          const parseItem = parseInt(stringItem);
          return parseItem + 3;
        });

        if (status === 400 && !isNaN(responseErros[0])) {
          setError({
            order: 'Arquivo inválido nas linhas ' + responseErros,
          });
        } else {
          setError({ order: 'Documento inválido' });
        }
      }
      const { status } = await error.response;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    fetchSearchDocument,
    searchDocument,
    setSearchDocument,
    loading,
  };
}
