import * as yup from 'yup';

export const ValidateSelect = yup.object().shape({
  type: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .test('is-type', 'Selecione um tipo de contrato ', (value) => {
      return value !== '0';
    })
    .required('document é obrigatório'),
  is_contract_required: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .required('document é obrigatório'),
});
export const ValidateNoContractSelect = yup.object().shape({
  is_contract_required: yup
    .string()
    .typeError('O campo deve ser selecionado.')
    .required('document é obrigatório'),
});
