import React from 'react';
import { useState } from 'react';
import { IconDownload } from '../../../../components/Details/Icons';
import ExportExcel from '../ExportExcel';
import ExportExcelErrors from '../ExportExcelErrors';
import styles from './styles.module.scss';

export function ButtonMenuDownload({ children, list, error }) {
  const [active, setActive] = useState(false);

  const templates =
    error > 0
      ? [
          {
            id: 1,
            title: 'Relatório de pagamento',
            Component: ({ title }) => (
              <ExportExcel movements={list} title={title} />
            ),
          },
          {
            id: 2,
            title: 'Modelo de erros',
            Component: ({ title }) => (
              <ExportExcelErrors movements={list} title={title} />
            ),
          },
        ]
      : [
          {
            id: 1,
            title: 'Relatório de pagamento',
            Component: ({ title }) => (
              <ExportExcel movements={list} title={title} />
            ),
          },
        ];

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      <IconDownload color="var(--primary)" size={'2rem'} />
      {active && (
        <ul className={styles.menu}>
          {templates?.map(({ id, title, Component }) => (
            <Component key={id} title={title} />
          ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
