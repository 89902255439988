import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { validateCNPJ, validateCPF } from 'validations-br';
import { useUser } from '../../../../hooks/useUser';
export const InputSearch = ({
  label,
  type,
  setValue,
  value,
  Icon,
  filter,
  width = 'auto',
  disabled = false,
}) => {
  const history = useHistory();
  const { user } = useUser();
  const permission = [15].every((item) => user?.permission.includes(item));
  const handleSearchePageOrder = () => {
    const clearValue = value && value?.replace(/[^0-9]/g, '');
    if ((validateCPF(clearValue) || validateCNPJ(clearValue)) && permission) {
      history.push({
        pathname: `/menu/search/${value}`,
        state: {
          value: value,
        },
      });
    }
  };
  useEffect(() => {
    filter && filter(0);

    handleSearchePageOrder();
    // eslint-disable-next-line
  }, [value]);
  return (
    <div className={styles.wrapper__input} style={{ width }}>
      <input
        disabled={disabled}
        type={type}
        onChange={({ target }) => setValue(target.value)}
      />
      <div className={!!value ? `${styles.isValue}` : `${styles.label}`}>
        {label}
      </div>
      {Icon && <Icon className={styles.icon} size="1.125rem" />}
    </div>
  );
};
