import { usePayment } from '../../../../../../hooks/usePayment';
import styles from './styles.module.scss';

export function IsModal() {
  const { handleCloseModal, handleCloseIsModal, showModal } = usePayment();
  return (
    <div
      style={{ visibility: showModal ? 'visible' : 'hidden' }}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <h2>Atenção</h2>
        <p>
          Ao assinalar a opção de “Não descontar a antecipação”, você está
          ciente de que eventuais débitos por antecipação não quitados pelo
          colaborador serão cobrados diretamente da empresa.
          <br />
          <br />
          Deseja prosseguir mesmo assim?
        </p>
        <div>
          <button className={styles.cancel} onClick={handleCloseModal}>
            Cancelar
          </button>
          <button className={styles.button} onClick={handleCloseIsModal}>
            Desejo prosseguir
          </button>
        </div>
      </div>
    </div>
  );
}
