import React, { useState, useEffect } from 'react';

import styles from './styles.module.scss';
import { IconMoon, IconPen, IconSun } from './icons';
import { useBalance } from '../../hooks/useBalance';
import { apiPut } from '../../services/api';
import { formatMoney } from '../../utils/formatMoney';
import { useUser } from '../../hooks/useUser';
import { Loader } from 'rsuite';

export const Limits = () => {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [newAmount, setNewAmount] = useState('');
  const { fetchGetLimits, limits } = useBalance();
  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (limits) {
          const products = [
            { idTransferLimitProduct: 1, name: 'Limite PIX' },
            { idTransferLimitProduct: 2, name: 'Limite TED' },
            { idTransferLimitProduct: 3, name: 'Limite Boleto' },
          ];

          const periods = [
            {
              idTransferLimitPeriod: 1,
              name: 'dia (6h às 20h)',
              Icon: <IconSun />,
            },
            {
              idTransferLimitPeriod: 2,
              name: 'noite  (20h às 6h)',
              Icon: <IconMoon />,
            },
          ];

          setData(limits);
          setProducts(products);
          setPeriods(periods);
        } else {
          fetchGetLimits();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [limits]);

  const productMap = products.reduce((acc, product) => {
    acc[product.idTransferLimitProduct] = product.name;
    return acc;
  }, {});

  const periodMap = periods.reduce((acc, period) => {
    acc[period.idTransferLimitPeriod] = period.name;
    return acc;
  }, {});

  const handleEdit = (item) => {
    setCurrentEdit(item);
    setNewAmount(item.amount);
  };

  const handleCancel = () => {
    setCurrentEdit(null);
    setNewAmount('');
  };

  const handleSave = async () => {
    if (currentEdit && newAmount) {
      setIsUpdating(currentEdit);
      setIsLoading(true);
      try {
        await apiPut(
          `/Company/transfer/limits/${currentEdit.product}/${currentEdit.period}/${newAmount}`,
        );

        fetchGetLimits();
        setNewAmount('');
      } catch (error) {
        console.error('Error updating data:', error);
        setIsUpdating(false);
      } finally {
        setIsUpdating(false);
        setIsLoading(false);
        setCurrentEdit(false);
      }
    }
  };

  return (
    <div className={styles.container_box}>
      <div className={styles.header_style}>
        <h1 className={styles.title_style}>Meus Limites</h1>
      </div>
      <div className={styles.cards}>
        {data.map((item, index) => (
          <>
            {index === 0 && <h2 className={styles.title}>Diurno </h2>}
            {![1, 4].includes(index) && (
              <div
                className={styles.card}
                key={`${item.product}-${item.period}`}
              >
                <span>
                  {productMap[item.product]} {periodMap[item.period]}
                </span>
                <div className={styles.amount}>
                  {item?.period === 1 ? (
                    <IconSun size="1.3rem" />
                  ) : (
                    <IconMoon size="1rem" />
                  )}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {currentEdit &&
                      currentEdit.product === item.product &&
                      currentEdit.period === item.period ? (
                        <input
                          type="number"
                          value={newAmount}
                          onChange={(e) => setNewAmount(e.target.value)}
                          disabled={isUpdating}
                        />
                      ) : isUpdating &&
                        isUpdating.product === item.product &&
                        isUpdating.period === item.period ? (
                        <span>carregando</span>
                      ) : (
                        <span>{formatMoney(item.amount)}</span>
                      )}
                    </>
                  )}
                </div>
                {item?.scheduled_at && (
                  <span className={styles.scheduled}>
                    {`Esperando aprovação do novo limite  de ${formatMoney(
                      item?.scheduled_amount,
                    )}`}
                  </span>
                )}
                <span></span>
                <div className={styles.action}>
                  {!item?.scheduled_at && (
                    <>
                      {currentEdit &&
                      currentEdit.product === item.product &&
                      currentEdit.period === item.period ? (
                        <>
                          <button
                            className={styles.confirm}
                            onClick={handleSave}
                            disabled={isUpdating}
                          >
                            Confirmar
                          </button>
                          <button
                            className={styles.cancel}
                            onClick={handleCancel}
                            disabled={isUpdating}
                          >
                            Cancelar
                          </button>
                        </>
                      ) : (
                        <>
                          {[59].every((item) =>
                            user?.permission.includes(item),
                          ) && (
                            <button
                              className={styles.edit}
                              onClick={() => handleEdit(item)}
                              disabled={isLoading}
                            >
                              <IconPen />
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {index === 2 && <h2 className={styles.title}>Noturno </h2>}
          </>
        ))}
      </div>
    </div>
  );
};
