import { useContext } from 'react';
import { CustomersContext } from '../data/customersContext';
import { StoreContext } from '../data/storeContext';

import {
  apiFormDataPutCustomers,
  apiGet,
  apiPost,
  apiPut,
  apiPutEditUser,
  apiOptions,
} from '../services/api';
import { fireStoreAdd } from '../utils/firestoreActions';
import { formatCustomers } from '../utils/formatCustomers';
import { useUser } from './useUser';

export function useCustomers() {
  const {
    customers,
    setCustomers,
    allCapabilities,
    setAllCapabilities,
    allProfiles,
    setAllProfiles,

    loading,
    setLoading,

    isNewCustomer,
    setIsNewCustomer,
    //edit form
    edit,
    setEdit,
    //collaborator
    usersCollaborator,
    setUsersCollaborator,

    isNewCustomerUpload,
    setIsNewCustomerUpload,
  } = useContext(CustomersContext);
  const { userLogout, user } = useUser();
  const { setError, error, setMessage, message } = useContext(StoreContext);
  async function fetchUploadCustomers(body, company) {
    try {
      setLoading({ upload: true });
      setError(false);
      const { data } = await apiFormDataPutCustomers(
        `/customer/upload`,
        body,
        company,
      );
      if (data) {
        fetchCustomers(company);
        setMessage({ customers: 'Inclusões ocorridas com exito' });
        await fireStoreAdd(
          {
            action: 'UPLOAD_CUSTOMERS',
            date: new Date(),
          },
          user,
        );

        setIsNewCustomerUpload(false);
      }
    } catch (error) {
      const { status } = await error.response;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
      if (status === 400) {
        setError({ order: 'Documento inválido, Revise os campos' });
      }
      if (status === 409) {
        setError({
          order: 'Documento Apresenta erros de formatação, Revise os campos',
        });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchCustomers() {
    try {
      setLoading({ customers: true, add: false });

      setCustomers([]);

      const { data } = await apiGet('/customer/list');
      const { data: profiles } = await apiOptions('/Customer/Profiles');

      const isCustomers = data
        .reverse()
        .map((item) => {
          return formatCustomers(item);
        })
        .map((customer) => {
          return {
            ...customer,
            name_profile: customer.profile
              ? profiles.find((profile) => profile.id === customer.profile)
                  ?.name
              : 'Indefinido',
          };
        });

      setCustomers(isCustomers);
    } catch (error) {
      const err = await error.response;
      if (err?.status === 401) {
        setError({ login: 'Você não está autenticado' });
        userLogout();
      }
      if (err?.status === 403) {
        setError({ login: 'Usuário sem autorização' });
        setCustomers([]);
      }
      if (err?.status === 500) {
        setError({ login: 'Error servidor' });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }
  async function fetchAddCustomers(body) {
    let dataUserExist;
    try {
      setLoading({ customers: false, add: true });

      if (body?.edit) {
        const { data } = await apiGet(
          `/Customer/document/${body.document.replace(/[^\d]/g, '')}`,
        );
        dataUserExist = await data;
        if (dataUserExist) {
          if (
            dataUserExist.additional_company_ids.includes(body.company) ||
            body.company === dataUserExist.company_id
          ) {
            const { data } = await apiPutEditUser(
              `/customer`,
              {
                is_active: true,
                first_name: body.first_name,
                last_name: body.last_name,
                document: body.document,
                email: body.email,
                telephone: body.telephone,
              },
              dataUserExist?.company_id
                ? dataUserExist?.company_id
                : body.company,
            );
            if (!!data) {
              body.edit
                ? setMessage({
                    login: `${data?.first_name}, editado com sucesso`,
                  })
                : setMessage({
                    login: `${data?.first_name}, cadastrado com sucesso`,
                  });
              setIsNewCustomer(false);
            }

            return data;
          } else {
            const { data } = await apiPutEditUser(
              `/customer`,
              {
                is_active: true,
                first_name: dataUserExist.first_name,
                last_name: dataUserExist.last_name,
                document: dataUserExist.document,
                email: dataUserExist.email,
                telephone: dataUserExist.telephone,
                profile: dataUserExist?.profile,
              },
              dataUserExist.company,
            );

            if (!!data) {
              body.edit
                ? setMessage({
                    login: `${data?.first_name}, editado com sucesso`,
                  })
                : setMessage({
                    login: `${data?.first_name}, cadastrado com sucesso`,
                  });
              setIsNewCustomer(false);
            }

            return data;
          }
        }
      }
      const { data: dataUserExistUser } = await apiGet(
        `/Customer/document/${body.document.replace(/[^\d]/g, '')}`,
      );
      dataUserExist = await dataUserExistUser;

      if (dataUserExist) {
        const { data } = await apiPut(`/customer`, {
          is_active: true,
          first_name: dataUserExist.first_name,
          last_name: dataUserExist.last_name,
          document: dataUserExist.document,
          email: dataUserExist.email,
          telephone: dataUserExist.telephone,
        });

        setMessage({
          login: `${data?.first_name}, cadastrado com sucesso`,
        });
        setIsNewCustomer(false);

        return data;
      } else {
        const { data } = await apiPutEditUser(
          `/customer`,
          {
            is_active: true,
            first_name: body.first_name,
            last_name: body.last_name,
            document: body.document,
            email: body.email,
            telephone: body.telephone,
            profile: !body?.edit ? body?.profile ?? 11 : null,
          },
          dataUserExist?.company_id ? dataUserExist?.company_id : body.company,
        );
        if (!!data) {
          body.edit
            ? setMessage({ login: `${data?.first_name}, editado com sucesso` })
            : setMessage({
                login: `${data?.first_name}, cadastrado com sucesso`,
              });
          setIsNewCustomer(false);
        }
        await fireStoreAdd(
          {
            action: 'ADD_CUSTOMER',
            userMutationId: data.customer_id,
            userMutationName: `${data.first_name}${data.last_name}`,
            date: new Date(),
          },
          user,
        );

        return data;
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
    } finally {
      setLoading({ customers: false, add: false });
      await fetchCustomers(body.company);
    }
  }
  async function fetchEditCustomers(body) {
    try {
      let user;
      setLoading({ customers: false, add: true, id: body.document });

      const { data: dataUserExist } = await apiGet(
        `/Customer/document/${body.document.replace(/[^\d]/g, '')}`,
      );

      if (dataUserExist) {
        const { data } = await apiPut(`/customer`, {
          is_active: body.is_active,
          first_name: body.first_name,
          last_name: body.last_name,
          document: body.document,
          email: body.email,
          telephone: body.telephone,
        });

        data && setIsNewCustomer(false);

        user = data;
      } else {
        const { data } = await apiPut(`/customer`, {
          is_active: body.is_active,
          first_name: body.first_name,
          last_name: body.last_name,
          document: body.document,
          email: body.email,
          telephone: body.telephone,
        });

        data && setIsNewCustomer(false);
        user = data;
      }

      setMessage({ login: `${user?.first_name}, editado com sucesso` });
      fetchCustomers();
    } catch (error) {
      setError({ login: 'Não foi possível editar ' });
    } finally {
      setLoading(false);
    }
  }

  async function fetchPasswordReset(body) {
    try {
      setLoading({ login: true });

      const { data } = await apiPost(`/Customer/Password`, {
        current_password: body.current_password,
        new_password: body.new_password,
      });

      data && setMessage({ login: `${data?.first_name}, editado com sucesso` });
    } catch (error) {
      setError({
        login: `Não foi possível Alterar senha de ${body?.first_name}`,
      });
    } finally {
      setLoading({ login: true });
    }
  }

  async function fetchCapabilities() {
    try {
      setLoading({ customers: true });

      const { data } = await apiOptions('/Customer/Capabilities');
      setAllCapabilities(data);
    } catch (error) {
      setError({ order: 'Não foi possível obter as opções de Permissões.' });
    } finally {
      setLoading({ customers: true });
    }
  }

  async function fetchProfiles() {
    try {
      setLoading({ customers: true });

      const { data } = await apiOptions('/Customer/Profiles');

      setAllProfiles(data);
    } catch (error) {
      setError({ order: 'Não foi possível obter as opções de Perfis.' });
    } finally {
      setLoading({ customers: true });
    }
  }

  async function fetchEditProfile(customer_id, profile, company_id) {
    try {
      setLoading({ customers: false, add: true });

      const { data } = await apiPost('/Customer/Profile', {
        customer_id,
        profile,
      });

      fetchCustomers(company_id);
      !data?.error &&
        data?.value &&
        setMessage({ login: 'Perfil alterado com sucesso!' });
      if (data?.error) {
        setError({
          order:
            data?.error?.message === 'Unauthorized'
              ? 'Não autorizado.'
              : 'Não foi possível alterar o perfil, tente novamente.',
        });
      }
    } catch (error) {
      const { status } = await error.response;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      } else {
        setError({
          order: 'Não foi possível alterar o perfil, tente novamente.',
        });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }

  async function fetchEditCapabilities(
    customer_id,
    capabilities,
    company_id,
    action,
  ) {
    try {
      setLoading({ customers: false, add: true });

      const { data } = await apiPost(`/Customer/Capabilities/${action}`, {
        customer_id,
        capabilities,
      });

      fetchCustomers(company_id);
      if (!data?.error && data?.value) {
        setMessage({ login: 'Permissões alteradas com sucesso!' });
      } else {
        setError({
          order:
            data?.error?.message === 'Unauthorized'
              ? 'Não autorizado.'
              : 'Não foi possível alterar os permissões, tente novamente.',
        });
      }
    } catch (error) {
      const { status } = await error.response;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      } else {
        setError({
          order: 'Não foi possível alterar as Permissões, tente novamente.',
        });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }

  return {
    loading,
    setLoading,

    error,
    setError,

    customers,
    setCustomers,

    message,
    setMessage,

    allCapabilities,
    allProfiles,

    fetchCustomers,
    fetchAddCustomers,
    fetchEditCustomers,
    fetchUploadCustomers,
    fetchCapabilities,
    fetchProfiles,
    fetchEditProfile,
    fetchEditCapabilities,

    isNewCustomer,
    setIsNewCustomer,
    //edit form
    edit,
    setEdit,

    isNewCustomerUpload,
    setIsNewCustomerUpload,
    fetchPasswordReset,
    //collaborator
    usersCollaborator,
    setUsersCollaborator,
  };
}
