import { IconFinally } from './icon/index';
import styles from '../styles/styles.module.scss';
import { useFormData } from '../../../hooks/useFormData';
import { useForm } from 'react-hook-form';
import { BiMenu, BiPlus } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
export default function FormCompleted({ clearFormSteps }) {
  const { setClearedForm } = useFormData();
  const history = useHistory();
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    clearFormSteps();
    setClearedForm();
  };
  return (
    <div className={styles.showFormComplete}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.step_three}>
          <IconFinally />
          <header>
            <h2>Cadastro concluído com Sucesso!</h2>
            <p>
              Colaborador deverá acessar o App para concluir as próximas etapas
              do cadastro.
            </p>
          </header>
        </div>
        <div className={styles.next_button}>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            <BiMenu color="var(--white)" size={20} />
            Ver Lista de Colaboradores
          </button>
          <button type="submit">
            <BiPlus color="var(--white)" size={20} />
            Novo Colaborador
          </button>
        </div>
      </form>
    </div>
  );
}
